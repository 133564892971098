import React from 'react';
import { Col } from 'reactstrap';
import { userRoles } from '../../constants/users';
import { UserType } from '../../interfaces/Auth';
import Product from '../../interfaces/Product';
import ProductCard from '../Cards/ProductCard';

interface CatalogListProps {
  products: Product[];
  userType: UserType;
  sidebarCollapsed: boolean;
  filtersCollapsed: boolean;
}

const CatalogList: React.FC<CatalogListProps> = ({ products, userType, filtersCollapsed, sidebarCollapsed }) => {
  const mdColSize = React.useMemo(() => {
    if (userType === userRoles.CONSUMER) {
      return 3;
    } else {
      const x = [filtersCollapsed, sidebarCollapsed].filter((v) => v === true).length;
      return 4 - x;
    }

  }, [userType, filtersCollapsed, sidebarCollapsed]);

  return (
    <React.Fragment>
      {products.map((product: Product, index: number) => (
        <Col md={mdColSize} xs={6} key={index}>
          <ProductCard product={product} />
        </Col>
      ))}
    </React.Fragment>
  )
}
export default CatalogList;