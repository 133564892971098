import React from 'react'
import { Input } from 'reactstrap';

interface PropTypes {
	position: number;
	length: number;
	onSelect: (index: number) => void
}
const GalleryIndicators: React.FC<PropTypes> = ({ position, length, onSelect }) => {

	const indexArr = React.useMemo(() => Array(length).fill(null), [length]);

	if (length === 0) return null;

	return (
		<div className="w-100 d-flex flex-wrap mt-1 p-2 justify-content-center">
			{indexArr.map((_, index: number) => (
				<Input
					key={index}
					type="radio"
					color='secondary'
					name="product-card-image"
					className="mx-1 bg-secondary border-secondary shadow-none p-1"
					checked={position === index}
					onChange={() => onSelect(index)}
				/>
			))}
		</div>
	)
}
export default GalleryIndicators;