import React from 'react';
import useCatalog from '../../../hooks/useCatalog';
import useAppNavigation from '../../../hooks/useAppNavigation';
import { useAppSelector } from '../../../store';
import { Category } from '../../../interfaces/Category';
import './categoryFilter.scss';
import CategoryItem from './CategoryItem';
import CategoryList from './CategoryList';
import classnames from 'classnames';

interface PropTypes {
  children?: React.ReactNode;
  categories: Category[];
}

const CategoryFilter: React.FC<PropTypes> = ({ categories }) => {
  const searchParams = useAppSelector((state) => state.filters.searchParams);
  const { retrieveCatalog } = useCatalog();
  const { returnToMeeting } = useAppNavigation();

  const handleCategory = React.useCallback((categoryId: string): void => {
    if (searchParams.categories.includes(categoryId)) return;
    retrieveCatalog({ categories: [categoryId] });
    returnToMeeting();
  }, [searchParams, retrieveCatalog, returnToMeeting]);

  return (
    <CategoryList className="primary-category">
      {categories.map((category) => (
        <CategoryItem category={category} searchParams={searchParams} handleSelectCategory={handleCategory} key={category.uid}>
          <h5 className={classnames("py-0", { "mb-3": parseInt(category.children_count) > 0, "mb-0": parseInt(category.children_count) === 0 })}>{category.name}</h5>
        </CategoryItem>
      ))}
    </CategoryList>
  )
}
export default CategoryFilter;