import React from 'react';
import { Badge } from 'reactstrap';
import { formatPrice } from '../../helpers/product';

interface PropTypes {
  amount_off: number;
  percent_off: number;
}
const CatalogDiscountBadge: React.FC<PropTypes> = ({ amount_off, percent_off }) => {

  const discountAmount = React.useMemo(() => {
    if (amount_off === 0) return;
    return percent_off % 25 === 0 ? `-${percent_off}%` : formatPrice(amount_off);
  }, [amount_off, percent_off])


  return (
    <Badge color={'danger'} className="position-absolute end-0 top-0" style={{ zIndex: 50 }}>
      {discountAmount}
    </Badge>
  )
}
export default CatalogDiscountBadge;