import React from 'react'
import { useAppDispatch, useAppSelector } from '../store';
import { likeProduct, unlikeProduct } from '../store/features/auth';


const useFavorite = () => {
  const { favorites, likedProductsLoading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const isFavorite = React.useCallback((sku: string) => {
    return favorites.some((item) =>
      item.baseSku === sku
    );
  }, [favorites]);


  const toggleFavorite = React.useCallback(async (sku: string) => {
    let prod = favorites.find((item) => item.baseSku === sku);
    if (prod) {
      await dispatch(unlikeProduct({ id: prod._id })).unwrap();

    } else {
      await dispatch(likeProduct({ baseSku: sku })).unwrap()
    };
  }, [favorites, dispatch]);

  return { isFavorite, toggleFavorite, likedProductsLoading }
}
export default useFavorite;