import React from "react";
import { useLocation } from "react-router-dom";
import { memoPaths } from "../../constants/routes";
import { userRoles } from "../../constants/users";
import { useAppSelector } from "../../store";
import LanguageDropdown from "../LanguageDropdown";
import Logo from "../Logo";
import ShareMeetingContainer from "../Share/MeetingContainer";
import NavCallInfo from "./NavCallInfo";
import NavSearch from "./Search";
import UserDropdown from "./UserDropdown";

// SALES-V2: Call in redux update
const Navbar: React.FC = () => {
  const userType = useAppSelector((state) => state.auth.userType);
  const catalogRightsToggled = useAppSelector((state) => state.appInterface.catalogRightsToggled);
  const dailyCallState = useAppSelector((state) => state.call.dailyMeetingState);
  const { pathname } = useLocation();

  const displayUserDropdownMenu = React.useMemo((): boolean => userType === userRoles.STYLIST && !pathname.includes(memoPaths.userEntry.stylist), [userType, pathname])

  return (
    <header id="page-topbar">
      <div className="navbar-header px-2">
        <div className="d-flex">
          <Logo className="ms-4" />
        </div>
        {userType === userRoles.STYLIST && dailyCallState === 'joined-meeting' &&
          <React.Fragment>
            <NavCallInfo />
            <NavSearch />
          </React.Fragment>}

        <div className="d-flex">
          {userType === userRoles.CONSUMER && catalogRightsToggled && <NavSearch />}
          {dailyCallState === "joined-meeting" && (
            <ShareMeetingContainer view="topbar" />
          )}
          <LanguageDropdown />
          {displayUserDropdownMenu && <UserDropdown />}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
