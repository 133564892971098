import classnames from 'classnames';
import React from 'react'
import { Category } from '../../../interfaces/Category';
import { CatalogSearchParams } from '../../../interfaces/Filters';
import CategoryList from './CategoryList';

interface PropTypes {
  children?: React.ReactNode;
  category: Category;
  searchParams: CatalogSearchParams;
  handleSelectCategory: (id: string) => void;
}

const CategoryItem: React.FC<PropTypes> = ({ category, searchParams, handleSelectCategory, children }) => {

  return (
    <li key={category.uid} className={classnames('mb-2 mt-0', { 'bg-light py-2 rounded-3': searchParams.categories.includes(category.uid) })}>
      <button onClick={() => handleSelectCategory(category.uid)} className="text-start border-0 bg-transparent">
        {children}
      </button>
      {category.children && <CategoryList className="secondary-category">
        {category.children.map((subCategory) => (
          <CategoryItem category={subCategory} handleSelectCategory={handleSelectCategory} searchParams={searchParams} key={subCategory.uid}>
            <i className='bx bx-chevron-right align-middle' />
            {subCategory.name}
          </CategoryItem>
        ))}
      </CategoryList>}
    </li>
  )
}
export default CategoryItem;
