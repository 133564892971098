import React from "react";
import { userRoles } from "../../constants/users";
import { formatPrice } from "../../helpers/product";
import { Look, LookDetailsView } from "../../interfaces/Look";
import { useAppDispatch, useAppSelector } from "../../store";
import { setLookDetailsView, setLooksRetrieved, setLookView, setSelectedLookId } from "../../store/features/looks";
import BackButton from "../Button/Back";
import ConfirmationModal from "../Modals/Confirmation";
import TabIcons from "./TabIcons";

const viewTabs: { key: LookDetailsView, icon: string }[] = [
  { key: "LOOK_PRODUCTS_LIST", icon: "bx bx-list-ul" },
  { key: "LOOK_PRODUCTS_COMPOSER", icon: "bx bxs-t-shirt" },
];
interface PropTypes {
  look: Look;
}

const LooksDetailsHeader: React.FC<PropTypes> = ({ look }) => {
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
  const { lookDetailsView, looksHasChanges } = useAppSelector((state) => state.looks);
  const userType = useAppSelector((state) => state.auth.userType);
  const dispatch = useAppDispatch();

  const handleBackAction = React.useCallback(() => {
    dispatch(setLooksRetrieved(false));
    dispatch(setLookView("LOOK_LIST"));
    dispatch(setSelectedLookId(undefined));
  }, [dispatch]);

  const handleViewToggle = React.useCallback((view: LookDetailsView) => {
    dispatch(setLookDetailsView(view));
  }, [dispatch]);

  const handleToggleConfirmationModal = React.useCallback(() => {
    if (looksHasChanges) setShowConfirmation((curr) => !curr)
    else handleBackAction();
  }, [looksHasChanges, handleBackAction]);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {userType === userRoles.STYLIST && <BackButton onClick={handleToggleConfirmationModal} />}

          <div className="ms-3">
            <span>{look.title || "-"}</span>
            <span className="font-size-12 fw-light ms-2">
              {formatPrice(look.total)}
            </span>
          </div>
        </div>
        <div>
          <TabIcons
            activeTab={lookDetailsView}
            tabs={viewTabs}
            onToggle={handleViewToggle}
          />
        </div>
      </div>
      <ConfirmationModal
        isShown={showConfirmation}
        title={"MODALS.LEAVE_UNSAVED_OUTFIT.HEADER"}
        message={"MODALS.LEAVE_UNSAVED_OUTFIT.SUBHEADER"}
        onCancel={handleToggleConfirmationModal}
        onSubmit={handleBackAction}
        submitBtnClass="btn-danger"
        submitText="GENERIC.BUTTONS.GO_BACK"
      />
    </React.Fragment>
  );
};

export default LooksDetailsHeader;
