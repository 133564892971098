import React from 'react'
import { Input, Label } from 'reactstrap';

interface PropTypes {
  options: {
    label: string;
    value: string;
    disabled: boolean;
    hex: string
  }[];
  label: string;
  onSelect: (e: any) => void
}

const ColorSelect: React.FC<PropTypes> = ({ options, label, onSelect }) => {
  return (
    <React.Fragment>
      <Label for={`${label}-select`} className="font-size-13">{label}</Label>
      <div className="px-0 mb-1">
        {options.map((option, index: number) => (
          <div className="d-inline me-1" key={index}>
            <Input
              title={option.label}
              type="radio"
              name={`attribute-${label}`}
              className="empty-radio shadow-none border-2 rounded-circle p-2"
              disabled={option.disabled}
              style={{ backgroundColor: `${option.hex}` }}
              onChange={() => onSelect(option.value)}
            />
          </div>))}
      </div>
    </React.Fragment>
  )
}
export default ColorSelect;