import classnames from "classnames";
import { t } from "i18next";
import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import Chat from "../components/Chat";
import CheckoutModal from "../components/Modals/CheckoutModal";
import EndCallConfirmationModal from "../components/Modals/EndCallConfirmationModal";
import MeetingNotesUpdateModal from "../components/Modals/MeetingNotesModal";
import PaymentStatusModal from "../components/Modals/PaymentStatusModal";
import { userRoles } from "../constants/users";
import useMqttMessage from "../hooks/mqtt/useMessage";
import useError from "../hooks/useError";
import { MQTT_END_CALL } from "../interfaces/Mqtt";
import { useScreen } from "../providers/ScreenSize";
import { useAppDispatch, useAppSelector } from "../store";
import { joinCall, leaveCall, participantsChange, setFatalError, setHardwareError } from "../store/features/call";
import { setEndCallModal, setPaymentStatusModal, toggleChatHighlight, toggleMeetingNotesModal, togglePaymentModal } from "../store/features/interface";
import { getMeeting } from "../store/features/meeting";
import MobileLayout from "./MobileLayout";
import TabletLayout from "./TabletLayout";
import { DailyEventObjectCameraError, DailyEventObjectFatalError, DailyEventObjectParticipant, DailyEventObjectParticipantLeft } from "@daily-co/daily-js";

// SALES-V2: Call in redux update
const CallLayout: React.FC = () => {
  const { paymentStatusModalToggled, meetingNotesModalToggled, isChatVisible, endCallConfirmationModalToggled, paymentModalToggled } = useAppSelector((state) => state.appInterface);
  const { dailyCallObject, dailyMeetingState } = useAppSelector((state) => state.call);
  const userType = useAppSelector((state) => state.auth.userType);
  const { callUrl } = useAppSelector((state) => state.meeting);
  const { paymentUrl, orderStatus } = useAppSelector((state) => state.order);
  const { isMobile, isDesktop } = useScreen();
  const sendMqttMessage = useMqttMessage();
  const dispatch = useAppDispatch();
  const { meetingId } = useParams();
  const { handleError } = useError();
  const navigate = useNavigate();

  const isChatHighlighted = React.useCallback(() => dispatch(toggleChatHighlight(true)), [dispatch]);

  const handleTogglePaymentModal = React.useCallback(() => dispatch(togglePaymentModal()), [dispatch]);

  const toggleStylistPaymentModal = () => {
    dispatch(setPaymentStatusModal(!paymentStatusModalToggled));
  }

  const toggleEndCallModal = () => {
    dispatch(setEndCallModal(!endCallConfirmationModalToggled));
  }

  const handleMeetingNotesModal = () => dispatch(toggleMeetingNotesModal());

  const confirmEndCall = () => {
    sendMqttMessage({ type: MQTT_END_CALL });
    dispatch(leaveCall()); // TODO: merge actions
    // setTimeout(() => {
    dispatch({ type: 'EndCall' });
    // }, 350)
  };

  const handleParticipantListener = React.useCallback((event?: DailyEventObjectParticipant | DailyEventObjectParticipantLeft) => {
    if (event?.action === 'participant-joined') toast.info(t('TOASTS.PARTICIPANT_JOIN'));
    if (event?.action === 'participant-left') toast.warning(t('TOASTS.PARTICIPANT_LEAVE'));
    dispatch(participantsChange());
  }, [dispatch]);

  const handleHardwareErrorListener = React.useCallback((event?: DailyEventObjectCameraError) => dispatch(setFatalError(event?.errorMsg.errorMsg || "Camera Error")), [dispatch]); // TODO: revisit

  const handleFatalErrorListener = React.useCallback((event?: DailyEventObjectFatalError) => {
    dispatch(setHardwareError(event?.errorMsg || "Fatal Error"));
    if (event?.errorMsg === 'Meeting has ended') navigate('/removed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  React.useEffect(() => {
    // console.log("onMount + initialize listeners");
    if (!dailyCallObject) return;
    dailyCallObject.on('participant-joined', handleParticipantListener);
    dailyCallObject.on('participant-left', handleParticipantListener);
    dailyCallObject.on('participant-updated', handleParticipantListener);
    dailyCallObject.on("camera-error", handleHardwareErrorListener);
    dailyCallObject.on("error", handleFatalErrorListener);

    return () => {
      // console.log("onUnmount + remove listeners");
      dailyCallObject.off('participant-joined', handleParticipantListener);
      dailyCallObject.off('participant-left', handleParticipantListener);
      dailyCallObject.off('participant-updated', handleParticipantListener);
      dailyCallObject.off("camera-error", handleHardwareErrorListener);
      dailyCallObject.off("error", handleFatalErrorListener);
    };
  }, [dailyCallObject, handleParticipantListener, handleHardwareErrorListener, handleFatalErrorListener]);

  React.useEffect(() => {
    const loadMeeting = async () => {
      await dispatch(getMeeting({
        meetingId: meetingId!
      })).unwrap()
        .then(() => {
          dispatch(joinCall());
        })
        .catch((error: any) => {
          toast.error(t("TOASTS.MEETING.RETRIEVE.ERROR"));
          handleError(error);
        });
    }

    if (!callUrl) {
      loadMeeting();
    } else if (callUrl) {
      dispatch(joinCall());
    } else {
      console.log('Something went wrong terribly wrong.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>

      <Container fluid={isDesktop ? 'xl' : 'sm'} className={classnames({ 'position-relative': !isMobile, 'h-100': isMobile })}>
        {isMobile && <MobileLayout>
          <Outlet />
        </MobileLayout>}
        {!isMobile && <TabletLayout>
          <Outlet />
        </TabletLayout>}

      </Container>

      {dailyMeetingState === 'joined-meeting' && (
        <React.Fragment>
          <Chat onClickDisplay={isChatVisible} notification={isChatHighlighted} />
          {userType === userRoles.CONSUMER && <React.Fragment>
            <CheckoutModal toggleModal={handleTogglePaymentModal} isOpen={paymentModalToggled} paymentUrl={paymentUrl} />
          </React.Fragment>}


          {userType === userRoles.STYLIST && <React.Fragment>
            <EndCallConfirmationModal toggleModal={toggleEndCallModal} isShown={endCallConfirmationModalToggled} confirm={confirmEndCall} />
            <PaymentStatusModal toggleModal={toggleStylistPaymentModal} isOpen={paymentStatusModalToggled} paymentUrl={paymentUrl} isComplete={orderStatus === 'paid'} />
            <MeetingNotesUpdateModal isOpen={meetingNotesModalToggled} toggleModal={handleMeetingNotesModal} />
          </React.Fragment>
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default CallLayout;
