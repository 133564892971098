import React from 'react';
import ReactSlider from "react-slider";
import { formatPrice } from "../../helpers/product";
import useCatalog from '../../hooks/useCatalog';
import { useAppSelector } from '../../store';
import { PriceFilter } from '../../interfaces/Filters';
import './pricefilter.scss';
interface PropTypes {
  price: PriceFilter
}

const PriceSelect: React.FC<PropTypes> = ({ price }) => {
  const searchParams = useAppSelector((state) => state.filters.searchParams);
  const { retrieveCatalog } = useCatalog();

  const handlePriceChange = React.useCallback((value: number[]) => {
    retrieveCatalog({ price: { from: Math.floor(value[0]), to: Math.floor(value[1]) } });
  }, [retrieveCatalog]);

  return (
    <React.Fragment>
      <ReactSlider
        className="price-filter-slider my-3"
        thumbClassName="price-filter-thumb"
        trackClassName="price-filter-track"
        // markClassName="price-filter-mark"
        defaultValue={[price.from, price.to]}
        value={[searchParams.price.from, searchParams.price.to]}
        min={price.from}
        max={price.to}
        // ariaLabel={['Lower thumb', 'Upper thumb']}
        // ariaValuetext={state => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div {...props}>
          <div className="price-filter-tooltip">
            {state.valueNow + ' \u20AC'}
          </div>
        </div>}
        pearling
        minDistance={0}
        onAfterChange={handlePriceChange}
      />
      <div className="my-2 d-flex font-size-13 w-100">
        <span>{formatPrice(searchParams.price.from)}</span>
        <span className="flex-fill text-center">-</span>
        <span>{formatPrice(searchParams.price.to)}</span>
      </div>
    </React.Fragment>
  )
}
export default PriceSelect;