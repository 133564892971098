import classnames from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import LoadingSpinner from '../../components/LoadingSpinner';
import UserProfileCard from '../../components/ProfileCard';
import { memoPaths } from '../../constants/routes';
import { userRoles } from '../../constants/users';
import useError from '../../hooks/useError';
import { useScreen } from '../../providers/ScreenSize';
import { useAppDispatch, useAppSelector } from '../../store';
import { setPathIndex, setShowroom } from '../../store/features/auth';
import { getMeeting } from '../../store/features/meeting';
import { contactEmail } from '../../common/appSettings';

const UserEntrypage: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { meetingId } = useParams();
  const { isMobile } = useScreen();
  const { handleError } = useError();
  const [error, setError] = React.useState<boolean>(false);
  const { mainCustomer, owner, meetingLoading, meetingId: existingMeetingId } = useAppSelector((state) => state.meeting);
  const { userType, authLoading } = useAppSelector((state) => state.auth);
  const redirectToShowRoom = React.useMemo(() => { return new URLSearchParams(search).get('showroom') }, [search]);
  const isLoading = React.useMemo((): boolean => authLoading || meetingLoading, [authLoading, meetingLoading]);
  const blockMobile = React.useMemo((): boolean => userType === userRoles.STYLIST && isMobile && !(redirectToShowRoom === 'true'), [userType, isMobile, redirectToShowRoom])

  const handleJoinCall = React.useCallback((): void => {
    const isShowroom: boolean = redirectToShowRoom === 'true';
    const path: string = userType === userRoles.STYLIST && isShowroom
      ? `${memoPaths.showroom}${meetingId}`
      : `${memoPaths.meeting}${meetingId}`;
    dispatch(setPathIndex(path));
    if (userType === userRoles.STYLIST) {
      dispatch(setShowroom(isShowroom));
    }
    navigate(path);
  }, [userType, meetingId, redirectToShowRoom, navigate, dispatch]);

  const LoadMeeting = React.useCallback(async (): Promise<void> => {
    await dispatch(getMeeting({
      meetingId: meetingId!
    })).unwrap()
      .catch((error: any) => {
        toast.error(t("TOASTS.MEETING.RETRIEVE.ERROR"));
        handleError(error);
        setError(true);
      });
  }, [dispatch, meetingId, handleError]);

  React.useEffect(() => {
    if (meetingId !== existingMeetingId) dispatch({ type: 'JoinCall' });
  }, [dispatch, meetingId, existingMeetingId]);

  React.useEffect(() => {
    LoadMeeting();
  }, [LoadMeeting]);

  const handleLogout = React.useCallback(() => {
    dispatch({ type: "Logout" })
  }, [dispatch]);

  return (
    <Container className={classnames("py-5 h-100", { "overflow-y-scroll": isMobile })}>
      <Row className="justify-content-center h-100">
        <Col xs={isMobile ? error ? 12 : 11 : error ? 4 : 8}>

          {blockMobile && <React.Fragment>
            <Card className="mt-2">
              <CardBody>
                <CardTitle>
                  {t("PAGES.USER_ENTRY.LABELS.APP_MOBILE_UNAVAILABLE")}
                </CardTitle>
                <p className="pt-1 mb-0">{t("PAGES.USER_ENTRY.LABELS.APP_NON_MOBILE")}</p>
              </CardBody>

            </Card>
            <Button onClick={handleLogout} color="primary" className="mt-5 d-flex mx-auto">Logout</Button>
          </React.Fragment>}

          {!blockMobile && <React.Fragment>
            {isLoading && <div className="position-absolute start-50 top-50 translate-middle">
              <LoadingSpinner className="text-primary text-center" size="lg" />
            </div>}

            {!isLoading && error && <Card className="mt-5 p-4 mx-auto border border-danger">
              <h2 className="">{t("PAGES.USER_ENTRY.ERROR.HEADER")}</h2>
              <p className="text-muted mb-0">{t("PAGES.USER_ENTRY.ERROR.SUBHEADER")}</p>

              {contactEmail ? <a href={`mailto:${contactEmail}?subject=Invalid Meeting!`} className="mx-auto mt-3">
                {contactEmail}
              </a> : null}
            </Card>}

            {!isLoading && !error && <React.Fragment>
              <h6><strong>{t("PAGES.USER_ENTRY.HEADER")}</strong></h6>
              <h6>{t("PAGES.USER_ENTRY.SUBHEADER")}</h6>
              <Row className="my-3">
                <Col md={6} lg={6} xl={6} className="p-1">
                  <UserProfileCard user={mainCustomer} />
                </Col>
                <Col md={6} lg={6} xl={6} className="p-1">
                  <UserProfileCard user={owner} />
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mb-3" color="primary" onClick={handleJoinCall} size="md">
                  <i className="bx bx-phone-call me-3 font-size-18 align-middle"></i>
                  {t("GENERIC.BUTTONS.JOIN_CALL")}
                </Button>
              </div>
            </React.Fragment>}
          </React.Fragment>}

        </Col>
      </Row>
    </Container>
  )
}
export default UserEntrypage;