import classnames from 'classnames';
import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap';
import Typography from '../Typography';

interface CollapsableListItemProps {
  label: string;
  id: string;
  clear?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const CollapsableListItem: React.FC<CollapsableListItemProps> = ({ id, children, label, clear, disabled }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const handleToggleCollapse = (): void => {
    setCollapsed(!collapsed);
  }

  return (
    <li className="mb-3">
      <div className="d-flex align-items-center">
        {clear && !disabled &&
          <Button disabled={disabled} id={`${id}-filter-clear`} size="sm" onClick={() => clear()} className="p-0 rounded-circle me-1 bg-transparent text-dark border-0 outline-0 shadow-none">
            <i className={classnames('align-middle font-size-18 text-danger bx bx-x')} />
            <UncontrolledTooltip fade={false} placement="top" target={`${id}-filter-clear`}>
              <Typography text={"GENERIC.BUTTONS.CLEAR"} Variant="span" />
              {" "}
              <Typography text={label} Variant="span" />
            </UncontrolledTooltip>
          </Button>}
        <Typography text={label} className="mb-0" Variant="h6" />
        <Button size="sm" onClick={handleToggleCollapse} className="rounded-circle ms-auto bg-transparent text-dark border-0 outline-0 shadow-none">
          <i className={classnames('align-middle font-size-15 text-primary fa', { 'fa-caret-up': collapsed, 'fa-caret-down': !collapsed })} />
        </Button>
      </div>
      <div className={classnames({ 'd-none': collapsed, 'd-block': !collapsed })}>
        {children}
      </div>
    </li>
  )
}
export default CollapsableListItem;