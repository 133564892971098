import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useAppSelector } from '../../store';

const CancelPage: React.FC = () => {
  const { t } = useTranslation();
  const paymentUrl = useAppSelector((state) => state.order.paymentUrl);

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6} lg={5} xl={5} className="text-center">
          <Card className="border border-2 border-danger">
            <CardBody className="p-2">
              <div className="avatar-md mx-auto border border-danger border-radius rounded-circle">
                <div className="avatar-title rounded-circle bg-light">
                  <i className="bx bx-x display-1 text-danger align-middle"></i>
                </div>
              </div>
              <div className="p-2 mt-4">
                <h4 className="text-danger">{t("PAGES.PAYMENT_CANCEL.HEADER")}</h4>
                <p className="text-muted">
                  {t("PAGES.PAYMENT_CANCEL.SUBHEADER")}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {paymentUrl && <Row className="justify-content-center">
        <Col md={6} lg={4} xl={4} className="text-center">
          <a className="card" href={paymentUrl} target="_blank" rel="noreferrer">
            <CardBody>
              <i className='bx bx-repost display-6 text-primary align-middle'></i>
              <div className="p-2">
                <h4>{t("PAGES.PAYMENT_CANCEL.BUTTONS.RETRY.TITLE")}</h4>
                <p className="text-muted">
                  {t("PAGES.PAYMENT_CANCEL.BUTTONS.RETRY.CONTENT")}
                </p>
              </div>
            </CardBody>
          </a>
        </Col>
      </Row>}
    </Container>
  )
}
export default CancelPage;