import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleMeetingNotesModal } from '../../store/features/interface';
import { ActionMenuButton } from '../Button/ActionMenu'

// SALES-V2: Call in redux update
const MeetingNotesToggleBtn: React.FC = () => {
	const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
	const dispatch = useAppDispatch();

	const handleMeetingNotesModal = React.useCallback(() => dispatch(toggleMeetingNotesModal()), [dispatch]);

	return (
		<ActionMenuButton
			disabled={dailyMeetingState !== 'joined-meeting'}
			id="toggle-meeting-notes-modal"
			icon={<i className="bx bxs-edit font-size-20 align-middle" />}
			color={'secondary'}
			onClick={handleMeetingNotesModal}
			tooltip={"GENERIC.LABELS.WRITE_NOTES"}
		/>
	)
}
export default MeetingNotesToggleBtn;