import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store';
import NotificationBadge from '../Badge/NotificationBadge';

const ShowcaseTab: React.FC = () => {
  const { t } = useTranslation();
  const notificationCounter = useAppSelector((state) => state.appInterface.showcaseNotificationCounter)
  const activeTab = useAppSelector((state) => state.appInterface.activeSidebarTab);
  const products = useAppSelector((state) => state.showcase.products);

  return (
    <React.Fragment>
      <NotificationBadge show={activeTab !== 'showcase'} count={notificationCounter} className="top-0 start-100 translate-middle" />
      {t("GENERIC.LABELS.SHOWCASE")}
      {products.length > 0 && <span>{" ("}{products.length > 9 ? '9+' : products.length}{")"}</span>}
    </React.Fragment>
  )
}
export default ShowcaseTab;