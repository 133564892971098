import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { CartProductPayload, CartState, UpdateCartPayload, UpdateCartProductPayload } from "../../interfaces/Cart";
import { createAppAsyncThunk } from "../extras";
import { getMeeting } from "./meeting";
import { createOrder } from "./order";
import { endCallState, joinCallState, resetState } from "./sharedActions";


export const retrieveCart = createAppAsyncThunk(
  "cart/retrieve",
  async (_, { rejectWithValue, getState }) => {
    try {
      const cartId = getState().cart?.cartId || '';
      const response: any = await api.CartService.retrieveCart(cartId);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
);

export const updateCart = createAppAsyncThunk(
  "cart/update",
  async (payload: UpdateCartPayload, { rejectWithValue, getState }) => {
    try {
      const cartId = getState().cart?.cartId || '';
      const response: any = await api.CartService.updateCart(cartId, payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const addCartItem = createAppAsyncThunk(
  "cart/item/add",
  async ({ cartId, payload }: { cartId: string, payload: CartProductPayload }, { rejectWithValue }) => {
    try {
      const response = await api.CartService.addCartItem(cartId, payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const updateCartItem = createAppAsyncThunk(
  "cart/item/update",
  async ({ cartId, itemId, payload }: { cartId: string, itemId: string, payload: UpdateCartProductPayload }, { rejectWithValue }) => {
    try {
      const response = await api.CartService.updateCartItem(cartId, itemId, payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const removeCartItem = createAppAsyncThunk(
  "cart/item/remove",
  async ({ cartId, itemId }: { cartId: string, itemId: string }, { rejectWithValue }) => {
    try {
      const response = await api.CartService.removeCartItem(cartId, itemId);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

const initialState: CartState = {
  cartProducts: [],
  cartSubtotal: 0,
  cartTotal: 0,
  cartInitialized: false,
  cartId: undefined,
  cartLoading: false,
  discount: undefined
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartLoading: (state, action: PayloadAction<boolean>) => {
      state.cartLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      /* ---- Retrieve Cart ---- */
      .addCase(retrieveCart.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(retrieveCart.fulfilled, (state, action: PayloadAction<any>) => {
        state.cartId = action.payload._id;
        state.cartProducts = action.payload.items;
        state.cartInitialized = true;
        state.cartSubtotal = action.payload.subtotal;
        state.cartTotal = action.payload.total;
        state.cartLoading = false;
        // if (action.payload.discountedTotal) {
        //   state.cartDiscountedTotal = action.payload.discountedTotal;
        // }
        if (action.payload.discount) {
          state.discount = action.payload.discount;
        }
      })
      .addCase(retrieveCart.rejected, (state) => {
        state.cartLoading = false;
      })

      /* ---- Update Cart ---- */
      .addCase(updateCart.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(updateCart.fulfilled, (state, action) => {
        state.discount = action.payload.discount;
        state.cartSubtotal = action.payload.subtotal;
        state.cartTotal = action.payload.total;
        state.cartLoading = false;
      })
      .addCase(updateCart.rejected, (state) => {
        state.cartLoading = false;
      })

      /* ---- Add Item to the Cart ---- */
      .addCase(addCartItem.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(addCartItem.fulfilled, (state) => {
        state.cartLoading = false;
      })
      .addCase(addCartItem.rejected, (state) => {
        state.cartLoading = false;
      })

      /* ---- Update Item in Cart ---- */
      .addCase(updateCartItem.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(updateCartItem.fulfilled, (state) => {
        state.cartLoading = false;
      })
      .addCase(updateCartItem.rejected, (state) => {
        state.cartLoading = false;
      })

      /* ---- Remove Item from Cart ---- */
      .addCase(removeCartItem.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(removeCartItem.fulfilled, (state) => {
        state.cartLoading = false;
      })
      .addCase(removeCartItem.rejected, (state) => {
        state.cartLoading = false;
      })

      /* EXTERNAL: ---- Get Meeting ---- */
      .addCase(getMeeting.fulfilled, (state, action) => {
        state.cartInitialized = true;
        state.cartId = action.payload.cartId._id;
        state.cartProducts = action.payload.cartId.items;
        state.cartSubtotal = action.payload.cartId.subtotal || 0;
        state.cartTotal = action.payload.cartId.total || 0;
        state.discount = action.payload.cartId.discount || undefined;
      })

      // NOTE: When an order is generated, the cart contents should be cleaned as the cart is no longer updatable
      /* ---- EXTERNAL: Generate Order ---- */
      .addCase(createOrder.fulfilled, () => {
        // state.cartProducts = initialState.cartProducts;
        // state.cartTotal = initialState.cartTotal;
        // state.cartSubtotal = initialState.cartSubtotal;
        // state.cartLoading = initialState.cartLoading;
        // state.cartId = initialState.cartId;
        // state.cartInitialized = initialState.cartInitialized;
        // state.discount = initialState.discount;
      })

      /* ---- SHARED:  End call ---- */
      .addCase(endCallState, (state) => {
        // state.cartProducts = initialState.cartProducts;
        // state.cartSubtotal = initialState.cartSubtotal;
        // state.cartTotal = initialState.cartTotal;
        // state.cartLoading = initialState.cartLoading;
        // state.cartId = initialState.cartId;
        state.cartInitialized = initialState.cartInitialized;
      })

      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.cartProducts = initialState.cartProducts;
        state.cartSubtotal = initialState.cartSubtotal;
        state.cartTotal = initialState.cartTotal;
        state.cartLoading = initialState.cartLoading;
        state.cartId = initialState.cartId;
        state.cartInitialized = initialState.cartInitialized;
        state.discount = initialState.discount;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.cartProducts = initialState.cartProducts;
        state.cartSubtotal = initialState.cartSubtotal;
        state.cartTotal = initialState.cartTotal;
        state.cartLoading = initialState.cartLoading;
        state.cartId = initialState.cartId;
        state.cartInitialized = initialState.cartInitialized;
        state.discount = initialState.discount;
      })
  }
});
export const { setCartLoading } = cartSlice.actions;
export default cartSlice.reducer;