import React from 'react';
import { Outlet } from 'react-router-dom';
import useError from '../hooks/useError';
import { useAppDispatch, useAppSelector } from '../store';
import { retrieveTenancyConfig } from '../store/features/tenancy';

const TenancyLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { handleError } = useError();
  const retrieved = useAppSelector((state) => state.tenancy.retrieved);

  React.useEffect(() => {
    if (retrieved) return;
    dispatch(retrieveTenancyConfig()).unwrap().catch((error) => {
      handleError(error);
    })
  }, [dispatch, handleError, retrieved]);

  return <Outlet />
}
export default TenancyLayout;