import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useLookProductActions from "../../components/ProductComposer/useLookProductActions";
import { MQTT_ADD_CART } from "../../interfaces/Mqtt";
import Product from "../../interfaces/Product";
import useCartActions from "../cart/useCartActions";
import useMqttMessage from "../mqtt/useMessage";
import useShowcaseActions from "../useShowcaseActions";

const getVariantProduct = (
  product: Product,
  activeSku?: string,
) => product?.variants?.find((variant) => variant.product.sku === activeSku)

const useProductInsert = () => {
  const { t } = useTranslation();
  const { addToCart } = useCartActions();
  const { handleAddLookProduct } = useLookProductActions();
  const sendMqttMessage = useMqttMessage();
  const { addToShowcase } = useShowcaseActions();

  const handleCartInsertByStylist = React.useCallback((product: Product, activeSku?: string): void => {
    const matchedProduct = getVariantProduct(product, activeSku);
    addToCart({ baseSku: product.sku, sku: matchedProduct?.product.sku });
  }, [addToCart]);

  const handleCartInsertByConsumer = React.useCallback((product: Product, activeSku?: string): void => {
    const matchedProduct = getVariantProduct(product, activeSku);
    sendMqttMessage({
      type: MQTT_ADD_CART,
      payload: { baseSku: product.sku, sku: matchedProduct?.product.sku },
    });
  }, [sendMqttMessage]);

  const handleOutfitInsert = React.useCallback((product: Product, activeSku?: string): void => {
    const matchedProduct = getVariantProduct(product, activeSku);
    handleAddLookProduct(product, matchedProduct?.product.sku);
    toast.info(t("TOASTS.COMPOSER.ADDED"));
  }, [handleAddLookProduct, t]);

  const handleShowcaseInsert = React.useCallback((product: Product, activeSku?: string): void => {
    const matchedProduct = getVariantProduct(product, activeSku);
    addToShowcase({
      baseSku: product.sku,
      sku: matchedProduct?.product.sku
    });
  }, [addToShowcase]);

  return {
    handleCartInsertByStylist,
    handleCartInsertByConsumer,
    handleOutfitInsert,
    handleShowcaseInsert,
  };
};

export default useProductInsert;
