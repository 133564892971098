import { recursiveLimit } from "../constants/catalog";
import { Category } from "../interfaces/Category";

export const findCat = ({ categoryId, options, currRecursion }: { categoryId: string, options?: Category[], currRecursion: number }): Category | undefined => {
  if (!Array.isArray(options) || currRecursion >= recursiveLimit) return;
  let returnValue = undefined;

  for (let category of options) {
    if (category.uid === categoryId) {
      returnValue = category;
      break;
    } else {
      returnValue = findCat({ categoryId, options: category.children, currRecursion: (currRecursion + 1) });
      if (returnValue) break;
    }
  }
  return returnValue;
}