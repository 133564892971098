import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { userRoles } from '../../constants/users';
import { useAppSelector } from '../../store';
import Logo from '../Logo';
import EndCallScreenConsumer from './EndConsumer';
import EndCallScreenStylist from './EndStylist';

interface PropTypes {
  isMobile?: boolean;
}

const EndCallScreen: React.FC<PropTypes> = ({ isMobile }) => {
  const userType = useAppSelector((state) => state.auth.userType);
  const { t } = useTranslation();

  return (
    <Row className={classnames('mt-5 h-100', { 'rounded': !isMobile })}>
      <Col lg={4} md={6} xs={12} className="text-center mx-auto">
        {isMobile && <Logo className="mt-2" />}
        <Card className={classnames("p-3 mx-3 shadow-lg", { 'mt-3': isMobile, 'mx-auto': !isMobile })}>
          <CardHeader tag="h3" className="text-primary bg-white text-center">{t("PAGES.MEETING.CALL_ENDED.HEADER")}</CardHeader>
          <CardBody>
            {userType === userRoles.CONSUMER && <EndCallScreenConsumer />}
            {userType === userRoles.STYLIST && <EndCallScreenStylist />}
          </CardBody>
        </Card>
        <h5 className="text-muted my-3 text-primary text-center mb-auto">{t("GENERIC.LABELS.CHOICE_THANKS")}</h5>
      </Col>
    </Row>
  )
}
export default EndCallScreen;