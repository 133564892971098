import React from 'react';
import useMqttMessage from '../../hooks/mqtt/useMessage';
import { MQTT_VIEW_LOOKS } from '../../interfaces/Mqtt';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActivePresentLook } from '../../store/features/interface';
import { ActionMenuButton } from '../Button/ActionMenu';

// SALES-V2: Call in redux update
const LooksPresentationToggleBtn: React.FC = () => {
	const { activePresentLook } = useAppSelector((state) => state.appInterface);
	const selectedLookId = useAppSelector((state) => state.looks.selectedLookId);
	const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
	const sendMqttMessage = useMqttMessage();
	const dispatch = useAppDispatch();

	const isPresentingLook = React.useMemo((): boolean => {
		return activePresentLook.startsWith('look') &&
			((activePresentLook.split('_')[1] === selectedLookId) ||
				(activePresentLook.startsWith('look') && selectedLookId === undefined));
	}, [activePresentLook, selectedLookId]);

	const presentLookbtnDisabled = React.useMemo((): boolean => {
		return (!isPresentingLook && selectedLookId === undefined) || dailyMeetingState !== 'joined-meeting';
	}, [isPresentingLook, selectedLookId, dailyMeetingState]);

	const handleToggleLooks = (): void => {
		if (isPresentingLook) {
			dispatch(setActivePresentLook(''));
			sendMqttMessage({ type: MQTT_VIEW_LOOKS, payload: { toggleLooks: false, lookId: undefined } });
		} else {
			if (selectedLookId) {
				dispatch(setActivePresentLook(`look_${selectedLookId}`));
				sendMqttMessage({ type: MQTT_VIEW_LOOKS, payload: { toggleLooks: true, lookId: selectedLookId } });
			}
		}
	}

	return (
		<ActionMenuButton
			id="toggle-looks-btn"
			className={isPresentingLook ? 'pulse-primary' : undefined}
			disabled={presentLookbtnDisabled}
			icon={<i className='bx bxs-t-shirt font-size-20 align-middle' />}
			color={isPresentingLook ? 'primary' : 'secondary'}
			onClick={handleToggleLooks}
			tooltip={isPresentingLook ? "GENERIC.BUTTONS.HIDE_LOOK" : "GENERIC.BUTTONS.SHOW_LOOK"} />
	)
}
export default LooksPresentationToggleBtn;