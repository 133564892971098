import { cartDiscountOptions } from "../constants/cart";
import { CartDiscount, CurrencyType } from "../interfaces/Cart";
import Product, { CartProduct } from "../interfaces/Product";
import { buildProductAddPayload } from "./product";

export const constructCartProduct = ({ product, existingProduct, sku }: { product: Product, existingProduct?: CartProduct, sku?: string }): any => {
  return {
    _id: existingProduct?._id,
    baseSku: product.sku,
    sku: sku ? sku : undefined,
    qty: existingProduct?.qty ? existingProduct?.qty + 1 : 1
  };
};

export const buildCartCreatePayload = (
  currency: CurrencyType,
  customerId: string,
  product: any,
  sku?: string
): { currency: CurrencyType, customerId: string, items: Partial<CartProduct>[] } => {
  return {
    currency: currency,
    customerId,
    items: [buildProductAddPayload(constructCartProduct({ product, sku }))]
  }
}

// export const buildCartCreateManyPayload = (
//   currency: CurrencyType,
//   customerId: string,
//   products: Product[]
// ): { currency: CurrencyType; customerId: string; items: CartProduct[] } => ({
//   currency,
//   customerId,
//   items: products.map((product: Product) =>
//     buildProductAddPayload(constructCartProduct({ product }))
//   ),

export const calcCartDiscountTotal = ({ type, value, cartTotal }: CartDiscount & { cartTotal: number }): number => {
  let total: number = 0;

  switch (type) {
    case cartDiscountOptions.PERCENTAGE:
      if (value > 0 && value <= 100) {
        total = cartTotal * (100 - value) / 100;
      }
      else {
        total = cartTotal;
      }
      break;

    case cartDiscountOptions.FIXED:
      if (value >= 0 && value <= cartTotal) {
        total = cartTotal - value;
      } else {
        total = cartTotal;
      }
      break;

    default:
      total = cartTotal;
      break;
  }
  return total;
}