import React from "react";

interface MicStatusProps {
  audioUnavailableMessage: string | null | undefined;
}

const CornerMicStatus: React.FC<MicStatusProps> = ({ audioUnavailableMessage }) => {
  return (
    <React.Fragment>
      {audioUnavailableMessage && (
        <i className='bx bx-microphone-off align-middle bg-secondary bg-soft text-white font-size-15 m-2 rounded-circle position-absolute top-0 end-0 p-1' />
      )}
    </React.Fragment>
  );
};
export default CornerMicStatus;
