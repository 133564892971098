import React from "react";
import { useTranslation, Trans } from "react-i18next";

interface PropTypes {
  text: string;
  textParams?: { [key: string]: string };
}

const TextConfigTrans: React.FC<PropTypes> = ({ text, textParams = {} }) => {
  const { t } = useTranslation();

  return (
    <Trans
      t={t}
      i18nKey={text}
      values={textParams}
      components={{
        break: <br />,
        span: <span />,
        bold: <strong />,
      }}
    />
  );
};

export default TextConfigTrans;
