import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface PropTypes {
  isOpen: boolean;
  toggle: () => void;
  confirm: () => void;
  title: string;
}


const ResetLookModal: React.FC<PropTypes> = ({ isOpen, toggle, title, confirm }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} centered={true} size="sm" toggle={toggle}>
      <ModalHeader className="text-center" toggle={toggle}>
        {t("MODALS.LOOK_RESET.HEADER")}
      </ModalHeader>
      <ModalBody className="text-center">
        {t("MODALS.LOOK_RESET.SUBHEADER")}<br />
        <span className="h5">{title}</span> ?
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="danger" onClick={toggle}>
          {t("GENERIC.BUTTONS.CANCEL")}
        </Button>
        <Button color="primary" onClick={confirm}>
          {t("GENERIC.BUTTONS.RESET")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ResetLookModal;