import axios from "axios";
import { Category } from "../interfaces/Category";
import { constructRequestHeaders, SERVER_URL } from "./helpers";

export default class CategoryService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/categories`
  }

  async retrieveCategories(): Promise<Category[]> {
    try {
      const response = await axios.get(`${this.baseUrl}`, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}