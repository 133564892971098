import { AxiosRequestHeaders } from "axios";
import { v4 as uuidv4 } from "uuid";
import { getStoreObj } from "../store";
import { api, applicationId } from "../common/appSettings";
import { CatalogSearchParams, PageSearchParams } from "../interfaces/Filters";
import { initialCatalogFilter } from '../constants/catalog';
import { isObjEqual } from "../helpers/utils";
interface ConstructRequestHeaderProps {
  noToken?: boolean;
}


export const SERVER_URL: string = `${api.protocol}://${api.host}${!!api.port ? `:${api.port}` : ""}`;

export const constructRequestHeaders = ({ noToken }: ConstructRequestHeaderProps): AxiosRequestHeaders => {
  const headers: AxiosRequestHeaders = {};

  if (!noToken) {
    const authToken = getStoreObj().auth.accessToken;
    if (authToken) headers.Authorization = `Bearer ${authToken}`;
  }

  headers["x-application-id"] = applicationId;
  headers["x-request-id"] = uuidv4();

  return headers;
};


// TODO: reduce size
export const constructCatalogQueryString = (filters: CatalogSearchParams): string => {
  let queryString = `?limit=${filters.limit}&skip=${filters.skip}`;

  if (filters?.categories?.length >= 1) {
    queryString += `&category_uid=${filters.categories.join('%2C')}`;
  }

  if (filters?.searchTerm) {
    queryString += `&search=${filters.searchTerm}`;
  }

  if (filters?.price) {
    queryString += `&price-from=${filters.price.from}`;
    queryString += `&price-to=${filters.price.to}`;
  }

  if (filters?.sort) {
    queryString += `&sort=${filters.sort}`;
  }

  if (isObjEqual(filters, initialCatalogFilter)) {
    queryString += `&top_product=true`
  }

  return queryString;
};

export const constructPageQueryString = (filters: PageSearchParams): string => {
  let queryString = `?limit=${filters.limit}&skip=${filters.skip}`;

  if (filters?.code) {
    queryString += `&code=${filters.code}`
  }

  if (filters?.searchTerm) {
    queryString += `&search=${filters.searchTerm}`;
  }

  if (filters?.sort) {
    queryString += `&sort=${filters.sort}`;
  }

  return queryString;
}
