import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStopwatch } from 'react-timer-hook';
import { useAppSelector } from '../../store';

const timeOffset = new Date();

const NavCallInfo: React.FC = () => {
  const { t } = useTranslation();
  const { mainCustomer, owner } = useAppSelector((state) => state.meeting);
  const { seconds, minutes, hours } = useStopwatch({ autoStart: true, offsetTimestamp: timeOffset });

  return (
    <div className="d-flex align-items-center flex-grow-1 ms-5 justify-content-start">
      <p className="text-muted mb-0 font-size-12">
        <span>{t("GENERIC.LABELS.IN_CALL_WITH")}{" "}<strong>{mainCustomer.fullName}</strong>{" "}{t("GENERIC.LABELS.FOR")}{" "}</span>
        {hours !== 0 && <strong>{("0" + hours + 'h').slice(-3)}:</strong>}
        {minutes !== 0 && <strong>{("0" + minutes + 'm').slice(-3)}:</strong>}
        <strong>{("0" + seconds + 's').slice(-3)}</strong>.
        <span>{" "}{t("GENERIC.LABELS.THE_HOST_IS")}{" "}<strong>{owner.fullName}</strong>.</span>
      </p>
    </div>
  )
}
export default NavCallInfo;