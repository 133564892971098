import usFlag from "../assets/images/flags/us.jpg";
// import spain from "../assets/images/flags/spain.jpg";
// import germany from "../assets/images/flags/germany.jpg";
import italy from "../assets/images/flags/italy.jpg";
// import russia from "../assets/images/flags/russia.jpg";
import { Language } from "../interfaces/Language";

const languages: Language[] = [
  // { label: "Spanish", code: 'sp', flag: spain },
  // { label: "German", code: 'gr', flag: germany },
  { label: "Italian", native: 'Italiano', code: 'it', flag: italy },
  // { label: "Russian", code: 'rs', flag: russia },
  { label: "English", native: 'English', code: 'en', flag: usFlag },
]

export default languages;
