import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col } from 'reactstrap';
import { initialCatalogFilter } from '../../constants/catalog';
import useCatalog from '../../hooks/useCatalog';
import { useAppSelector } from '../../store';
import LoadingSpinner from '../LoadingSpinner';
import Typography from '../Typography';
import CatalogList from './CatalogList';
import ProductCountDisplay from './ProductCountDisplay';
import SearchTerm from './SearchTerm';

const CatalogBrowser: React.FC = () => {
  const userType = useAppSelector((state) => state.auth.userType);
  const pagination = useAppSelector((state) => state.catalog.pagination);
  const { products, catalogLoading, initializedCatalogs, error } = useAppSelector((state) => state.catalog)
  const { retrieveCatalog } = useCatalog();
  const { sidebarCollapsed, catalogFiltersCollapsed } = useAppSelector((state) => state.appInterface);

  const handleRetrieveProducts = React.useCallback((skip?: number) => {
    retrieveCatalog({ skip: skip ? skip : initialCatalogFilter.skip, }, true);
  }, [retrieveCatalog]);

  const handleFetchMore = React.useCallback(() => {
    handleRetrieveProducts(pagination.limit * pagination.page);
  }, [handleRetrieveProducts, pagination]);

  React.useEffect(() => {
    if (!catalogLoading && !initializedCatalogs && !error)
      handleRetrieveProducts();
  }, [catalogLoading, error, initializedCatalogs, handleRetrieveProducts])

  return (
    <React.Fragment>

      {!catalogLoading && products.length > 0 && <ProductCountDisplay />}

      <SearchTerm />

      <InfiniteScroll
        className="row g-2 overflow-hidden"
        dataLength={products.length}
        hasMore={pagination.page < pagination.pages}
        scrollableTarget="scroll-parent"
        next={handleFetchMore}

        loader={<Col xs={12} md={12} className="text-center">
          <LoadingSpinner className="text-primary" size="lg" />
        </Col>}

        endMessage={<Col xs={12} md={12} className="text-center">
          {catalogLoading ? <LoadingSpinner className="text-primary" size="lg" /> :
            <Typography Variant="h3" text={
              products.length === 0 ? "GENERIC.LABELS.CATALOGUE_NO_RESULTS" : "GENERIC.LABELS.CATALOGUE_END"} />
          }
        </Col>}
      >
        <CatalogList
          products={products}
          userType={userType}
          sidebarCollapsed={sidebarCollapsed}
          filtersCollapsed={catalogFiltersCollapsed}
        />
      </InfiniteScroll>
    </React.Fragment>
  )
}
export default CatalogBrowser;