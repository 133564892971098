import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Banner from './Banner';
import Socials from './Socials';
import "./profile.scss";
import Avatar from './Avatar';
import classnames from 'classnames';

interface PropTypes {
  user: any;
  className?: string;
}

const UserProfileCard: React.FC<PropTypes> = ({ user, className }) => {
  return (
    <Card className={classnames('overflow-hidden h-100 profile-card', className)}>
      <Banner url={user.banner?.sizes?.original?.url} userType={user.type} />
      <CardBody className="py-0 text-center">
        <Avatar url={user.avatar?.sizes?.[150]?.url} fullName={user.fullName} initials={user.initials} />
        <h5 className="my-3 font-size-13 text-truncate">
          {user.fullName}
        </h5>
        {user?.social && <Socials socials={user.social} />}
        {user?.description && <React.Fragment>
          <hr />
          <span>{user.description}</span>
        </React.Fragment>}
      </CardBody>
    </Card>
  )
}
export default UserProfileCard;