import React from 'react';
import { Formik, Form } from 'formik';

interface PropTypes {
  innerRef?: React.RefObject<any>;
  initialValues: any;
  validate?: any;
  onSubmit: (arg?: any) => void;
  children?: React.ReactNode;
  [key: string]: any;
}

const FormikForm: React.FC<PropTypes> = (props) => {
  const { innerRef, initialValues, validate, onSubmit, children, ...rest } = props
  return <Formik
    innerRef={innerRef}
    initialValues={initialValues}
    validate={validate}
    onSubmit={onSubmit}
    {...rest}>
    <Form>{children}</Form>
  </Formik>
};
export default FormikForm;