import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import api from "../../api";
import useDeleteLook from "../../hooks/look/useDelete";
import useLookModalDetails from "../../hooks/look/useModalDetails";
import useFetch from "../../hooks/useFetch";
import { Look, UpdateLookPayload } from "../../interfaces/Look";
import { getLooks } from "../../store/features/looks";
import ConfirmationModal from "../Modals/Confirmation";
import UpdateOutfitModal from "../Modals/UpdateOutfitModal";
import TableEmpty from "../Table/TableEmpty";
import TableHeaders from "../Table/TableHeaders";
import LooksTableRow from "./TableRow";

interface PropTypes {
  looks: Look[];
  onDetails: (lookId: string) => void;
}
const apiUpdateLook = (payload: UpdateLookPayload, lookId: string) => api.LookService.updateLook(payload, lookId);

const LooksTable: React.FC<PropTypes> = ({ looks, onDetails }) => {
  const updateLook = useFetch(apiUpdateLook, getLooks);
  const { t } = useTranslation();
  const [isEditShown, selectedLook, openEditModal, closeEditModal] = useLookModalDetails();
  const [
    lookToDeleteTitle,
    isDeleteShown,
    deleting,
    openDeleteModal,
    closeDeleteModal,
    handleDeletion,
  ] = useDeleteLook();

  const confirmUpdate = React.useCallback((name: string, description?: string) => {
    const payload: UpdateLookPayload = {
      title: name,
      description: description ?? ''
    };
    if (selectedLook) {
      updateLook({
        actionProps: { payload, lookId: selectedLook._id },
        onSuccess: closeEditModal,
      });
    }
  }, [updateLook, closeEditModal, selectedLook]);

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table className="table align-middle mb-0 table-nowrap">
          <TableHeaders>
            <th className="w-50" scope="col">
              {t("GENERIC.LABELS.NAME")}
            </th>
            <th className="w-25" scope="col">
              {t("GENERIC.LABELS.TOTAL")}
            </th>
            <th className="w-25" scope="col"></th>
          </TableHeaders>
          <tbody>
            {looks.length > 0 ? (
              looks.map((l, i) => (
                <LooksTableRow key={i} look={l} looks={looks} onDetails={onDetails} onEdit={openEditModal} onDelete={openDeleteModal} />
              ))
            ) : (
              <TableEmpty colSpan={3}>
                {t("GENERIC.LABELS.OUTFITS_EMPTY")}
              </TableEmpty>
            )}
          </tbody>
        </Table>
      </div>
      <UpdateOutfitModal
        isShown={isEditShown}
        confirm={confirmUpdate}
        id={selectedLook?._id || ''}
        name={selectedLook?.title || ''}
        description={selectedLook?.description || ''}
        looks={looks}
        toggleModal={closeEditModal}
      />
      <ConfirmationModal
        isShown={isDeleteShown}
        title="MODALS.DELETE_OUTFIT.HEADER"
        message="MODALS.DELETE_OUTFIT.SUBHEADER"
        messageParams={{ outfitTitle: lookToDeleteTitle }}
        submitBtnClass="btn-danger"
        submitText="GENERIC.BUTTONS.DELETE"
        submitting={deleting}
        onCancel={closeDeleteModal}
        onSubmit={handleDeletion}
      />
    </React.Fragment>
  );
};

export default LooksTable;
