import React from 'react'
import Tile from '.';
import { useAppSelector } from '../../store';

// SALES-V2: Call in redux update
const MainParticipantTile: React.FC = () => {
  const activeParticipant = useAppSelector((state) => state.call.activeParticipant);

  // TODO: Better Move component higher up instead of conditional render
  return (
    <React.Fragment>
      {activeParticipant && <Tile
        id={activeParticipant.user_id}
        videoTrackState={activeParticipant.tracks.video}
        audioTrackState={activeParticipant.tracks.audio}
        isLocalPerson={activeParticipant.local}
        isMainScreen={true}
        name={activeParticipant.user_id}
        userData={activeParticipant?.userData || undefined}
        className="main" // TODO: re-evalute
      />}
    </React.Fragment>
  )
}
export default MainParticipantTile;