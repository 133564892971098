import classnames from 'classnames';
import React from 'react';
import { userRoles } from '../../constants/users';
import { UserType } from '../../interfaces/Auth';
import SkeletonImage from '../SkeletonImage';

interface PropTypes {
  url?: string,
  className?: string
  userType: UserType
}

const Banner: React.FC<PropTypes> = ({ url, className, userType }) => {
  return (
    <div className={classnames('banner bg-soft', className, { 'bg-success': userType === userRoles.STYLIST, 'bg-primary': userType === userRoles.CONSUMER })} >
      {url && <div className="banner-bg px-0 h-100">
        <SkeletonImage
          src={url}
          className={classnames("img-fluid card-img-top h-100")}
          defaultSrc={""}
        />
      </div>}
    </div>
  )
}
export default Banner;