import React from "react";
import { initialCatalogFilter } from "../constants/catalog";
import { CatalogSearchParams } from "../interfaces/Filters";
import { useAppDispatch, useAppSelector } from "../store";
import { getCatalog } from "../store/features/catalog";
import useError from "./useError";

const useCatalog = () => {
  const searchParams = useAppSelector((state) => state.filters.searchParams);
  const dispatch = useAppDispatch();
  const { handleError } = useError()

  const retrieveCatalog = React.useCallback((filters?: Partial<CatalogSearchParams>, retainItems?: boolean) => {
    const filterObj = {
      ...searchParams,
      ...filters,
    };
    if (!retainItems) filterObj.skip = initialCatalogFilter.skip;

    dispatch(getCatalog({ filters: filterObj, retainItems })).unwrap()
      .catch((error: any) => {
        handleError(error);
      });
  }, [handleError, dispatch, searchParams]);


  return { retrieveCatalog }
}
export default useCatalog;