import React from 'react'
import { useTranslation } from 'react-i18next';
// import { formatPrice } from '../../helpers/product';
// import { useAppSelector } from '../../store';

const CartTab: React.FC = () => {
  // const total = useAppSelector((state) => state.cart.cartTotal);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {t("GENERIC.LABELS.CART")}
      {/* {total ? ` - ${formatPrice(total)}` : null} */}
    </React.Fragment>
  )
}
export default CartTab;