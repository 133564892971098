import classnames from 'classnames';
import React from 'react'
import { magentoProductTypes } from '../../constants/product';
import { formatPrice } from '../../helpers/product';
import { ProductType } from '../../interfaces/Product';
import Typography from '../Typography';

interface PropTypes {
  type: ProductType;
  hasSelection?: boolean;
  price: {
    regular: number;
    final: number;
  };
  className?: string;
}

const ProductPrice: React.FC<PropTypes> = ({ type, hasSelection = false, price, className }) => {

  const hasDiscount = price.regular !== price.final;

  return <p className={classnames("text-black mb-3", className)}>
    {type === magentoProductTypes.CONFIGURABLE && !hasSelection && <Typography className="text-muted" Variant="span" text="GENERIC.LABELS.AS_LOW_AS" />}

    {hasDiscount ?
      <React.Fragment>
        <s className="text-muted">{" "}{formatPrice(price.regular)}</s>
        {" "}{formatPrice(price.final)}
      </React.Fragment>
      :
      <span className={classnames({ "fw-bolder": hasDiscount })}>{" "}{formatPrice(price.final)}</span>}
  </p>

}
export default ProductPrice;