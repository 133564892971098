import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import { Button, Card, Col, Row } from 'reactstrap';
import { useAppSelector } from '../../store';
import Logo from '../Logo';
import TimeBox from './TimeBox';

interface PropTypes {
  isMobile?: boolean;
}

const EarlyJoinScreen: React.FC<PropTypes> = ({ isMobile }) => {
  const startDate = useAppSelector((state) => state.meeting.startDate);
  const { t } = useTranslation();

  const expiryTimestamp = new Date(Date.parse(startDate!));

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning
  } = useTimer({ expiryTimestamp, autoStart: true, onExpire: () => console.warn('refresh page maybe') });

  const handleReload = (): void => {
    window.location.reload();
  }

  return (
    <Row className={'mt-5 h-100'}>
      <Col lg={4} md={6} xs={12} className="text-center mx-auto">
        {isMobile && <Logo className="mx-auto mt-2" />}
        <Card className={classnames("px-1 py-3 text-center", { 'mt-3 mx-2': isMobile, 'mx-auto': !isMobile })}>
          {isRunning &&
            <React.Fragment>
              <h3 className="text-primary">{t("PAGES.MEETING.EARLY_JOIN.NOT_READY.HEADER")}</h3>

              <span>{t("PAGES.MEETING.EARLY_JOIN.NOT_READY.SUBHEADER")}</span>
              <Row className="justify-content-center my-3">
                {days !== 0 && <TimeBox text={t('GENERIC.TIME_UNITS.DAYS')} num={days} />}
                {hours !== 0 && <TimeBox text={t('GENERIC.TIME_UNITS.HOURS')} num={hours} />}
                {minutes !== 0 && <TimeBox text={t('GENERIC.TIME_UNITS.MINUTES')} num={minutes} />}
                {days === 0 && <TimeBox text={t('GENERIC.TIME_UNITS.SECONDS')} num={seconds} />}
              </Row>
            </React.Fragment>
          }
          {!isRunning &&
            <React.Fragment>
              <h3 className="text-primary">{t("PAGES.MEETING.EARLY_JOIN.READY.HEADER")}</h3>
              <p>{t("PAGES.MEETING.EARLY_JOIN.READY.SUBHEADER")}</p>
              <Button color="primary" className="w-50 mx-auto" onClick={handleReload}>{t("GENERIC.BUTTONS.CONTINUE")}</Button>
            </React.Fragment>
          }
        </Card>
        <h5 className="text-muted my-3 text-primary text-center mb-auto">{t("GENERIC.LABELS.PATIENCE_THANKS")}</h5>
      </Col>
    </Row>
  )
}
export default EarlyJoinScreen;