import React from 'react'
import { Card, CardBody } from 'reactstrap';


interface CardProps {
  title: string,
  text: string,
  children?: React.ReactNode
}

const SuccessCard: React.FC<CardProps> = ({ title, text, children }) => {
  return (
    <Card>
      <CardBody>
        <div className="p-2">
          <div className="text-center">
            <div className="avatar-md mx-auto border border-success border-radius rounded-circle">
              <div className="avatar-title rounded-circle bg-light">
                <i className='bx bx-check display-1 text-success'></i>
              </div>
            </div>
            <div className="p-2 mt-4">
              <h4>{title}</h4>
              <p className="text-muted">{text}</p>
              {children}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
export default SuccessCard;