import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { userRoles } from '../constants/users';
import { useScreen } from '../providers/ScreenSize';
import { useAppDispatch, useAppSelector } from '../store';
import { leaveCall } from '../store/features/call';

// SALES-V2: Call in redux update
const MobileGuard: React.FC = () => {
  const dailyCallState = useAppSelector((state) => state.call.dailyMeetingState);
  const userType = useAppSelector((state) => state.auth.userType);
  const meetingId = useAppSelector((state) => state.meeting.meetingId);
  const isShowroom = useAppSelector((state) => state.auth.isShowroom);
  const { isMobile } = useScreen();
  const dispatch = useAppDispatch();

  const isNonShowroomStylist = React.useMemo<boolean>(() => userType === userRoles.STYLIST && isMobile && !isShowroom, [userType, isMobile, isShowroom]);

  React.useEffect(() => {
    if ((dailyCallState === 'joined-meeting' || dailyCallState === 'joining-meeting' || dailyCallState === 'loading') && isNonShowroomStylist) {
      dispatch(leaveCall());
    };
  }, [dispatch, isNonShowroomStylist, dailyCallState]);

  return (
    <React.Fragment>
      {isNonShowroomStylist
        ?
        <Navigate to={`/${userType}/${meetingId}`} />
        :
        <Outlet />}
    </React.Fragment>
  )
}
export default MobileGuard;