// @ts-nocheck
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { setMainParticipant } from "../store/features/call";
import useOrderStatusPolling from "../components/Sidebar/useOrderStatusPolling";
import { MqttMessage, MQTT_ADD_CART, MQTT_CONFIGURATOR_SET_PANEL, MQTT_CONFIGURATOR_SET_VARIANTS, MQTT_CONFIGURATOR_TOGGLE, MQTT_CONFIGURATOR_UPDATE_STEP, MQTT_CONFIGURATOR_UPDATE_VARIANT, MQTT_DELETE_CART, MQTT_END_CALL, MQTT_GRAND_CATALOG_ACCESS, MQTT_REFRESH_CART, MQTT_REFRESH_SHOWCASE, MQTT_REMOVE_CART, MQTT_SEND_CATALOG, MQTT_SEND_PRODUCT, MQTT_VIEW_LOOKS, MQTT_VIEW_PAYMENT, MQTT_VIEW_USER, MQTT_VIEW_WINDOW } from "../interfaces/Mqtt";
import { leaveCall } from "../store/features/call";
import { setConfiguratorActivePanel, setConfiguratorStep, setConfiguratorVariants, toggleConfigurator, updateConfiguratorVariant } from '../store/features/details';
import { getLook, setLookDetailsView, setLookView, setSelectedLook, setSelectedLookId } from "../store/features/looks";
import { useAppDispatch, useAppSelector } from "./../store";
import { retrieveCart } from "./../store/features/cart";
import { setActiveSidebarTab, setActiveWindow, setPaymentModalActive, setPreviousWindow, toggleCatalogRights } from "./../store/features/interface";
import { retrieveOrder } from "./../store/features/order";
import useCartActions from "./cart/useCartActions";
import useAppNavigation from "./useAppNavigation";
import useCatalog from "./useCatalog";
import useError from "./useError";
import useScreenSize from "./useScreenSize";
import useShowcaseActions from "./useShowcaseActions";

// SALES-V2: Call in redux update
export const useMqttReducer = () => {
  const { addToCart, removeFromCart, deleteFromCart } = useCartActions();
  const userType = useAppSelector((state) => state.auth.userType);
  const visitedProductSku = useAppSelector((state) => state.details.product?.sku);
  const { returnToMeeting, visitProductDetails } = useAppNavigation();
  const { checkOrderStatus, isPolling } = useOrderStatusPolling();
  const { retrieveShowcase } = useShowcaseActions();
  const { retrieveCatalog } = useCatalog();
  const { isMobile } = useScreenSize();
  const { handleError } = useError();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const reducer = useCallback(async ({ payload, originator, type }: MqttMessage): Promise<void> => {

    if (userType === originator) return;

    switch (type) {

      case MQTT_END_CALL:
        dispatch({ type: 'EndCall' });
        dispatch(leaveCall());
        break;

      case MQTT_VIEW_USER:
        dispatch(setMainParticipant(payload.userId));
        dispatch(setActiveWindow('video'));
        returnToMeeting();
        break;

      case MQTT_ADD_CART:
        addToCart({ baseSku: payload.baseSku, sku: payload?.sku, notes: payload?.notes, variants: payload?.variants || [] });
        break;

      case MQTT_REMOVE_CART:
        removeFromCart(payload.productSku);
        break;

      case MQTT_DELETE_CART:
        deleteFromCart(payload.productId)
        break;

      case MQTT_REFRESH_CART:
        await dispatch(retrieveCart()).unwrap()
          .then(() => {
            toast.info(t("TOASTS.CART.UPDATED"));
          }).catch((error) => {
            handleError(error)
          });
        break;

      case MQTT_SEND_PRODUCT:
        visitProductDetails(payload.productSku);
        break;

      case MQTT_VIEW_LOOKS:
        if (payload?.toggleLooks && payload?.lookId) {

          if (isMobile) {
            returnToMeeting();
            dispatch(setActiveWindow('looks'));
            dispatch(setSelectedLookId(payload.lookId));
          } else {
            dispatch(setActiveSidebarTab('outfits'));
            dispatch(setLookView("LOOK_DETAILS"));
            dispatch(setLookDetailsView('LOOK_PRODUCTS_COMPOSER'));
          }
          dispatch(getLook({ lookId: payload.lookId })).unwrap().catch((error) => {
            handleError(error);
            toast.error(t('TOASTS.OUTFITS.GET.ERROR'));
          });
        } else {
          if (isMobile) {
            dispatch(setPreviousWindow());
          } else {
            dispatch(setSelectedLookId(undefined));
            dispatch(setSelectedLook(undefined));
            dispatch(setActiveSidebarTab('cart'));
          }
        }
        break;

      case MQTT_SEND_CATALOG:
        returnToMeeting();
        retrieveCatalog({ ...payload.filters });
        dispatch(setActiveWindow('productCatalog'));
        break;

      case MQTT_VIEW_PAYMENT:
        dispatch(retrieveOrder(payload.orderId)).unwrap()
          .then(() => {
            dispatch(setPaymentModalActive());
            if (!isPolling) {
              checkOrderStatus({ orderId: payload.orderId, timelapsed: 0 });
            }
          })
          .catch((error) => {
            handleError(error);
          })
        break;

      case MQTT_VIEW_WINDOW:
        returnToMeeting();
        dispatch(setActiveWindow(payload.window));
        break;

      case MQTT_REFRESH_SHOWCASE:
        retrieveShowcase();
        break;

      case MQTT_GRAND_CATALOG_ACCESS:
        payload ? toast.success(t("TOASTS.CATALOG_ACCESS_GRANTED")) : toast.warning(t("TOASTS.CATALOG_ACCESS_REVOKED"))
        dispatch(toggleCatalogRights(payload));
        break;

      case MQTT_CONFIGURATOR_TOGGLE:
        if (visitedProductSku && visitedProductSku === payload.sku) {
          dispatch(toggleConfigurator(payload.status));
          if (payload.panel) dispatch(setConfiguratorActivePanel(payload.panel));
          if (payload.step) dispatch(setConfiguratorStep(payload.step));
          if (payload.variants) dispatch(setConfiguratorVariants(payload.variants));
        }

        break;

      case MQTT_CONFIGURATOR_UPDATE_STEP:
        if (visitedProductSku && visitedProductSku === payload.sku)
          dispatch(setConfiguratorStep(payload.step));

        break;

      case MQTT_CONFIGURATOR_UPDATE_VARIANT:
        if (visitedProductSku && visitedProductSku === payload.sku)
          dispatch(updateConfiguratorVariant(payload.variant));
        break;

      case MQTT_CONFIGURATOR_SET_VARIANTS:
        if (visitedProductSku && visitedProductSku === payload.sku)
          dispatch(setConfiguratorVariants(payload.variants));
        break;

      case MQTT_CONFIGURATOR_SET_PANEL:
        if (visitedProductSku && visitedProductSku === payload.sku)
          dispatch(setConfiguratorActivePanel(payload.panel));
        break;

      default:
        throw new Error(`Action ${type} not supported.`);
    }
  }, [userType, visitedProductSku, isMobile, checkOrderStatus, isPolling, retrieveShowcase, t, handleError, deleteFromCart, returnToMeeting, retrieveCatalog, dispatch, visitProductDetails, addToCart, removeFromCart]);
  return reducer;
}