import { LookProductPayload } from "../interfaces/Look";
import Product, { LookProduct } from "../interfaces/Product";

export const buildLookAddProductPayload = (product: Product, sku?: string): LookProductPayload => ({
  baseSku: product.sku,
  sku: sku ? sku : undefined,
  width: 100,
  height: 100,
  x: 1,
  y: 1,
  zIndex: 1,
  rotation: 0,
});

export const buildLookUpdateProductPayload = (product: LookProduct, sku?: string): LookProductPayload => ({
  baseSku: product.baseSku,
  sku: sku ? sku : undefined,
  width: product?.width ?? 100,
  height: product?.height ?? 100,
  x: product?.x ?? 1,
  y: product?.y ?? 1,
  zIndex: product?.zIndex ?? 1,
  rotation: product?.rotation ?? 0,
});

export const getValidZIndex = (zIndex: number): number => {
  if (zIndex < 1) return 1;
  else if (zIndex > 2000) return 1999;
  else return zIndex;
};
