import React from 'react'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { memoPaths } from '../constants/routes';
import { userRoles } from '../constants/users';
import { useAppSelector } from '../store';

const MeetingGuard: React.FC = () => {
  const { meetingId } = useParams();
  const existingMeetingId = useAppSelector((state) => state.meeting.meetingId);
  const { userType, isShowroom } = useAppSelector((state) => state.auth);

  return meetingId !== existingMeetingId ? <Navigate to={`${userType === userRoles.STYLIST ? memoPaths.userEntry.stylist : memoPaths.userEntry.consumer}${meetingId}${isShowroom ? '?showroom=true' : ''}`} /> : <Outlet />
}

export default MeetingGuard;