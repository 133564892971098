import React from 'react';
import { Col } from 'reactstrap';

interface PropTypes {
  text: string;
  num: number;
}

const TimeBox: React.FC<PropTypes> = ({ text, num }) => {
  return (
    <Col className="py-1 px-0 rounded border border-primary m-1" xs={3} lg={2}>
      <div className="fw-bold">
        {text}
      </div>
      {("0" + num).slice(-2)}
    </Col>
  )
}
export default TimeBox;