import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { userRoles } from '../../constants/users';
import { formatPrice } from '../../helpers/product';
import useFavorite from '../../hooks/useFavorite';
import useShowcaseActions from '../../hooks/useShowcaseActions';
import { UserType } from '../../interfaces/Auth';
import { ShowcaseProduct } from '../../interfaces/Product';
import FavouriteButton from '../Button/Favourite';
import SkeletonImage from '../SkeletonImage';

interface PropTypes {
  product: ShowcaseProduct;
  onRemove: (id: string) => void
  visitDetails: (sku: string) => void
  index: number;
  userType: UserType;
}

const ShowcaseTableItem: React.FC<PropTypes> = ({ product, onRemove, index, userType, visitDetails }) => {
  const { t } = useTranslation();
  const { isFavorite, toggleFavorite, likedProductsLoading } = useFavorite();
  const { showcaseLoading } = useShowcaseActions();
  const isInFavorites = React.useMemo(() => isFavorite(product.baseSku), [product, isFavorite]);

  const handleVisitDetails = React.useCallback(() => {
    visitDetails(encodeURIComponent(product.baseSku));
  }, [product.baseSku, visitDetails]);

  return (
    <tr onClick={handleVisitDetails} style={{ cursor: 'pointer' }}>
      <td className="w-25 p-1">
        <SkeletonImage
          loading="lazy"
          className="sidebar-product-img"
          skeletonClassName="sidebar-product-img"
          src={product.details.product.thumbnail.url}
          alt={product.details.product.thumbnail.label ? product.details.product.thumbnail.label : product.details.product.name}
        />
      </td>
      <td className="w-75 p-1">
        <h5 className="font-size-13 text-wrap text-dark product-card-description mt-1">
          {product.details.product.name}
        </h5>
        <p className="mb-0 text-muted">
          {product.details.product.price_range.maximum_price.final_price.value && <React.Fragment>{formatPrice(product.details.product.price_range.maximum_price.final_price.value) || '-'}</React.Fragment>}
        </p>
      </td>
      <td className="w-auto p-1">
        <FavouriteButton
          id={`showcase-favorite-btn-${index}`}
          isFavorite={isInFavorites}
          onClick={(e) => { e.stopPropagation(); toggleFavorite(product.baseSku) }}
          disabled={likedProductsLoading}
          className="rounded-circle me-1 p-2"
          size="sm"
          tooltip={isInFavorites ? t("GENERIC.BUTTONS.REMOVE_FROM_FAVORITES") : t("GENERIC.BUTTONS.ADD_TO_FAVORITES")}
        />
        {userType === userRoles.STYLIST && <Button
          id={`showcase-remove-btn-${index}`}
          color="danger"
          outline
          disabled={showcaseLoading}
          className="rounded-circle p-2"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(product._id);
          }}>
          <i className="bx bx-trash align-middle font-size-18" />
          <UncontrolledTooltip fade={false} placement="top" target={`showcase-remove-btn-${index}`}>
            {t("GENERIC.BUTTONS.REMOVE")}
          </UncontrolledTooltip>
        </Button>}
      </td>
    </tr>
  )
}
export default ShowcaseTableItem;
