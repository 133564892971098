import React from "react";
import classnames from "classnames";
import { DropdownItem } from "reactstrap";

interface PropTypes {
  option: {
    label: string;
    value: string;
    disabled?: boolean;
  };
  selectedValue: string;
  onSelect: (value: any) => void;
}

const DropdownItemInput: React.FC<PropTypes> = ({
  option,
  selectedValue,
  onSelect,
}) => {
  return (
    <DropdownItem
      defaultValue={option.value}
      className={classnames({
        active: option.value === selectedValue,
        "text-decoration-line-through": option.disabled,
      })}
      disabled={option.disabled}
      onClick={() => onSelect(option.value)}
    >
      {option.label}
    </DropdownItem>
  );
};

export default DropdownItemInput;
