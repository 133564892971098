import React from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import DropdownItemInput from "./DropdownItem";

interface PropTypes {
  inputLabel: string;
  value: any;
  options: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
  onSelect: (value: any) => void;
}

const DropdownSelectInput: React.FC<PropTypes> = ({
  inputLabel,
  options,
  value,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const selectedOptionLabel = React.useMemo(
    () =>
      options.find((o) => o.value === value)?.label ??
      t("GENERIC.INPUTS.SELECT.PLACEHOLDER"),
    [value, options, t]
  );

  const toggle = (): void => {
    setMenuOpen((isOpen) => !isOpen);
  };

  return (
    <React.Fragment>
      <Label for={`${inputLabel}-select`} className="font-size-13">
        {inputLabel}
      </Label>

      <Dropdown
        isOpen={menuOpen}
        toggle={toggle}
        id={`${inputLabel}-select`}
        className="border rounded-2 mb-3"
      >
        <DropdownToggle
          className="btn header-item text-start waves-effect w-100"
          style={{ height: "auto" }}
          tag="button"
        >
          <Row className="g-0 justify-content-between flex-nowrap w-100">
            <Col className="text-nowrap text-truncate">
              <span>{selectedOptionLabel}</span>
            </Col>
            <Col className="col-auto">
              <span
                className={classnames(
                  "bx",
                  menuOpen ? "bx-chevron-up" : "bx-chevron-down"
                )}
              />
            </Col>
          </Row>
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: "100%" }}>
          {options.map((o, index) => (
            <DropdownItemInput
              key={`${o.value}-${index}`}
              selectedValue={value}
              option={o}
              onSelect={onSelect}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
export default DropdownSelectInput;
