import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { findCat } from '../../helpers/catalog';
import useAppNavigation from '../../hooks/useAppNavigation';
import useCatalog from '../../hooks/useCatalog';
import { Category } from '../../interfaces/Category';
import Product from '../../interfaces/Product';
import { useAppSelector } from '../../store';

interface PropTypes {
	product?: Product;
}

const CustomBreadcrumb: React.FC<PropTypes> = ({ product }) => {
	const { t } = useTranslation();
	const { returnToMeeting } = useAppNavigation();
	const { retrieveCatalog } = useCatalog();
	const { searchParams, categories } = useAppSelector((state) => state.filters);

	// BUG: this should be recalculated when visiting product details and kept in redux state
	// Description: navigating to product details from another source keeps that last selected category 
	// in the breadcrumb, if no category is selected no category breadcrumb is displayed
	const activeCategory: Category | undefined = React.useMemo(() => {
		if (searchParams.categories?.[0]) {
			return findCat({ categoryId: searchParams.categories[0], options: categories, currRecursion: 0 });
		}
		return undefined;
	}, [searchParams.categories, categories]);

	const handleSelectCatalog = (): void => {
		retrieveCatalog({ categories: [activeCategory?.uid!] });
		returnToMeeting();
	};

	return (
		<Breadcrumb listClassName="mb-0">
			<BreadcrumbItem>
				{product ? <button className="border-0 bg-transparent px-0" onClick={returnToMeeting}>
					{t('GENERIC.LABELS.CATALOGUE')}
				</button>
					:
					<span className="px-0 text-black">
						{t("GENERIC.LABELS.CATALOGUE")}
					</span>}
			</BreadcrumbItem>
			{activeCategory && <BreadcrumbItem className="product-breadcrumb">
				{product ?
					<button className="border-0 bg-transparent px-0" onClick={handleSelectCatalog}>
						{activeCategory.name}
					</button>
					: <span>{activeCategory.name}</span>}
			</BreadcrumbItem>}
			{product && <BreadcrumbItem active className="product-breadcrumb">
				{product.name}
			</BreadcrumbItem>}
		</Breadcrumb>
	)
}
export default CustomBreadcrumb;