import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ChatMessageProps {
  isLocal: boolean;
  entry: { sender: string, message: string; }
}

const ChatMessage: React.FC<ChatMessageProps> = ({ isLocal, entry }) => {
  const { t } = useTranslation();
  return (
    <li className={classNames({ "right": !isLocal })}>
      <div className="conversation-list">
        <div className="ctext-wrap mb-1">
          <div className="conversation-name">
            {isLocal ? t("GENERIC.LABELS.YOU") : entry.sender}
          </div>
          <p className="message-item">{entry.message}</p>
        </div>
      </div>
    </li>
  )
}
export default ChatMessage;