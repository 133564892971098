import classnames from 'classnames';
import React from 'react'
import { useTranslation } from 'react-i18next';

interface PropTypes {
  Variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  text: string;
  className?: string
}

const Typography: React.FC<PropTypes> = ({ Variant, text, className }) => {
  const { t } = useTranslation();

  if (Variant) return <Variant className={classnames(className)}>
    {t(text)}
  </Variant>

  return <React.Fragment>
    {t(text)}
  </React.Fragment>
}
export default Typography;