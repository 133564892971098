import React from 'react';
import CatalogRightsToggleBtn from './CatalogRightsToggle';
import CatalogToggleBtn from './CatalogToggle';
import LooksPresentToggleBtn from './LooksPresentToggle';
import MeetingNotesToggleBtn from './MeetingNotesToggle';
import ProductPresentationToggleBtn from './ProductPresentToggle';

const StylistActionMenu: React.FC = () => {

  return (
    <div className="d-flex w-50 justify-content-center">
      <CatalogToggleBtn />

      <LooksPresentToggleBtn />

      <ProductPresentationToggleBtn />

      <MeetingNotesToggleBtn />

      <CatalogRightsToggleBtn />
    </div>
  )
}
export default StylistActionMenu;
