import React from "react";
import { Button } from "reactstrap";
import useCartActions from "../../hooks/cart/useCartActions";
import { useAppSelector } from "../../store";
import useLookProductActions from "./useLookProductActions";


const ComposerActionMenu: React.FC = () => {
  const { addToCart, cartActionsDisabled } = useCartActions();
  const { handleZIndex, handleRemoveLookProduct } = useLookProductActions();
  const { selectedItem, lookProductsCurrent } = useAppSelector((state) => state.looks);

  const product = React.useMemo(() => {
    return lookProductsCurrent.find((prod) => prod._id === selectedItem)
  }, [lookProductsCurrent, selectedItem])

  return (
    <React.Fragment>
      {product && <React.Fragment>
        <Button
          title="Bring Forward"
          color="primary"
          id={`bring-item-forward`}
          className={"border-0 rounded-circle px-2 py-1 mx-2"}
          style={{ bottom: -30, left: 0 }}
          onClick={() => handleZIndex(product, product.zIndex + 1)}
        >
          <i className="bx bx-caret-up font-size-13 align-middle" />
        </Button>
        <span className="border py-1 px-2 monospace  text-black bg-secondary bg-soft font-monospace">{product.zIndex}</span>
        <Button
          title="Send backward"
          color="primary"
          id={`send-item-backward`}
          className={"border-0 rounded-circle px-2 py-1 mx-2"}
          style={{ bottom: -30, left: 30 }}
          onClick={() => handleZIndex(product, product.zIndex - 1)}
        >
          <i className="bx bx-caret-down font-size-13 align-middle" />
        </Button>

        <span className="text-secondary">{" - "}</span>

        <Button
          title="Add to cart"
          color="primary"
          id={`add-look-item-cart`}
          disabled={cartActionsDisabled}
          className={"border-0 rounded-circle px-2 py-1 mx-2"}
          style={{ top: 0, left: -30 }}
          onClick={() => addToCart({ baseSku: product.baseSku, sku: product?.sku })}>
          <i className="bx bxs-cart-alt font-size-13 align-middle" />
        </Button>

        <Button
          title="Remove item"
          color="danger"
          id={`remove-look-item`}
          className={"border-0 rounded-circle px-2 py-1 mx-2"}
          style={{ top: 0, right: -30 }}
          onClick={() => handleRemoveLookProduct(product._id)}
        >
          <i className="bx bx-x font-size-13 align-middle" />
        </Button>
      </React.Fragment>}
    </React.Fragment>
  );
};

export default ComposerActionMenu;
