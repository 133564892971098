import classnames from 'classnames';
import React from 'react';

interface LoadingSpinnerProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  isBtn?: boolean;
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className, size, isBtn, message }) => {
  let sizeClass = '';

  switch (size) {
    case 'sm':
      sizeClass = 'spinner-sm';
      break;
    case 'md':
      sizeClass = 'spinner-md';
      break;
    case 'lg':
      sizeClass = 'spinner-lg';
      break;
    default:
      sizeClass = 'spinner-md';
      break;
  }

  return (
    <div className={classnames('spinner-border', { 'spinner-border-sm me-1': isBtn, [`${className}`]: className, [`${sizeClass}`]: size })} role="status">
      <span className="sr-only">
        {message ? message : 'Loading..'}
      </span>
    </div>
  )
}
export default LoadingSpinner;