import React from 'react';
import { Button } from 'reactstrap';
import { userRoles } from '../../constants/users';
import { useScreen } from '../../providers/ScreenSize';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleChatDisplay } from '../../store/features/interface';
import NotificationBadge from '../Badge/NotificationBadge';
import ShareMeetingContainer from '../Share/MeetingContainer';
import CameraFlipToggleBtn from './CameraFlipToggle';
import CameraToggleBtn from './CameraToggle';
import CatalogToggleBtn from './CatalogToggle';
import EndCallButton from './EndCallButton';
import MicrophoneToggleBtn from './MicrophoneToggle';

// SALES-V2: Call in redux update
const UserActionsMenu: React.FC = () => {
  const { isChatVisible, chatHasNotifications, chatNotificationCounter, catalogRightsToggled } = useAppSelector((state) => state.appInterface);
  const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
  const userType = useAppSelector((state) => state.auth.userType);
  const dispatch = useAppDispatch();
  const { isMobile } = useScreen();

  const handleChatToggle = React.useCallback(() => dispatch(toggleChatDisplay()), [dispatch]);

  return (
    <div className={`d-flex justify-content-center flex-fill`}>
      <CameraFlipToggleBtn />

      <CameraToggleBtn />

      <MicrophoneToggleBtn />

      {isMobile && <Button
        id="toggle-chat-btn"
        disabled={dailyMeetingState !== 'joined-meeting'}
        color={isChatVisible ? 'primary' : 'secondary'}
        className="position-relative p-2 mx-2 rounded-circle outline-none border-0"
        onClick={handleChatToggle}
      >
        <i className="bx bxs-message-rounded font-size-20 align-middle" />
        <NotificationBadge count={chatNotificationCounter} show={chatHasNotifications} className="top-0 start-100 translate-middle-x" />
      </Button>}

      <EndCallButton />

      {isMobile && <ShareMeetingContainer view='mobile' />}

      {(isMobile && userType === userRoles.CONSUMER && catalogRightsToggled) && <CatalogToggleBtn />}
    </div>
  )
}
export default UserActionsMenu;