import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import useCreateLook from "../../hooks/look/useCreate";
import useError from "../../hooks/useError";
import { CreateLookPayload, Look } from "../../interfaces/Look";
import { useAppDispatch, useAppSelector } from "../../store";
import { getLook, getLooks, setLookView } from "../../store/features/looks";
import LoadingSpinner from "../LoadingSpinner";
import LooksTable from "./Table";
// import SearchLook from "./SearchLook";
import { createMeetingLook } from "../../store/features/meeting";

const LooksListView: React.FC = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector((state) => state.meeting.mainCustomer.profileId);
  const { looksLoading, looksRetrieved, looks } = useAppSelector((state) => state.looks);
  const { handleError } = useError();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const createLook = useCreateLook();

  const handleCreateNewOutfit = React.useCallback(async () => {
    setSubmitting(true);
    const translatedOutfitName = t("OUTFIT");
    const looksNames = looks.reduce(
      (acc: any, l: Look) => ({ ...acc, [l.title]: l._id }),
      {}
    );
    let newLookNameId = 1;
    for (let index = 0; index < looks.length; ++index) {
      if (!looksNames.hasOwnProperty([translatedOutfitName, newLookNameId].join(" ")))
        break;

      ++newLookNameId;
    }
    const payload: CreateLookPayload = {
      title: [translatedOutfitName, newLookNameId].join(" "),
      customerId: customerId,
    };
    createLook({
      payload,
      onSuccess: async (res) => {
        await dispatch(createMeetingLook({ look: res._id })).unwrap();
        dispatch(setLookView("LOOK_DETAILS"));
      },
      onFinally: () => {
        setSubmitting(false);
      }
    });
  }, [customerId, looks, t, createLook, dispatch]);

  const handleDetails = React.useCallback((lookId: string) => {
    dispatch((getLook({ lookId }))).unwrap()
      .then(() => {
        dispatch(setLookView("LOOK_DETAILS"));
      })
      .catch((error) => {
        handleError(error)
      })
  }, [handleError, dispatch]);

  React.useEffect(() => {
    if (!looksRetrieved) dispatch(getLooks()).unwrap()
      .catch((error) => {
        handleError(error)
      })
  }, [looksRetrieved, handleError, dispatch]);

  return (
    <Card className="mb-0 h-100 shadow-none">
      <CardBody className="px-2 pt-2 pb-0 sidebar-list-container">
        {looksLoading && <div className="position-absolute top-50 start-50 translate-middle" >
          <LoadingSpinner size="md" className="text-primary" />
        </div>}
        {!looksLoading && <LooksTable looks={looks} onDetails={handleDetails} />}
      </CardBody>

      {!looksLoading && <CardFooter className="bg-white border-top d-flex">
        {/* <SearchLook /> */}
        <Button color="primary" disabled={submitting} className="ms-auto" onClick={handleCreateNewOutfit}>
          {submitting && <LoadingSpinner isBtn />}
          <i className="bx bxs-t-shirt me-1  align-middle font-size-15" />
          <span className="ms-1">{t("GENERIC.LABELS.OUTFIT_CREATE")}</span>
        </Button>
      </CardFooter>}
    </Card>
  );
};

export default LooksListView;
