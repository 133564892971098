import React from "react";
import { useNavigate } from "react-router-dom";
import { memoPaths } from "../constants/routes";
import { useAppSelector } from "../store";

const useAppNavigation = () => {
  const meetingId = useAppSelector((state) => state.meeting.meetingId);
  const navigate = useNavigate();

  const returnToMeeting = React.useCallback((): void => {
    navigate(`${memoPaths.meeting}${meetingId}`);
  }, [navigate, meetingId]);

  const visitProductDetails = React.useCallback((sku: string): void => {
    navigate(`${memoPaths.meeting}${meetingId}${memoPaths.products.details}${encodeURIComponent(sku)}`);
  }, [navigate, meetingId]);

  return { returnToMeeting, visitProductDetails }
}
export default useAppNavigation;