import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { userRoles } from '../../constants/users';
import { UserType } from '../../interfaces/Auth';

interface PropTypes {
  isShown: boolean;
  toggleModal: () => void;
  userType: UserType;
}

const MqttConnectionErrorModal: React.FC<PropTypes> = ({ isShown, toggleModal, userType }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isShown} centered={true} toggle={toggleModal}>
      <ModalHeader className="border-bottom-3 border-warning" toggle={toggleModal}>
        <i className='bx bx-error rounded-circle bg-light text-warning p-1 border border-warning align-middle me-2' />
        {t("MODALS.MQTT_ERROR.HEADER")}
      </ModalHeader>
      <ModalBody>
        {userType === userRoles.STYLIST && <React.Fragment>
          <p>{t("MODALS.MQTT_ERROR.SUBHEADER.STYLIST")}</p>
          <ul>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_1")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_2")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_3")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_4")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_5")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_6")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.STYLIST_7")}</li>
          </ul>

        </React.Fragment>}
        {userType === userRoles.CONSUMER && <React.Fragment>
          <p>{t("MODALS.MQTT_ERROR.SUBHEADER.CONSUMER")}</p>
          <ul>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.CONSUMER_1")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.CONSUMER_2")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.CONSUMER_3")}</li>
            <li>{t("MODALS.MQTT_ERROR.MESSAGES.CONSUMER_4")}</li>
          </ul>
        </React.Fragment>}

        {/* Unfortunately your connection to Internet doesn't fully support our system for real-time communication between you and the stylist(s).
          Please inform her/him that you wouldn't be able to receive updates on your side nor display catalog and product pages. */}
      </ModalBody>
      <ModalFooter>
        <Button className="mx-auto" color="primary" onClick={toggleModal}>{t("MODALS.MQTT_ERROR.BUTTONS.UNDERSTAND")}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MqttConnectionErrorModal;