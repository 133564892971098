import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import EligoCard from "../../components/Cards/Eligo";
import SuccessCard from "../../components/Cards/Success";
import LoadingSpinner from "../../components/LoadingSpinner";
import api from "../../api";
import { useTranslation } from "react-i18next";
import useError from "../../hooks/useError";

const MeetingConfirmation: React.FC = () => {
  const { meetingId } = useParams();
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { handleError } = useError();

  const handleSubmit = async (): Promise<void> => {
    try {
      if (!meetingId) return;
      setSubmitting(true);
      await api.MeetingService.confirmGuestMeeting(meetingId);
      setConfirmed(true);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message || error);
        handleError(error);
      }
      else toast.error(t("TOASTS.MEETING_CONFIRM.GENERIC_ERROR"));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          {confirmed ? (
            <SuccessCard title={t("GENERIC.LABELS.SUCCESS")} text={t("PAGES.CONFIRM_MEETING.LABELS.SUCCESS_MESSAGE")} />
          ) : (
            <EligoCard title={t("PAGES.CONFIRM_MEETING.HEADER")} description={t("PAGES.CONFIRM_MEETING.SUBHEADER")}>
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <Button
                    className="w-md waves-effect waves-light"
                    color="primary"
                    type="button"
                    disabled={submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <LoadingSpinner isBtn />} {t("GENERIC.BUTTONS.CONFIRM")}
                  </Button>
                </Col>
              </Row>
            </EligoCard>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MeetingConfirmation;
