import React from 'react';
import useMqttMessage from '../../hooks/mqtt/useMessage';
import { MQTT_GRAND_CATALOG_ACCESS } from '../../interfaces/Mqtt';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleCatalogRights } from '../../store/features/interface';
import { ActionMenuButton } from '../Button/ActionMenu';

// SALES-V2: Call in redux update
const CatalogRightsToggleBtn: React.FC = () => {
	const { catalogRightsToggled } = useAppSelector((state) => state.appInterface);
	const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
	const sendMqttMessage = useMqttMessage();
	const dispatch = useAppDispatch();

	const handleToggleCatalogRights = React.useCallback(() => {
		dispatch(toggleCatalogRights(!catalogRightsToggled));
		sendMqttMessage({ type: MQTT_GRAND_CATALOG_ACCESS, payload: !catalogRightsToggled });
	}, [dispatch, sendMqttMessage, catalogRightsToggled]);

	return (
		<ActionMenuButton
			className={catalogRightsToggled ? 'pulse-primary' : undefined}
			disabled={dailyMeetingState !== 'joined-meeting'}
			id="toggle-catalog-rights"
			icon={<i className="bx bx-transfer-alt font-size-20 align-middle" />}
			color={catalogRightsToggled ? 'primary' : 'secondary'}
			onClick={handleToggleCatalogRights}
			tooltip={catalogRightsToggled ? "GENERIC.BUTTONS.REVOKE_CATALOG_ACCESS" : "GENERIC.BUTTONS.GRAND_CATALOG_ACCESS"}
		/>
	)
}
export default CatalogRightsToggleBtn;