import { useState, useEffect } from "react";
import debounce from "debounce";

const useWindowResize = (): { width: number, height: number, updateNumber: number } => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [updateNumber, setUpdateNumber] = useState<number>(0);

  const handleResize = debounce(() => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    setUpdateNumber(updateNumber + 0.001);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize.clear();
    };
  }, [handleResize]);
  return { width, height, updateNumber };
};

export default useWindowResize;
