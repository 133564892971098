import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button, UncontrolledTooltip } from 'reactstrap'
import { toggleChatDisplay } from '../../store/features/interface';
import NotificationBadge from '../Badge/NotificationBadge';


const ChatFAB: React.FC = () => {
  const { isChatVisible, chatNotificationCounter, chatHasNotifications } = useAppSelector((state) => state.appInterface)
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const toggleChat = (): void => {
    dispatch(toggleChatDisplay())
  }

  return (
    <Button id="chat-fab-btn" color="primary" className="position-absolute top-100 start-100 translate-middle rounded-circle p-3" onClick={toggleChat} style={{ zIndex: 50 }}>
      <i className="bx bxs-message-rounded font-size-20 align-middle" />
      <NotificationBadge count={chatNotificationCounter} show={chatHasNotifications} className="fs-6 top-0 start-100 translate-middle-x" />
      <UncontrolledTooltip fade={false} placement="left" target="chat-fab-btn">
        {isChatVisible ? t("GENERIC.BUTTONS.CLOSE_CHAT") : t("GENERIC.BUTTONS.OPEN_CHAT")}
      </UncontrolledTooltip>
    </Button >
  )
}
export default ChatFAB;