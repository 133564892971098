import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';

interface PropTypes {
  handleBack: () => void
}

const ProductError: React.FC<PropTypes> = ({ handleBack }) => {
  const { t } = useTranslation();
  return (
    <Row className="m-1" style={{ minHeight: "25.5rem" }}>
      <Col className="col-12 text-center">
        <h2 className="text-center mt-5">{t("GENERIC.LABELS.PRODUCT_NOT_FOUND")}</h2>
        <Button color="none" size="md" className="text-primary shadow-none outline-none " onClick={handleBack}>
          <i className="fa fa-arrow-left font-size-18 align-middle me-3" />
          <span>{t("GENERIC.BUTTONS.GO_BACK")}</span>
        </Button>
      </Col>
    </Row>
  )
}
export default ProductError;