import React, { RefObject } from "react";
import useOnClickInside from "../../hooks/useOnClickInside";
import useWindowResize from "../../hooks/useWindowResize";
import { LookProduct } from "../../interfaces/Product";
import { useAppDispatch, useAppSelector } from "../../store";
import { setSelectedItem } from "../../store/features/looks";
import ProductComposerItem from "./Item";
import useLookProductActions from "./useLookProductActions";

interface PropTypes {
  containerRef: RefObject<HTMLDivElement>;
}

const ProductComposerContainer: React.FC<PropTypes> = ({ containerRef }) => {
  const { handleDragStop, handleResizeStop, handleRotation, handleZIndex, handleRemoveLookProduct } = useLookProductActions();
  const { lookDetailsView, lookProductsCurrent, selectedItem } = useAppSelector((state) => state.looks)
  const userType = useAppSelector((state) => state.auth.userType);
  const dispatch = useAppDispatch();
  const [containerBounds, setContainerBounds] = React.useState<{ top: number, left: number, bottom: number, right: number } | undefined>(undefined);
  const { height, width } = useWindowResize();
  const containerClickedHandler = React.useCallback(() => { dispatch(setSelectedItem("")) }, [dispatch]);

  useOnClickInside(containerRef, containerClickedHandler);

  const handleMouseDown = React.useCallback((productId: string): void => {
    dispatch(setSelectedItem(productId));
  }, [dispatch]);

  React.useEffect(() => {
    // NOTE: Look product outof bounding box issue is caused due to the fact that tabs are not rendedered conditionally,
    // but instead when the parent component is rendered, as a result
    // the container has 0 size dimensions, resulting in first render to have a bad bounding box
    if (containerRef && containerRef.current && lookDetailsView === 'LOOK_PRODUCTS_COMPOSER') {
      const rect = containerRef.current.getBoundingClientRect();// TODO:  figure out how to correctly get position 
      setContainerBounds({
        left: Math.round(0),
        right: Math.round(rect.width),
        bottom: Math.round(rect.height),
        top: Math.round(0)
      })
    }
  }, [containerRef, lookDetailsView, height, width]);   // selectedItem, lookProductsCurrent

  return (
    <React.Fragment>
      {containerBounds && lookProductsCurrent.map((p: LookProduct) => (
        <ProductComposerItem
          userType={userType}
          key={p._id}
          product={p}
          containerRef={containerRef}
          containerBounds={containerBounds}
          isActive={p._id === selectedItem}
          onDragStop={handleDragStop}
          onRotateStop={handleRotation}
          onResizeStop={handleResizeStop}
          onMouseDown={handleMouseDown}
          onRemove={handleRemoveLookProduct}
          onZIndex={handleZIndex}
        />
      ))}
    </React.Fragment>
  );
};
export default ProductComposerContainer;