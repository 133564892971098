import axios, { AxiosResponse } from 'axios';
import { MqttConfigOptions } from '../interfaces/Mqtt';
import { constructRequestHeaders, SERVER_URL } from "./helpers";



export default class MqttService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/queue/register/mqtt`;
  }

  async registerMqtt(): Promise<MqttConfigOptions> {
    try {
      const response: AxiosResponse<{ data: MqttConfigOptions, meta: {} }> = await axios.get(`${this.baseUrl}`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}