import React from 'react';
import { useTranslation } from 'react-i18next';
import { productDescriptionLimit } from '../../common/appSettings';

interface ProductDescriptionProps {
  description: string;
};

const ProductDescription: React.FC<ProductDescriptionProps> = ({ description }) => {
  const [toggleMore, setToggleMore] = React.useState<boolean>(true);
  const { t } = useTranslation();

  const cleanedDescription = React.useMemo(() => {
    const doc = new DOMParser().parseFromString(description, 'text/html');
    return doc.body.textContent || "";
  }, [description]);

  const exceedLimit = React.useMemo(() => cleanedDescription.length >= productDescriptionLimit, [cleanedDescription]);

  const finalDescription = React.useMemo(() => {
    let result = cleanedDescription;
    if (exceedLimit) {
      if (toggleMore) result = cleanedDescription.slice(0, productDescriptionLimit) + "...";
      result += " ";
    } else result = cleanedDescription;
    return result;
  }, [exceedLimit, cleanedDescription, toggleMore]);

  const toggleReadMore = React.useCallback(() => setToggleMore((curr) => !curr), []);

  return (
    <p className="text-muted my-3 font-size-11">
      {finalDescription}
      {exceedLimit ? <button
        className="text-black px-0 font-size-12 border-0 bg-transparent"
        onClick={toggleReadMore}
      >
        {toggleMore ? t("GENERIC.BUTTONS.SHOW_MORE") : t("GENERIC.BUTTONS.SHOW_LESS")}
      </button> : null}
    </p>
  )
}
export default ProductDescription;