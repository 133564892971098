import React from "react";
import { useAppSelector } from "../../store";
import LooksDetailsView from "../Looks/DetailsView";
import LooksListView from "../Looks/ListView";

const Looks: React.FC = () => {
  const lookView = useAppSelector((state) => state.looks.lookView);
  return (
    <React.Fragment>
      {lookView === "LOOK_LIST" && <LooksListView />}
      {lookView === "LOOK_DETAILS" && <LooksDetailsView />}
    </React.Fragment>
  );
};
export default Looks;
