import classNames from 'classnames';
import { useMqttState } from 'mqtt-react-hooks';
import React from 'react';
import { useScreen } from '../../providers/ScreenSize';
import { useAppSelector } from '../../store';
import MqttErrorIndicator from './MqttErrorIndicator';

const StatusIndicators: React.FC = () => {
  const { client } = useMqttState();
  const userType = useAppSelector((state) => state.auth.userType);
  const { isMobile } = useScreen();

  return (
    <div className={classNames("position-absolute top-100 translate-middle", { "end-0": isMobile, "start-0": !isMobile })} style={{ zIndex: 50 }}>
      <div className="d-flex flex-row">
        {!client?.connected && <MqttErrorIndicator userType={userType} />}
      </div>
    </div>
  )
}
export default StatusIndicators;