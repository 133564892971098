import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, Table, UncontrolledTooltip } from 'reactstrap';
import { cartDiscountInitialValues, cartDiscountOptions } from '../../constants/cart';
import { userRoles } from '../../constants/users';
import { formatPrice } from '../../helpers/product';
import useCartActions from '../../hooks/cart/useCartActions';
import useMqttMessage from '../../hooks/mqtt/useMessage';
import useError from '../../hooks/useError';
import { CartDiscount } from '../../interfaces/Cart';
import { MQTT_DELETE_CART, MQTT_REFRESH_CART, MQTT_VIEW_PAYMENT } from '../../interfaces/Mqtt';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateCart } from '../../store/features/cart';
import { setPaymentStatusModal, togglePaymentModal } from '../../store/features/interface';
import { createOrder } from '../../store/features/order';
import LoadingSpinner from '../LoadingSpinner';
import DiscountModal from '../Modals/DiscountModal';
import TableHeaders from '../Table/TableHeaders';
import CartTableContent from './CartTableContent';
import useOrderStatusPolling from './useOrderStatusPolling';

const Cart: React.FC = () => {
  const dispatch = useAppDispatch();
  const { cartTotal, cartSubtotal, cartProducts, cartLoading, discount } = useAppSelector((state) => state.cart);
  const userType = useAppSelector((state) => state.auth.userType);
  const { orderCreating, paymentUrl, orderStatus, orderId } = useAppSelector((state) => state.order);
  const paymentModalToggled = useAppSelector((state) => state.appInterface.paymentModalToggled)
  const { deleteFromCart } = useCartActions();
  const sendMqttMessage = useMqttMessage();
  const { handleError } = useError();
  const { t } = useTranslation();
  const { checkOrderStatus, isPolling } = useOrderStatusPolling();
  const [showDiscountModal, setShowDiscountModal] = React.useState<boolean>(false);

  const removeCartItem = (productId: string): void => {
    if (userType === userRoles.STYLIST) {
      deleteFromCart(productId);
    } else if (userType === userRoles.CONSUMER) {
      sendMqttMessage({ type: MQTT_DELETE_CART, payload: { productId: productId } });
    }
  }

  const handleCheckout = React.useCallback(async (): Promise<void> => {
    if (cartProducts.length > 0 || paymentUrl) {

      if (paymentUrl) {
        sendMqttMessage({ type: MQTT_VIEW_PAYMENT, payload: { orderId: orderId! } })
        dispatch(setPaymentStatusModal(true));
        checkOrderStatus({ orderId: orderId!, timelapsed: 0 })
      }
      else {
        await dispatch(createOrder()).unwrap().then((res) => {
          toast.info(t("TOASTS.ORDER.CREATE_SUCCESS"));
          dispatch(setPaymentStatusModal(true));
          checkOrderStatus({ orderId: res.id, timelapsed: 0 })

          sendMqttMessage({ type: MQTT_VIEW_PAYMENT, payload: { orderId: res.id } });
        }).catch((error: any) => {
          if (error?.status !== 401) toast.error(t("TOASTS.ORDER.CREATE_FAILED"));
          handleError(error);
        });
      }

    }
  }, [dispatch, handleError, sendMqttMessage, checkOrderStatus, t, orderId, cartProducts, paymentUrl]);

  const handleTogglePaymentModal = React.useCallback((): void => {
    dispatch(togglePaymentModal());
    if (!paymentModalToggled && !isPolling) {
      checkOrderStatus({ orderId: orderId!, timelapsed: 0 });
    }
  }, [dispatch, checkOrderStatus, isPolling, orderId, paymentModalToggled]);

  const toggleDiscountModal = React.useCallback((): void => {
    setShowDiscountModal(!showDiscountModal);
  }, [setShowDiscountModal, showDiscountModal]);

  const handleSubmitDiscount = React.useCallback((formData: CartDiscount) => {
    const discount = formData.type === cartDiscountOptions.PERCENTAGE && formData.value === 0 ? cartDiscountInitialValues : formData;
    dispatch(updateCart({ discount })).unwrap()
      .then(() => {
        toast.success(t("TOASTS.CART.DISCOUNT.SUCCESS"));
        sendMqttMessage({ type: MQTT_REFRESH_CART });
        toggleDiscountModal();
      })
      .catch((error) => {
        toast.error(t("TOASTS.CART.DISCOUNT.ERROR"))
        handleError(error);
      })
  }, [dispatch, handleError, sendMqttMessage, t, toggleDiscountModal])

  return (
    <React.Fragment>
      <Card className="mb-0 h-100 shadow-none">
        <CardBody className="px-2 pt-2 sidebar-list-container">
          <div className="table-responsive">
            <Table className="table align-middle mb-0 table-nowrap">
              <TableHeaders>
                <th className="w-25" scope="col">
                  {t("GENERIC.LABELS.PRODUCT")}
                </th>
                <th className="w-50" scope="col">
                  {t("GENERIC.LABELS.DESCRIPTION")}
                </th>

              </TableHeaders>
              <CartTableContent products={cartProducts} onRemove={removeCartItem} />
            </Table>
          </div>
        </CardBody>
        <CardFooter className="bg-white border-top d-flex">
          {orderStatus !== 'paid' && <React.Fragment>

            <div className="my-auto">
              {discount && !(discount.type === cartDiscountOptions.FIXED && discount.value === 0) && <React.Fragment>
                <p className="font-size-13 mb-0">
                  <strong>{t("GENERIC.LABELS.DISCOUNT")}:{" "}</strong>
                  {discount.type === cartDiscountOptions.FIXED ? formatPrice(discount.value) : `${discount.value} %`}
                </p>
                <p className="font-size-13 mb-0">
                  <strong>{t("GENERIC.LABELS.SUBTOTAL")}:{" "}</strong>
                  {formatPrice(cartSubtotal)}
                </p>
              </React.Fragment>}
              <p className="font-size-13 mb-0">
                <strong>{t("GENERIC.LABELS.TOTAL")}:{" "}</strong>
                {formatPrice(cartTotal)}
              </p>
            </div>

            {userType === userRoles.CONSUMER && paymentUrl &&
              <Button color="success" className="ms-auto font-size-13" onClick={handleTogglePaymentModal}>
                <i className='bx bx-cart align-middle' />
                <span className="ms-1 align-middle">
                  {t("GENERIC.LABELS.CHECKOUT")}
                </span>
              </Button>}

            {userType === userRoles.STYLIST && <div className="ms-auto mt-auto">

              <Button id="cart-discount-btn" color="primary" className="font-size-13 me-2" disabled={isPolling || orderCreating || cartLoading} onClick={toggleDiscountModal}>
                <i className='bx bxs-discount align-middle font-size-17' />
                <UncontrolledTooltip fade={false} placement="top" target="cart-discount-btn">
                  {t("GENERIC.BUTTONS.DISCOUNT")}
                </UncontrolledTooltip>
              </Button>

              <Button color="success" className="font-size-13" disabled={(cartProducts.length === 0 && !paymentUrl) || isPolling || orderCreating || cartLoading} onClick={handleCheckout}>
                {isPolling || orderCreating || cartLoading ? <LoadingSpinner isBtn className="align-middle" /> : <i className='bx bx-cart align-middle font-size-17' />}
                <span className="ms-1 align-middle">
                  {!paymentUrl ? t("GENERIC.LABELS.CHECKOUT") : t("GENERIC.LABELS.PAYMENT_SHOW")}
                </span>
              </Button>

            </div>}
          </React.Fragment>}
          {orderStatus === 'paid' && <div className="w-100 text-center">
            <p className="font-size-13 py-2 mb-0">{t("GENERIC.LABELS.PAYMENT_COMPLETED")}</p>
            <div className="me-auto p-2">
              <span className="font-size-13">
                <strong className="me-5">{t("GENERIC.LABELS.TOTAL")}</strong>
                {formatPrice(cartTotal)}
              </span>
            </div>
          </div>}
        </CardFooter>
      </Card>
      <DiscountModal
        isOpen={showDiscountModal}
        toggleModal={toggleDiscountModal}
        confirm={handleSubmitDiscount} />
    </React.Fragment>
  )
}
export default Cart;