import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { badgesDisplayDays } from "../../constants/looks";
import { formatPrice } from "../../helpers/product";
import { Look } from "../../interfaces/Look";
import IconButton from "../Button/Icon";
import InlineEdit from "./InlineEdit";

interface PropTypes {
  look: Look;
  looks: Look[];
  onDetails: (lookId: string) => void;
  onEdit: (look: Look) => void;
  onDelete: (look: Look) => void;
}

const LooksTableRow: React.FC<PropTypes> = ({
  look,
  looks,
  onDetails,
  onEdit,
  onDelete,
}) => {
  const daysFromCreation = new Date(Date.parse(look.createdAt)).getDay();
  const daysFromLastUpdate = new Date(Date.parse(look.updatedAt)).getDay();
  const { t } = useTranslation();
  const [editing, setEditing] = React.useState<boolean>(false);
  const handleToggle = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.stopPropagation();
    setEditing((v) => !v);
  };

  return (
    <React.Fragment>
      {editing ? (
        <tr>
          <td colSpan={3}>
            <InlineEdit look={look} looks={looks} onClose={handleToggle} />
          </td>
        </tr>
      ) : (
        <tr onClick={() => onDetails(look._id)} style={{ cursor: "pointer" }}>
          <td>
            <h5 className="font-size-13 text-wrap text-dark product-card-description">
              <IconButton
                icon="bx bx-pencil"
                color="btn-primary"
                className="me-1"
                onClick={handleToggle}
              />
              {look.title || "-"}
            </h5>
            {daysFromCreation <= badgesDisplayDays.create ? (
              <h5 className="mb-0">
                <span className="badge bg-danger">
                  {t("GENERIC.LABELS.NEW")}
                </span>
              </h5>
            ) : (
              <React.Fragment>
                {daysFromLastUpdate <= badgesDisplayDays.update ? (
                  <h5 className="mb-0">
                    <span className="badge bg-warning">
                      {t("GENERIC.LABELS.RECENTLY_UPDATED")}
                    </span>
                  </h5>
                ) : null}
              </React.Fragment>
            )}
          </td>
          <td>{formatPrice(look.total)}</td>
          <td className="text-center">
            <Button
              color="secondary"
              size="sm"
              className="rounded-circle me-1 p-2"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(look);
              }}
            >
              <i className="bx bxs-cog align-middle font-size-18" />
            </Button>
            <Button
              color="danger"
              outline
              size="sm"
              className="rounded-circle p-2"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(look);
              }}
            >
              <i className="bx bx-trash align-middle font-size-18" />
            </Button>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default LooksTableRow;
