export const memoPaths = {
  default: "/",
  userEntry: { stylist: '/stylist/', consumer: '/consumer/' },
  showroom: "/showroom/",
  meeting: "/meeting/",
  products: { details: '/details/' },

  /////////
  confirmation: "/confirmation",
  success: "/success",
  cancel: "/cancel"
}