import React from "react";
import { toast } from "react-toastify";
import api from "../api";
import {
  buildProductAddPayload,
  buildProductUpdatePayload,
  buildAddProps,
  buildRemoveProps,
  buildUpdateProps,
} from "../helpers/product";
import { CartProductPayload, UpdateCartProductPayload } from "../interfaces/Cart";
import { MQTT_REFRESH_CART } from "../interfaces/Mqtt";
import { CartProduct } from "../interfaces/Product";
import { useAppDispatch } from "../store";
import { retrieveCart, setCartLoading } from "../store/features/cart";
import useMqttMessage from "./mqtt/useMessage";
import useFetch from "./useFetch";
import useFetchSimple from "./useFetchSimple";
import i18next from "i18next";

// TODO: refactor with redux dispatch
const actionList: any = {
  add: async (cartId: string, payload: CartProductPayload) => {
    await api.CartService.addCartItem(cartId, payload);
  },
  update: (cartId: string, itemId: string, payload: UpdateCartProductPayload) => api.CartService.updateCartItem(cartId, itemId, payload),
  remove: (cartId: string, itemId: string) => api.CartService.removeCartItem(cartId, itemId),
  refetch: retrieveCart,
};

const onSuccessAdd = (sendMqttMessage: any, cartId: string) => () => {
  sendMqttMessage({ type: MQTT_REFRESH_CART, payload: { cartId } });
  toast.info(i18next.t('TOASTS.CART.ITEM_ADDED'));
}

const onSuccessUpdate = (sendMqttMessage: any, cartId: string) => () => {
  sendMqttMessage({ type: MQTT_REFRESH_CART, payload: { cartId } });
  toast.info(i18next.t('TOASTS.CART.ITEM_UPDATED'));
}

const onSuccessRemove = (sendMqttMessage: any, cartId: string) => () => {
  sendMqttMessage({ type: MQTT_REFRESH_CART, payload: { cartId } });
  toast.info(i18next.t('TOASTS.CART.ITEM_REMOVED'));
}

const onError = (dispatch: any) => () => {
  dispatch(setCartLoading(false));
}

const useProductHandler = () => {
  const addProduct = useFetch(actionList.add, actionList.refetch);
  const updateProduct = useFetch(actionList.update, actionList.refetch);
  const removeProduct = useFetch(actionList.remove, actionList.refetch);
  const sendMqttMessage = useMqttMessage();
  const dispatch = useAppDispatch();

  return React.useCallback(({ cartId, product }: { cartId: string, product: any }) => {
    if (product.qty <= 0) {
      dispatch(setCartLoading(true));
      removeProduct(buildRemoveProps(cartId, product, onSuccessRemove(sendMqttMessage, cartId), onError(dispatch)));
    }
    else if (product._id) {
      dispatch(setCartLoading(true));

      updateProduct(buildUpdateProps(cartId, product, onSuccessUpdate(sendMqttMessage, cartId), onError(dispatch)));
    }
    else if (!product._id) {
      dispatch(setCartLoading(true));
      addProduct(buildAddProps(cartId, product, onSuccessAdd(sendMqttMessage, cartId), onError(dispatch)));
    }
    else console.log("Wrong product");
  }, [addProduct, updateProduct, removeProduct, sendMqttMessage, dispatch]);
};

export const useProductMultiHandler = () => {
  const [retrieveCart] = useFetchSimple(actionList.refetch, false);
  const sendMqttMessage = useMqttMessage();
  const dispatch = useAppDispatch();

  return React.useCallback(
    async ({ cartId, products }: { cartId: string, products: Omit<CartProduct, 'price'>[] }) => {
      try {
        dispatch(setCartLoading(true));
        for (let index = 0; index < products.length; index++) {
          if (products[index]._id)
            await actionList.update(cartId, products[index]._id, buildProductUpdatePayload(products[index]))
          else
            await await actionList.add(cartId, buildProductAddPayload(products[index]))
        }
        onSuccessAdd(sendMqttMessage, cartId)();
        retrieveCart({ cartId });
      } catch (error) {
        console.log(error)
      } finally {
        dispatch(setCartLoading(false));
      }
    },
    [retrieveCart, sendMqttMessage, dispatch]
  );
}

export default useProductHandler;