import classNames from 'classnames';
import React from 'react';
import { Card } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner';
import Logo from '../Logo';

interface PropTypes {
  isMobile?: boolean;
}

const LoadingScreen: React.FC<PropTypes> = ({ isMobile }) => {
  return (
    <div className={classNames('d-flex flex-column justify-content-center screen-loading', { 'h-100': isMobile, 'core-content rounded mt-5': !isMobile })}>
      <Card className="p-3 mx-auto text-center">
        <h2>
          <Logo />
        </h2>
      </Card>
      <LoadingSpinner className="text-white mx-auto" size="lg" />
    </div>
  )
}
export default LoadingScreen;