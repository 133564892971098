import classNames from 'classnames';
import React from 'react';
import { Button, ButtonProps, UncontrolledTooltip } from 'reactstrap';

interface PropTypes {
  tooltip?: string;
}

const FabButton: React.FC<PropTypes & ButtonProps> = (props) => {
  const { color, style, tooltip, children, id, className, onClick, ...rest } = props;

  return (
    <Button
      id={id}
      color={color || 'primary'}
      style={{ ...style, zIndex: 50 }}
      className={classNames("position-sticky rounded-pill me-2", className)}
      onClick={onClick}
      {...rest}
    >
      {children}
      {tooltip && id && <UncontrolledTooltip fade={false} placement="left" target="chat-fab-btn">
        {tooltip}
      </UncontrolledTooltip>}
    </Button>
  )
}
export default FabButton;