import React from "react";
import { CreateLookPayload } from "../../interfaces/Look";
import { useAppDispatch } from "../../store";
import { createLook, getLook } from "../../store/features/looks";
import useError from "../useError";
import useFetchSimple from "../useFetchSimple";

const useCreateLook = () => {
  const dispatch = useAppDispatch();
  const { handleError } = useError();
  const [fetchLook] = useFetchSimple(getLook, false);

  return React.useCallback(async ({ payload, onSuccess, onFinally }: { payload: CreateLookPayload, onSuccess: (params?: any) => void, onFinally?: () => void }) => {
    try {
      const response = await dispatch(createLook(payload)).unwrap();
      fetchLook({ lookId: response._id }, onSuccess);
    } catch (error) {
      handleError(error);
    } finally {
      if (onFinally) onFinally();
    }
  }, [handleError, dispatch, fetchLook]);
}
export default useCreateLook;
