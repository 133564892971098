import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardHeader, Col, Row } from "reactstrap";
import CustomBreadcrumb from "../../components/Breadcrumb";
import BackButton from "../../components/Button/Back";
import LoadingSpinner from "../../components/LoadingSpinner";
import ProductDetails from "../../components/ProductDetails";
import ProductError from "../../components/ProductDetails/ProductError";
import ProductPrice from "../../components/ProductDetails/ProductPrice";
import Typography from "../../components/Typography";
import { userRoles } from "../../constants/users";
import { constructInitialProduct } from "../../helpers/product";
import useCartActions from "../../hooks/cart/useCartActions";
import useMqttMessage from "../../hooks/mqtt/useMessage";
import useAppNavigation from "../../hooks/useAppNavigation";
import useError from "../../hooks/useError";
import { MQTT_ADD_CART, MQTT_CONFIGURATOR_SET_VARIANTS, MQTT_CONFIGURATOR_TOGGLE } from "../../interfaces/Mqtt";
import { VariantProduct } from "../../interfaces/Product";
import FiltersLayout from "../../layouts/FiltersLayout";
import { useScreen } from "../../providers/ScreenSize";
import { useAppDispatch, useAppSelector } from "../../store";
import { getDetails, setConfiguratorVariants, toggleConfigurator } from "../../store/features/details";
import ConfiguratorWrapper from "./configuratorWrapper";

const ProductDetailsPage: React.FC = () => {
  const [activeVariant, setActiveVariant] = React.useState<VariantProduct>();
  const { sidebarCollapsed, catalogRightsToggled } = useAppSelector((state) => state.appInterface);
  const { product, detailsLoading, showConfigurator, configuratorActivePanel, configuratorData, configuratorStep, configuratorVariants, hasValidConfiguratorConfig } = useAppSelector((state) => state.details);
  const { isMobile } = useScreen();
  const { productId } = useParams<string>();
  const { handleError } = useError();
  const { returnToMeeting } = useAppNavigation();
  const dispatch = useAppDispatch();
  const prevProductId = React.useRef<string | undefined>(undefined);
  const { addToCart } = useCartActions();
  const userType = useAppSelector((state) => state.auth.userType);
  const sendMqttMessage = useMqttMessage();
  const { i18n, t } = useTranslation();

  const getProduct = React.useCallback(
    async (productId: string): Promise<void> => {
      dispatch(getDetails(productId))
        .unwrap()
        .then(({ product }) => {
          setActiveVariant(constructInitialProduct(product));
          prevProductId.current = productId;
        })
        .catch((error: any) => {
          handleError(error);
        });
    }, [handleError, dispatch]);

  const handleChange = (value: VariantProduct): void => setActiveVariant((prevState) => ({ ...prevState, ...value }));

  const onSuccess = React.useCallback((output: any) => {
    console.log(output)
    if (product) {
      if (userType === userRoles.STYLIST) {
        addToCart({ baseSku: product.sku, variants: output });
      } else if (userType === userRoles.CONSUMER) {
        sendMqttMessage({ type: MQTT_ADD_CART, payload: { baseSku: product.sku, variants: output } });
      }
      dispatch(setConfiguratorVariants(output));
      sendMqttMessage({ type: MQTT_CONFIGURATOR_SET_VARIANTS, payload: { sku: product.sku, variants: output } });
      sendMqttMessage({ type: MQTT_CONFIGURATOR_TOGGLE, payload: { sku: product.sku, status: false } });
    };
    dispatch(toggleConfigurator(false));
  }, [addToCart, sendMqttMessage, dispatch, product, userType]);

  const displayBreadcrumbs: boolean = React.useMemo(() => {
    return userType === userRoles.STYLIST || (userType === userRoles.CONSUMER && catalogRightsToggled)
  }, [userType, catalogRightsToggled]);

  const onConfiguratorClose = React.useCallback(() => {
    dispatch(toggleConfigurator(false));
    if (product)
      sendMqttMessage({ type: MQTT_CONFIGURATOR_TOGGLE, payload: { sku: product.sku, status: false } });
  }, [dispatch, sendMqttMessage, product]);

  React.useEffect(() => {
    if (productId && prevProductId.current !== productId) getProduct(productId);
  }, [productId, getProduct]);

  const handleConfiguratorSuccess = React.useCallback((e: any) => {
    const { customization } = (e as CustomEvent).detail
    try {
      let result = JSON.parse(customization);
      if (result)
        onSuccess(result);
    } catch (error) {
      toast.error(t('TOASTS.CONFIGURATOR.GENERIC_ERROR'));
      if (product)
        sendMqttMessage({ type: MQTT_CONFIGURATOR_TOGGLE, payload: { sku: product.sku, status: false } });
      dispatch(toggleConfigurator(false));
    }
  }, [onSuccess, dispatch, t, sendMqttMessage, product]);

  React.useEffect(() => {
    if (hasValidConfiguratorConfig) {
      window.addEventListener('configurator-close', onConfiguratorClose);
      window.addEventListener('configurator-submit', handleConfiguratorSuccess);
    };
    return () => {
      window.removeEventListener('configurator-close', onConfiguratorClose);
      window.removeEventListener('configurator-submit', handleConfiguratorSuccess);
    }
  }, [hasValidConfiguratorConfig, onConfiguratorClose, onSuccess, handleConfiguratorSuccess]);

  const configuratorState = React.useMemo(() => {
    try {
      return JSON.stringify(configuratorVariants);
    } catch (error) {
      // NOTE: do nothing
    }
  }, [configuratorVariants]);

  return (
    <React.Fragment>
      {isMobile && (
        <div className="h-100">
          <Row className="h-100 w-100 m-auto">
            <Col className="overflow-auto h-100">
              {!detailsLoading && product && activeVariant && (
                <Card className="p-0 my-2">
                  {displayBreadcrumbs && <CustomBreadcrumb product={product} />}
                  <CardHeader className="bg-transparent px-2 mb-0 d-flex">
                    {!displayBreadcrumbs && <BackButton onClick={returnToMeeting} className="ms-auto align-self-start" />}
                    <div className="text-center w-100">
                      <Typography text={product?.name || '-'} Variant="h4" className="mb-2" />
                      <ProductPrice className="font-size-16" price={activeVariant.price} type={product.__typename} hasSelection={activeVariant.selected} />
                    </div>
                  </CardHeader>
                  {showConfigurator && configuratorData && hasValidConfiguratorConfig ? (
                    <ConfiguratorWrapper baseSku={product.sku}>
                      <garments-tool
                        name={product.name}
                        language={i18n.language}
                        config={configuratorData}
                        onClose={onConfiguratorClose}
                        price={product.price_range.maximum_price.final_price.value.toString()}
                        initialconfig={configuratorState ? configuratorState : ""}
                        initialstep={configuratorStep.toString()}
                        initialactivepanel={configuratorActivePanel}
                      />
                    </ConfiguratorWrapper>
                  ) : (
                    <ProductDetails
                      hasValidConfiguratorConfig={hasValidConfiguratorConfig}
                      userType={userType}
                      hasSelection={activeVariant.selected}
                      isMobile={isMobile}
                      price={activeVariant.price}
                      sku={activeVariant.sku}
                      images={activeVariant.images}
                      product={product}
                      onChange={handleChange}
                    />
                  )}

                </Card>
              )}
              {detailsLoading && (
                <div className="position-absolute top-50 start-50 translate-middle">
                  <LoadingSpinner className="text-primary" size="lg" />
                </div>
              )}
              {!detailsLoading && !product && <ProductError handleBack={returnToMeeting} />}
            </Col>
          </Row>
        </div>
      )}

      {!isMobile && (
        <Col md={sidebarCollapsed ? 12 : 8} className="h-100 pe-0">
          <div className={classnames("h-100", { rounded: !isMobile })}>
            <FiltersLayout>

              {detailsLoading && <div className="position-absolute top-50 start-50 translate-middle">
                <LoadingSpinner className="text-primary" size="lg" />
              </div>}

              {!detailsLoading && !product && <ProductError handleBack={returnToMeeting} />}

              {!detailsLoading && product && activeVariant && (
                <React.Fragment>
                  {displayBreadcrumbs && <CustomBreadcrumb product={product} />}
                  {showConfigurator && configuratorData && hasValidConfiguratorConfig ? (
                    <div className="w-100 m-auto overflow-auto h-100">
                      <ConfiguratorWrapper baseSku={product.sku}>
                        <garments-tool
                          name={product.name}
                          language={i18n.language}
                          config={configuratorData}
                          onClose={onConfiguratorClose}
                          price={product.price_range.maximum_price.final_price.value.toString()}
                          initialconfig={configuratorState ? configuratorState : ""}
                          initialstep={configuratorStep.toString()}
                          initialactivepanel={configuratorActivePanel}
                        />
                      </ConfiguratorWrapper>
                    </div>
                  ) : (
                    <ProductDetails
                      hasValidConfiguratorConfig={hasValidConfiguratorConfig}
                      userType={userType}
                      isMobile={isMobile}
                      hasSelection={activeVariant.selected}
                      price={activeVariant.price}
                      sku={activeVariant.sku}
                      images={activeVariant.images}
                      product={product}
                      onChange={handleChange}
                    />
                  )}
                </React.Fragment>
              )}
            </FiltersLayout>
          </div>
        </Col>
      )}
    </React.Fragment>
  );
};
export default ProductDetailsPage;
