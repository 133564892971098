import React from 'react';
import { useAppSelector } from '../../store';
import * as Yup from 'yup';
import ModalForm from '../Forms/FormModal';
import { cartDiscountInitialValues, cartDiscountOptions } from '../../constants/cart';
import CartDiscountUpdateFields from '../Cart/DiscountUpdateFields';
import Typography from '../Typography';
import { CartDiscount } from '../../interfaces/Cart';


interface PropTypes {
  toggleModal: () => void;
  isOpen: boolean;
  confirm: (values: CartDiscount) => void;
}

const validationSchema = Yup.object().shape({
  type: Yup.string().oneOf([cartDiscountOptions.PERCENTAGE, cartDiscountOptions.FIXED]).required(),
  value: Yup.number().required("MODALS.CART_DISCOUNT.INPUTS.AMOUNT.ERRORS.INVALID").when('type', {
    is: cartDiscountOptions.PERCENTAGE,
    then: (amountSchema) => amountSchema.min(0, 'MODALS.CART_DISCOUNT.INPUTS.AMOUNT.ERRORS.MIN_LIMIT').max(100, 'MODALS.CART_DISCOUNT.INPUTS.AMOUNT.ERRORS.MAX_LIMIT'),
    otherwise: (amountSchema) => amountSchema.min(0, 'MODALS.CART_DISCOUNT.INPUTS.AMOUNT.ERRORS.MIN_LIMIT'),
  })
});

const DiscountModal: React.FC<PropTypes> = ({ toggleModal, isOpen, confirm }) => {
  const { cartSubtotal: total, discount } = useAppSelector((state) => state.cart);

  const initialFormData = React.useMemo(() => ({
    ...cartDiscountInitialValues,
    ...discount
  }), [discount]);

  return (
    <ModalForm
      modalOpen={isOpen}
      modalTitle="MODALS.CART_DISCOUNT.HEADER"
      initialValues={initialFormData}
      validationSchema={validationSchema}
      onSubmit={confirm}
      onCancel={toggleModal}
      mode="edit"
      modalSize="sm"
    >
      <Typography Variant={'p'} className="text-center d-inline" text={"MODALS.CART_DISCOUNT.SUBHEADER"} />
      <CartDiscountUpdateFields total={total} />
    </ModalForm>
  )
}
export default DiscountModal;