import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, Popover, PopoverBody } from 'reactstrap';
import { useAppSelector } from '../../store';
import LoadingSpinner from '../LoadingSpinner';

interface PropTypes {
  toggleModal: () => void;
  isOpen: boolean;
  paymentUrl?: string;
  isComplete?: boolean;
}

const PaymentStatusModal: React.FC<PropTypes> = ({ isOpen, toggleModal, paymentUrl, isComplete }) => {
  const isLocalMode = useAppSelector((state) => state.appInterface.isLocalMode);
  const [popoverVisible, setPopoverVisible] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleCopy = (): void => {
    if (paymentUrl) {
      navigator.clipboard.writeText(paymentUrl);
      toast.info(t("TOASTS.LINK_COPY"));
    }
  }

  const handleClose = React.useCallback(() => {
    if (isComplete) {
      toggleModal();
    } else {
      setPopoverVisible(true);
    }
  }, [isComplete, toggleModal]);

  const handlePopOverClose = () => {
    setPopoverVisible(false);
    toggleModal();
  }

  const handleHidePopOver = () => {
    setPopoverVisible(false);
  }

  return (
    <Modal isOpen={isOpen} centered={true} backdrop="static">
      <div className="modal-header border-0">
        <button className="btn-close" aria-label="close" id="close-paymentModal-btn" onClick={handleClose} />
      </div>

      <Popover offset={[0, 10]} placement="right" isOpen={popoverVisible} target="close-paymentModal-btn">
        <PopoverBody className="shadow">
          <div>
            <h6>{t("MODALS.PAYMENT_STATUS.LABELS.POPOVER_CLOSE")}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <Button color="secondary" onClick={handleHidePopOver}>
              {t("GENERIC.BUTTONS.CANCEL")}
            </Button>
            <Button color="danger" onClick={handlePopOverClose}>
              {t("GENERIC.BUTTONS.CLOSE")}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
      <ModalBody className="d-flex flex-column text-center">

        {isComplete && <React.Fragment>
          <div className="mx-auto bg-primary bg-soft rounded-circle">
            <i className='bx bx-check m-2 bg-success rounded-circle text-white align-middle' style={{ fontSize: '3.5rem' }} />
          </div>
          <h4 className="my-3 text-primary">{t("MODALS.PAYMENT_STATUS.LABELS.COMPLETE")}</h4>
          <div>
            <Button color="success" onClick={toggleModal}>{t("MODALS.PAYMENT_STATUS.BUTTONS.RETURN_TO_CALL")}</Button>
          </div>
        </React.Fragment>
        }

        {!isComplete && <React.Fragment>
          <div className="mx-auto bg-primary bg-soft rounded-circle p-3">
            <LoadingSpinner className="text-primary align-middle" size="sm" />
          </div>
          <h4 className="text-primary pt-3 pb-2">{t("MODALS.PAYMENT_STATUS.LABELS.WAITING")}</h4>
          {paymentUrl && isLocalMode && <React.Fragment>
            <p className="mx-5 text-muted">{t("MODALS.PAYMENT_STATUS.LABELS.QR_CODE")}</p>
            <div className="input-group bg-light rounded mt-2">
              <a className="form-control text-primary bg-transparent overflow-x-scroll text-start text-nowrap border-0 hide-scrollbar" href={paymentUrl} children={paymentUrl} target="_blank" rel="noreferrer" />
              {/* <Input disabled className="form-control text-primary bg-transparent overflow-x-scroll text-start text-nowrap border-0 hide-scrollbar" value={paymentUrl} /> */}
              <Button color="primary" type="button" onClick={handleCopy}>
                <i className='bx bxs-copy align-middle' />
              </Button>
            </div>
            <div className="my-4">
              <Button color="primary" disabled>
                <i className='bx bx-mail-send align-middle me-2' />
                {t("MODALS.PAYMENT_STATUS.BUTTONS.SEND_EMAIL")}
              </Button>
            </div>
            <div className="mx-auto p-4 my-2">
              <QRCode size={196} value={paymentUrl} />

            </div>
          </React.Fragment>}
        </React.Fragment>}
      </ModalBody>
    </Modal>
  )
}
export default PaymentStatusModal;