import React from 'react';
import { useAppSelector } from '../../store';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

const EndCallScreenConsumer: React.FC = () => {
  const { paymentUrl, orderStatus } = useAppSelector((state) => state.order);
  const { t } = useTranslation();

  const isPaid = React.useMemo(() => orderStatus === 'paid', [orderStatus]);

  const handleCopy = (): void => {
    if (paymentUrl) {
      navigator.clipboard.writeText(paymentUrl);
      toast.info(t("TOASTS.LINK_COPY"));
    }
  }

  return <React.Fragment>
    <div className="text-center">
      <i className='rounded-circle bg-primary bg-soft mx-auto p-3 my-3 bx bxs-shopping-bag-alt text-primary align-middle' style={{ fontSize: '3.5rem' }} />
      {!isPaid && <React.Fragment>
        <p className="mb-0">{t("PAGES.MEETING.CALL_ENDED.CART_SAVED")}</p>
        <p>{t("PAGES.MEETING.CALL_ENDED.CART_EXPIRATION")}</p>
      </React.Fragment>}
    </div>
    {!isPaid && paymentUrl && <div className="input-group bg-light rounded my-2">
      <a className="form-control text-primary bg-transparent overflow-x-scroll text-start text-nowrap border-0 hide-scrollbar" href={paymentUrl} children={paymentUrl} />
      <Button color="primary" type="button" onClick={handleCopy}>
        <i className='bx bxs-copy' />
      </Button>
    </div>}
    <p>{t("PAGES.MEETING.CALL_ENDED.EMAIL")}</p>
  </React.Fragment>
}
export default EndCallScreenConsumer;