import React from "react";
import { composerRatio } from "../../constants/looks";
import { useAppSelector } from "../../store";
import LoadingSpinner from "../LoadingSpinner";
import ProductComposerContainer from "../ProductComposer/Container";

const LooksDetailsComposerView: React.FC = () => {
  const looksUpdating = useAppSelector((state) => state.looks.looksUpdating);
  const composerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="h-100 w-auto border border-secondary" ref={composerRef} style={{ aspectRatio: `${composerRatio}` }}>
      {looksUpdating && <React.Fragment>
        <div className="position-absolute h-100 w-100 rounded-2 bg-black" style={{ opacity: 0.20, zIndex: 3000 }}>
        </div>
        <div className="position-absolute top-50 start-50 translate-middle" style={{ zIndex: 3050 }}>
          <LoadingSpinner size="md" className="text-primary" />
        </div>
      </React.Fragment>}
      {<ProductComposerContainer containerRef={composerRef} />}
    </div>
  );
};

export default LooksDetailsComposerView;
