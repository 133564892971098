import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import IconButton from "../Button/Icon";
import useFetch from "../../hooks/useFetch";
import api from "../../api";
import { Look } from "../../interfaces/Look";
import { getLooks } from "../../store/features/looks";

const apiUpdateLook = (payload: any, lookId: string) =>
  api.LookService.updateLook(payload, lookId);

interface PropTypes {
  look: Look;
  looks: Look[];
  onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const InlineEdit: React.FC<PropTypes> = ({ look, looks, onClose }) => {
  const { t } = useTranslation();
  const updateLook = useFetch(apiUpdateLook, getLooks);
  const [lookName, setLookName] = React.useState<string>(look.title);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const invalidNameError = React.useMemo<string | null>(() => {
    if (!lookName) return "MODALS.UPDATE_OUTFIT.INPUTS.NAME.EMPTY";

    const newLookName = lookName.toLowerCase().replace(/\s+/g, "");
    const matchIndex = looks.findIndex(
      (l: Look) =>
        l.title.toLowerCase().replace(/\s+/g, "") === newLookName &&
        l._id !== look._id
    );

    return matchIndex > -1
      ? "MODALS.UPDATE_OUTFIT.INPUTS.NAME.DUPLICATE"
      : null;
  }, [look, lookName, looks]);

  const handleSave = () => {
    setSubmitting(true);
    const payload = {
      title: lookName,
      description: look.description ?? "",
    };
    updateLook({
      actionProps: { payload, lookId: look._id },
      onSuccess: () => {
        toast.info(t("TOASTS.OUTFITS.UPDATED"));
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") handleSave();
  };

  const handleInputTextClick = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLookName(e.target.value);
  };

  return (
    <div className="mb-2" style={{ minWidth: 260 }}>
      <div className="d-flex flex-nowrap align-items-center g-0 ms-n2">
        <div className="flex-grow-1 ps-2">
          <Input
            placeholder={t("MODALS.UPDATE_OUTFIT.INPUTS.NAME.PLACEHOLDER")}
            defaultValue={lookName}
            autoFocus
            onKeyDown={handleKeyDown}
            onClick={handleInputTextClick}
            onChange={handleInputTextChange}
          />
        </div>
        <div className="flex-shrink-1 ps-2">
          <IconButton
            icon="bx bxs-save"
            color="btn-primary"
            className="me-1"
            disabled={invalidNameError !== null || submitting}
            onClick={handleSave}
          />
          <IconButton icon="bx bx-x" color="btn-secondary" onClick={onClose} />
        </div>
      </div>

      {invalidNameError && (
        <div className="text-danger text-wrap my-1">{t(invalidNameError)}</div>
      )}
    </div>
  );
};

export default InlineEdit;
