import React from 'react'
import useMqttMessage from '../../hooks/mqtt/useMessage';
import { MQTT_CONFIGURATOR_SET_PANEL, MQTT_CONFIGURATOR_UPDATE_STEP, MQTT_CONFIGURATOR_UPDATE_VARIANT } from '../../interfaces/Mqtt';
import { useAppDispatch } from '../../store';
import { setConfiguratorActivePanel, setConfiguratorStep, updateConfiguratorVariant } from '../../store/features/details';

interface PropTypes {
  children?: React.ReactNode;
  baseSku: string;
}

const ConfiguratorWrapper: React.FC<PropTypes> = ({ baseSku, children }) => {
  const sendMqttMessage = useMqttMessage();
  const dispatch = useAppDispatch();

  const variantChange = React.useCallback((e: any) => {
    const { variant } = (e as CustomEvent).detail;
    dispatch(updateConfiguratorVariant(variant));
    sendMqttMessage({ type: MQTT_CONFIGURATOR_UPDATE_VARIANT, payload: { sku: baseSku, variant } })
  }, [dispatch, sendMqttMessage, baseSku]);

  const stepChange = React.useCallback((e: any) => {
    const { step } = (e as CustomEvent).detail;
    dispatch(setConfiguratorStep(step));
    sendMqttMessage({ type: MQTT_CONFIGURATOR_UPDATE_STEP, payload: { sku: baseSku, step } })
  }, [dispatch, sendMqttMessage, baseSku]);

  const panelChange = React.useCallback((e: any) => {
    const { panel } = (e as CustomEvent).detail;
    dispatch(setConfiguratorActivePanel(panel));
    sendMqttMessage({ type: MQTT_CONFIGURATOR_SET_PANEL, payload: { sku: baseSku, panel } })
  }, [dispatch, sendMqttMessage, baseSku])

  React.useEffect(() => {
    window.addEventListener('configurator-update-variant', variantChange);
    window.addEventListener('configurator-update-step', stepChange);
    window.addEventListener('configurator-select-panel', panelChange);

    return () => {
      window.removeEventListener('configurator-update-variant', variantChange);
      window.removeEventListener('configurator-update-step', stepChange);
      window.removeEventListener('configurator-select-panel', panelChange);
    }
  }, [stepChange, variantChange, panelChange]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}
export default ConfiguratorWrapper;