import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { setActiveWindow } from '../../store/features/interface';
import Showcase from '../Sidebar/Showcase';

const MobileShowcaseView: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBack = () => {
    dispatch(setActiveWindow('video'));
  }

  return (
    <div className="px-3" style={{ height: '90%' }} >
      <div className="my-1">
        <Button color="none" onClick={handleBack} className="text-primary" >
          <i className="fa fa-arrow-left font-size-15" />
        </Button>
        <h5 className="mb-0 d-inline">{t("GENERIC.LABELS.SHOWCASE")}</h5>
      </div>
      <Showcase />
    </div>
  )
}
export default MobileShowcaseView;