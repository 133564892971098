import React from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import LanguageItem from "./LanguageItem";
import { useTranslation, withTranslation } from "react-i18next";
import languages from "../../common/languages";

const LanguageDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const [menu, setMenu] = React.useState<boolean>(false)

  const toggle = (): void => {
    setMenu(!menu);
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img src={languages.find(lang => lang.code === i18n.language)!.flag} alt="" height="16" className="me-1" />
          {/* <span> {languages.find(lang => lang.code === i18n.language)!.native}</span> */}
        </DropdownToggle>
        <DropdownMenu className="language-switch" end>
          {languages.map((language, index: number) => (
            <LanguageItem key={index} language={language} />
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(LanguageDropdown);