import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { orderPollInterval, orderPollMaxDuration } from "../../common/appSettings";
import { useAppDispatch } from "../../store";
import { setPaymentStatusModal } from "../../store/features/interface";
import { retrieveOrder } from "../../store/features/order";

const useOrderStatusPolling = () => {
  const dispatch = useAppDispatch();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const { t } = useTranslation();

  const checkOrderStatus = useCallback(({ orderId, timelapsed }: { orderId: string, timelapsed: number }): void => {
    setIsPolling(true);
    dispatch(retrieveOrder(orderId)).unwrap().then((response) => {
      // if (response.code === 200) {
      if (response?.status === 'paid') {
        toast.success(t("TOASTS.ORDER.PAYMENT_COMPLETE"));
        setIsPolling(false);
      } else if (timelapsed >= orderPollMaxDuration) {
        toast.error(t("TOASTS.ORDER.PAYMENT_RUN_OUT"));
        dispatch(setPaymentStatusModal(false));
        setIsPolling(false);
      } else {
        setTimeout(() => {
          checkOrderStatus({ orderId, timelapsed: timelapsed + orderPollInterval });
        }, orderPollInterval);
      }
      // }
    }).catch((err) => {
      toast.error(t("TOASTS.ORDER.PAYMENT_ERROR"));
      dispatch(setPaymentStatusModal(false));
      setIsPolling(false);
    })
  }, [dispatch, t]);

  return { checkOrderStatus, isPolling }
}
export default useOrderStatusPolling;