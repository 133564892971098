import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import * as Yup from "yup";
import api from '../../api';
import EligoCard from '../../components/Cards/Eligo';
import SuccessCard from '../../components/Cards/Success';
import LoadingSpinner from '../../components/LoadingSpinner';

const initialFormValues: { userEmail: string } = {
  userEmail: ''
}

const validationSchema = Yup.object().shape({
  userEmail: Yup.string().required("PAGES.RECOVER_PASSWORD.INPUTS.EMAIL.EMPTY")
});

const PasswordRecoveryPage: React.FC = () => {
  const [verified, setVerified] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const submitHandler = async (values: { userEmail: string }): Promise<void> => {
    try {
      await api.UserService.passwordRecovery({ email: values.userEmail });
      setVerified(true);
    } catch (error: any) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
    }
  }

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          {verified ? (
            <SuccessCard title="Success!" text="A message with further instructions have been sent to your email address!" />
          ) : (
            <EligoCard title={t("PAGES.RECOVER_PASSWORD.HEADER")} description={t("PAGES.RECOVER_PASSWORD.SUBHEADER")}>
              <Formik
                initialValues={initialFormValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
              >
                {({ errors, touched, dirty, isSubmitting }) => (
                  <Form>

                    <Field id="userName" name="userName" type="text" autoComplete="username" className={'form-control hidden'} hidden />
                    <Label for="userEmail" className={`${errors.userEmail && touched.userEmail ? 'text-danger' : ''}`}>{t("PAGES.RECOVER_PASSWORD.INPUTS.EMAIL.LABEL")}</Label>
                    <div className="input-group">
                      <Field id="userEmail" name="userEmail" type="email" placeholder={t("PAGES.RECOVER_PASSWORD.INPUTS.EMAIL.LABEL")} className={`form-control ${errors.userEmail && touched.userEmail ? 'is-invalid' : ''}`} />
                    </div>
                    {errors.userEmail && touched.userEmail && <div className="text-danger my-1">
                      {t(errors.userEmail)}
                    </div>}
                    <div className="text-center">
                      <Button color="primary" className="w-md waves-effect waves-light mt-2" disabled={!dirty || isSubmitting} type="submit">
                        {isSubmitting && <LoadingSpinner isBtn />} {t("PAGES.RECOVER_PASSWORD.BUTTONS.RESET_PASSWORD")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </EligoCard>
          )}
        </Col>
      </Row>
    </Container>)
}
export default PasswordRecoveryPage;