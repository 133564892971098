import { logoDark } from "../assets";
import { magentoProductTypes } from "../constants/product";
import Product, { CartProduct, ProductMedia, VariantProduct } from "../interfaces/Product";


export const buildProductAddPayload = ({ baseSku, qty, sku, notes, variants }: Omit<CartProduct, 'price'>) => ({ baseSku, qty, sku, notes, variants });
export const buildProductUpdatePayload = ({ qty }: { qty: number }) => ({ qty });

export const buildAddProps = (cartId: string, product: CartProduct, onSuccess?: any, onError?: any) => ({
  actionProps: {
    cartId,
    payload: buildProductAddPayload(product),
  },
  refetchProps: { cartId },
  onSuccess: onSuccess,
  onError: onError
});

export const buildUpdateProps = (cartId: string, product: CartProduct, onSuccess?: any, onError?: any) => ({
  actionProps: {
    cartId,
    itemId: product._id,
    payload: buildProductUpdatePayload(product),
  },
  refetchProps: { cartId },
  onSuccess: onSuccess,
  onError: onError
});

export const buildRemoveProps = (cartId: string, { _id }: CartProduct, onSuccess?: any, onError?: any) => ({
  actionProps: {
    cartId,
    itemId: _id,
  },
  refetchProps: { cartId },
  onSuccess: onSuccess,
  onError: onError
});

export const formatPrice = (num: number, defaultValue = 0) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(!num && num !== 0 ? defaultValue : num);

export const constructProductMainImage = (product: Product): string => product?.image?.url ? product.image.url : logoDark;

export const constructProductImages = (product: Product): ProductMedia[] => {
  return product.media_gallery.filter((media) => (media.__typename === "ProductImage") ? { ...media, label: media.label || product.name } : null);
}

export const constructInitialProduct = (product: Product): VariantProduct => {
  return {
    name: product.name,
    description: product.description.html,
    price: {
      regular: product.price_range.maximum_price.regular_price.value,
      final: product.price_range.maximum_price.final_price.value,
    },
    sku: product.sku,
    images: constructProductImages(product),
    selected: product.__typename === magentoProductTypes.CONFIGURABLE ? false : true
  }
}