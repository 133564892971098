import { useMemo } from "react";
import useWindowResize from "./useWindowResize";

const breakpoints = {
  xSmall: 0,
  small: 576,
  medium: 768,
  large: 992,
  xLarge: 1025,
  xXLarge: 1400,
};

const getViewportsByWidth = (width: number) => ({
  isXSmall: width < breakpoints.small,
  isSmall: width >= breakpoints.small && width < breakpoints.medium,
  isMedium: width >= breakpoints.medium && width < breakpoints.large,
  isLarge: width >= breakpoints.large && width < breakpoints.xLarge,
  isXLarge: width >= breakpoints.xLarge && width < breakpoints.xXLarge,
  isXXLarge: width >= breakpoints.xXLarge,
});

const useScreenSize = () => {
  const { width } = useWindowResize();

  return useMemo(() => {
    const viewports = getViewportsByWidth(width);

    return {
      width,
      ...viewports,
      isMobile: viewports.isXSmall || viewports.isSmall,
      isTablet: viewports.isMedium,
      isDesktop: viewports.isXLarge || viewports.isXXLarge,
    };
  }, [width]);
};
export default useScreenSize;