import CartService from "./CartService";
import MeetingService from "./MeetingService";
import MqttService from "./MqttService";
import OrderService from "./OrderService";
import ProductService from "./ProductService";
import UserService from "./UserService"
import LookService from "./LookService"
import CategoryService from "./CategoryService";
import { TenancySrevice } from "./TenancyService";

interface ApiObject {
  UserService: UserService;
  ProductService: ProductService;
  MeetingService: MeetingService;
  MqttService: MqttService;
  CartService: CartService;
  OrderService: OrderService;
  LookService: LookService;
  CategoryService: CategoryService;
  TenancyService: TenancySrevice;
}

const api: ApiObject = {
  UserService: new UserService(),
  ProductService: new ProductService(),
  MeetingService: new MeetingService(),
  MqttService: new MqttService(),
  CartService: new CartService(),
  OrderService: new OrderService(),
  LookService: new LookService(),
  CategoryService: new CategoryService(),
  TenancyService: new TenancySrevice()
}

export default api;