import React from 'react';

interface PropTypes {
  colSpan?: number;
}

const TableEmpty: React.FC<PropTypes> = ({ colSpan, children }) => {
  return (
    <tr>
      {colSpan ?
        <td colSpan={colSpan} className="text-center fw-light pt-4 h6">
          {children}
        </td>
        :
        <td className="text-center fw-light pt-4 h6">
          {children}
        </td>
      }
    </tr>)
}
export default TableEmpty;