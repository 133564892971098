import * as React from "react";
import Moveable, { Able, MoveableManagerInterface, Renderer } from "react-moveable";
import classnames from "classnames";
import { UserType } from "../../interfaces/Auth";
import { LookProduct } from "../../interfaces/Product";
import { constructProductMainImage } from "../../helpers/product";
import ComposerActionMenu from "./ActionMenu";
import useWindowResize from "../../hooks/useWindowResize";
import { composer } from "../../constants/looks";
import SkeletonImage from "../SkeletonImage";
import { userRoles } from "../../constants/users";

interface PropTypes {
  userType: UserType;
  product: LookProduct;
  isActive: boolean;
  containerRef: React.RefObject<HTMLDivElement>
  containerBounds: { left: number, top: number, right: number, bottom: number }
  onDragStop: (product: LookProduct, size: { height: number, width: number }, position: { x: number, y: number }) => void;
  onResizeStop: (product: LookProduct, { size, position }: { size: { width: number, height: number }; position: { x: number, y: number } }) => void;
  onRotateStop: (product: LookProduct, rotation: number) => void
  onMouseDown: (lookProductId: string) => void;
  onRemove: (lookProductId: string) => void;
  onZIndex: (product: LookProduct, zIndex: number) => void;
}

const DimensionViewable: Able = {
  name: "dimensionViewable", props: {}, events: {}, render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
    const rect = moveable.getRect();

    return <div key={"dimension-viewer"} className={"moveable-dimension border rounded-3 px-2 py-2 shadow-sm"} style={{
      position: "absolute",
      left: `${rect.width / 2}px`,
      top: `${rect.height + 40}px`,
      background: "#F7F4F3",
      color: "white",
      fontSize: "13px",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      willChange: "transform",
      transform: "translate(-50%, 0px)",
    }}>
      {<ComposerActionMenu />}
    </div>
  }
}

const calcPosition: any = (curDim: number, newDim: number, val: number) => {
  if (curDim && curDim > 0) return Math.round(newDim * val / curDim);
}

const ProductComposerItem: React.FC<PropTypes> = ({ product, userType, containerRef, containerBounds, isActive, onDragStop, onResizeStop, onRotateStop, onMouseDown, onRemove, onZIndex }) => {
  const createdComposerHeight = composer.height;
  const createdComposerWidth = composer.width;
  const { width, height } = useWindowResize();
  const [target, setTarget] = React.useState<HTMLElement>();
  const [frame, setFrame] = React.useState<{ translate: number[], rotate: number }>({
    translate: [product.x, product.y],
    rotate: product.rotation
  });

  const [newX, setNewX] = React.useState<number>(1);
  const [newY, setNewY] = React.useState<number>(1);
  const [newHeight, setNewHeight] = React.useState<number>(100);
  const [newWidth, setNewWidth] = React.useState<number>(100);

  React.useEffect(() => {
    setTarget(document.querySelector<HTMLElement>(`.lp${product._id}`)!);
  }, [product]);

  React.useEffect(() => {
    setNewY(calcPosition(createdComposerHeight, containerRef.current?.clientHeight, product.y));
    setNewX(calcPosition(createdComposerWidth, containerRef.current?.clientWidth, product.x));
    setNewHeight(calcPosition(createdComposerHeight, containerRef.current?.clientHeight, product.height));
    setNewWidth(calcPosition(createdComposerWidth, containerRef.current?.clientWidth, product.width));
    // eslint-disable-next-line
  }, [width, height, product]); // TODO: needs more investigation regarding rotation

  return (
    /* * (containerBounds.right - containerBounds.left) / composer.width */
    <React.Fragment>
      <div
        style={{
          transform: `translate(${newX}px, ${newY}px) rotate(${product.rotation || 0}deg)`,
          minWidth: 50,
          maxWidth: 250,
          height: newHeight,
          width: newWidth,
          zIndex: product.zIndex
        }}
        onMouseDown={() => onMouseDown(product._id)}
        className={classnames(`position-absolute  lp${product._id}`, { 'active-look-product': isActive })}
      >
        <SkeletonImage
          style={{
            objectFit: "contain",
          }}
          loading="lazy"
          className="h-100 w-100"
          skeletonClassName="h-100 w-100"
          src={constructProductMainImage(product.details.product)}
          alt={product.details.product.name || "-"}
        />
        {/* {newHeight}, {newWidth} <br /> */}
        {/* {newY}, {newX} <br /> */}
        {/* {containerRef.current?.clientHeight}, {containerRef.current?.clientWidth} */}
      </div>

      {isActive && userType === userRoles.STYLIST &&
        <Moveable
          props={{
            dimensionViewable: true,
            product: product,
            onRemove: onRemove,
            onZIndex: onZIndex
          }}
          ables={[DimensionViewable]}

          target={target}
          snappable={true}
          bounds={containerBounds}
          padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
          origin={false}
          // throttleDrag={throttles.drag}
          // throttleResize={throttles.resize}
          // throttleRotate={throttles.rotate}

          draggable={true}
          onDragStart={e => {
            // e.set(frame.translate);         
          }}
          onDrag={e => {
            frame.translate[0] = e.beforeTranslate[0];
            frame.translate[1] = e.beforeTranslate[1];
            e.target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px) rotate(${frame.rotate}deg)`;
            e.target.style.height = `${e.height}px`;
            e.target.style.width = `${e.width}px`;
          }}
          onDragEnd={(e) => {
            onDragStop(
              product,
              {
                height: calcPosition(containerRef.current?.clientHeight, createdComposerHeight, e.target.clientHeight),
                width: calcPosition(containerRef.current?.clientWidth, createdComposerWidth, e.target.clientWidth)
              },
              {
                x:
                  (frame.translate[0] < 0 || frame.translate[0] > containerBounds.right - parseInt(e.target.style.width)) ?
                    0 :
                    calcPosition(containerRef.current?.clientHeight, createdComposerHeight, frame.translate[0]),
                y:
                  (frame.translate[1] < 0 || frame.translate[1] > containerBounds.bottom - parseInt(e.target.style.height)) ?
                    0 :
                    calcPosition(containerRef.current?.clientWidth, createdComposerWidth, frame.translate[1])
              })
          }}

          rotatable={true}
          rotationPosition={'top'}
          onRotateStart={(e) => {
            setFrame(frame);
          }}
          onRotate={(e) => {
            const matrix = new WebKitCSSMatrix(e.target.style.transform)
            const x = matrix['e'] < 0 ? 0 : matrix['e'];
            const y = matrix['f'] < 0 ? 0 : matrix['f'];
            frame.translate[0] = x;
            frame.translate[1] = y;
            frame.rotate = Math.round(e.beforeRotation);
            e.target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px) rotate(${Math.round(e.beforeRotation)}deg)`;
          }}
          onRotateEnd={(e) => {
            if (e) {
              onRotateStop(product, Math.round(frame.rotate));
              e.target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px) rotate(${Math.round(frame.rotate)}deg)`;
            }
          }}

          resizable={true}
          onResizeStart={e => {
            e.setOrigin(["%", "%"]);
            e.dragStart && e.dragStart.set(frame.translate);
          }}
          onResize={e => {
            const beforeTranslate = e.drag.beforeTranslate;
            frame.translate = beforeTranslate;
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${frame.rotate})`;
          }}
          onResizeEnd={(e) => {
            const matrix = new WebKitCSSMatrix(e.target.style.transform)
            const x = matrix['e'] < 0 ? 0 : matrix['e'];
            const y = matrix['f'] < 0 ? 0 : matrix['f'];
            onResizeStop(
              product,
              {
                size:
                {
                  height: calcPosition(containerRef.current?.clientHeight, createdComposerHeight, e.target.clientHeight),
                  width: calcPosition(containerRef.current?.clientWidth, createdComposerWidth, e.target.clientWidth)
                },
                position:
                {
                  x: calcPosition(containerRef.current?.clientWidth, createdComposerWidth, Math.round(x)),
                  y: calcPosition(containerRef.current?.clientHeight, createdComposerHeight, Math.round(y))
                }
              })
          }}
          keepRatio={true}
        // useResizeObserver={true}
        />
      }
    </React.Fragment>
  )
}
export default ProductComposerItem