import classnames from "classnames";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormActions from "./FormActions";
import FormikForm from "./FormikForm";

interface PropTypes {
  modalOpen: boolean;
  initialValues: any;
  validationSchema: any;
  modalTitle: string;
  // modalTitleValues: null;
  cancelText?: string;
  submitText?: string;
  modalSize?: string;
  footerClass?: string;
  mode: 'create' | 'edit' | 'show'; // create; edit; show; any
  onSubmit: (e: any) => void;
  onCancel: () => void;
  children: React.ReactNode;
}

const ModalForm: React.FC<PropTypes> = ({
  modalOpen,
  initialValues,
  validationSchema,
  modalTitle = "",
  // modalTitleValues = null,
  cancelText = "GENERIC.BUTTONS.CANCEL",
  submitText = "GENERIC.BUTTONS.SUBMIT",
  modalSize = "md",
  footerClass = "border-0",
  mode = "create", // create, edit, show, any
  onSubmit,
  onCancel,
  children,
}) => {
  const { t } = useTranslation();
  const formRef: React.RefObject<FormikProps<any> | null> = React.createRef();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    formRef.current?.submitForm();
  };

  return (
    <Modal
      isOpen={modalOpen}
      toggle={onCancel}
      backdrop="static"
      keyboard={false}
      centered
      size={modalSize}
    >
      <ModalHeader toggle={onCancel}>
        {t(modalTitle)}
        {/* {t(modalTitle, modalTitleValues)} */}
      </ModalHeader>

      <ModalBody>
        <FormikForm
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {children}
        </FormikForm>
      </ModalBody>

      <ModalFooter className={classnames(footerClass)}>
        {(mode === "create" || mode === "edit") && (
          <FormActions
            className="w-100 justify-content-end"
            cancelText={cancelText}
            submitText={submitText}
            onCancel={onCancel}
            onSubmit={handleSubmit}
          />
        )}

        {mode === "show" && (
          <Col className="col-12 col-sm-auto mx-auto">
            <button
              type="button"
              className="btn btn-primary w-100 pe-5 ps-5"
              onClick={onCancel}
            >
              {t("actions.close")}
            </button>
          </Col>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalForm;
