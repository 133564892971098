import { ActiveWindow } from "./AppInterface";
import { CatalogSearchParams } from "./Filters";
import { UserType } from "./Auth";
import { CartProductVariant } from "./Product";

export type MqttMessage = MqttAction & {
  originator: UserType;
}

export interface MqttConfigOptions {
  broker: string;
  clientId: string;
  enabled: boolean;
  username: string;
  password: string;
  protocol: 'wss';
}

export interface MqttState {
  broker?: string;
  clientId?: string;
  enabled?: boolean;
  username?: string;
  password?: string;
  protocol: 'wss';
  mqttLoading: boolean;
  mqttInitialized: boolean;
}

export const MQTT_END_CALL = 'end/call';
type MqttEndCall = {
  type: typeof MQTT_END_CALL,
  payload?: undefined
}

export const MQTT_VIEW_USER = 'view/user';
type MqttViewUser = {
  type: typeof MQTT_VIEW_USER,
  payload: {
    userId: string;
  }
}

export const MQTT_ADD_CART = 'add/cart';
type MqttAddCart = {
  type: typeof MQTT_ADD_CART,
  payload: {
    baseSku: string;
    sku?: string;
    notes?: string; // TODO: remove
    variants?: CartProductVariant[];
  }
}

export const MQTT_REMOVE_CART = 'remove/cart';
type MqttRemoveCart = {
  type: typeof MQTT_REMOVE_CART,
  payload: {
    productSku: string;
  }
}

export const MQTT_DELETE_CART = 'delete/cart';
type MqttDeleteCart = {
  type: typeof MQTT_DELETE_CART,
  payload: {
    productId: string;
  }
}

export const MQTT_REFRESH_CART = 'refresh/cart';
type MqttRefreshCart = {
  type: typeof MQTT_REFRESH_CART,
  payload?: undefined
}

export const MQTT_SEND_PRODUCT = 'send/product';
type MqttViewProduct = {
  type: typeof MQTT_SEND_PRODUCT,
  payload: {
    productSku: string;
  }
}

export const MQTT_VIEW_LOOKS = 'view/looks'
type MqttViewLooks = {
  type: typeof MQTT_VIEW_LOOKS,
  payload: {
    toggleLooks?: boolean,
    lookId?: string;
  }
}

export const MQTT_SEND_CATALOG = 'send/catalog';
type MqttSendCatalog = {
  type: typeof MQTT_SEND_CATALOG,
  payload: {
    filters: CatalogSearchParams
  }
}

export const MQTT_VIEW_PAYMENT = 'view/payment';
type MqttViewPayment = {
  type: typeof MQTT_VIEW_PAYMENT,
  payload: {
    orderId: string;
  }
}

export const MQTT_VIEW_WINDOW = 'view/window';
type MqttViewWindow = {
  type: typeof MQTT_VIEW_WINDOW;
  payload: {
    window: ActiveWindow;
  }
}

export const MQTT_REFRESH_SHOWCASE = 'refresh/showcase';
type MqttRefreshShowcase = {
  type: typeof MQTT_REFRESH_SHOWCASE;
  payload?: undefined
}


export const MQTT_GRAND_CATALOG_ACCESS = 'access/catalog';
type MqttGrandCatalogAccess = {
  type: typeof MQTT_GRAND_CATALOG_ACCESS;
  payload: boolean;
}

export const MQTT_CONFIGURATOR_UPDATE_STEP = 'configurator/updateStep';
type MqttConfiguratorUpdateStep = {
  type: typeof MQTT_CONFIGURATOR_UPDATE_STEP;
  payload: {
    sku: string;
    step: number;
  }
}

export const MQTT_CONFIGURATOR_UPDATE_VARIANT = 'configurator/updateVariant';
type MqttConfiguratorUpdateVariant = {
  type: typeof MQTT_CONFIGURATOR_UPDATE_VARIANT;
  payload: {
    sku: string;
    variant: Partial<CartProductVariant>;
  }
}

export const MQTT_CONFIGURATOR_SET_VARIANTS = 'configurator/setVariants';
type MqttConfiguratorSetVariants = {
  type: typeof MQTT_CONFIGURATOR_SET_VARIANTS;
  payload: {
    sku: string;
    variants: CartProductVariant[];
  }
}

export const MQTT_CONFIGURATOR_TOGGLE = 'configurator/init';
type MqttConfiguratorToggle = {
  type: typeof MQTT_CONFIGURATOR_TOGGLE;
  payload: {
    sku: string;
    status: boolean;
    step?: number;
    panel?: string;
    variants?: CartProductVariant[];
  }
}

export const MQTT_CONFIGURATOR_SET_PANEL = 'configurator/setPanel';
type MqttConfiguratorSetPanel = {
  type: typeof MQTT_CONFIGURATOR_SET_PANEL;
  payload: {
    sku: string;
    panel: string;
  }
}

export type MqttAction = MqttConfiguratorSetPanel | MqttConfiguratorSetVariants | MqttConfiguratorUpdateVariant | MqttConfiguratorToggle | MqttConfiguratorUpdateStep | MqttGrandCatalogAccess | MqttRefreshShowcase | MqttDeleteCart | MqttEndCall | MqttViewWindow | MqttViewUser | MqttAddCart | MqttRemoveCart | MqttRefreshCart | MqttViewProduct | MqttViewLooks | MqttSendCatalog | MqttViewPayment;