import React from 'react';
import { useAppSelector } from '../../store';
import Typography from '../Typography';

const ProductCountDisplay: React.FC = () => {
  const pagination = useAppSelector((state) => state.catalog.pagination);

  return (
    <div className="mt-n2 mb-2 ms-3">
      {pagination.total}{" "}
      <Typography Variant="span" text={"GENERIC.LABELS.PRODUCTS"} />
    </div>
  )
}
export default ProductCountDisplay;