import { IMessageStructure, useSubscription } from 'mqtt-react-hooks';
import React from 'react'
import { useMqttReducer } from '../../hooks/useMqtt';
import { MqttMessage } from '../../interfaces/Mqtt';
import { useAppSelector } from '../../store';

const MqttWrapper: React.FC = ({ children }) => {
	const meetingId = useAppSelector((state) => state.meeting.meetingId);
	const clientId = useAppSelector((state) => state.mqtt.clientId);
	const mqttDispatch = useMqttReducer();

	const { message } = useSubscription(
		`${clientId}/meeting/${meetingId}`
	);

	React.useEffect(() => {
		if (message) {
			let msg: string | IMessageStructure | undefined = message.message;
			try {
				const result: MqttMessage = JSON.parse(msg as string);
				mqttDispatch(result);
			} catch (error: any) {
				console.log(error);
			}
		}
		// NOTE: no idea how to correctly avoid warning, adding mqttReducer to dep array breaks the app
		// eslint-disable-next-line
	}, [message]);

	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	)
}
export default MqttWrapper;