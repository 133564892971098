import React from "react";
import { useTranslation } from "react-i18next";

const LooksDetailsEmpty: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center">
      <div className="col-12 col-md-8 text-center fw-light font-size-14">
        <p className="my-4">{t("GENERIC.LABELS.OUTFIT_EMPTY")}</p>
        <p>
          {t("GENERIC.LABELS.OUTFIT_EMPTY_ADD_ITEMS")}
        </p>
      </div>
    </div>
  );
};

export default LooksDetailsEmpty;
