import axios from 'axios';
import { CreateCartResponse } from '../interfaces/Cart';
import { Look } from '../interfaces/Look';
import { MeetingUpdateRequest } from '../interfaces/Meeting';
import { OrderCreateSuccessResponse } from '../interfaces/Order';
import { ShowcaseProduct } from '../interfaces/Product';
import { constructRequestHeaders, SERVER_URL } from "./helpers";
// TEMP:
interface GetMeetingResponse {
  callUrl: string;
  meetingId: string;
  customerProfile: any;
  ownerProfile: any;
  startDate: string;
  finishDate: string;
  notes?: string;
  endingNotes: string;
  products: ShowcaseProduct[];
  looks: Look[];
  cartId: CreateCartResponse; // TODO: better type name
  orderId?: OrderCreateSuccessResponse; // TODO: better type name
}
export default class MeetingService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/meetings/`;
  }

  async getMeeting(meetingId: string): Promise<GetMeetingResponse> {
    try {
      const response = await axios.get(`${this.baseUrl}${meetingId}`, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async deleteMeeting(meetingId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}${meetingId}`, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async updateMeeting({ meetingId, payload }: MeetingUpdateRequest): Promise<any> {
    try {
      const response = await axios.put(`${this.baseUrl}${meetingId}`, payload, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error)
    }
  }

  async confirmGuestMeeting(meetingId: string): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}${meetingId}/confirm`, undefined, {
        headers: constructRequestHeaders({ noToken: true })
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async getMeetingProducts(meetingId: string): Promise<ShowcaseProduct[]> {
    try {
      const response = await axios.get(`${this.baseUrl}${meetingId}/products`, {
        headers: constructRequestHeaders({})
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error)
    }
  }

  async createMeetingProduct(meetingId: string, payload: { baseSku: string, sku?: string }): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}${meetingId}/product`, payload, {
        headers: constructRequestHeaders({})
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async deleteMeetingProduct(meetingId: string, productId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}${meetingId}/products/${productId}`, {
        headers: constructRequestHeaders({})
      })
      return await Promise.resolve(response.data.data)
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async clearMeetingProducts(meetingId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}${meetingId}/allProducts`, {
        headers: constructRequestHeaders({})
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async createMeetingLook(meetingId: string, look: string): Promise<Look> {
    try {
      const response = await axios.post(`${this.baseUrl}${meetingId}/look`, { look }, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}