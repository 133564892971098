import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";
import { Row } from "reactstrap";
import { userRoles } from "../../constants/users";
import { useScreen } from "../../providers/ScreenSize";
import { useAppSelector } from "../../store";
import Tile from "../Tile";
import PresentationTile from "./PresentationTile";

// SALES-V2: Call in redux update
const ParticipantGrid: React.FC = () => {
  const { activeWindow, activePresentWindow } = useAppSelector((state) => state.appInterface);
  const { participants, activeParticipant } = useAppSelector((state) => state.call);
  const userType = useAppSelector((state) => state.auth.userType);
  const location = useLocation();
  const { isMobile } = useScreen();

  const hasExceededFiveTiles = React.useMemo(() => participants.length > 5, [participants]);

  return (
    <Row className={classNames('flex-row flex-nowrap w-100 m-0 participant-grid', { 'row-cols-3': isMobile, 'py-2 row-cols-5': !isMobile, 'overflow-x-scroll': hasExceededFiveTiles })} >
      {userType === userRoles.STYLIST && activePresentWindow && <PresentationTile activePresentWindow={activePresentWindow} />}
      {participants.map((participant) => (
        <React.Fragment key={participant.user_id}>
          {(activeWindow !== "video" || (activeWindow === "video" && participant.user_id !== activeParticipant?.user_id) || location.pathname.includes('/detail')) && (
            <Tile
              id={participant.user_id}
              videoTrackState={participant.tracks.video}
              audioTrackState={participant.tracks.audio}
              isLocalPerson={participant.local}
              isMainScreen={false}
              name={participant.user_name}
              userData={participant?.userData || undefined}
              className={'col rounded'}
            />
          )}
        </React.Fragment>
      ))}
    </Row>
  );
};
export default ParticipantGrid;