import axios from 'axios';
import { CreateLookPayload, Look, LookProductPayload, UpdateLookPayload } from '../interfaces/Look';
import { constructRequestHeaders, SERVER_URL } from "./helpers";

export default class LookService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/look`;
  }

  async createLook(payload: CreateLookPayload): Promise<Look> {
    try {
      const formData = new FormData();

      formData.append('title', payload.title);
      if (payload.description) formData.append('description', payload.description);
      if (payload.customerId) formData.append('customerId', payload.customerId);
      if (payload.products) formData.append('products', JSON.stringify(payload.products));
      if (payload.photos) formData.append('photos', JSON.stringify(payload.photos));

      const response = await axios.post(`${this.baseUrl}`, formData, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async updateLook(payload: UpdateLookPayload, id: string): Promise<Look> {

    const formData = new FormData();

    formData.append('title', payload.title);
    if (payload.description) formData.append('description', payload.description);
    if (payload.customerId) formData.append('customerId', payload.customerId);
    if (payload.products) formData.append('products', JSON.stringify(payload.products));
    if (payload.photos) formData.append('photos', JSON.stringify(payload.photos));

    try {
      const response = await axios.put(`${this.baseUrl}s/${id}`, formData, {
        headers: constructRequestHeaders({})
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async getLooks(search: string): Promise<Look[]> {
    try {
      let buildUri: string = `${this.baseUrl}s`;
      if (search) buildUri = buildUri.concat(`?search=${search}`);
      const response = await axios.get(buildUri, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async getLook(lookId: string): Promise<Look> {
    try {
      const response = await axios.get(`${this.baseUrl}s/${lookId}`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async deleteLook(lookId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}s/${lookId}`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async createLookProduct(lookId: string, payload: LookProductPayload): Promise<Look> {
    try {
      const response = await axios.post(`${this.baseUrl}s/${lookId}/product`, payload, { headers: constructRequestHeaders({}) });

      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async updateLookProduct(lookId: string, productId: string, payload: LookProductPayload): Promise<Look> {
    try {
      const response = await axios.put(`${this.baseUrl}s/${lookId}/products/${productId}`, payload, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async deleteLookProduct(lookId: string, productId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}s/${lookId}/products/${productId}`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data?.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}