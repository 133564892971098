import React from 'react'
import { useLocation } from 'react-router-dom';
import { presentTypes } from '../../constants/interface';
import { memoPaths } from '../../constants/routes';
import useMqttMessage from '../../hooks/mqtt/useMessage';
import { MQTT_SEND_CATALOG, MQTT_SEND_PRODUCT, MQTT_VIEW_WINDOW } from '../../interfaces/Mqtt';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActivePresentWindow } from '../../store/features/interface';
import { ActionMenuButton } from '../Button/ActionMenu';

// SALES-V2: Call in redux update
const ProductPresentationToggleBtn: React.FC = () => {
	const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
	const { activeWindow, activePresentWindow } = useAppSelector((state) => state.appInterface);
	const { product, detailsLoading } = useAppSelector((state) => state.details);
	const searchParams = useAppSelector((state) => state.filters.searchParams);
	const catalogLoading = useAppSelector((state) => state.catalog.catalogLoading);
	const sendMqttMessage = useMqttMessage();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const isLoading = React.useMemo(() => detailsLoading || catalogLoading, [detailsLoading, catalogLoading]);

	const isPresentingItem = React.useMemo((): boolean => (
		(activeWindow !== 'productCatalog' || pathname.includes(memoPaths.products.details)) &&
		activePresentWindow?.window === presentTypes.DETAILS &&
		activePresentWindow?.sku === product?.sku

	) || (

			activePresentWindow?.window === presentTypes.CATALOG &&
			!pathname.includes(memoPaths.products.details)
		), [pathname, activePresentWindow, product, activeWindow]);

	const presentItemBtnDisabled = React.useMemo((): boolean => {
		return !isPresentingItem &&
			(
				isLoading ||
				!(activeWindow === 'productCatalog' || pathname.includes(memoPaths.products.details))
			)
	}, [isPresentingItem, isLoading, activeWindow, pathname])

	const handlePresent = (): void => {
		if (isPresentingItem) {
			dispatch(setActivePresentWindow());
			sendMqttMessage({ type: MQTT_VIEW_WINDOW, payload: { window: 'video' } });
		} else {
			if (pathname.includes(memoPaths.products.details)) {
				if (product) {
					dispatch(setActivePresentWindow({
						window: presentTypes.DETAILS,
						name: product?.name || "",
						sku: product?.sku || "",
						img: product?.thumbnail?.url || ""
					}));
					sendMqttMessage({ type: MQTT_SEND_PRODUCT, payload: { productSku: product.sku } });
				}
			} else {
				dispatch(setActivePresentWindow({ window: presentTypes.CATALOG }));
				sendMqttMessage({ type: MQTT_SEND_CATALOG, payload: { filters: { ...searchParams } } });
			}
		}
	}

	return (
		<ActionMenuButton
			id="toogle-presentation-btn"
			className={isPresentingItem ? 'pulse-primary' : undefined}
			disabled={(dailyMeetingState !== 'joined-meeting') || presentItemBtnDisabled}
			icon={<i className="bx bxs-up-arrow-square font-size-20 align-middle" />}
			color={isPresentingItem ? 'primary' : 'secondary'}
			onClick={handlePresent}
			tooltip={isPresentingItem ? "GENERIC.BUTTONS.HIDE" : "GENERIC.BUTTONS.PRESENT"}
		/>
	)
}
export default ProductPresentationToggleBtn;