import React from "react";
import { buildLookAddProductPayload, getValidZIndex } from "../../helpers/look";
import Product, { LookProduct } from "../../interfaces/Product";
import { useAppDispatch, useAppSelector } from "../../store";
import { setLookCurrentProducts } from "../../store/features/looks";
import { v4 as uuidv4 } from "uuid";

const useLookProductActions = () => {
  const { lookProductsCurrent } = useAppSelector((state) => state.looks);
  const dispatch = useAppDispatch();

  const handleDragStop = React.useCallback((product: LookProduct, size: { height: number, width: number }, position: { x: number, y: number }): void => {
    const matchIndex = lookProductsCurrent.findIndex((p) => p._id === product._id);
    if (matchIndex > -1) {
      let updatedProduct: LookProduct = { ...lookProductsCurrent[matchIndex] };
      let newProducts: LookProduct[] = [...lookProductsCurrent];
      updatedProduct.x = position.x;
      updatedProduct.y = position.y;
      updatedProduct.height = size.height;
      updatedProduct.width = size.width;
      newProducts[matchIndex] = updatedProduct;
      dispatch(setLookCurrentProducts(newProducts));
    }
  }, [lookProductsCurrent, dispatch]);

  const handleResizeStop = React.useCallback((product: LookProduct, { size, position }: { size: { width: number, height: number }, position: { x: number, y: number } }): void => {
    const matchIndex = lookProductsCurrent.findIndex((p) => p._id === product._id);
    if (matchIndex > -1) {
      let updatedProduct: LookProduct = { ...lookProductsCurrent[matchIndex] };
      let newProducts: LookProduct[] = [...lookProductsCurrent];
      updatedProduct.x = position.x;
      updatedProduct.y = position.y;
      updatedProduct.width = size.width;
      updatedProduct.height = size.height;
      newProducts[matchIndex] = updatedProduct;
      dispatch(setLookCurrentProducts(newProducts));
    }
  }, [lookProductsCurrent, dispatch]);

  const handleRotation = React.useCallback((product: LookProduct, rotation: number): void => {
    const matchIndex = lookProductsCurrent.findIndex((p) => p._id === product._id);
    if (matchIndex > -1) {
      let updatedProduct: LookProduct = { ...lookProductsCurrent[matchIndex] };
      let newProducts: LookProduct[] = [...lookProductsCurrent];
      updatedProduct.rotation = Math.round(rotation)
      newProducts[matchIndex] = updatedProduct;
      dispatch(setLookCurrentProducts(newProducts));
    }
  }, [lookProductsCurrent, dispatch]);

  const handleZIndex = React.useCallback((product: LookProduct, zIndex: number) => {
    const matchIndex = lookProductsCurrent.findIndex((p) => p._id === product._id);

    if (matchIndex > -1) {
      let updatedProduct: LookProduct = { ...lookProductsCurrent[matchIndex] };
      let newProducts: LookProduct[] = [...lookProductsCurrent];
      updatedProduct.zIndex = getValidZIndex(zIndex);
      newProducts[matchIndex] = updatedProduct;
      dispatch(setLookCurrentProducts(newProducts));
    }
  }, [lookProductsCurrent, dispatch]);

  const handleRemoveLookProduct = React.useCallback((productId: string) => {
    let newProducts: LookProduct[] = [...lookProductsCurrent].filter((p) => p._id !== productId);
    dispatch(setLookCurrentProducts(newProducts));
  }, [lookProductsCurrent, dispatch]);

  const handleAddLookProduct = React.useCallback((product: Product, sku?: string) => {
    const newProduct: LookProduct = {
      ...buildLookAddProductPayload(product, sku), _id: `temp-${uuidv4()}`, details: {
        product: sku ? product.variants.find((variant) => variant.product.sku === sku)?.product! : product
      },
    }
    let newProducts: LookProduct[] = [...lookProductsCurrent, newProduct];
    dispatch(setLookCurrentProducts(newProducts));
  }, [lookProductsCurrent, dispatch]);

  return {
    handleDragStop,
    handleResizeStop,
    handleRotation,
    handleZIndex,
    handleRemoveLookProduct,
    handleAddLookProduct
  };
};

export default useLookProductActions;
