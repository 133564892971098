import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, FormGroup, Label } from 'reactstrap';
import * as Yup from "yup";
import LoadingSpinner from '../../components/LoadingSpinner';
import { memoPaths } from '../../constants/routes';
import { userRoles } from '../../constants/users';
import useError from '../../hooks/useError';
import { LoginPayload } from '../../interfaces/Auth';
import { useAppDispatch } from '../../store';
import { loginUser, setLoggedIn, setPathIndex } from '../../store/features/auth';

const initialFormValues: LoginPayload = {
  email: '',
  password: ''
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("PAGES.LOGIN.INPUTS.EMAIL.EMPTY"),
  password: Yup.string().required("PAGES.LOGIN.INPUTS.PASSWORD.EMPTY"),
});

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleError } = useError();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { search } = useLocation();
  const meetingId = React.useMemo(() => { return new URLSearchParams(search).get('meetingId') }, [search]);
  const redirectToShowRoom = React.useMemo(() => { return new URLSearchParams(search).get('showroom') }, [search]);

  const submitHandler = async (values: LoginPayload): Promise<void> => {
    await dispatch(loginUser({
      "email": values.email,
      "password": values.password
    })).unwrap().then((response: any) => {
      let pathIndex = '/';
      if (meetingId) {
        pathIndex = response.userType === userRoles.STYLIST
          ? `${memoPaths.userEntry.stylist}${meetingId}${redirectToShowRoom === 'true' ? '?showroom=true' : ''}`
          : `${memoPaths.userEntry.consumer}${meetingId}`;
        toast.info(t("TOASTS.LOGIN_SUCCESS"));
      }
      // NOTE: Setting the pathIndex in the slice is not optimal, as we would have to pass unneccesary values (meetingId, isShowroom) in the slice, increasing complexity
      // NOTE: Setting isLoggedIn in the slice, causes the app to be stack on route "/" because loginPage Index re-renders when isLoggedIn changes
      dispatch(setPathIndex(pathIndex));
      dispatch(setLoggedIn(true));
    }).catch((error: any) => {
      handleError(error);
      toast.error(t("TOASTS.INVALID_CREDENTIALS"), { position: 'top-right' });
    });
  }

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ errors, touched, dirty, isSubmitting }) => (
        <Form>
          <FormGroup>
            <Label for="email" className={classNames({ 'text-danger': errors.email && touched.email })}>{t("PAGES.LOGIN.INPUTS.EMAIL.LABEL")}</Label>
            <div className="input-group">
              <Field id="email" name="email" type="email" placeholder={t("PAGES.LOGIN.INPUTS.EMAIL.PLACEHOLDER")} autoComplete="email" className={classNames('form-control', { 'is-invalid': errors.email && touched.email })} />
            </div>
            {errors.email && touched.email && <div className="text-danger my-1">
              {t(errors.email)}
            </div>}
          </FormGroup>
          <FormGroup>
            <Label for="password" className={classNames({ 'text-danger': errors.password && touched.password })}>{t("PAGES.LOGIN.INPUTS.PASSWORD.LABEL")}</Label>
            <div className="input-group">
              <Field id="password" name="password" type={showPassword ? 'text' : 'password'} placeholder={t("PAGES.LOGIN.INPUTS.PASSWORD.PLACEHOLDER")} autoComplete="new-password" className={classNames('form-control', { 'is-invalid': errors.password && touched.password })} />
              <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {errors.password && touched.password && <div className="text-danger my-1">
              {t(errors.password)}
            </div>}
          </FormGroup>
          <Button color="primary" className="w-100 mt-2 w-md" disabled={!dirty || isSubmitting} type="submit">
            {isSubmitting && <LoadingSpinner isBtn />} {t("GENERIC.BUTTONS.CONFIRM")}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
export default LoginForm;