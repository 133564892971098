import React from "react";
import { useCallback } from "react";
import { useAppDispatch } from "../store";
import useError from "./useError";

const useFetchSimple = (
  action: any,
  initialLoading = true,
  initialError = null
): [(props?: any, onSuccess?: Function) => void, boolean, any] => {
  const dispatch = useAppDispatch();
  const { handleError } = useError();
  const [loading, setLoading] = React.useState<boolean>(initialLoading);
  const [error, setError] = React.useState<any>(initialError);

  const retrieve = useCallback(
    async (props = {}, onSuccess = null) => {
      try {
        setLoading(true);
        setError(null);

        const result = await dispatch(action(props)).unwrap();
        if (onSuccess) onSuccess(result);
      } catch (error: any) {
        handleError(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [action, dispatch, handleError]
  );

  return [retrieve, loading, error];
};
export default useFetchSimple;
