import { Connector } from 'mqtt-react-hooks';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import useError from '../../hooks/useError';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMqttConfig } from '../../store/features/mqtt';
import MqttWrapper from './wrapper';

const MqttProvider: React.FC = () => {
  const { broker, username, password, clientId, protocol, mqttLoading, mqttInitialized } = useAppSelector((state) => state.mqtt);
  const dispatch = useAppDispatch();
  const { handleError } = useError();

  React.useEffect(() => {
    if (!mqttInitialized) {
      dispatch(getMqttConfig()).unwrap()
        .catch((error) => { handleError(error) });
    };
  }, [mqttInitialized, dispatch, handleError]);

  const options = React.useMemo(() => {
    return {
      clientId: `${clientId}-${uuidv4()}`,
      username,
      password
    }
  }, [clientId, password, username]);

  return (
    <React.Fragment>
      {!mqttLoading &&
        <Connector brokerUrl={`${protocol}://${broker}`} options={options}>
          <MqttWrapper>
            <Outlet />
          </MqttWrapper>
        </Connector>
      }
    </React.Fragment>
  );
};


export default MqttProvider;