import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useError = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleError = React.useCallback((error: any): void => {
		if (error?.status === 401) {
			toast.warning(t("TOASTS.SESSION_EXPIRED"));
			dispatch({ type: 'Logout' });
		};
	}, [dispatch, t]);
	return { handleError }
}

export default useError;