import { CatalogSearchParams } from "../interfaces/Filters";
import { initialPagination } from "./pagination";

export const recursiveLimit = 5;

export const initialCatalogFilterPrice = {
  from: 0,
  to: 1000
}

export const initialCatalogFilter: CatalogSearchParams = {
  price: initialCatalogFilterPrice,
  categories: [],
  limit: initialPagination.limit, // not sure if used
  skip: initialPagination.skip, // not sure if used
  searchTerm: "",
  sort: ""
}