import { useCallback } from "react"
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useError from "../../hooks/useError";
import { useAppDispatch } from "../../store";
import { getFilters } from "../../store/features/filters";

const useFetchFilters = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleError } = useError();

  return useCallback((): void => {
    dispatch(getFilters()).unwrap().then(() => {
    }).catch((error) => {
      handleError(error);
      toast.error(t("TOASTS.FILTERS.RETRIEVE_ERROR"));
    })

  }, [dispatch, handleError, t]);
}
export default useFetchFilters;