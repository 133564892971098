import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { MqttConfigOptions, MqttState } from "../../interfaces/Mqtt";
import { createAppAsyncThunk } from "../extras";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const getMqttConfig = createAppAsyncThunk(
  "mqtt/getConfig",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.MqttService.registerMqtt();
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: MqttState = {
  broker: undefined,
  clientId: undefined,
  enabled: undefined,
  username: undefined,
  password: undefined,
  protocol: 'wss',
  mqttLoading: true,
  mqttInitialized: false
}

export const mqttSlice = createSlice({
  name: 'mqtt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Retrieve Config ---- */
      .addCase(getMqttConfig.pending, (state) => {
        state.mqttLoading = true;
      })
      .addCase(getMqttConfig.fulfilled, (state, action: PayloadAction<MqttConfigOptions>) => {
        state.mqttLoading = false;
        state.mqttInitialized = true;
        state.broker = action.payload.broker;
        state.clientId = action.payload.clientId;
        state.enabled = action.payload.enabled;
        state.password = action.payload.password;
        state.protocol = action.payload.protocol;
        state.username = action.payload.username;
      })
      .addCase(getMqttConfig.rejected, (state) => {
        state.mqttLoading = false;
      })

      /* ---- End call ---- */
      .addCase(endCallState, (state) => {
        // state.mqttInitialized = initialState.mqttInitialized;
        // state.mqttLoading = initialState.mqttLoading;
        // state.broker = initialState.broker;
        // state.clientId = initialState.clientId;
        // state.enabled = initialState.enabled;
        // state.password = initialState.password;
        // state.username = initialState.username;
        // state.protocol = initialState.protocol;
      })

      /* ---- Join call ---- */
      .addCase(joinCallState, (state) => {
        state.mqttInitialized = initialState.mqttInitialized;
        state.mqttLoading = initialState.mqttLoading;
        state.broker = initialState.broker;
        state.clientId = initialState.clientId;
        state.enabled = initialState.enabled;
        state.password = initialState.password;
        state.username = initialState.username;
        state.protocol = initialState.protocol;
      })

      /* ---- Log out ---- */
      .addCase(resetState, (state) => {
        state.mqttInitialized = initialState.mqttInitialized;
        state.mqttLoading = initialState.mqttLoading;
        state.broker = initialState.broker;
        state.clientId = initialState.clientId;
        state.enabled = initialState.enabled;
        state.password = initialState.password;
        state.username = initialState.username;
        state.protocol = initialState.protocol;
      })
  }
})
export default mqttSlice.reducer;