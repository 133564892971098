import React from "react";
import { createSelector } from "@reduxjs/toolkit";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import useProductInsert from "./useInsert";
import { parseJSON } from "../../helpers/utils";
import Product from "../../interfaces/Product";
import { setDragProductEnabled } from "../../store/features/interface";
import { userRoles } from "../../constants/users";

const domElementId = "draggable__product";
const dataTransferFormat = "text/plain";

const dragImageDimensions = {
  width: 100,
  height: 100,
};

const constructProductDragImage = (product: any) => {
  const containerElement = document.createElement("div");
  containerElement.setAttribute("id", domElementId);
  containerElement.style.position = "absolute";
  containerElement.style.top = [-2 * dragImageDimensions.height, "px"].join("");
  containerElement.style.left = [-2 * dragImageDimensions.width, "px"].join("");
  containerElement.style.zIndex = "-1";

  const imgElement = document.createElement("img");
  imgElement.src = product?.thumbnail?.url;
  imgElement.style.height = `${dragImageDimensions.height}px`;
  imgElement.style.width = `${dragImageDimensions.width}px`;

  containerElement.append(imgElement);
  document.body.append(containerElement);

  return containerElement;
};

const desctructDataTransfer = (
  event: any
): { product?: Product; activeSku?: string } => {
  const stringifiedData = event.dataTransfer.getData(dataTransferFormat);
  const data = parseJSON(stringifiedData);

  return data ?? { product: undefined, activeSku: undefined };
};

const selector = createSelector(
  [
    ({ auth }: RootState) => auth.userType,
    ({ appInterface }: RootState) => appInterface.activeSidebarTab,
  ],
  (userType, activeSidebarTab) => ({ userType, activeSidebarTab })
);

const useProductDraggable = () => {
  const dispatch = useAppDispatch();
  const { userType, activeSidebarTab } = useAppSelector(selector);
  const {
    handleCartInsertByStylist,
    handleCartInsertByConsumer,
    handleOutfitInsert,
    handleShowcaseInsert,
  } = useProductInsert();

  const handleDragStart = React.useCallback(
    (
      event: any,
      data: { product?: Product; activeSku?: string },
    ): void => {
      event.dataTransfer.setData(dataTransferFormat, JSON.stringify(data));

      event.dataTransfer.setDragImage(constructProductDragImage(data.product), 0, 0);

      dispatch(setDragProductEnabled(true));
    },
    [dispatch]
  );

  const handleDragEnd = React.useCallback((): void => {
    const elementToRemove = document.getElementById(domElementId);
    if (elementToRemove) elementToRemove.remove();

    dispatch(setDragProductEnabled(false));
  }, [dispatch]);

  const handleDropAction = React.useCallback(
    (event: any): void => {
      const { product, activeSku } = desctructDataTransfer(event);

      if (!product) return;

      switch (activeSidebarTab) {
        case "cart":
          if (userType === userRoles.STYLIST) handleCartInsertByStylist(product, activeSku);
          else handleCartInsertByConsumer(product, activeSku);
          break;
        case "outfits":
          handleOutfitInsert(product, activeSku);
          break;
        case "showcase":
          handleShowcaseInsert(product, activeSku);
          break;
        default:
          break;
      }
    },
    [
      userType,
      activeSidebarTab,
      handleCartInsertByStylist,
      handleCartInsertByConsumer,
      handleOutfitInsert,
      handleShowcaseInsert,
    ]
  );

  return { handleDragStart, handleDragEnd, handleDropAction };
};

export default useProductDraggable;
