export const currencyFormat = (num: number): string => ["\u20AC", num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")].join("");

export const parseJSON = (value: string, defaultValue?: any) => {
	if (!value || typeof value !== "string") return defaultValue;

	try {
		const parsedJSON = JSON.parse(value);
		if (typeof parsedJSON === "object") return parsedJSON;
	} catch { }

	return defaultValue;
};

export const escapeCharacter = (text: string, char: string): string => {
	return text.replaceAll(char, "");
}

export const capitalizeFirstLetter = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export const isObjEqual = (a: any, b: any): boolean => {
	if (a === b) return true;
	if (a instanceof Date && b instanceof Date)
		return a.getTime() === b.getTime();
	if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
		return a === b;
	if (a.prototype !== b.prototype) return false;
	const keys = Object.keys(a);
	if (keys.length !== Object.keys(b).length) return false;
	return keys.every(k => isObjEqual(a[k], b[k]));
};