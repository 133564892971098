import classNames from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { ActionMenuButton } from '../Button/ActionMenu';


// SALES-V2: Call in redux update
const CameraToggleBtn: React.FC = () => {
  const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
  const isCamMuted = useAppSelector((state) => state.appInterface.isCamMuted);
  const dispatch = useAppDispatch();

  const handleToggleCamera = React.useCallback(() => dispatch({ type: 'ToggleCameraState' }), [dispatch]);

  return (
    <ActionMenuButton
      id="toggle-cam-btn"
      disabled={dailyMeetingState !== 'joined-meeting'}
      icon={<i className={classNames("bx font-size-20 align-middle", { "bxs-video-off": isCamMuted, "bxs-video": !isCamMuted })} />}
      color={isCamMuted ? 'primary' : 'secondary'}
      onClick={handleToggleCamera}
      tooltip={isCamMuted ? "GENERIC.BUTTONS.CAM_ON" : "GENERIC.BUTTONS.CAM_OFF"}
    />
  )
}
export default CameraToggleBtn;