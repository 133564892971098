import { createContext, useContext } from "react";
import useScreenSize from "../../hooks/useScreenSize";

const ScreenContext = createContext<any>(null);

interface PropTypes {
  children?: React.ReactNode;
}

const ScreenProvider = ({ children }: PropTypes) => {
  const screenSize = useScreenSize();

  return (
    <ScreenContext.Provider value={screenSize}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => useContext(ScreenContext);

export default ScreenProvider;
