import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from ".";

// SALES-V2: Call in redux update
export type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<AsyncThunkConfig>();