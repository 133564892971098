import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/routes";
import "./assets/scss/theme.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/animations.scss";
import ReactGa from "react-ga4";
import { analytics } from "./common/appSettings";

if (analytics.id) {
  ReactGa.initialize(analytics.id);
}
// type CustomEvents<K extends string> = { [key in K]: (event: CustomEvent) => void }
// type CustomElement<T, K extends string = ''> = Partial<T & DOMAttributes<T> & { children: React.ReactNode } & CustomEvents<`on${K}`>>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "garments-tool": any;
      // "garments-tool": CustomElement<any, 'onSuccess' | 'onClose'>
    }
  }
}



const App: React.FC = () => {

  useEffect(() => {
    if (analytics.id) {
      ReactGa.send({ hitType: "pageview", path: window.location.pathname + window.location.search });
    }
  }, []);

  return (
    <BrowserRouter>
      <MainRoutes />

      <ToastContainer
        position="bottom-right"
        limit={4}
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        draggable={false}
        pauseOnHover={false}
        pauseOnFocusLoss={true}
        theme="colored"
      />
    </BrowserRouter>
  );
};



export default App;