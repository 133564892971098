import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, CardHeader, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import { userRoles } from "../../constants/users";
import { buildLookUpdateProductPayload } from "../../helpers/look";
import useCartActions from "../../hooks/cart/useCartActions";
import useLookActions from "../../hooks/look/useLookActions";
import useMqttMessage from "../../hooks/mqtt/useMessage";
import useError from "../../hooks/useError";
import { MQTT_VIEW_LOOKS } from "../../interfaces/Mqtt";
import { useAppDispatch, useAppSelector } from "../../store";
import { addLookItem, getLook, removeLookItem, resetLookCurrentProducts, setLooksUpdating, setSelectedItem, updateLookItem } from "../../store/features/looks";
import LoadingSpinner from "../LoadingSpinner";
import ResetLookModal from "../Modals/ResetLookModal";
import LooksDetailsComposerView from "./DetailsComposerView";
import LooksDetailsEmpty from "./DetailsEmpty";
import LooksDetailsHeader from "./DetailsHeader";
import LooksProductList from "./ProductList";

const LooksDetailsView: React.FC = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { removeProductFromLook } = useLookActions();
  const { addMultiToCart, cartActionsDisabled } = useCartActions();
  const { handleError } = useError();
  const { t } = useTranslation();
  const sendMqttMessage = useMqttMessage();
  const dispatch = useAppDispatch();
  const { activePresentLook } = useAppSelector((state) => state.appInterface);
  const cartLoading = useAppSelector((state) => state.cart.cartLoading);
  const userType = useAppSelector((state) => state.auth.userType);
  const { looksLoading, looksUpdating, lookRetrieved, selectedLookId, selectedLook, lookDetailsView, lookProductsCurrent, looksHasChanges, lookProductsInitial } = useAppSelector((state) => state.looks);
  const isListEmpty = React.useMemo(() => { return !!(selectedLook?.products.length === 0) }, [selectedLook]);
  const isComposerEmpty = React.useMemo(() => { return (isListEmpty && lookProductsCurrent.length === 0) }, [isListEmpty, lookProductsCurrent]);

  const handleAddAllToCart = React.useCallback(() => {
    if (selectedLook && selectedLook.products.length > 0) {
      // addMultiToCart(selectedLook.products.map((p) => p.details.product));
      addMultiToCart(selectedLook.products.map((p) => p));
    }
  }, [selectedLook, addMultiToCart]);

  const handleResetLook = React.useCallback(() => {
    dispatch(resetLookCurrentProducts());
    setShowModal(false);
  }, [dispatch]);

  const handleSaveLook = React.useCallback(async () => {
    dispatch(setLooksUpdating(true));
    dispatch(setSelectedItem(""));
    for (const product of lookProductsCurrent) {
      if (lookProductsInitial.find((prod) => product._id === prod._id)) {

        await dispatch(updateLookItem({
          lookId: selectedLookId!, productId: product._id, payload: buildLookUpdateProductPayload(product, product?.sku)
        })).unwrap()
          .catch((error) => {
            handleError(error);
            toast.error(`${t("TOASTS.COMPOSER.UPDATE_ERROR")} ${product.details.product.name}`);
          })

      } else {

        // TODO: name feels wrong for, buildLookAddProductPayload is designed for product details in mind
        await dispatch(addLookItem({
          lookId: selectedLookId!, payload: buildLookUpdateProductPayload(product, product?.sku)
        })).unwrap()
          .catch((error) => {
            handleError(error);
            toast.error(`${t("TOASTS.COMPOSER.ADD_ERROR")} ${product.details.product.name}`);
          })
      }
    }

    for (const product of lookProductsInitial) {
      if (!lookProductsCurrent.find((prod) => product._id === prod._id)) {
        await dispatch(removeLookItem({ lookId: selectedLookId!, productId: product._id })).unwrap()
          .catch((error) => {
            handleError(error);
            toast.error(`${t("TOASTS.COMPOSER.DELETE_ERROR")} ${product.details.product.name}`);
          })
      }
    }

    await dispatch(getLook({ lookId: selectedLookId! })).unwrap()
      .catch((error) => {
        handleError(error);
      })

    dispatch(setLooksUpdating(false));
    toast.info(`'${selectedLook!.title}' ${t("TOASTS.COMPOSER.UPDATED")}`);
    if (activePresentLook.split('_')[1] === selectedLookId) {
      sendMqttMessage({ type: MQTT_VIEW_LOOKS, payload: { toggleLooks: true, lookId: selectedLookId } });
    }
  }, [activePresentLook, selectedLook, selectedLookId, lookProductsCurrent, lookProductsInitial, t, dispatch, sendMqttMessage, handleError]);

  React.useEffect(() => {
    if (!lookRetrieved && selectedLookId) {
      dispatch(getLook({ lookId: selectedLookId })).unwrap()
        .catch((error: any) => {
          handleError(error);
        });
    }
  }, [lookRetrieved, selectedLookId, activePresentLook, dispatch, handleError]);

  const handleToggleModal = React.useCallback(() => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
      dispatch(setSelectedItem(""));
    }
  }, [dispatch, showModal]);

  return (
    <React.Fragment>
      <Card className="mb-0 h-100 shadow-none">
        <CardHeader className="bg-white p-2 border-bottom">
          {selectedLook && <LooksDetailsHeader look={selectedLook} />}
        </CardHeader>
        <CardBody className="px-2 pt-0 pb-0 sidebar-look-cardbody">
          {!looksLoading && selectedLook &&
            <TabContent activeTab={lookDetailsView} className="position-relative h-100">
              <TabPane tabId="LOOK_PRODUCTS_LIST" className="sidebar-list-container">
                {isListEmpty && <LooksDetailsEmpty />}
                {!isListEmpty && <LooksProductList products={lookProductsInitial} onRemove={removeProductFromLook} />}
              </TabPane>
              <TabPane tabId="LOOK_PRODUCTS_COMPOSER" id="outfit-composer" className="h-100">
                {isComposerEmpty && <LooksDetailsEmpty />}
                {!isComposerEmpty && <LooksDetailsComposerView />}
              </TabPane>
            </TabContent>}
          {looksLoading && <div className="position-absolute top-50 start-50 translate-middle" >
            <LoadingSpinner size="md" className="text-primary" />
          </div>}
        </CardBody>

        {userType === userRoles.STYLIST && <CardFooter className="bg-white d-flex border-top">
          {lookDetailsView === 'LOOK_PRODUCTS_COMPOSER' && <React.Fragment>
            <Button id="save-look-btn" color="success" className="me-3" disabled={(isComposerEmpty || cartLoading || looksUpdating) || !looksHasChanges} onClick={handleSaveLook}>
              <i className='bx bxs-save font-size-18 align-middle' />
              <UncontrolledTooltip fade={false} placement="top" target="save-look-btn">
                {t("GENERIC.BUTTONS.SAVE_LOOK")}
              </UncontrolledTooltip>
            </Button>

            <Button id="reset-look-btn" color="danger" className="me-auto" disabled={(isComposerEmpty || cartLoading || looksUpdating) || !looksHasChanges} onClick={handleToggleModal}>
              <i className="bx bx-undo font-size-18 align-middle" />
              <UncontrolledTooltip fade={false} placement="top" target="reset-look-btn">
                {t("GENERIC.BUTTONS.RESET_LOOK")}
              </UncontrolledTooltip>
            </Button>

          </React.Fragment>}
          <Button id="send-all-cart-btn" color="primary" className="ms-auto" disabled={(isListEmpty || looksUpdating) || looksHasChanges || cartActionsDisabled} onClick={handleAddAllToCart}>
            <i className="bx bxs-cart-alt me-1 align-middle font-size-18" />
            <span className="ms-1">
              {t("GENERIC.BUTTONS.SEND_ALL_CART")}
            </span>
            <UncontrolledTooltip fade={false} placement="top" target="send-all-cart-btn">
              {t("GENERIC.BUTTONS.RESET_LOOK")}
            </UncontrolledTooltip>
          </Button>
        </CardFooter>}
      </Card>
      <ResetLookModal isOpen={showModal} toggle={handleToggleModal} title={selectedLook?.title || '-'} confirm={handleResetLook} />
    </React.Fragment>
  );
};

export default LooksDetailsView;
