import React from 'react';

interface CornerNameProps {
  name: string;
}

const CornerName: React.FC<CornerNameProps> = ({ name }) => {
  return (
    <small className='text-white position-absolute bottom-0 start-0 py-1 px-2 bg-secondary bg-soft mw-100'>
      {name}
    </small>
  )
}
export default CornerName;