import React from "react";
import { createSelector } from "@reduxjs/toolkit";
import SearchInput from "../Search/Input";
import useCatalog from "../../hooks/useCatalog";
import useAppNavigation from "../../hooks/useAppNavigation";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { setActiveWindow } from "../../store/features/interface";

const selector = createSelector(
  [
    ({ appInterface }: RootState) => appInterface.activeWindow,
    ({ filters }: RootState) => filters.searchParams,
  ],
  (activeWindow, searchParams) => ({
    activeWindow,
    searchParams,
    searchTerm: searchParams.searchTerm ?? "",
  })
);

const NavSearch = () => {
  const dispatch = useAppDispatch();
  const { retrieveCatalog } = useCatalog();
  const { activeWindow, searchTerm } = useAppSelector(selector);
  const { returnToMeeting } = useAppNavigation();

  const handleSearch = React.useCallback(
    (searchTerm: string) => {
      if (activeWindow !== "productCatalog")
        dispatch(setActiveWindow("productCatalog"));

      returnToMeeting();

      retrieveCatalog({ searchTerm });
    },
    [activeWindow, retrieveCatalog, returnToMeeting, dispatch]
  );

  return <SearchInput initialValue={searchTerm} onSearch={handleSearch} />;
};

export default NavSearch;
