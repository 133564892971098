import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputGroup, UncontrolledTooltip } from 'reactstrap';
import { formatPrice } from '../../helpers/product';
import useFavorite from '../../hooks/useFavorite';
import { CartProduct } from '../../interfaces/Product';
import FavouriteButton from '../Button/Favourite';
import SkeletonImage from '../SkeletonImage';

interface PropTypes {
	product: CartProduct;
	onRemove: (id: string) => void;
	onAdd: ({ baseSku, sku }: { baseSku: string, sku?: string }) => void;
	toggleModal: (product: CartProduct) => void;
	visitDetails: (sku: string) => void;
	index: number;
	actionsDisabled: boolean;
}

const CartTableItem: React.FC<PropTypes> = ({ product, actionsDisabled, index, visitDetails, onRemove, onAdd, toggleModal }) => {
	const { t } = useTranslation();
	const { isFavorite, toggleFavorite, likedProductsLoading } = useFavorite();

	const handleVisitDetails = React.useCallback(() => {
		visitDetails(encodeURIComponent(product.baseSku));
	}, [product.baseSku, visitDetails]);

	return (
		<tr onClick={handleVisitDetails} style={{ cursor: 'pointer' }}>
			<td className="w-25 p-1">
				<SkeletonImage
					loading="lazy" className="sidebar-product-img" skeletonClassName="sidebar-product-img" src={product.details.product.thumbnail?.url}
					alt={product.details.product?.thumbnail?.url ? product.details.product.thumbnail.label : product.details.product.thumbnail.label} />
			</td>
			<td className="w-75 p-1">
				<h5 className="font-size-13 text-wrap text-dark product-card-description mt-1">
					{product.details.product.name}
				</h5>
				<p className="mb-0 text-muted">
					{product?.price ? formatPrice(product.price) : '-'}
				</p>
				{!product.details.product.el_configurable && <div style={{ width: '7rem' }} >
					<InputGroup>
						<Button color="primary" disabled={product.qty <= 1 || actionsDisabled} onClick={(e) => {
							e.stopPropagation();
							onRemove(product._id!);
						}}>
							-
						</Button>
						<Input
							type="text"
							value={product.qty}
							name="demo_vertical"
							readOnly
							onClick={(e) => { e.stopPropagation(); }}
						/>
						<Button color="primary" disabled={actionsDisabled} onClick={(e) => {
							e.stopPropagation();
							onAdd({ baseSku: product.baseSku, sku: product?.sku });
						}}>
							+
						</Button>
					</InputGroup>
				</div>}
			</td>
			<td className="w-auto p-1">
				<FavouriteButton
					id={`cart-favorite-btn-${index}`}
					isFavorite={isFavorite(product.baseSku)}
					onClick={(e) => {
						e.stopPropagation();
						toggleFavorite(product.baseSku)
					}}
					disabled={likedProductsLoading}
					className="rounded-circle me-1 p-2"
					size="sm"
					tooltip={isFavorite(product.baseSku) ? t("GENERIC.BUTTONS.REMOVE_FROM_FAVORITES") : t("GENERIC.BUTTONS.ADD_TO_FAVORITES")}
				/>
				<Button
					id={`cart-remove-btn-${product._id}`}
					color="danger"
					outline
					className="rounded-circle p-2"
					size="sm"
					onClick={(e) => {
						e.stopPropagation();
						toggleModal(product)
					}}
					disabled={actionsDisabled}
				>
					<i className="bx bx-trash align-middle font-size-18" />
					<UncontrolledTooltip fade={false} placement="top" target={`cart-remove-btn-${product._id}`}>
						{t("GENERIC.BUTTONS.REMOVE")}
					</UncontrolledTooltip>
				</Button>
			</td>
		</tr>
	)
}
export default CartTableItem;