import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { AppInterfaceState } from "../interfaces/AppInterface";
import { AuthState } from "../interfaces/Auth";
import { CatalogState } from "../interfaces/Catalog";
import { FilterState } from "../interfaces/Filters";
import { LookState } from "../interfaces/Look";
import { MeetingState } from "../interfaces/Meeting";
import { OrderState } from "../interfaces/Order";
import auth from "./features/auth";
import call from "./features/call";
import cart from "./features/cart";
import catalog from "./features/catalog";
import details from "./features/details";
import filters from "./features/filters";
import appInterface from "./features/interface";
import looks from "./features/looks";
import meeting from "./features/meeting";
import mqtt from "./features/mqtt";
import order from "./features/order";
import showcase from "./features/showcase";
import tenancy from "./features/tenancy";

const persistConfig: PersistConfig<any> = {
  key: 'eligo-sales-',
  storage,
  throttle: 200
}

// NOTE: if we don't persist meetingId, refreshing the page will cause MeetingGuard componet to redirect us back to userEntryPage
export default combineReducers({
  looks: persistReducer<LookState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'looks', whitelist: ['lookView', 'lookDetailsView', 'selectedLookId'] }, looks),
  cart,
  order: persistReducer<OrderState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'order', whitelist: ['paymentUrl'] }, order),
  catalog: persistReducer<CatalogState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'catalog', whitelist: ['pagination', 'catalogView'] }, catalog),
  filters: persistReducer<FilterState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'filters', blacklist: ['initializedFilters', 'filtersLoading', 'categories'] }, filters),
  auth: persistReducer<AuthState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'auth', whitelist: ['accessToken', 'userId', 'profileId', 'pathIndex', 'isLoggedIn', 'isShowroom'] }, auth),
  appInterface: persistReducer<AppInterfaceState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'interface', blacklist: ['dragProductEnabled', 'chatHasNotifications', 'chatNotificationCounter', 'meetingNotesModalToggled', 'isChatVisible', 'endCallConfirmationModalToggled', 'paymentStatusModalToggled', 'showcaseNotificationCounter', 'paymentModalToggled'] }, appInterface),
  meeting: persistReducer<MeetingState, AnyAction>({ ...persistConfig, key: persistConfig.key + 'meeting', whitelist: ['meetingId'] }, meeting),
  mqtt,
  details,
  showcase,
  tenancy,
  call
});