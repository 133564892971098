import React from 'react';
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from "reactstrap";
import error from "../../assets/images/error-img.png"
import { applicationCopyright } from '../../common/appSettings';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container className="my-5">
      <Row>
        <Col lg="12">
          <div className="text-center mb-5">
            <h1 className="display-2 fw-medium">
              4
              <i className="bx bx-buoy bx-spin text-primary display-3" />
              4
            </h1>
            <h4 className="text-uppercase">{t("PAGES.404.LABELS.PAGE_NOT_FOUND")}</h4>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8" xl="6">
          <div>
            <img src={error} alt="error" className="img-fluid" />
          </div>
        </Col>
      </Row>
      <div className="mt-5 text-center">{applicationCopyright}</div>
    </Container>
  )
}
export default NotFoundPage;