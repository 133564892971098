import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps, UncontrolledTooltip } from "reactstrap";

interface PropTypes {
  icon: React.ReactElement;
  tooltip?: string;
}

export const ActionMenuButton: React.FC<PropTypes & ButtonProps> = ({ ...props }) => {
  const { icon, className, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Button
      {...rest}
      className={classnames("p-2 mx-2 rounded-circle outline-none border-0", className)}>
      {icon}
      {props.tooltip && props.id && <UncontrolledTooltip fade={false} placement="top" target={props.id}>
        {t(props.tooltip)}
      </UncontrolledTooltip>}
    </Button>
  );
}
