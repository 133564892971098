import classnames from 'classnames';
import React from 'react';

interface PropTypes {
  count: number;
  show: boolean;
  className?: string;
}

const NotificationBadge: React.FC<PropTypes> = ({ count, show, className }) => {
  return (
    <React.Fragment>
      {count > 0 && show && <div className={classnames("position-absolute badge bg-danger rounded-circle rounded-pill pulse-danger", className)} >
        {count > 9 ? "9+" : count}
      </div>}
    </React.Fragment>
  )
}
export default NotificationBadge;