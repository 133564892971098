import axios from 'axios';
import { CreateOrderPayload } from '../interfaces/Order';
import { constructRequestHeaders, SERVER_URL } from "./helpers";

export default class OrderService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/order`;
  }

  async createOrder(payload: CreateOrderPayload): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}/`, payload, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async retrieveOrder(orderId: string): Promise<any> {
    try {
      const response = await axios.get(`${this.baseUrl}s/${orderId}`, { headers: constructRequestHeaders({}) })
      return await Promise.resolve(response.data.data)
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error)
    }
  }
}