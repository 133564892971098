import { useField } from 'formik';
import React from 'react';
import { Alert, InputGroupText } from 'reactstrap';
import { cartDiscountOptions } from '../../constants/cart';
import { calcCartDiscountTotal } from '../../helpers/cart';
import { formatPrice } from '../../helpers/product';
import { CartDiscountFixed, CartDiscountPercentage } from '../../interfaces/Cart';
import { FormikInputGroup, RadioInput } from '../Forms/FormikInputs';
import Typography from '../Typography';

interface PropTypes {
  total: number;
}

const CartDiscountUpdateFields: React.FC<PropTypes> = ({ total }) => {
  const [typeField] = useField<CartDiscountPercentage | CartDiscountFixed>('type');
  const [amountField] = useField<number>('value');
  return (
    <React.Fragment>
      <RadioInput
        className="me-2"
        name="type"
        label="MODALS.CART_DISCOUNT.INPUTS.FIXED.LABEL"
        value={cartDiscountOptions.FIXED}
        side="left"
      />

      <RadioInput
        className="me-2"
        name="type"
        label="MODALS.CART_DISCOUNT.INPUTS.PERCENTAGE.LABEL"
        value={cartDiscountOptions.PERCENTAGE}
        side="left"
      />

      <FormikInputGroup
        name="value"
        label="MODALS.CART_DISCOUNT.INPUTS.AMOUNT.LABEL"
        placeholder="Set Discount Amount"
        className="form-control"
        type="number"
      >
        <InputGroupText>
          {typeField.value === cartDiscountOptions.PERCENTAGE ? '%' : '€'}
        </InputGroupText>
      </FormikInputGroup>

      <Alert color="primary">
        <i className='bx bx-info-circle font-size-17 align-middle me-2' />
        <Typography Variant="span" text={"MODALS.CART_DISCOUNT.LABELS.NEW_TOTAL"} />{": "}
        <strong>{formatPrice(typeField.value ? calcCartDiscountTotal({ type: typeField.value, value: amountField.value, cartTotal: total }) : total)}</strong>
      </Alert>
    </React.Fragment>
  )
}
export default CartDiscountUpdateFields;