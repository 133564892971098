import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Typography from "../Typography";
import ShareInput from "./Input";

interface PropTypes {
  isShown: boolean;
  shareLinks: { label: string, link: string, message?: string }[];
  onClose: () => void;
}

const ShareMeetingModal: React.FC<PropTypes> = ({ isShown, shareLinks, onClose, }) => {
  return (
    <Modal isOpen={isShown} size="md" centered toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <Typography text={"MODALS.SHARE_MEETING.HEADER"} />
      </ModalHeader>
      <ModalBody>
        {shareLinks.map((shareLink, index: number) => (
          <div key={index}>
            <Typography Variant="h6" text={shareLink.label} className="fw-bold" />
            {shareLink.message && <Typography Variant="p" className="text-muted" text={shareLink.message} />}
            <ShareInput text={shareLink.link} />
          </div>
        ))}
      </ModalBody>
      <ModalFooter className="border-0">
        <Button color="secondary" onClick={onClose}>
          <Typography text={"GENERIC.BUTTONS.CLOSE"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ShareMeetingModal;
