import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import * as Yup from "yup";
import api from '../../api';
import EligoCard from '../../components/Cards/Eligo';
import SuccessCard from '../../components/Cards/Success';
import LoadingSpinner from '../../components/LoadingSpinner';

const initialFormValues: { userPassword: string } = {
  userPassword: ''
}

const validationSchema = Yup.object().shape({
  userPassword: Yup.string().min(5, "PAGES.CONFIRM_EMAIL.INPUTS.PASSWORD.TOO_SHORT").required("PAGES.CONFIRM_EMAIL.INPUTS.PASSWORD.EMPTY")
});

const ConfirmationPage: React.FC = () => {
  const { confirmationId } = useParams();
  const { t } = useTranslation();
  const [verified, setVerified] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const submitHandler = async (values: { userPassword: string }): Promise<void> => {
    if (confirmationId) {
      try {
        await api.UserService.confirmMail({ token: confirmationId, password: values.userPassword });
        setVerified(true);
      } catch (error: any) {
        if (error?.response?.data?.error?.message) {
          toast.error(error?.response?.data?.error?.message);
        }
      }
    }
  }

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          {verified ? (
            <SuccessCard title="Success!" text="Your account has been verified!" />
          ) : (
            <EligoCard title={t("PAGES.CONFIRM_EMAIL.HEADER")} description={t("PAGES.CONFIRM_EMAIL.SUBHEADER")}>
              <Formik
                initialValues={initialFormValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
              >
                {({ errors, touched, dirty, isSubmitting }) => (
                  <Form>

                    <Field id="userName" name="userName" type="text" autoComplete="username" className={'form-control hidden'} hidden />
                    <Label for="userPassword" className={`${errors.userPassword && touched.userPassword ? 'text-danger' : ''}`}>{t("PAGES.CONFIRM_EMAIL.INPUTS.PASSWORD.LABEL")}</Label>
                    <div className="input-group">
                      <Field id="userPassword" name="userPassword" type={showPassword ? 'text' : 'password'} placeholder={t("PAGES.CONFIRM_EMAIL.INPUTS.PASSWORD.PLACEHOLDER")} autoComplete="new-password" className={`form-control ${errors.userPassword && touched.userPassword ? 'is-invalid' : ''}`} />
                      <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                    {errors.userPassword && touched.userPassword && <div className="text-danger my-1">
                      {t(errors.userPassword)}
                    </div>}
                    <Button color="primary" className="w-md waves-effect waves-light mt-2" disabled={!dirty || isSubmitting} type="submit">
                      {isSubmitting && <LoadingSpinner isBtn />}  {t("GENERIC.BUTTONS.CONFIRM")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </EligoCard>
          )}
        </Col>
      </Row>
    </Container>
  )
}
export default ConfirmationPage;