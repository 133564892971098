import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { memoPaths } from '../../constants/routes';
import useError from '../../hooks/useError';
import { useAppDispatch, useAppSelector } from '../../store';
import { loginGuest, setLoggedIn, setPathIndex } from '../../store/features/auth';

const useGuestLogin = () => {
  const dispatch = useAppDispatch();
  const { guestToken, meetingId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useError();
  const { authLoading, isLoggedIn } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (!guestToken || isLoggedIn) return;

    dispatch(loginGuest({ username: guestToken })).unwrap()
      .then((res) => {
        const pathIndex = `${memoPaths.userEntry.consumer}${meetingId}`;
        dispatch(setPathIndex(pathIndex));
        dispatch(setLoggedIn(true));
        toast.info(t("TOASTS.LOGIN_SUCCESS"));
        navigate(`${pathIndex}?accessToken=${res.token}`);
      })
      .catch((error) => {
        toast.error(error.message);
        handleError(error);
      })
  }, [guestToken, meetingId, isLoggedIn, handleError, dispatch, navigate, t]);

  return authLoading;
}
export default useGuestLogin;