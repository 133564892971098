import classNames from "classnames";
import React from "react";
import { Button, ButtonProps } from "reactstrap";

const BackButton: React.FC<ButtonProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <Button {...rest} outline color="primary" className={classNames("rounded-circle border-0 p-1", className)}>
      <i className={classNames("bx bx-arrow-back font-size-20 align-middle")} />
    </Button>
  );
};

export default BackButton;
