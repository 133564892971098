import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../api";
import { buildLookAddProductPayload, buildLookUpdateProductPayload } from "../../helpers/look";
import { LookProductPayload } from "../../interfaces/Look";
import Product, { LookProduct } from "../../interfaces/Product";
import { useAppSelector } from "../../store";
import { getLook } from "../../store/features/looks";
import useFetch from "../useFetch";

const actionsList: any = {
  add: (lookId: string, payload: LookProductPayload) => api.LookService.createLookProduct(lookId, payload),
  update: (lookId: string, productId: string, payload: LookProductPayload) => api.LookService.updateLookProduct(lookId, productId, payload),
  remove: (lookId: string, productId: string) => api.LookService.deleteLookProduct(lookId, productId),
};

const useLookActions = () => {
  const lookId = useAppSelector((state) => state.looks.selectedLookId);
  const createLookProduct = useFetch(actionsList.add, getLook);
  const updateLookProduct = useFetch(actionsList.update, getLook);
  const deleteLookProduct = useFetch(actionsList.remove, getLook);
  const { t } = useTranslation();

  const addProductToLook = React.useCallback((product: Product): void => {
    if (lookId) {
      createLookProduct({
        actionProps: { lookId, payload: buildLookAddProductPayload(product) },
        refetchProps: { lookId },
        onSuccess: toast.info(t("TOASTS.OUTFITS.ADD"))
      });
    } else {
      console.log("select an outfit before you proceed");
    }
  }, [lookId, createLookProduct, t]);

  const modifyProductInLook = React.useCallback((product: LookProduct): void => {
    updateLookProduct({
      actionProps: {
        lookId,
        productId: product._id,
        payload: buildLookUpdateProductPayload(product),
      },
      refetchProps: { lookId },
    });
  }, [lookId, updateLookProduct]);

  const removeProductFromLook = React.useCallback((lookProductId: string): void => {
    deleteLookProduct({
      actionProps: { lookId, productId: lookProductId },
      refetchProps: { lookId },
      onSuccess: toast.info(t("TOASTS.OUTFITS.REMOVE"))
    });
  }, [lookId, deleteLookProduct, t]);

  return { addProductToLook, modifyProductInLook, removeProductFromLook };
};

export default useLookActions;
