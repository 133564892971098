import React from 'react';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { initialCatalogFilterPrice } from '../../constants/catalog';
import { useAppSelector } from '../../store';
import CollapsableListItem from '../CollapsableListItem';
import LoadingSpinner from '../LoadingSpinner';
import Typography from '../Typography';
import CategoryFilter from './CategoryFilter/categoryFilter';
import PriceSelect from './priceFilter';
import useClearFilters from './useClearFilters';
import useFetchFilters from './useFetchFilters';

const Filters: React.FC = () => {
  const { categories, filtersLoading, initializedFilters, searchParams } = useAppSelector((state) => state.filters);
  const clearFilters = useClearFilters();
  const fetchFilters = useFetchFilters();

  const hasActiveFilter = React.useMemo(
    () =>
      searchParams.categories.length > 0 ||
      searchParams.price.from !== initialCatalogFilterPrice.from ||
      searchParams.price.to !== initialCatalogFilterPrice.to ||
      !!searchParams.searchTerm,
    [searchParams]
  );

  React.useEffect(() => {
    if (!initializedFilters && !filtersLoading) {
      fetchFilters();
    }
  }, [initializedFilters, searchParams, filtersLoading, fetchFilters, clearFilters])

  return (
    <Card className="mb-0 overflow-y-scroll h-100">
      <CardBody className="mb-0 p-2">
        {filtersLoading &&
          <div className="text-center w-100" style={{ minHeight: '350px' }}>
            <div className="position-absolute top-50 start-50 translate-middle" >
              <LoadingSpinner className="text-primary" size="md" />
            </div>
          </div>}
        {!filtersLoading &&
          <React.Fragment>
            <CardTitle className="d-flex justify-content-between">
              <Typography className="mb-0" Variant="h5" text={"GENERIC.LABELS.FILTERS"} />
              {hasActiveFilter && <Button color="primary" size="sm" className="ms-auto badge py-1" onClick={() => clearFilters('all')}>
                <Typography text={"GENERIC.BUTTONS.CLEAR"} Variant="span" />
              </Button>}
            </CardTitle>
            <hr className="text-primary my-2" />
            <ul className="list-unstyled">
              {categories.length > 0 &&
                <CollapsableListItem id="category" label={"GENERIC.LABELS.CATEGORIES"} clear={() => clearFilters('category')} disabled={searchParams.categories.length === 0}>
                  <CategoryFilter categories={categories} />
                </CollapsableListItem>}
              {<CollapsableListItem
                id="price"
                label={"GENERIC.LABELS.PRICE"}
                clear={() => clearFilters('price')}
                disabled={initialCatalogFilterPrice.from === searchParams.price.from && initialCatalogFilterPrice.to === searchParams.price.to}>
                <PriceSelect price={initialCatalogFilterPrice} />
              </CollapsableListItem>}
            </ul>
          </React.Fragment>}
      </CardBody>
    </Card>
  )
}
export default Filters;