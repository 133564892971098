import React from "react";

interface PropTypes {
  draggable?: boolean;
  onDragStart: (event: any) => void;
  onDragEnd: () => void;
  children: React.ReactNode;
}

const DraggableItem: React.FC<PropTypes> = ({
  draggable = true,
  onDragStart,
  onDragEnd,
  children,
}) => {
  const handleDragStart = (event: any) => {
    // e.dataTransfer.dropEffect = "none";
    event.dataTransfer.effectAllowed = "move";
    onDragStart(event);
  };

  const handleDragEnd = (event: any) => {
    // event.dataTransfer.clearData();
    onDragEnd();
  };

  return (
    <div
      style={{ cursor: draggable ? "move" : "default" }}
      draggable={draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </div>
  );
};

export default DraggableItem;
