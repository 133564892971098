import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { useAppSelector } from '../../store';

const RemovedFromCallPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathIndex } = useAppSelector((state) => state.auth);

  const handleRejoinCall = (): void => {
    navigate(pathIndex)
  }

  return (
    <Container className="my-5">
      <Row>
        <Col xs={12} md={5} className="mx-auto">
          <Card className="mt-5">
            <CardBody className="text-center">
              <CardTitle>{t("PAGES.REMOVED_FROM_CALL.HEADER")}</CardTitle>
              <CardText>{t("PAGES.REMOVED_FROM_CALL.SUBHEADER")}</CardText>
              <Button color="primary" onClick={handleRejoinCall}>{t("GENERIC.BUTTONS.JOIN_CALL")}</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default RemovedFromCallPage;