import React from 'react';
import { userRoles } from '../../constants/users';
import { useAppDispatch, useAppSelector } from '../../store';
import { leaveCall } from '../../store/features/call';
import { setEndCallModal } from '../../store/features/interface';
import { ActionMenuButton } from '../Button/ActionMenu';

// SALES-V2: Call in redux update
const EndCallButton: React.FC = () => {
  const { userType, isShowroom } = useAppSelector((state) => state.auth);
  const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
  const dispatch = useAppDispatch();

  const handleLeaveCall = React.useCallback(() => {
    if (userType === userRoles.STYLIST && !isShowroom) {
      dispatch(setEndCallModal(true));
    } else {
      dispatch(leaveCall());
    };
  }, [userType, isShowroom, dispatch]);

  return (
    <ActionMenuButton
      id="end-call-btn"
      disabled={dailyMeetingState !== 'joined-meeting'}
      icon={<i className="bx bxs-phone font-size-20 align-middle" />}
      color="danger"
      onClick={handleLeaveCall}
      tooltip={(userType === userRoles.STYLIST && !isShowroom) ? "GENERIC.BUTTONS.END_CALL" : "GENERIC.BUTTONS.LEAVE_CALL"}
    />
  )
}
export default EndCallButton;