import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { composer, composerRatio } from '../../constants/looks';
import { constructProductMainImage, formatPrice } from '../../helpers/product';
import useError from '../../hooks/useError';
import useWindowResize from '../../hooks/useWindowResize';
import { useAppDispatch, useAppSelector } from '../../store';
import { setPreviousWindow } from '../../store/features/interface';
import { getLook } from '../../store/features/looks';
import BackButton from '../Button/Back';
import LoadingSpinner from '../LoadingSpinner';
import SkeletonImage from '../SkeletonImage';


const CustomerLooks: React.FC = () => {
  const { selectedLook, selectedLookId, looksLoading, lookRetrieved } = useAppSelector((state) => state.looks);
  const dispatch = useAppDispatch();
  const { handleError } = useError();
  const { width } = useWindowResize();

  React.useEffect(() => {
    if (selectedLookId && !lookRetrieved) {
      dispatch(getLook({ lookId: selectedLookId })).unwrap()
        .then(() => { })
        .catch((error: any) => {
          handleError(error);
          toast.error('Error retrieving look');
        })
    }
  }, [selectedLookId, lookRetrieved, dispatch, handleError]);

  const handleBack = (): void => {
    dispatch(setPreviousWindow());
  }

  return (
    <Card className="p-1 my-2" style={{ minHeight: '100%' }}>
      {!looksLoading && selectedLook &&
        <CardHeader className="bg-transparent px-0 mb-0 d-flex">
          <BackButton onClick={handleBack} />
          <div className="ms-1">
            <h5 className="font-size-18">{selectedLook.title}</h5>
            <h5 className="mb-0 text-muted font-size-16">
              {formatPrice(selectedLook.total)}
            </h5>
          </div>
        </CardHeader>}
      <CardBody className="d-flex p-0 mx-auto mt-2">
        {!looksLoading && selectedLook && <div className="border border-secondary my-auto" style={{ height: (width - composer.offset) / composerRatio, width: (width - composer.offset) }}>
          {selectedLook?.products?.length > 0 && selectedLook.products.map((product, index) => (
            <SkeletonImage
              key={index}
              style={{
                transform: `translate(${product.x * (width - composer.offset) / composer.width}px, ${product.y * (width - composer.offset) / composer.width}px) rotate(${product.rotation || 0}deg) `,
                objectFit: "contain",
                height: product.height * (width - composer.offset) / composer.width,
                width: product.width * (width - composer.offset) / composer.width,
                zIndex: product.zIndex
              }}
              loading="lazy"
              className="position-absolute"
              skeletonClassName="position-absolute"
              skeletonStyle={{
                transform: `translate(${product.x * (width - composer.offset) / composer.width}px, ${product.y * (width - composer.offset) / composer.width}px) rotate(${product.rotation || 0}deg) `,
                objectFit: "contain",
                height: product.height * (width - composer.offset) / composer.width,
                width: product.width * (width - composer.offset) / composer.width,
                zIndex: product.zIndex
              }}
              src={constructProductMainImage(product.details.product)}
              alt={product?.details?.product.name || "-"}
            />
          ))}

          {selectedLook?.products?.length === 0 && <div className="text-center my-auto">
            <h5 className="mb-3">
              This outfit contains no items.
            </h5>
            <Button onClick={handleBack} color="primary" className="p-2 rounded-circle">
              <i className="bx bx-arrow-back font-size-20 align-middle" />
            </Button>
          </div>}
        </div>}

        {!selectedLook && !looksLoading && <div className="m-auto text-center">
          <h5 className="mb-3">
            Look not found
          </h5>
          <Button onClick={handleBack} color="primary" className="p-2 rounded-circle">
            <i className="bx bx-arrow-back font-size-20 align-middle" />
          </Button>
        </div>}
      </CardBody>
      {looksLoading &&
        <div className="position-absolute top-50 start-50 translate-middle" >
          <LoadingSpinner className="text-primary" size="lg" />
        </div>}
    </Card>
  )
}
export default CustomerLooks;