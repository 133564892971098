import classnames from 'classnames';
import React from 'react'
import { Button, ButtonProps, UncontrolledTooltip } from 'reactstrap';

interface PropTypes {
  isFavorite: boolean;
  tooltip?: string;
}

const FavouriteButton: React.FC<PropTypes & ButtonProps> = ({ ...props }) => {
  const { isFavorite, ...rest } = props;

  return (
    <Button
      {...rest}
      outline={!props.isFavorite}
      className={classnames(props.className)}
      color="danger"
      onClick={props.onClick}
    >
      <i className='bx bxs-heart align-middle font-size-18' />
      {props.tooltip && props.id && <UncontrolledTooltip fade={false} placement="top" target={props.id}>
        {props.tooltip}
      </UncontrolledTooltip>}
    </Button>
  )
}
export default FavouriteButton;