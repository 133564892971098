import React from "react";
import { FaShareAlt } from "react-icons/fa";
import { userRoles } from "../../constants/users";
import useCopyText from "../../hooks/useCopyText";
import useModal from "../../hooks/useModal";
import { useAppSelector } from "../../store";
import { ActionMenuButton } from "../Button/ActionMenu";
import ShareMeetingModal from "./MeetingModal";
import ShareTopbarButton from "./TopbarButton";

const constructShareLinks = (
  appOrigin: string,
  meetingId: string
) => [
    {
      label: "GENERIC.LABELS.STYLIST",
      link: [appOrigin, userRoles.STYLIST, meetingId].join("/"),
      message: "MODALS.SHARE_MEETING.LABELS.MOBILE_UNAVAILABLE"
    },
    {
      label: "GENERIC.LABELS.SHOWROOM",
      link: [appOrigin, userRoles.STYLIST, `${meetingId}?showroom=true`].join("/"),
    },
    {
      label: "GENERIC.LABELS.CONSUMER",
      link: [appOrigin, userRoles.CONSUMER, meetingId].join("/")
    },
  ];

interface PropTypes {
  view: "topbar" | "mobile";
}

// SALES-V2: Call in redux update
const ShareMeetingContainer: React.FC<PropTypes> = ({ view }) => {
  const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
  const meetingId = useAppSelector((state) => state.meeting.meetingId);
  const authUserType = useAppSelector((state) => state.auth.userType);
  const [isShown, openShareModal, closeShareModal] = useModal();
  const copyLink = useCopyText();

  const shareLinks = React.useMemo(() =>
    constructShareLinks(window.location.origin, meetingId!),
    [meetingId]);

  const handleShareClick = React.useCallback((): void => {
    switch (authUserType) {
      case userRoles.STYLIST:
        openShareModal();
        break;
      case userRoles.CONSUMER:
        copyLink({ text: shareLinks?.[2]?.link });
        break;
      default:
        console.log("Unknown user type");
        break;
    }
  }, [authUserType, shareLinks, copyLink, openShareModal]);

  return (
    <React.Fragment>
      {view === "topbar" && (
        <ShareTopbarButton
          id="topbar-share-meeting-btn"
          tooltip={"GENERIC.BUTTONS.SHARE_CALL"}
          onClick={handleShareClick}
        />
      )}

      {view === "mobile" && (
        <ActionMenuButton
          id="mobile-share-meeting-btn"
          icon={<FaShareAlt size={20} className="text-white" />}
          disabled={dailyMeetingState !== "joined-meeting"}
          color="primary"
          tooltip={"GENERIC.BUTTONS.SHARE_CALL"}
          onClick={handleShareClick}
        />
      )}

      <ShareMeetingModal
        isShown={isShown}
        shareLinks={shareLinks}
        onClose={closeShareModal}
      />
    </React.Fragment>
  );
};

export default ShareMeetingContainer;
