import React from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MQTT_REFRESH_SHOWCASE } from '../interfaces/Mqtt';
import { useAppDispatch, useAppSelector } from '../store';
import { addShowcaseItem, clearShowcaseItems, getShowcaseItems, removeShowcaseItem } from '../store/features/showcase';
import useMqttMessage from './mqtt/useMessage';
import useError from './useError';

const useShowcaseActions = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const sendMqttMessage = useMqttMessage();
	const { handleError } = useError();
	const showcaseLoading = useAppSelector((state) => state.showcase.showcaseLoading);

	const retrieveShowcase = React.useCallback(() => {
		dispatch(getShowcaseItems()).unwrap()
			.catch((error) => {
				handleError(error);
			})
	}, [dispatch, handleError]);

	const addToShowcase = React.useCallback(({ baseSku, sku }: { baseSku: string, sku?: string }) => {
		dispatch(addShowcaseItem({ baseSku, sku })).unwrap()
			.then(() => {
				sendMqttMessage({ type: MQTT_REFRESH_SHOWCASE });
				toast.info(t("TOASTS.SHOWCASE.ADD.SUCCESS"));
			})
			.catch((error) => {
				toast.warning(t("TOASTS.SHOWCASE.ADD.ERROR"));
				handleError(error);
			});
	}, [dispatch, handleError, sendMqttMessage, t]);

	const removeFromShowcase = React.useCallback(({ productId }: { productId: string }) => {
		dispatch(removeShowcaseItem({ productId })).unwrap()
			.then(() => {
				sendMqttMessage({ type: MQTT_REFRESH_SHOWCASE });
			}).catch((error) => {
				handleError(error);
			});
	}, [dispatch, handleError, sendMqttMessage]);

	const clearShowcase = React.useCallback(() => {
		dispatch(clearShowcaseItems()).unwrap().then(() => {
			// setShowModal(false);
			sendMqttMessage({ type: MQTT_REFRESH_SHOWCASE });
		}).catch((error) => {
			handleError(error);
		});
	}, [dispatch, handleError, sendMqttMessage]);


	return { showcaseLoading, retrieveShowcase, addToShowcase, removeFromShowcase, clearShowcase }


}
export default useShowcaseActions;