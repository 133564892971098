import React from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";

interface ShareTopbarButtonTypes {
  id: string;
  tooltip: string;
  onClick: () => void;
}

const ShareTopbarButton: React.FC<ShareTopbarButtonTypes> = ({
  id,
  tooltip,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-inline-block">
      <button
        id={id}
        className="header-item border-0 text-primary bg-transparent"
        style={{ padding: "0.47rem 0.75rem" }}
        onClick={onClick}
      >
        <i className="bx bx-share-alt font-size-22" />
      </button>

      <UncontrolledTooltip fade={false} placement="bottom" target={id}>
        {t(tooltip)}
      </UncontrolledTooltip>
    </div>
  );
};

export default ShareTopbarButton;
