import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Label } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateMeeting } from '../../store/features/meeting';
import LoadingSpinner from '../LoadingSpinner';

const EndCallScreenStylist: React.FC = () => {
  const { endingNotes, meetingId } = useAppSelector((state) => state.meeting);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<'initial' | 'submitted'>('initial');
  const dispatch = useAppDispatch();

  const moveCaretToEnd = (e: any) => {
    let temp = e.target.value;
    e.target.value = "";
    e.target.value = temp;
  }

  const submitHandler = async (values: { endingNotes: string }): Promise<any> => {
    setLoading(true);
    await dispatch(updateMeeting({
      meetingId: meetingId!,
      payload: {
        endingNotes: values.endingNotes
      }
    })).unwrap()
      .then(() => {
        toast.info(t("TOASTS.MEETING.UPDATE.END_NOTES.SUCCESS"));
        setStep('submitted');
      })
      .catch(() => {
        toast.error(t("TOASTS.MEETING.UPDATE.END_NOTES.ERROR"))
      })
      .finally(() => setLoading(false));
  }

  return <React.Fragment>
    {step === 'initial' && <React.Fragment>
      <div className="text-center">
        <i className="rounded-circle bg-primary bg-soft p-3 my-3 bx bxs-shopping-bag-alt text-primary align-middle" style={{ fontSize: "3.5rem" }} />
        <p className="mt-3">
          {t("MODALS.END_CALL.SUBHEADER_STYLIST")}
        </p>
        <p>{t("GENERIC.LABELS.TAB_CLOSE_SAFE")}</p>
      </div>

      <Formik initialValues={{ endingNotes }} onSubmit={submitHandler}>
        <Form className="mt-5">
          <Label for="endingNotes">{t("MODALS.END_CALL.LABELS.MEETING_NOTES")}</Label>
          <Field
            onFocus={moveCaretToEnd}
            disabled={loading}
            id="ending-notes"
            maxLength="350"
            name="endingNotes"
            style={{ maxHeight: 300 }}
            component="textarea"
            placeholder={t("MODALS.END_CALL.INPUTS.ENDING_NOTES.PLACEHOLDER")}
            autoFocus
            className="form-control mb-4" />
          <div className="text-center">
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <LoadingSpinner isBtn className="me-3" />}
              {t("GENERIC.BUTTONS.SUBMIT")}
            </Button>
          </div>
        </Form>
      </Formik>
    </React.Fragment>}

    {step === 'submitted' && <React.Fragment>
      <div className="text-center">
        <i className='bx bx-check m-2 bg-success rounded-circle text-white align-middle' style={{ fontSize: '3.5rem' }} />
        <p className="mt-3">
          {t("MODALS.END_CALL.LABELS.NOTES_SAVED")}
        </p>
        <p>{t("GENERIC.LABELS.TAB_CLOSE_SAFE")}</p>
      </div>
    </React.Fragment>}

  </React.Fragment>
}
export default EndCallScreenStylist;