import axios from 'axios';
import { Pagination, CatalogSearchParams } from '../interfaces/Filters';
import Product from '../interfaces/Product';
import { constructCatalogQueryString, constructRequestHeaders, SERVER_URL } from "./helpers";

export default class ProductService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/products`;
  }

  async getProduct(productId: string): Promise<Product> {
    try {
      const response = await axios.get(`${this.baseUrl}/${productId}`, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async getProducts(filters: CatalogSearchParams): Promise<{ data: Product[], meta: { pagination: Pagination, platform: string, version: string } }> {
    try {
      const query = constructCatalogQueryString(filters);
      const response = await axios.get(`${this.baseUrl}${query}`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}
