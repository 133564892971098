import DailyIframe from "@daily-co/daily-js";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallState } from "../../interfaces/Call";
import { createAppAsyncThunk } from "../extras";
import { getUserDetails } from "./auth";
import { getMeeting } from "./meeting";
import { toggleCameraState, toggleMicrophoneState } from "./sharedActions";

export const joinCall = createAppAsyncThunk(
  "call/joinCall",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      const newDailyCallObject = DailyIframe?.createCallObject({
        url: state.call.url,
        token: state.call.token,
        userName: state.call.localParticipantName,
        startVideoOff: state.appInterface.isCamMuted,
        startAudioOff: state.appInterface.isMicMuted
      });

      // Promise, but never completes if you await
      newDailyCallObject.setUserData({ role: state.auth.userType });

      await newDailyCallObject.join();

      const deviceCount = await newDailyCallObject.enumerateDevices();

      return {
        callObject: newDailyCallObject,
        participants: newDailyCallObject.participants(),
        videoDevices: deviceCount?.devices.filter((device: MediaDeviceInfo) => device.kind === "videoinput").length
      };
    } catch (rejected: any) {
      return rejectWithValue(rejected)
    }
  }
);

// SALES-V2: Call in redux update
const initialState: CallState = {
  activeParticipantId: undefined,
  activeParticipant: undefined,
  participants: [],
  dailyMeetingState: "loading",
  dailyCallObject: null,
  localParticipantName: "Guest",
  availableVideoDevices: undefined,
  token: "",
  url: "",
  clickAllowTimeoutFired: false,
  camOrMicError: null,
  fatalError: null
}

export const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    leaveCall: (state) => {
      try {
        state.dailyCallObject?.leave();

        // TODO: REVISIT, REALY BAD
        if (state.dailyMeetingState === "error") state.dailyCallObject?.destroy().then(() => state.dailyCallObject === null);
        state.dailyMeetingState = "left-meeting";
      } catch (error) {
        state.dailyMeetingState = "error";
      }
    },
    ejectFromCall: (state, action: PayloadAction<string>) => {
      state.dailyCallObject?.updateParticipant(action.payload, {
        eject: true
      });
    },
    setMainParticipant: (state, action: PayloadAction<string>) => {
      state.activeParticipantId = action.payload;
      state.activeParticipant = state.participants.find((participant) => participant.user_id === action.payload);
    },
    participantsChange: (state) => {
      // TODO: maybe handle update/join/leave events differently
      if (state.dailyCallObject) {
        const participants = Object.values(state.dailyCallObject.participants());
        state.participants = participants;
        state.activeParticipant = participants.find((participant) => !participant.local);
      }
    },
    setFatalError: (state, action: PayloadAction<string>) => {
      state.fatalError = action.payload;
    },
    setHardwareError: (state, action: PayloadAction<string>) => {
      state.camOrMicError = action.payload;
    },
    setAllowTimeout: (state) => {
      state.clickAllowTimeoutFired = true;
    }
  },
  extraReducers: (builder) => {
    builder
      /* ---- EXTERNAL: Get User ----*/
      .addCase(getUserDetails.fulfilled, (state, action) => {
        let name: string[] = [];
        if (action.payload?.profile?.firstname) name.push(action.payload?.profile?.firstname);
        if (action.payload?.profile?.lastname) name.push(action.payload?.profile?.lastname);
        if (name.length !== 0) state.localParticipantName = [...name].join(" ");
      })

      /* ---- EXTERNAL: Get Meeting ----*/
      .addCase(getMeeting.fulfilled, (state, action) => {
        state.url = action.payload.callUrl.split("?")[0];
        state.token = action.payload.callUrl.split("?")[1].substring(2)
      })

      /* ---- Join call ---- */
      .addCase(joinCall.pending, (state) => {
        state.dailyMeetingState = "joining-meeting";
      })
      .addCase(joinCall.fulfilled, (state, action) => {
        state.dailyMeetingState = "joined-meeting";
        state.dailyCallObject = action.payload.callObject;
        const participants = Object.values(action.payload.callObject.participants());
        state.participants = participants;
        // Initialize the main participant as the first non-local participant
        state.activeParticipant = participants.find((participant) => !participant.local);
        state.availableVideoDevices = action.payload.videoDevices;
      })
      .addCase(joinCall.rejected, (state) => {
        state.dailyMeetingState = "error";
      })
      /* ---- SHARED: Toggle Microphone ---- */
      .addCase(toggleMicrophoneState, (state) => {
        state.dailyCallObject?.setLocalAudio(!state.dailyCallObject.localAudio());
      })
      /* ---- SHARED: Toggle Camera ---- */
      .addCase(toggleCameraState, (state) => {
        state.dailyCallObject?.setLocalVideo(!state.dailyCallObject.localVideo());
      })
    // TODO: log out /endcall shared actions
  }
});
export const { leaveCall, ejectFromCall, participantsChange, setHardwareError, setAllowTimeout, setFatalError, setMainParticipant } = callSlice.actions;
export default callSlice.reducer;