import React from 'react';
import FiltersLayout from '../../layouts/FiltersLayout';
import CustomBreadcrumb from '../Breadcrumb';
import CatalogBrowser from './browser';

const ProductCatalog: React.FC = () => {

  return (
    <FiltersLayout>

      <CustomBreadcrumb />

      <CatalogBrowser />

    </FiltersLayout>
  )
}
export default ProductCatalog;

