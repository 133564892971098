import axios from "axios";
import { EmailConfirmPayload, LoginGuestPayload, LoginPayload } from "../interfaces/Auth";
import { getStoreObj } from "../store";
import { constructRequestHeaders, SERVER_URL } from "./helpers";

export default class UserService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/`;
  }

  async loginUser(payload: LoginPayload): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}v1/auth/login`, payload, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async loginGuest(payload: LoginGuestPayload): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}v1/auth/login`, payload, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async getUserDetails(userId: string): Promise<any> {
    try {
      const response = await axios.get(`${this.baseUrl}v1/users/${userId}`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async confirmMail(payload: EmailConfirmPayload): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}v1/user/confirm/email`, payload);
      return await Promise.resolve(response);
    } catch (error: any) {
      return await Promise.reject(error);
    }
  }

  async passwordRecovery(email: Object): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}v1/auth/passwordRecovery`, email);
      return await Promise.resolve(response);
    } catch (error: any) {
      return await Promise.reject(error);
    }
  }

  async likeProduct(payload: { baseSku: string, sku?: string }): Promise<any> {
    try {
      const { userId, profileId } = getStoreObj().auth;
      if (userId && profileId) {
        const response = await axios.post(`${this.baseUrl}v1/users/${userId}/profiles/${profileId}/favorite`, payload, { headers: constructRequestHeaders({}) });
        return await Promise.resolve(response.data.data);
      }
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async unlikeProduct(productId: string): Promise<any> {
    try {
      const { userId, profileId } = getStoreObj().auth;
      if (userId && profileId) {
        const response = await axios.delete(`${this.baseUrl}v1/users/${userId}/profiles/${profileId}/favorites/${productId}`, { headers: constructRequestHeaders({}) })
        return await Promise.resolve(response.data.data);
      }
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}