import React from 'react'


const TableHeaders: React.FC = ({ children }) => {
  return (
    <thead>
      <tr>
        {children}
      </tr>
    </thead>
  )
}
export default TableHeaders;