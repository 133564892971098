import React from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { userRoles } from '../constants/users';
import useError from '../hooks/useError';
import { UserType } from '../interfaces/Auth';
import { useAppDispatch, useAppSelector } from '../store';
import { getUserDetails, initAuth } from '../store/features/auth';
import { resetState } from '../store/features/sharedActions';

interface AuthRouteProps {
  allowedRoles: UserType[];
  allowedToken?: boolean;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ allowedRoles, allowedToken }) => {
  const { search } = useLocation();
  const { isLoggedIn, userType, userInitialized, userId } = useAppSelector((state) => state.auth);
  const { meetingId } = useParams();
  const dispatch = useAppDispatch();
  const { handleError } = useError();
  const accessToken = React.useMemo(() => { return new URLSearchParams(search).get('accessToken') }, [search]);
  const redirectToShowRoom = React.useMemo(() => { return new URLSearchParams(search).get('showroom') }, [search]);

  React.useEffect(() => {
    if (!userInitialized && userId) {
      dispatch(getUserDetails({ userId: userId })).unwrap()
        .catch((error) => {
          handleError(error);
        })
    }
  }, [userId, userInitialized, dispatch, handleError]);

  if (isLoggedIn && !userInitialized) return null;

  // case consumer with access token
  if (meetingId && allowedToken && accessToken && allowedRoles.includes(userRoles.CONSUMER) && !isLoggedIn) {
    dispatch(resetState());
    dispatch(initAuth({ userType: userRoles.CONSUMER, accessToken }));
    return <Outlet />;
  }

  // case stylist with access token
  if (meetingId && allowedToken && accessToken && allowedRoles.includes(userRoles.STYLIST) && !isLoggedIn) {
    dispatch(resetState());
    dispatch(initAuth({ userType: userRoles.STYLIST, accessToken }));
    return <Outlet />;
  }

  if (allowedRoles.includes(userType) && isLoggedIn && userInitialized) {
    return <Outlet />;
  }

  if (!allowedRoles.includes(userType) && isLoggedIn && userInitialized) {
    return <Navigate to={`/${userType}/${meetingId}${redirectToShowRoom === 'true' ? '?showroom=true' : ''}`} />;
  }

  return <Navigate to={`/login?meetingId=${meetingId}${redirectToShowRoom === 'true' ? '&showroom=true' : ''}`} replace />
}
export default AuthRoute;