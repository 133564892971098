import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userRoles } from "../../constants/users";
import { ActiveWindow, AppInterfaceState, PresentWindowCatalog, PresentWindowDetails, SidebarTabOptions } from "../../interfaces/AppInterface";
import { deleteLook, getLook } from "./looks";
import { endCallState, joinCallState, resetState, toggleCameraState, toggleMicrophoneState } from "./sharedActions";
import { clearShowcaseItems, getShowcaseItems } from "./showcase";


// SALES-V2: Call in redux update
const initialState: AppInterfaceState = {
  dragProductEnabled: false,
  activeSidebarTab: 'cart',
  activeWindow: 'video',
  lastActiveWindow: 'video',
  paymentModalToggled: false,
  endCallConfirmationModalToggled: false,
  paymentStatusModalToggled: false,
  showcaseModalToggled: false,
  meetingNotesModalToggled: false,
  sidebarCollapsed: false,
  catalogFiltersCollapsed: false,
  isLocalMode: false,
  isMicMuted: false,
  isCamMuted: false,
  isChatVisible: false,
  activePresentWindow: undefined,
  activePresentLook: '',
  chatHasNotifications: false,
  chatNotificationCounter: 0,
  showcaseNotificationCounter: 0,
  catalogRightsToggled: false
}

export const appInterfaceSlice = createSlice({
  name: 'appInterface',
  initialState,
  reducers: {
    setDragProductEnabled: (state, action: PayloadAction<boolean>) => {
      state.dragProductEnabled = action.payload;
    },
    setActiveSidebarTab: (state, action: PayloadAction<SidebarTabOptions>) => {
      state.activeSidebarTab = action.payload;
      // NOTE: for tablet/Desktop only
      if (action.payload === 'showcase') {
        state.showcaseNotificationCounter = 0;
      }
    },
    setActiveWindow: (state, action: PayloadAction<ActiveWindow>) => {
      state.lastActiveWindow = action.payload === state.activeWindow ? state.lastActiveWindow : state.activeWindow
      state.activeWindow = action.payload;
      // NOTE: for mobile only
      if (action.payload === 'showcase') {
        state.showcaseNotificationCounter = 0;
      }
    },
    setPreviousWindow: (state) => {
      state.activeWindow = 'video'; // TODO: got to update it in the future
    },
    setEndCallModal: (state, action: PayloadAction<boolean>) => {
      state.endCallConfirmationModalToggled = action.payload;
    },
    setPaymentStatusModal: (state, action: PayloadAction<boolean>) => {
      state.paymentStatusModalToggled = action.payload;
    },
    setSidebarCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    setCatalogFiltersCollapsed: (state) => {
      state.catalogFiltersCollapsed = !state.catalogFiltersCollapsed;
    },
    setPaymentModalActive: (state) => {
      state.paymentModalToggled = true;
    },
    togglePaymentModal: (state) => {
      state.paymentModalToggled = !state.paymentModalToggled;
    },
    toggleMeetingNotesModal: (state) => {
      state.meetingNotesModalToggled = !state.meetingNotesModalToggled;
    },
    toggleLocalMode: (state) => {
      state.isLocalMode = !state.isLocalMode;
    },
    toggleChatDisplay: (state) => {
      state.isChatVisible = !state.isChatVisible
      state.chatHasNotifications = false;
      state.chatNotificationCounter = 0;
    },
    toggleChatHighlight: (state, action: PayloadAction<boolean>) => {
      state.chatHasNotifications = state.isChatVisible ? false : action.payload;
      if (action.payload) {
        state.chatNotificationCounter += 1;
      }
    },
    setActivePresentWindow: (state, action: PayloadAction<PresentWindowCatalog | PresentWindowDetails | undefined>) => {
      state.activePresentWindow = action.payload;
    },
    setActivePresentLook: (state, action: PayloadAction<string>) => {
      state.activePresentLook = action.payload;
    },
    toggleShowcaseClearModal: (state) => {
      state.showcaseModalToggled = !state.showcaseModalToggled
    },
    toggleCatalogRights: (state, action: PayloadAction<boolean>) => {
      state.catalogRightsToggled = action.payload;
      if (!action.payload) {
        state.activeWindow = 'video';
      }
    }
  },
  extraReducers: (builder) => {
    builder
      /* ---- EXTERNAL: Clear showcase ---- */
      .addCase(clearShowcaseItems.fulfilled, (state) => {
        state.showcaseModalToggled = false;
      })

      /* ---- EXTERNAL: Delete Outfit ---- */
      .addCase(deleteLook.fulfilled, (state, action) => {
        if (state.activePresentLook.split('_')[1] === action.meta.arg) {
          state.activePresentLook = '';
        }
      })

      /* ---- EXTERNAL: Get Outfit ---- */
      .addCase(getLook.rejected, (state, action: PayloadAction<any, string, { arg: { lookId: string } }, never>) => {
        if (action.payload?.userType === userRoles.CONSUMER) {
          state.activeSidebarTab = 'cart';
        }
        if (action.meta.arg.lookId === state.activePresentLook.split('_')[1]) {
          state.activePresentLook = '';
        }
      })

      /* ---- EXTERNAL: Get showcase items ---- */
      .addCase(getShowcaseItems.fulfilled, (state, action) => {
        if (state.activeSidebarTab !== 'showcase' && state.activeWindow !== 'showcase') {
          if (action.payload.notificationCounter > 0) {
            state.showcaseNotificationCounter = action.payload.notificationCounter;
          }
        }
      })
      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.activeSidebarTab = initialState.activeSidebarTab;
        state.activeWindow = initialState.activeWindow;
        state.lastActiveWindow = initialState.lastActiveWindow;
        state.paymentModalToggled = initialState.paymentModalToggled;
        state.endCallConfirmationModalToggled = initialState.endCallConfirmationModalToggled;
        state.sidebarCollapsed = initialState.sidebarCollapsed;
        state.catalogFiltersCollapsed = initialState.catalogFiltersCollapsed;
        state.isLocalMode = initialState.isLocalMode;
        state.isMicMuted = initialState.isMicMuted;
        state.isCamMuted = initialState.isCamMuted;
        state.isChatVisible = initialState.isChatVisible;
        state.chatHasNotifications = initialState.chatHasNotifications;
        state.chatNotificationCounter = initialState.chatNotificationCounter
        state.activePresentWindow = initialState.activePresentWindow
        state.activePresentLook = initialState.activePresentLook;
        state.catalogRightsToggled = initialState.catalogRightsToggled;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.activeSidebarTab = initialState.activeSidebarTab;
        state.activeWindow = initialState.activeWindow;
        state.lastActiveWindow = initialState.lastActiveWindow;
        state.paymentModalToggled = initialState.paymentModalToggled;
        state.endCallConfirmationModalToggled = initialState.endCallConfirmationModalToggled;
        state.sidebarCollapsed = initialState.sidebarCollapsed;
        state.catalogFiltersCollapsed = initialState.catalogFiltersCollapsed;
        state.isLocalMode = initialState.isLocalMode;
        state.isMicMuted = initialState.isMicMuted;
        state.isCamMuted = initialState.isCamMuted;
        state.isChatVisible = initialState.isChatVisible;
        state.chatHasNotifications = initialState.chatHasNotifications;
        state.chatNotificationCounter = initialState.chatNotificationCounter
        state.activePresentWindow = initialState.activePresentWindow
        state.activePresentLook = initialState.activePresentLook;
        state.catalogRightsToggled = initialState.catalogRightsToggled;
      })

      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        state.activeSidebarTab = initialState.activeSidebarTab;
        // state.activeWindow = initialState.activeWindow;
        state.lastActiveWindow = initialState.lastActiveWindow;
        state.paymentModalToggled = initialState.paymentModalToggled;
        state.endCallConfirmationModalToggled = initialState.endCallConfirmationModalToggled;
        state.sidebarCollapsed = initialState.sidebarCollapsed;
        state.catalogFiltersCollapsed = initialState.catalogFiltersCollapsed;
        state.isLocalMode = initialState.isLocalMode;
        // state.isMicMuted = initialState.isMicMuted;
        // state.isCamMuted = initialState.isCamMuted;
        state.isChatVisible = initialState.isChatVisible;
        state.chatHasNotifications = initialState.chatHasNotifications;
        state.chatNotificationCounter = initialState.chatNotificationCounter
        state.activePresentWindow = initialState.activePresentWindow
        state.activePresentLook = initialState.activePresentLook;
        state.catalogRightsToggled = initialState.catalogRightsToggled;
      })
      /* ---- SHARED: Toggle Microphone ---- */
      .addCase(toggleMicrophoneState, (state) => {
        state.isMicMuted = !state.isMicMuted
      })
      /* ---- SHARED: Toggle Camera ---- */
      .addCase(toggleCameraState, (state) => {
        state.isCamMuted = !state.isCamMuted;
      })
  }
});
export const { setDragProductEnabled, toggleCatalogRights, togglePaymentModal, setActivePresentLook, toggleMeetingNotesModal, setPaymentStatusModal, setActivePresentWindow, setEndCallModal, toggleChatDisplay, toggleChatHighlight, setActiveSidebarTab, setPaymentModalActive, setCatalogFiltersCollapsed, setSidebarCollapsed, setActiveWindow, setPreviousWindow, toggleLocalMode, toggleShowcaseClearModal } = appInterfaceSlice.actions;
export default appInterfaceSlice.reducer;
