import classnames from 'classnames';
import React from 'react';

interface PropTypes {
  children?: React.ReactNode;
  className?: string;
}
const CategoryList: React.FC<PropTypes> = ({ children, className }) => {
  return (
    <ul className={classnames('list-unstyled', className)}>
      {children}
    </ul>
  )
}
export default CategoryList;