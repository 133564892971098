import classnames from "classnames";
import classNames from "classnames";
import { Field, FieldHookConfig, isFunction, useField } from "formik";
import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from "react-i18next";
import { FormGroup, InputGroup, InputGroupText, Label } from "reactstrap";

interface FormikFormGroupProps {
  label: string,
  hasError?: any,
  children: React.ReactNode
  side?: 'left' | 'right'
}

// interface InputProps {
//   label: string,
//   placeholder: string,
//   className?: string,
//   children?: React.ReactNode
//   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
// }
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const FormikFormGroup: React.FC<FormikFormGroupProps> = ({ label = "", hasError, side = 'right', children }) => {
  const { t } = useTranslation();

  return (
    <FormGroup className="formik-form-group">
      {side === 'left' && children}
      {label && <Label className={classnames({ "label-error": hasError })}>
        {t(label)}
      </Label>}
      {side === 'right' && children}
    </FormGroup>
  );
};

const RadioInput: React.FC<InputProps & FieldHookConfig<any> & { side?: 'left' | 'right' }> = ({ label, placeholder, className, side, onChange, ...rest }) => {

  const [field, meta] = useField<any>(rest);
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value)
  }

  return (
    <FormikFormGroup label={label} hasError={meta.touched && meta.error} side={side}>
      <Field
        className={classnames({ error: meta.touched && meta.error }, className)}
        value={field.value}
        placeholder={placeholder}
        type="radio"
        onChange={handleChange}
        {...rest}
      />
    </FormikFormGroup>
  )
}

const TextInput: React.FC<InputProps & FieldHookConfig<string>> = ({ label, placeholder, className, onChange, ...rest }) => {
  const [field, meta] = useField<any>(rest);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label} hasError={meta.touched && meta.error}>
      <Field
        className={classnames({ error: meta.touched && meta.error }, className)}
        value={field.value}
        placeholder={placeholder}
        type="text"
        onChange={handleChange}
        {...rest}
      />
      {meta.touched && meta.error && (
        <div className="text-danger my-1">{t(meta.error)}</div>
      )}
    </FormikFormGroup>
  );
};

const PasswordInput: React.FC<InputProps & FieldHookConfig<string>> = ({ label, placeholder, onChange, ...rest }) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [field, meta] = useField(rest);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label} hasError={meta.touched && meta.error}>
      <InputGroup>
        <Field
          className={`${meta.touched && meta.error ? "error" : ""}`}
          value={field.value}
          type={passwordVisible ? "text" : "password"}
          placeholder={placeholder}
          onChange={handleChange}
          {...rest}
        />

        <InputGroupText>
          <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => setPasswordVisible(!passwordVisible)}>
            <i className={classNames('mdi', { 'mdi-eye-outline': passwordVisible, 'mdi-eye-off-outline': !passwordVisible })} />
          </span>
        </InputGroupText>

        {meta.touched && meta.error && (
          <div className="text-danger my-1">{t(meta.error)}</div>
        )}
      </InputGroup>
    </FormikFormGroup>
  );
};

const FormikInputGroup: React.FC<InputProps & FieldHookConfig<string>> = ({ children, label, placeholder, className, onChange, ...rest }) => {
  const [field, meta] = useField<string>(rest);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label} hasError={meta.touched && meta.error}>
      <InputGroup>
        <Field
          className={classnames({ error: meta.touched && meta.error }, className)}
          value={field.value}
          placeholder={placeholder}
          onChange={handleChange}
          {...rest}
        />
        {children}
      </InputGroup>
      {meta.touched && meta.error && (
        <div className="text-danger my-1">{t(meta.error)}</div>
      )}

    </FormikFormGroup>
  );
};

export { TextInput, PasswordInput, FormikInputGroup, RadioInput };