import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { initialCatalogFilter, initialCatalogFilterPrice } from "../../constants/catalog";
import { CatalogSearchParams, FilterState } from "../../interfaces/Filters";
import { createAppAsyncThunk } from "../extras";
import { getCatalog } from "./catalog";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const getFilters = createAppAsyncThunk(
  "filters/getFilters",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.CategoryService.retrieveCategories();
      return {
        categories: response,
        priceRange: initialCatalogFilterPrice
      };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: FilterState = {
  filtersLoading: false,
  categories: [], // [2] should be default
  initializedFilters: false,
  searchParams: initialCatalogFilter
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Retrieve Filters ---- */
      .addCase(getFilters.pending, (state) => {
        state.filtersLoading = true;
      })
      .addCase(getFilters.fulfilled, (state, action: PayloadAction<any>) => {
        state.categories = action.payload.categories;
        state.filtersLoading = false;
        state.searchParams.price = action.payload.priceRange;
        state.initializedFilters = true;
      })
      .addCase(getFilters.rejected, (state) => {
        state.filtersLoading = false;
        state.initializedFilters = true;
      })

      /* ---- EXTERNAL: Get Catalog ---- */
      // : PayloadAction<undefined, string, { arg: { filters: CatalogSearchParams, reset: boolean } }>
      .addCase(getCatalog.pending, (state, action: PayloadAction<undefined, string, { arg: { filters?: Partial<CatalogSearchParams> } }>) => {
        state.searchParams = { ...state.searchParams, ...action.meta.arg.filters };
      })

      /* ---- End call ---- */
      .addCase(endCallState, (state) => {
        state.filtersLoading = initialState.filtersLoading;
        // state.categories = initialState.categories;
        state.filtersLoading = initialState.filtersLoading;
        state.initializedFilters = initialState.initializedFilters
      })

      /* ---- Join call ---- */
      .addCase(joinCallState, (state) => {
        state.filtersLoading = initialState.filtersLoading;
        state.categories = initialState.categories;
        state.initializedFilters = initialState.initializedFilters
        state.searchParams = initialState.searchParams;
      })

      /* ---- Log out ---- */
      .addCase(resetState, (state) => {
        state.filtersLoading = initialState.filtersLoading;
        state.categories = initialState.categories;
        state.initializedFilters = initialState.initializedFilters
        state.searchParams = initialState.searchParams;
      })
  }
})
export default filterSlice.reducer;