import React from 'react';
import { Col, Row } from 'reactstrap';
import { constructProductImages } from '../../helpers/product';
import Product, { ConfigurableProductOption, VariantProduct } from '../../interfaces/Product';
import ColorSelect from '../Input/ColorSelect';
import DropdownSelect from '../Input/DropdownSelect';

// TODO: improvement point, go through all attribute options,
// ignoring disabled ones and auto-select if there is a single non-disabled attribute options

const constructProductVariants = (product: Product) =>
  Array.isArray(product.variants)
    ? product.variants.map((pv) => ({
      attributes: pv.attributes.reduce((acc: Record<string, string>, curValue) => {
        if (curValue?.code) acc[curValue.code] = curValue.uid;
        return acc;
      }, {}),
      product: pv.product,
    })) : [];

const isOptionDisabled = (
  productVariants: any,
  attributeCode: string,
  uid: string,
  selectedValue: any,
): boolean =>
  !productVariants.some(
    (pv: any) =>
      pv.attributes[attributeCode] === uid &&
      Object.entries(selectedValue).every(([key, value]) =>
        key !== attributeCode && value ? pv.attributes[key] === value : true
      )
  );

const constructConfigurableOptions = (
  product: Product,
  productVariants: any,
  selectedValue: any
) =>
  Array.isArray(product.configurable_options)
    ? product.configurable_options.reduce(
      (acc: any, curAttr: ConfigurableProductOption) => {
        const options = curAttr.values.map((v) => ({
          label: v.label,
          value: v.uid,
          hex: v.swatch_data?.value,
          disabled: isOptionDisabled(
            productVariants,
            curAttr.attribute_code,
            v.uid,
            selectedValue
          ),
        }));

        acc.push({
          attribute_code: curAttr.attribute_code,
          label: curAttr.label,
          uid: curAttr.uid,
          options,
          isColorSelection: curAttr.values.some(
            (attrVal) => attrVal.swatch_data?.__typename === "ColorSwatchData"
          ),
        });

        return acc;
      },
      []
    )
    : [];

const getInitialValue = (product: Product) => Array.isArray(product.configurable_options)
  ? product.configurable_options.reduce(
    (acc: Record<string, string>, cur: ConfigurableProductOption) => {
      acc[cur.attribute_code] = cur.values.length === 1 ? cur.values[0].uid : ""
      return acc;
    }, {})
  : {};

interface PropTypes {
  product: Product;
  onProductSelect: ({ name, description, sku, price, images, selected }: VariantProduct) => void;
}

const ProductAttributes: React.FC<PropTypes> = ({ product, onProductSelect }) => {
  const [selectedValue, setSelectedValue] = React.useState<Record<string, string>>(getInitialValue(product));
  // const [init, setInit] = React.useState<boolean>(false);

  const productVariants = React.useMemo(() => constructProductVariants(product), [product]);

  const configurableOptions = React.useMemo<{
    attribute_code: string;
    isColorSelection: boolean;
    label: string;
    options: {
      disabled: boolean;
      hex: string | undefined;
      label: string;
      value: string;
    }[];
    uid: string;
  }[]>(() => constructConfigurableOptions(product, productVariants, selectedValue),
    [product, selectedValue, productVariants]
  );

  const handleSelect = React.useCallback((overrideAttr: Record<string, string>) => {
    const newValue = { ...selectedValue, ...overrideAttr };
    setSelectedValue(newValue);

    const entryFound = productVariants.find((pv) =>
      Object.entries(newValue).every(([key, value]) => value && pv.attributes[key] === value)
    );

    if (entryFound)
      onProductSelect({
        name: entryFound.product.name,
        description: "",
        sku: entryFound.product.sku,
        price: {
          regular: entryFound.product.price_range.maximum_price.regular_price.value,
          final: entryFound.product.price_range.maximum_price.final_price.value,
        },
        images: constructProductImages(entryFound.product),
        selected: true,
      });
  }, [selectedValue, productVariants, onProductSelect]);

  React.useEffect(() => {
    // if (!init) {
    handleSelect(selectedValue);
    //   setInit(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      {configurableOptions.map((co: any) => (
        <Col md={6} xs={6} key={co.uid}>
          <div className="px-0 mb-1">
            {co.isColorSelection ?
              <ColorSelect
                label={co.label}
                options={co.options}
                onSelect={(value) => handleSelect({ [co.attribute_code]: value })}
              />
              :
              <DropdownSelect
                inputLabel={co.label}
                value={selectedValue[co.attribute_code]}
                options={co.options}
                onSelect={(value) => handleSelect({ [co.attribute_code]: value })}
              />}
          </div>
        </Col>
      ))}
    </Row>
  )
}
export default ProductAttributes;
