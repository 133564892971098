import axios from "axios";
import { constructRequestHeaders, SERVER_URL } from "./helpers";

export class TenancySrevice {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/`;
  }

  async getConfig() {
    try {
      const response = await axios.get(`${this.baseUrl}v1/config`, { headers: constructRequestHeaders({}) });
      return await Promise.resolve(response.data.data)
    } catch (error) {
      return await Promise.reject(error)
    }
  }
}