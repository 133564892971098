import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Look } from '../../interfaces/Look';

interface UpdateOutfitModalProps {
  isShown: boolean;
  looks: Look[]
  id: string,
  name: string;
  description?: string;
  toggleModal: () => void;
  confirm: (name: string, description?: string) => void;
}

const UpdateOutfitModal: React.FC<UpdateOutfitModalProps> = ({ isShown, id, name, description, looks, toggleModal, confirm }) => {
  const [lookName, setName] = React.useState<string | undefined>(name);
  const [lookDescription, setDescription] = React.useState<string | undefined>(description);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (isShown) {
      setName(name);
      setDescription(description);
    }
  }, [isShown, name, description]);

  const invalidNameError = React.useMemo<string | null>(() => {
    if (!lookName) return "MODALS.UPDATE_OUTFIT.INPUTS.NAME.EMPTY";

    const newLookName = lookName.toLowerCase().replace(/\s+/g, "");
    const matchIndex = looks.findIndex(
      (l: Look) =>
        l.title.toLowerCase().replace(/\s+/g, "") === newLookName &&
        l._id !== id
    );

    return matchIndex > -1
      ? "MODALS.UPDATE_OUTFIT.INPUTS.NAME.DUPLICATE"
      : null;
  }, [id, lookName, looks]);

  return (
    <Modal isOpen={isShown} toggle={toggleModal} size="md">
      <ModalHeader>
        {t("MODALS.UPDATE_OUTFIT.HEADER")}
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <p className="font-size-14 mb-0">{t("GENERIC.LABELS.NAME")}</p>
          <Input onChange={(e) => setName(e.target.value)} defaultValue={name} placeholder={t("MODALS.UPDATE_OUTFIT.INPUTS.NAME.PLACEHOLDER")} />
          {isShown && invalidNameError && (
            <div className="text-danger my-1">{t(invalidNameError)}</div>
          )}
        </div>
        <div>
          <p className="font-size-14 mb-0">{t("GENERIC.LABELS.DESCRIPTION")}</p>
          <Input type="textarea" onChange={(e) => setDescription(e.target.value)} defaultValue={description} placeholder={t("MODALS.UPDATE_OUTFIT.INPUTS.DESCRIPTION.PLACEHOLDER")} />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={toggleModal}>
          {t("GENERIC.BUTTONS.CANCEL")}
        </Button>
        <Button color="success" disabled={invalidNameError !== null} onClick={() => confirm(lookName!, lookDescription)}>
          {t("GENERIC.BUTTONS.SAVE")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default UpdateOutfitModal;