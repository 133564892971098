import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { magentoProductTypes } from '../../constants/product';
import { UserType } from '../../interfaces/Auth';
import Product, { ProductMedia, VariantProduct } from '../../interfaces/Product';
import Gallery from '../Gallery';
import ProductActions from './ProductActions';
import ProductAttributes from './ProductAttributes';
import ProductDescription from './ProductDescription';
import ProductPrice from './ProductPrice';

interface PropTypes {
  product: Product;
  userType: UserType;
  hasSelection: boolean;
  sku: string;
  price: {
    regular: number;
    final: number;
  };
  images: ProductMedia[];
  isMobile: boolean;
  hasValidConfiguratorConfig: boolean;
  onChange: ({ name, description, sku, price, images, selected }: VariantProduct) => void;
}

const ProductDetails: React.FC<PropTypes> = ({ hasValidConfiguratorConfig, product, userType, price, images, sku, isMobile, hasSelection, onChange }) => {

  return isMobile ? <CardBody className="pt-0">
    <Row>
      <Col className="text-center col-12">
        <Gallery images={images} />
      </Col>
      <Col className="col-12">
        <ProductActions hasValidConfiguratorConfig={hasValidConfiguratorConfig} userType={userType} product={product} activeSku={sku} hasSelection={hasSelection} />
        <ProductDescription description={product.description.html} />
        {product.__typename === magentoProductTypes.CONFIGURABLE && <ProductAttributes product={product} onProductSelect={onChange} />}
      </Col>
    </Row>
  </CardBody>
    :
    <Row className="mx-0">
      <Col className="text-center" md={6}>
        <Gallery hasValidConfiguratorConfig={hasValidConfiguratorConfig} product={product} activeSku={sku} images={images} hasSelection={hasSelection} />
        <ProductActions hasValidConfiguratorConfig={hasValidConfiguratorConfig} userType={userType} product={product} activeSku={sku} hasSelection={hasSelection} />
      </Col>
      <Col md={6}>
        <h4 className="mb-2">{product.name}</h4>
        <ProductPrice className="font-size-16" price={price} type={product.__typename} hasSelection={hasSelection} />
        <ProductDescription description={product.description.html} />
        {product.__typename === magentoProductTypes.CONFIGURABLE && <ProductAttributes product={product} onProductSelect={onChange} />}
      </Col>
    </Row>
}
export default ProductDetails;