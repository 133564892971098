import React from 'react';
import { DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Language } from '../../interfaces/Language';


const LanguageItem: React.FC<{ language: Language }> = ({ language }) => {
    const { i18n } = useTranslation();
    return (
        <DropdownItem defaultValue={`${language.code}`} onClick={() => { i18n.changeLanguage(language.code); localStorage.setItem('I18N_LANGUAGE', language.code) }}
            className={`notify-item ${i18n.language === language.code ? "active" : "none"}`}>
            <img src={`${language.flag}`}
                alt={`${language.label}`}
                className="me-1"
                height="12" />
            <span className="align-middle">
                {language.native}
            </span>
        </DropdownItem>
    )
}

export default LanguageItem;
