import React from "react";

const useModal = (initialValue = false): [boolean, () => void, () => void] => {
  const [open, setOpen] = React.useState<boolean>(initialValue);

  const openHandler = React.useCallback((): void => {
    setOpen(true);
  }, []);

  const closeHandler = React.useCallback((): void => {
    setOpen(false);
  }, []);

  return [open, openHandler, closeHandler];
};

export default useModal;
