import classNames from "classnames";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useScreen } from "../providers/ScreenSize";

const MainLayout: React.FC = () => {
  const { isMobile } = useScreen();

  return (
    <div id="layout-wrapper">
      {!isMobile && <Navbar />}

      <div className="main-content">
        <div className={classNames('page-content', { 'p-0 m-0': isMobile })}>
          <Outlet />
        </div>
      </div>

      {!isMobile && <Footer />}
    </div>
  );
};
export default MainLayout;