import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

interface PropTypes {
  isMobile?: boolean;
}

const WaitingForParticipantsScreen: React.FC<PropTypes> = ({ isMobile }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('d-flex flex-column justify-content-center screen-waiting h-100', { 'rounded': !isMobile })}>
      <Card className="p-3 mx-auto text-center">
        <h3>{t("PAGES.MEETING.WAITING.HEADER")}</h3>
        <p className="text-muted mb-0">{t("PAGES.MEETING.WAITING.SUBHEADER")}</p>
      </Card>
    </div>
  )
}
export default WaitingForParticipantsScreen;