import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import LoadingSpinner from "../LoadingSpinner";

interface PropTypes {
  className?: string;
  cancelText?: string;
  submitText?: string;
  submitBtnClass?: string;
  submitBtnType?: "submit" | "button" | "reset";
  submitting?: boolean;
  onCancel: (e: any) => void;
  onSubmit: (e: any) => void;
}

const FormActions: React.FC<PropTypes> = ({
  className,
  cancelText = "GENERIC.BUTTONS.CANCEL",
  submitText = "GENERIC.BUTTONS.SAVE",
  submitBtnClass = "btn-success",
  submitBtnType = "submit",
  submitting = false,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Row className={classnames("g-0 ms-n2", className)}>
      <Col className="col-12 col-sm-auto ps-2 order-2 order-sm-1">
        <button
          className="btn btn-light w-100 px-4"
          type="button"
          disabled={submitting}
          onClick={onCancel}
        >
          {t(cancelText)}
        </button>
      </Col>

      <Col className="col-12 col-sm-auto ps-2 order-1 order-sm-2 mb-3 mb-sm-0">
        <button
          className={classnames("btn save-event w-100 px-4", submitBtnClass)}
          type={submitBtnType}
          disabled={submitting}
          onClick={(e) => onSubmit(e)}
        >
          {submitting && <LoadingSpinner isBtn />}
          {t(submitText)}
        </button>
      </Col>
    </Row>
  );
};

export default FormActions;
