import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAppSelector } from '../../store';
import useGuestLogin from './useGuestLogin';

const GuestEntryPage: React.FC = () => {
  const { isLoggedIn, pathIndex } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const loading = useGuestLogin();

  if (isLoggedIn) return <Navigate to={pathIndex} replace />;

  return (
    <Container className="py-3">
      <Row className="justify-content-center">
        <Col xs="12" md="4">
          <Card className="text-center mt-5">
            <CardBody className="px-0">
              {loading && <React.Fragment>
                <CardTitle className="mb-5 border-bottom border-primary pb-2">
                  {t("PAGES.GUEST_ENTRY.LABELS.LOGGING_IN")}
                </CardTitle>
                <LoadingSpinner size="lg" className="text-primary" />
              </React.Fragment>
              }

              {!loading && <React.Fragment>
                <CardTitle className="mb-5 border-bottom border-danger pb-2">
                  {t("GENERIC.LABELS.SOMETHING_WENT_WRONG")}
                </CardTitle>
                <p> {t("PAGES.GUEST_ENTRY.LABELS.INVALID_CREDENTIALS")}</p>
                <p> {t("PAGES.GUEST_ENTRY.LABELS.CONTACT_SUPPORT")}</p>
              </React.Fragment>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default GuestEntryPage;