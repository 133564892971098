import classnames from 'classnames';
import React from 'react';
import { SupportedSocialMedia } from '../../interfaces/Meeting';

interface PropTypes {
  className?: string;
  socials: Record<SupportedSocialMedia, string>;
}

const Socials: React.FC<PropTypes> = ({ className, socials }) => {
  return (
    <div className={classnames('d-flex justify-content-center w-75 mx-auto', className)}>
      {Object.entries(socials).map(([key, value]: any, index) => (
        <a target="_blank" rel="noopener noreferrer" href={value} className="text-primary px-2" key={`${index}-${key}`}>
          <i className={`bx ${key === 'website' ? 'bx-globe' : 'bxl-' + key} font-size-18`} />
        </a>
      ))}
    </div>
  )
}
export default Socials;