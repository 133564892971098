import { DailyEventObjectAppMessage } from '@daily-co/daily-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleChatDisplay } from '../../store/features/interface';
import './Chat.scss';
import ChatMessage from './ChatMessage';

interface ChatProps {
  onClickDisplay: boolean
  notification: () => void
}

// SALES-V2: Call in redux update
const Chat: React.FC<ChatProps> = ({ onClickDisplay, notification }) => {
  const { dailyCallObject, localParticipantName } = useAppSelector((state) => state.call);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [chatHistory, setChatHistory] = React.useState<{ sender: string, message: string }[]>([]);
  const messageList = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChange = (event: any): void => {
    setInputValue(event.target.value);
  };

  const scrollToEndOfMessageList = (): void => {
    if (messageList.current) {
      messageList.current.scrollTop = messageList.current.scrollHeight;
    }
  }

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    dailyCallObject!.sendAppMessage({ message: inputValue }, '*');
    const name: string = dailyCallObject!.participants().local.user_name
      ? dailyCallObject!.participants().local.user_name
      : 'Guest';
    setChatHistory([
      ...chatHistory,
      {
        sender: name,
        message: inputValue
      },
    ]);
    setInputValue('');
    inputRef.current?.focus();
  }

  const handleCloseChat = (): void => {
    dispatch(toggleChatDisplay())
  }

  /* Update chat state based on a message received to all participants.*/
  React.useEffect(() => {
    if (!dailyCallObject) return;

    const handleAppMessage = (event?: DailyEventObjectAppMessage): void => {
      if (event) {
        const participants = dailyCallObject.participants();
        const name = participants[event.fromId].user_name
          ? participants[event.fromId].user_name
          : 'Guest';
        setChatHistory([
          ...chatHistory,
          {
            sender: name,
            message: event.data.message,
          },
        ]);
        notification();
      }
    }
    scrollToEndOfMessageList();
    dailyCallObject.on('app-message', handleAppMessage);

    return function cleanup() {
      dailyCallObject.off('app-message', handleAppMessage);
    };
  }, [dailyCallObject, chatHistory, notification]);

  React.useEffect(() => {
    scrollToEndOfMessageList();
  }, [onClickDisplay, notification])

  return (
    <React.Fragment>
      {onClickDisplay && (
        <div className="chat shadow-lg">
          <div className="py-2 px-3 border-bottom">
            <Row>
              <Col xs={9} md={4} className="my-auto">
                <h5 className="font-size-15 mb-0">{t("GENERIC.LABELS.CHAT")}</h5>
              </Col>
              <Col xs={3} md={8} className="text-end">
                <Button id="chat-inner-close-btn" color="danger" className="rounded-circle p-1" onClick={handleCloseChat}>
                  <i className='bx bx-x font-size-20 align-middle' />
                </Button>
              </Col>
            </Row>
          </div>

          <div className="w-100 user-chat">
            <div className="chat-conversation p-3" ref={messageList}>
              <ul className="list-unstyled mb-0">
                {chatHistory.map((entry, index: number) => (
                  <ChatMessage entry={entry} isLocal={entry.sender === localParticipantName} key={`entry-${index}`} />
                ))}
              </ul>
            </div>
          </div>
          <Row className="p-3 border-top mx-0">
            <form onSubmit={handleSubmit} className="px-0">
              <Row>
                <Col >
                  <Input
                    id="chatInput"
                    className="chat-input text-muted"
                    type="text"
                    autoFocus
                    innerRef={inputRef}
                    placeholder={t("GENERIC.INPUTS.MESSAGE.PLACEHOLDER")}
                    style={{ fontSize: '1rem' }}
                    value={inputValue}
                    onChange={handleChange}
                  />
                </Col>
                <Col className="col-auto my-auto">
                  <Button id="chat-inner-send-btn" color="primary" type="submit" disabled={inputValue === ''} className="rounded-circle align-center waves-effect waves-light p-2">
                    <i className='bx bx-right-arrow-alt align-middle' style={{ fontSize: '1.5rem' }} />
                  </Button>
                </Col>
              </Row>
            </form>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
}
export default Chat;