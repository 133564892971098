import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { TenancyState } from "../../interfaces/Tenancy";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const retrieveTenancyConfig = createAsyncThunk(
  "tenancy/retrieveConfig",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.TenancyService.getConfig();
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

const initialState: TenancyState = {
  logo: undefined,
  banner: undefined,
  title: undefined,
  retrieved: false
}

export const TenancySlice = createSlice({
  name: "tenancy",
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    /* ---- Get Tenancy Config ---- */
    .addCase(retrieveTenancyConfig.pending, (state, action) => { })
    .addCase(retrieveTenancyConfig.fulfilled, (state, action) => {
      state.logo = action.payload?.logo?.sizes?.original?.url || '';
      state.banner = action.payload?.banner?.sizes?.original?.url || '';
      state.title = action.payload.title;
      state.retrieved = true;
    })
    .addCase(retrieveTenancyConfig.rejected, (state, action) => { })

    /* ---- Join call ---- */
    .addCase(joinCallState, (state) => { })

    .addCase(endCallState, (state) => { })

    /* ---- SHARED: Log out ---- */
    .addCase(resetState, (state) => {
      state.logo = initialState.logo;
      state.banner = initialState.banner;
      state.title = initialState.title;
      state.retrieved = initialState.retrieved;
    })
})
export default TenancySlice.reducer