import React, { LegacyRef } from 'react';

interface PropTypes {
    videoTrack: MediaStreamTrack | null | undefined;
    videoEl: LegacyRef<HTMLVideoElement> | undefined;
}

const Video: React.FC<PropTypes> = ({ videoTrack, videoEl }) => {
    return (
        <React.Fragment>
            {videoTrack && <video autoPlay muted playsInline ref={videoEl} className="h-100 w-100" />}
        </React.Fragment>
    )
}

export default Video;
