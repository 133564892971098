import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'reactstrap';
import useCatalog from '../../hooks/useCatalog';
import { useAppSelector } from '../../store';

interface PropTypes {
  disabled?: boolean
}

// TODO: investigate and merge NavSearch component
const CatalogSearch: React.FC<PropTypes> = ({ disabled }) => {
  const { searchParams } = useAppSelector((state) => state.filters);
  const [value, setValue] = React.useState<string>("");
  const { retrieveCatalog } = useCatalog();
  const { t } = useTranslation();

  const handleSubmit = React.useCallback((e: any) => {
    e.preventDefault();
    retrieveCatalog({ searchTerm: value });
  }, [value, retrieveCatalog]);

  React.useEffect(() => {
    setValue(searchParams.searchTerm || "");
  }, [searchParams.searchTerm]);

  return (
    <Form
      className="d-flex align-items-center w-100 justify-content-between"
      onSubmit={handleSubmit}
    >
      <div className="w-100 px-4">
        <div className="input-group">
          <span
            className="input-group-text border bg-white border-end-0 pe-1"
            style={{ borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <i className="bx bx-search-alt" />
          </span>
          <Input
            value={value}
            className="form-control border border-start-0 border-end-0 pe-0"
            type="text"
            placeholder={t("GENERIC.INPUTS.SEARCH.PLACEHOLDER") + "..."}
            disabled={disabled}
            onChange={(e) => { setValue(e.target.value); }}
          />

          {value.length > 0 && <Button
            outline
            disabled={value.length === 0}
            className="input-group-text border border-start-0 px-3 py-0"
            onClick={() => setValue("")}
          >
            <i className="bx bx-x font-size-20 align-middle" />
          </Button>}

          <Button
            color="primary"
            className="input-group-text border-0 px-2 py-0"
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: '50%', borderBottomRightRadius: '50%' }}
            type="submit"
          >
            <i className="bx bx-caret-right font-size-20 align-middle" />
          </Button>
        </div>
      </div>
    </Form>
  )
}
export default CatalogSearch;