import { useMqttState } from "mqtt-react-hooks";
import React from "react";
import { MqttAction } from "../../interfaces/Mqtt";
import { useAppSelector } from "../../store";

const useMqttMessage = () => {
  const { auth: { userType }, meeting: { meetingId } } = useAppSelector((state) => state);
  const { client } = useMqttState();

  return React.useCallback(({ type, payload }: MqttAction) => {
    if (client?.connected) {
      const message = { type, payload, originator: userType };
      client?.publish(`eligo/meeting/${meetingId}`, JSON.stringify(message));
    }
  }, [userType, meetingId, client]);
}
export default useMqttMessage;