import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap'

interface EndCallConfirmationModalProps {
  toggleModal: () => void;
  isShown: boolean;
  confirm: () => void
}

const EndCallConfirmationModal: React.FC<EndCallConfirmationModalProps> = ({ toggleModal, isShown, confirm }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isShown} size="md" centered={true} toggle={() => toggleModal()}>
      <ModalHeader className="border-0" toggle={toggleModal}>
        {t("MODALS.END_CALL.HEADER")}
      </ModalHeader>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={toggleModal} >
          {t("GENERIC.BUTTONS.CANCEL")}
        </Button>
        <Button color="danger" onClick={confirm}>
          {t("GENERIC.BUTTONS.END_CALL")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default EndCallConfirmationModal;