import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { formatPrice } from '../../helpers/product';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActiveWindow, setPreviousWindow } from '../../store/features/interface';

const MobileCartButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { returnToMeeting } = useAppNavigation();
  const { t } = useTranslation();
  const { cartProducts, cartTotal } = useAppSelector((state) => state.cart);
  const activeWindow = useAppSelector((state) => state.appInterface.activeWindow);

  const handleToggleCart = (): void => {
    if (activeWindow === 'cart') {
      dispatch(setPreviousWindow());
    } else {
      dispatch(setActiveWindow('cart'));
    }
    returnToMeeting();
  };

  const totalItems = React.useMemo(() => {
    return cartProducts.reduce((arr, { qty }) => arr + qty, 0)
  }, [cartProducts]);

  return (
    <Button className="px-0 outline-0 shadow-none bg-transparent border-0 text-start" onClick={handleToggleCart}>
      <span className="text-black">{t("GENERIC.LABELS.CART")}</span>
      <span className="text-muted position-relative mx-2">
        <i className='bx bxs-shopping-bag-alt align-middle font-size-16' />
        {cartProducts.length > 0 &&
          <span className="position-absolute top-100 start-100 translate-middle badge rounded-circle bg-primary">
            {totalItems}
            <span className="visually-hidden">{t("GENERIC.LABELS.ITEMS_IN_CART")}</span>
          </span>}
      </span>
      <span className="text-muted">
        {formatPrice(cartTotal)}
      </span>
    </Button>
  )
}
export default MobileCartButton;