import { useCallback } from "react";
import { useAppDispatch } from "../store";
import useError from "./useError";

const useFetch = (action: any, refetchAction: any) => {
  const dispatch = useAppDispatch();
  const { handleError } = useError();

  // NOTE: loading -> true display loader, on finally if loading true -> false
  return useCallback(async ({ actionProps, refetchProps, onSuccess, onError }) => {
    try {
      await action(...Object.values(actionProps));
      await dispatch(refetchAction(refetchProps)).unwrap();
      if (onSuccess) onSuccess();
    } catch (error: any) {
      handleError(error);
      if (onError) onError();
    }
  },
    [action, refetchAction, dispatch, handleError]
  );
};
export default useFetch;