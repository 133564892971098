import React from 'react';
import { Col, Row } from 'reactstrap';
import UserActionsMenu from '../components/ActionMenu/UserActionsMenu';
import ParticipantGrid from '../components/Call/ParticipantGrid';
import Logo from '../components/Logo';
import MobileCartButton from '../components/Mobile/MobileCartButton';
import MobileShowcaseButton from '../components/Mobile/MobileShowcaseButton';
import EarlyJoinScreen from '../components/Screen/EarlyJoinScreen';
import EndCallScreen from '../components/Screen/EndCallScreen';
import LoadingScreen from '../components/Screen/LoadingScreen';
import SessionExpiredScreen from '../components/Screen/SessionExpired';
import StatusIndicators from '../components/StatusIndicators';
import { userRoles } from '../constants/users';
import useWindowResize from '../hooks/useWindowResize';
import { useAppSelector } from '../store';

const MobileLayout: React.FC = ({ children }) => {
  const dailyCallState = useAppSelector((state) => state.call.dailyMeetingState);
  const startDate = useAppSelector((state) => state.meeting.startDate);
  const isShowroom = useAppSelector((state) => state.auth.isShowroom);
  const userType = useAppSelector((state) => state.auth.userType);
  const { width } = useWindowResize();

  return (
    <React.Fragment>
      {dailyCallState !== 'joined-meeting' ? (
        <Row className="h-100">
          <Col className="p-0">
            {(dailyCallState === 'loading' || dailyCallState === 'joining-meeting') && <LoadingScreen isMobile />}
            {dailyCallState === 'left-meeting' && <EndCallScreen isMobile />}
            {dailyCallState === 'error' &&
              <React.Fragment>
                {startDate && Date.parse(startDate) > Date.now() ? <EarlyJoinScreen isMobile /> : <SessionExpiredScreen isMobile />}
              </React.Fragment>
            }
          </Col>
        </Row>
      ) : (
        <React.Fragment>
          <Row className="joined-mobile-screen ">
            <Col className="p-0 h-100 position-relative">
              {children}
            </Col>
          </Row>
          <Row className="joined-mobile-bottom border-top border-2 border-secondary">
            <Col xs={12} className="d-flex flex-column align-items-start p-2">
              <ParticipantGrid />
              {width > 320 && <Logo className="my-2 mx-auto" />}
              {userType === userRoles.CONSUMER && <div className="d-flex justify-content-around w-100">
                <MobileCartButton />
                <MobileShowcaseButton />
              </div>}
              <div className="mt-auto w-100 d-flex">
                <UserActionsMenu />
              </div>
            </Col>
          </Row>
          {!isShowroom && <StatusIndicators />}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default MobileLayout;