import React from 'react'
import { useTranslation } from 'react-i18next';

const OutfitsTab: React.FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {t("GENERIC.LABELS.OUTFITS")}
    </React.Fragment>
  )
}
export default OutfitsTab;