import classnames from 'classnames';
import React from 'react';
import { defaultUserAvatar } from '../../assets';
import SkeletonImage from '../SkeletonImage';

interface PropTypes {
  className?: string;
  fullName: string;
  initials: string;
  url?: string;
}

const Avatar: React.FC<PropTypes> = ({ className, url, fullName, initials }) => {
  return (
    <div className={classnames('avatar-md profile-user-wid mx-auto', className)}>
      {url ?
        <SkeletonImage
          src={url}
          alt={fullName}
          className={classnames("avatar-md border border-3 border-light rounded-circle bg-light",)}
          defaultSrc={defaultUserAvatar}
        />
        :
        <div className="avatar-md rounded-circle bg-primary d-flex border border-3 border-light">
          <h1 className="text-white m-auto text-uppercase">{initials}</h1>
        </div>}
    </div>
  )
}
export default Avatar;