import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { UserType } from '../../interfaces/Auth';
import MqttConnectionErrorModal from '../Modals/MqttConnectionErrorModal';

interface PropTypes {
  userType: UserType;
}

const MqttErrorIndicator: React.FC<PropTypes> = ({ userType }) => {
  const [modalShown, setModalShown] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const toggleModal = () => {
    setModalShown(!modalShown)
  }

  return (
    <React.Fragment>
      <Button id="mqtt-error-indicator" color="warning" className="p-1 rounded-circle shadow-none" onClick={toggleModal}>
        <i className='bx bx-error fs-3' />
        <UncontrolledTooltip fade={false} placement="top" target="mqtt-error-indicator">
          {t("INDICATORS.MQTT.WARNING")}
        </UncontrolledTooltip>
      </Button>
      <MqttConnectionErrorModal isShown={modalShown} userType={userType} toggleModal={toggleModal} />
    </React.Fragment>
  )
}
export default MqttErrorIndicator;