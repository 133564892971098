import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const SuccessPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6} lg={5} xl={5} className="text-center">
          <Card className="border border-2 border-success">
            <CardBody className='p-2'>
              <div className="avatar-md mx-auto border border-success border-radius rounded-circle">
                <div className="avatar-title rounded-circle bg-light">
                  <i className='bx bx-check display-1 text-success align-middle' />
                </div>
              </div>
              <div className="p-2 mt-4">
                <h4 className="text-success">
                  {t("PAGES.PAYMENT_SUCCESS.HEADER")}
                </h4>
                <p className="text-muted">
                  {t("PAGES.PAYMENT_SUCCESS.SUBHEADER")}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default SuccessPage;