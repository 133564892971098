import classNames from 'classnames';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Filters from '../components/Filters';
import MobileFilters from '../components/Mobile/MobileFilters';
import CatalogSearch from '../components/productCatalog/CatalogSearch';
import FabButton from '../components/Button/FloatingActionButton';
import useScreenSize from '../hooks/useScreenSize';
import useScrollPosition from '../hooks/useScrollPosition';
import { useAppDispatch, useAppSelector } from '../store';
import { setCatalogFiltersCollapsed } from '../store/features/interface';
import { userRoles } from '../constants/users';


interface PropTypes {
  children?: React.ReactNode;
}

const FiltersLayout: React.FC<PropTypes> = ({ children }) => {
  const userType = useAppSelector((state) => state.auth.userType);
  const { catalogFiltersCollapsed } = useAppSelector((state) => state.appInterface);
  const catalogRightsToggled = useAppSelector((state) => state.appInterface.catalogRightsToggled)
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const { onScroll, goingUp, isAtTop } = useScrollPosition();

  const displayFilters: boolean = React.useMemo(() => {
    return (userType === userRoles.STYLIST || (userType === userRoles.CONSUMER && catalogRightsToggled)) && !isMobile
  }, [userType, catalogRightsToggled, isMobile]);

  const toggleCollapsibleFilters = React.useCallback(() => dispatch(setCatalogFiltersCollapsed()), [dispatch]);

  const enableFilters = React.useMemo(() => {
    return isMobile && userType === userRoles.CONSUMER && catalogRightsToggled;
  }, [catalogRightsToggled, userType, isMobile]);

  return (
    <Row className="h-100 w-100 m-auto">

      {displayFilters && <React.Fragment>
        <Button id="toggle-filters-btn" className="position-absolute top-50 start-0 collapse-button ms-n4 outline-none bg-white my-auto shadow rounded-none" onClick={toggleCollapsibleFilters}>
          <i className={classNames('font-size-18 text-primary fa', { 'fa-angle-right': catalogFiltersCollapsed, 'fa-angle-left': !catalogFiltersCollapsed })} />
          <UncontrolledTooltip fade={false} placement="right" target="toggle-filters-btn">
            {catalogFiltersCollapsed ? t("GENERIC.BUTTONS.FILTERS_SHOW") : t("GENERIC.BUTTONS.FILTERS_HIDE")}
          </UncontrolledTooltip>
        </Button>

        <Col md={3} className={classNames('h-100 ps-0', { 'd-none': catalogFiltersCollapsed, 'd-block': !catalogFiltersCollapsed })} >
          <Filters />
        </Col>
      </React.Fragment>}
      {isMobile && catalogRightsToggled && <MobileFilters isOpen={showFilters} toggle={() => setShowFilters(!showFilters)} />}


      {goingUp && !isAtTop && enableFilters && <div className="d-flex justify-content-between position-fixed py-2 start-0 bg-white" style={{ zIndex: 500 }}>
        {/* <Button color="primary" className="p-2 m-1" onClick={() => setShowFilters(!showFilters)}>
          <i className="bx bx-menu font-size-20 align-middle" />
        </Button> */}
        <CatalogSearch />
      </div>}
      <Col
        onScroll={onScroll}
        id="scroll-parent"
        md={catalogFiltersCollapsed || (userType === userRoles.CONSUMER && !catalogRightsToggled) ? 12 : 9}
        className="overflow-auto h-100 bg-white py-2 rounded position-relative"
      >
        {enableFilters && <div className="d-flex justify-content-between">
          {/* <Button color="primary" className="p-2 m-1" onClick={() => setShowFilters(!showFilters)}>
            <i className="bx bx-menu font-size-20 align-middle" />
          </Button> */}
          <CatalogSearch />
        </div>}

        {children}

        {isMobile && catalogRightsToggled && <FabButton
          id={'filter-fab-button'}
          onClick={() => setShowFilters(!showFilters)}
          className="bottom-0 start-100"
        >
          {isAtTop && t("GENERIC.LABELS.FILTERS")}
          <i className="bx bx-filter font-size-20 align-middle" />
        </FabButton>}
      </Col>

    </Row>
  )
}
export default FiltersLayout;