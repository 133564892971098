import classnames from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

interface PropTypes {
	isMobile?: boolean;
}

const SessionExpiredScreen: React.FC<PropTypes> = ({ isMobile }) => {
	return (
		<Row className={'mt-5 h-100'}>
			<Col lg={4} md={6} xs={12} className="text-center mx-auto">
				<Card className={classnames("px-1 py-3 text-center", { 'mt-3 mx-2': isMobile, 'mx-auto': !isMobile })}>
					<CardHeader tag="h3" className="text-primary bg-white text-center">{t("PAGES.MEETING.SESSION_EXPIRED.HEADER")}</CardHeader>
					<CardBody>
						<p className="text-muted mb-0">{t("PAGES.MEETING.SESSION_EXPIRED.SUBHEADER")}</p>
					</CardBody>
				</Card>
				<h5 className="text-muted my-3 text-primary text-center mb-auto">{t("GENERIC.LABELS.CHOICE_THANKS")}</h5>
			</Col>
		</Row>
	)
}
export default SessionExpiredScreen;