import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateMeeting } from '../../store/features/meeting';
import LoadingSpinner from '../LoadingSpinner';

interface PropTypes {
  toggleModal: () => void;
  isOpen: boolean;
}

const MeetingNotesUpdateModal: React.FC<PropTypes> = ({ isOpen, toggleModal }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { notes, meetingId } = useAppSelector((state) => state.meeting);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const moveCaretToEnd = (e: any) => {
    let temp = e.target.value;
    e.target.value = "";
    e.target.value = temp;
  }

  const submitHandler = async (values: { notes: string }): Promise<any> => {
    setLoading(true);
    await dispatch(updateMeeting({
      meetingId: meetingId!,
      payload: {
        notes: values.notes
      }
    })).unwrap()
      .then(() => {
        toast.info(t("TOASTS.MEETING.UPDATE.END_NOTES.SUCCESS"));
        setLoading(false);
        toggleModal();
      })
      .catch(() => {
        toast.error(t("TOASTS.MEETING.UPDATE.END_NOTES.ERROR"));
        setLoading(false);
      })
  }

  return (
    <Modal isOpen={isOpen} centered={true} toggle={toggleModal} size="md" autoFocus={false}>
      <ModalHeader className="border-0" toggle={toggleModal}>
        {t("MODALS.MEETING_NOTES.HEADER")}
      </ModalHeader>
      <ModalBody className="pb-0">
        <Formik initialValues={{ notes: notes! }} onSubmit={submitHandler}>
          <Form>
            <Label for="notes">{t("MODALS.MEETING_NOTES.INPUTS.NOTES.LABEL")}</Label>
            <Field
              onFocus={moveCaretToEnd}
              disabled={loading}
              id="meeting-notes"
              maxLength="350"
              name="notes"
              style={{ minHeight: 150, maxHeight: 300 }}
              component="textarea"
              placeholder={t("MODALS.MEETING_NOTES.INPUTS.NOTES.PLACEHOLDER")}
              autoFocus
              className="form-control mb-1" />
            <div className="modal-footer border-0">
              <Button color="light" onClick={toggleModal}>{t("GENERIC.BUTTONS.CANCEL")}</Button>
              <Button color="success" type="submit" disabled={loading}>
                {loading && <LoadingSpinner isBtn className="me-3" />}
                {t("GENERIC.BUTTONS.SAVE")}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  )
}
export default MeetingNotesUpdateModal;