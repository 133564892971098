import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface RemoveConfirmModalProps {
  isShown: boolean;
  name?: string;
  toggleModal: () => void;
  confirm: () => void;
}

const RemoveConfirmationModal: React.FC<RemoveConfirmModalProps> = ({ isShown, name, toggleModal, confirm, }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isShown} size="md" toggle={toggleModal}>
      <ModalHeader>
        {t("MODALS.REMOVE_ITEM.HEADER")}
      </ModalHeader>
      <ModalBody>
        <p className="font-size-14 mb-0">
          {t("MODALS.REMOVE_ITEM.SUBHEADER")}{" "}<span className="text-primary">{name}</span> ?
        </p>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={toggleModal} >
          {t("GENERIC.BUTTONS.CLOSE")}
        </Button>
        <Button color="danger" onClick={confirm}>
          {t("GENERIC.BUTTONS.DELETE")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default RemoveConfirmationModal;