import React from "react";
import { Button, Input } from "reactstrap";
import useCopyText from "../../hooks/useCopyText";

interface ShareInputTypes {
  text: string;
}

const ShareInput: React.FC<ShareInputTypes> = ({ text }) => {
  const copyLink = useCopyText();
  return (
    <div className="input-group bg-light rounded my-3">
      <Input
        disabled
        className="form-control bg-transparent overflow-x-scroll text-start text-nowrap border-0 hide-scrollbar"
        value={text}
      />
      <Button
        color="primary"
        type="button"
        onClick={() => copyLink({ text })}
      >
        <i className="bx bxs-copy" />
      </Button>
    </div>
  );
};

export default ShareInput;
