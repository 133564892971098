import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { initialPagination } from "../../constants/pagination";
import { CatalogState } from "../../interfaces/Catalog";
import { CatalogSearchParams } from "../../interfaces/Filters";
import { createAppAsyncThunk } from "../extras";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const getCatalog = createAppAsyncThunk(
  "catalog/getCatalog",
  async ({ filters, retainItems }: { filters: CatalogSearchParams, retainItems?: boolean }, { rejectWithValue }) => {
    try {
      const response = await api.ProductService.getProducts(
        filters
      );
      return { products: response.data, pagination: response.meta.pagination };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
);

const initialState: CatalogState = {
  products: [],
  initializedCatalogs: false,
  catalogLoading: false,
  error: undefined,
  pagination: initialPagination
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Retrieve Catalog Products ---- */
      .addCase(getCatalog.pending, (state, action: PayloadAction<undefined, string, { arg: { filters: CatalogSearchParams, retainItems?: boolean } }>) => {
        state.catalogLoading = true;
        if (!action.meta.arg.retainItems) {
          state.products = [];
        };
      })
      .addCase(getCatalog.fulfilled, (state, action: PayloadAction<any>) => {
        state.initializedCatalogs = true;
        if (action.payload) state.products = [...state.products, ...action.payload.products];
        state.catalogLoading = false;
        state.error = initialState.error;
        state.pagination = action.payload.pagination;

      })
      .addCase(getCatalog.rejected, (state, action) => {
        state.catalogLoading = false;
        state.error = action.payload;
      })

      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        state.products = initialState.products;
        state.initializedCatalogs = initialState.initializedCatalogs;
        state.catalogLoading = initialState.catalogLoading;
      })

      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.products = initialState.products;
        state.initializedCatalogs = initialState.initializedCatalogs;
        state.catalogLoading = initialState.catalogLoading;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.products = initialState.products;
        state.initializedCatalogs = initialState.initializedCatalogs;
        state.catalogLoading = initialState.catalogLoading;
      })
  }
});
export default catalogSlice.reducer;