import { CartDiscount } from "../interfaces/Cart";


export const cartDiscountInitialValues: CartDiscount = Object.freeze({
  type: 'fixed',
  value: 0
});

export const cartDiscountOptions = Object.freeze({
  FIXED: 'fixed',
  PERCENTAGE: 'percentage'
});