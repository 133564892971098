import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { magentoProductTypes } from '../../constants/product';
import { userRoles } from '../../constants/users';
import { escapeCharacter } from '../../helpers/utils';
import useCartActions from '../../hooks/cart/useCartActions';
import useMqttMessage from '../../hooks/mqtt/useMessage';
import useFavorite from '../../hooks/useFavorite';
import useShowcaseActions from '../../hooks/useShowcaseActions';
import { UserType } from '../../interfaces/Auth';
import { MQTT_ADD_CART, MQTT_CONFIGURATOR_TOGGLE } from '../../interfaces/Mqtt';
import Product from '../../interfaces/Product';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleConfigurator } from '../../store/features/details';
import FavouriteButton from '../Button/Favourite';
import useLookProductActions from '../ProductComposer/useLookProductActions';
import Typography from '../Typography';

interface PropTypes {
  product: Product;
  userType: UserType;
  activeSku: string;
  hasSelection: boolean;
  hasValidConfiguratorConfig: boolean,
}

const ProductActions: React.FC<PropTypes> = ({ userType, product, hasSelection, activeSku, hasValidConfiguratorConfig }) => {
  const { addToCart, cartActionsDisabled } = useCartActions();
  const { t } = useTranslation();
  const { handleAddLookProduct } = useLookProductActions();
  const { addToShowcase } = useShowcaseActions();
  const sendMqttMessage = useMqttMessage();
  const showcaseLoading = useAppSelector((state) => state.showcase.showcaseLoading);
  const { selectedLookId, lookView, looksLoading } = useAppSelector((state) => state.looks);
  const hasCatalogRights = useAppSelector((state) => state.appInterface.catalogRightsToggled);
  // const { configuratorActivePanel, configuratorStep, configuratorVariants } = useAppSelector((state) => state.details);
  const addToOutfitEnabled = lookView === "LOOK_DETAILS" && !!selectedLookId;
  const { isFavorite, toggleFavorite, likedProductsLoading } = useFavorite();
  const dispatch = useAppDispatch();

  const isInFavorites = React.useMemo(() => isFavorite(product.sku), [product, isFavorite])

  const handleAddToCart = (): void => {
    if (userType === userRoles.STYLIST) {
      if (product.__typename === magentoProductTypes.CONFIGURABLE) {
        const matchedProduct = product?.variants?.find((variant) => variant.product.sku === activeSku);
        if (matchedProduct) {
          addToCart({ baseSku: product.sku, sku: matchedProduct.product.sku });
        }
      } else {
        addToCart({ baseSku: product.sku });
      }
    } else if (userType === userRoles.CONSUMER) {
      if (product.__typename === magentoProductTypes.CONFIGURABLE) {
        const matchedProduct = product?.variants?.find((variant) => variant.product.sku === activeSku);
        if (matchedProduct) {
          sendMqttMessage({ type: MQTT_ADD_CART, payload: { baseSku: product.sku, sku: matchedProduct.product.sku } });
        }
      } else {
        sendMqttMessage({ type: MQTT_ADD_CART, payload: { baseSku: product.sku } });
      }
    }
  }

  const handleToggleConfigurator = React.useCallback(() => {
    dispatch(toggleConfigurator(true));
    sendMqttMessage({
      type: MQTT_CONFIGURATOR_TOGGLE,
      payload: {
        sku: product.sku,
        status: true,
        // step: configuratorStep,
        // panel: configuratorActivePanel,
        // variants: configuratorVariants
      }
    });
  }, [dispatch, sendMqttMessage, product.sku]);

  const handleAddToShowcase = React.useCallback((): void => {
    // if (product.__typename === magentoProductTypes.CONFIGURABLE) {
    const matchedProduct = product.variants?.find((variant) => variant.product.sku === activeSku);
    addToShowcase({
      baseSku: product.sku,
      sku: matchedProduct?.product.sku
    });
  }, [addToShowcase, activeSku, product]);

  const handleAddToOutfit = (): void => {
    if (addToOutfitEnabled) {
      if (product.__typename === magentoProductTypes.SIMPLE || product.__typename === magentoProductTypes.VIRTUAL) {
        handleAddLookProduct(product);
        toast.info(t("TOASTS.COMPOSER.ADDED"));
      } else {
        const matchedProduct = product.variants?.find((variant) => variant.product.sku === activeSku);
        if (matchedProduct) {
          handleAddLookProduct(product, matchedProduct.product.sku);
          toast.info(t("TOASTS.COMPOSER.ADDED"));
        };
      };
    };
  };

  const handleToggleFavorite = React.useCallback(() => {
    toggleFavorite(product.sku);
  }, [product.sku, toggleFavorite]);

  return (
    <React.Fragment>
      <Row className="justify-content-center row-cols-2">
        {/* TODO: translation  and convert to Toast*/}
        {!!product.el_configurable && !hasValidConfiguratorConfig && <Typography Variant="h6" text="invalid-config-a" className="mb-0 text-danger" />}
        <Col className="px-1 mt-2" xxl={10} md={9} xs={9}>
          {!product?.el_configurable && <Button
            className="px-0 py-2 w-100"
            type="button"
            color="primary"
            onClick={handleAddToCart}
            disabled={!hasSelection || cartActionsDisabled}
          >
            <i className='bx bxs-cart-alt me-1 align-middle' />
            <span>{t("GENERIC.BUTTONS.ADD_CART")}</span>
          </Button>}

          {!!product.el_configurable && (userType === userRoles.STYLIST || hasCatalogRights) && <Button
            className="px-0 py-2 w-100"
            type="button"
            color="primary"
            disabled={cartActionsDisabled || !hasValidConfiguratorConfig}
            onClick={handleToggleConfigurator} >
            <i className='bx bx-customize me-1 align-middle' />
            <span>{t("GENERIC.BUTTONS.CONFIGURE")}</span>
          </Button>}
        </Col>
        <Col className="px-1 mt-2" xxl={2} md={3} xs={2}>
          <FavouriteButton
            id={`details-favorite-btn-${escapeCharacter(product.uid, "=")}`}
            isFavorite={isInFavorites}
            onClick={handleToggleFavorite}
            disabled={likedProductsLoading}
            className="p-2 w-100"
            tooltip={isInFavorites ? t("GENERIC.BUTTONS.REMOVE_FROM_FAVORITES") : t("GENERIC.BUTTONS.ADD_TO_FAVORITES")}
          />
        </Col>
      </Row>
      {userType === userRoles.STYLIST && <Row className="justify-content-center row-cols-1 row-cols-sm-2">
        <Col xxl={6} md={12} className="px-1 mt-1">
          <Button
            className="px-0 py-2 w-100"
            type="button"
            disabled={!hasSelection || showcaseLoading}
            outline
            color="primary"
            onClick={handleAddToShowcase}
          >
            <i className='bx bxs-slideshow me-1 align-middle' />
            <span>{t("GENERIC.BUTTONS.ADD_SHOWCASE")}</span>
          </Button>
        </Col>
        <Col xxl={6} md={12} className="px-1 mt-1">
          <Button
            className="px-0 py-2 w-100"
            type="button"
            disabled={!addToOutfitEnabled || !hasSelection || looksLoading}
            outline
            color="primary"
            onClick={handleAddToOutfit}
          >
            <i className='bx bxs-t-shirt me-1 align-middle' />
            <span>{t("GENERIC.BUTTONS.ADD_COMPOSER")}</span>
          </Button>
        </Col>
      </Row>}
    </React.Fragment>
  )
}
export default ProductActions;