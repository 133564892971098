import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { logoLight } from '../../assets';
import { applicationCopyright } from '../../common/appSettings';
import EligoCard from '../../components/Cards/Eligo';
import { useAppSelector } from '../../store';
import LoginForm from './LoginForm';

const LoginPage: React.FC = () => {
  const { isLoggedIn, pathIndex } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  if (isLoggedIn) return <Navigate to={pathIndex} replace />;

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>

          <EligoCard title={t("PAGES.LOGIN.HEADER")} description={t("PAGES.LOGIN.SUBHEADER")} image={logoLight}>
            <LoginForm />
            <div className="mt-4 text-center">
              <Link to="/passwordreset" className="text-muted">
                <i className="mdi mdi-lock me-1" />{" "}
                {t("GENERIC.LABELS.FORGOT_PASSWORD")}
              </Link>
            </div>
          </EligoCard>

          <div className="mt-5 text-center text-muted font-size-14">
            {applicationCopyright}
          </div>

        </Col>
      </Row>
    </Container>
  );
};
export default LoginPage;
