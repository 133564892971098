import React from 'react';
import { useTranslation } from 'react-i18next';
import useAppNavigation from '../../hooks/useAppNavigation';
import useShowcaseActions from '../../hooks/useShowcaseActions';
import { UserType } from '../../interfaces/Auth';
import { ShowcaseProduct } from '../../interfaces/Product';
import TableEmpty from '../Table/TableEmpty';
import ShowcaseTableItem from './ShowcaseTableItem';

interface PropTypes {
  products: ShowcaseProduct[];
  userType: UserType;
}

const ShowcaseTableContent: React.FC<PropTypes> = ({ products, userType }) => {
  const { visitProductDetails } = useAppNavigation();
  const { t } = useTranslation();
  const { removeFromShowcase } = useShowcaseActions();

  const handleRemoveItem = React.useCallback((productId: string) => {
    removeFromShowcase({ productId });
  }, [removeFromShowcase]);

  return (
    <tbody>
      {products &&
        <React.Fragment>
          {products.map((product, index: number) => (
            <ShowcaseTableItem product={product} key={`${product._id}_${index}`} onRemove={handleRemoveItem} visitDetails={visitProductDetails} index={index} userType={userType} />
          ))}
        </React.Fragment>}
      {products.length === 0 && <TableEmpty colSpan={3}>
        {t("GENERIC.LABELS.SHOWCASE_EMPTY")}
      </TableEmpty>}
    </tbody>
  )
}
export default ShowcaseTableContent;