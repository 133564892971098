import React from "react";
import { Nav, TabContent, TabPane } from "reactstrap";
import { SidebarTabOptions } from "../../interfaces/AppInterface";
import { useAppDispatch, useAppSelector } from "../../store";
import { setActiveSidebarTab } from "../../store/features/interface";
import CartTab from "./CartTab";
import OutfitsTab from "./Outfits";
import ShowcaseTab from "./ShowcaseTab";
import Cart from "./Cart";
import "./index.scss";
import Looks from "./Looks";
import Showcase from "./Showcase";
import TabHeader from "./TabHeader";
import DraggableDropArea from "../Draggable/DropArea";
import useProductDraggable from "../../hooks/product/useDraggable";
import { userRoles } from "../../constants/users";

const Sidebar: React.FC = () => {
  const activeSidebarTab = useAppSelector((state) => state.appInterface.activeSidebarTab);
  const userType = useAppSelector((state) => state.auth.userType);
  const { selectedLook, lookDetailsView } = useAppSelector((state) => state.looks);
  const dragProductEnabled = useAppSelector((state) => state.appInterface.dragProductEnabled);
  const dispatch = useAppDispatch();
  const { handleDropAction } = useProductDraggable();

  const handleToggle = (tabId: SidebarTabOptions): void => {
    dispatch(setActiveSidebarTab(tabId));
  }

  return (
    <React.Fragment>
      <Nav tabs className="border-0">
        <TabHeader toggle={() => handleToggle('cart')} active={'cart' === activeSidebarTab}>
          <CartTab />
        </TabHeader>

        <TabHeader disabled={userType === userRoles.CONSUMER && !selectedLook} toggle={() => handleToggle('outfits')} active={'outfits' === activeSidebarTab}>
          <OutfitsTab />
        </TabHeader>

        <TabHeader toggle={() => handleToggle('showcase')} active={'showcase' === activeSidebarTab}>
          <ShowcaseTab />
        </TabHeader>
      </Nav>
      <DraggableDropArea enabled={dragProductEnabled} message="PRODUCT_DROP_AREA_MESSAGE" onDrop={handleDropAction}>
        <TabContent activeTab={activeSidebarTab} className="h-100">
          <TabPane tabId={'cart'} key={`${'cart'}-pane`} className="core-content">
            <Cart />
          </TabPane>

          <TabPane tabId={'outfits'} key={`${'outfits'}-pane`} className={lookDetailsView === 'LOOK_PRODUCTS_COMPOSER' ? '' : 'core-content'}>
            <Looks />
          </TabPane>

          <TabPane tabId={'showcase'} key={`${'showcase'}-pane`} className="core-content">
            <Showcase />
          </TabPane>
        </TabContent>
      </DraggableDropArea>
    </React.Fragment>
  );
};

export default Sidebar;