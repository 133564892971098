import React from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonProps } from 'reactstrap';
import { memoPaths } from '../../constants/routes';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActiveWindow } from '../../store/features/interface';
import { ActionMenuButton } from '../Button/ActionMenu';

const CatalogToggleBtn: React.FC<ButtonProps> = ({ className, style, ...rest }) => {
	const activeWindow = useAppSelector((state) => state.appInterface.activeWindow);
	const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
	const { returnToMeeting } = useAppNavigation();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const handleToggleWindow = React.useCallback(() => {
		if (pathname.includes(memoPaths.products.details)) returnToMeeting();
		activeWindow === 'productCatalog' ? dispatch(setActiveWindow('video')) : dispatch(setActiveWindow('productCatalog'));
	}, [dispatch, returnToMeeting, pathname, activeWindow]);

	return (
		<ActionMenuButton
			id="toggle-catalog-btn"
			className={className}
			style={style}
			disabled={dailyMeetingState !== 'joined-meeting'}
			icon={<i className='bx bxs-book font-size-20 align-middle' />}
			color={activeWindow === "productCatalog" ? 'primary' : 'secondary'}
			onClick={handleToggleWindow}
			tooltip={activeWindow === "productCatalog" ? 'GENERIC.BUTTONS.CLOSE_CATALOGUE' : 'GENERIC.BUTTONS.OPEN_CATALOGUE'} />
	)
}
export default CatalogToggleBtn;