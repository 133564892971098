import React from 'react';
import { Col } from 'reactstrap';
import WaitingForParticipantsScreen from '../../components/Screen/WaitingParticipantsScreen';
import MainParticipantTile from '../../components/Tile/MainParticipantTile';
import { useScreen } from '../../providers/ScreenSize';
import { useAppSelector } from '../../store';

// SALES-V2: Call in redux update
const Showroom: React.FC = () => {
  const { isMobile } = useScreen();
  const activeParticipantId = useAppSelector((state) => state.call.activeParticipantId);

  return (
    <React.Fragment>

      {isMobile && <React.Fragment>
        {activeParticipantId ? <MainParticipantTile /> : <WaitingForParticipantsScreen isMobile={isMobile} />}
      </React.Fragment>}

      {!isMobile && <Col className="h-100">
        {activeParticipantId ? <MainParticipantTile /> : <WaitingForParticipantsScreen isMobile={isMobile} />}
      </Col>}

    </React.Fragment>
  )
}
export default Showroom;