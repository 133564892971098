import React from 'react';
import Product, { ProductMedia } from '../../interfaces/Product';
import GalleryControls from './Controls';
import GalleryIndicators from './Indicators';
import './Gallery.scss';
import { logoDark } from '../../assets';
import { SidebarTabOptions } from '../../interfaces/AppInterface';
import { UserType } from '../../interfaces/Auth';
import { LookView } from '../../interfaces/Look';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { useAppSelector } from '../../store';
import { useScreen } from '../../providers/ScreenSize';
import useProductDraggable from '../../hooks/product/useDraggable';
import DraggableItem from '../Draggable/Item';
import classnames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SkeletonImage from '../SkeletonImage';
import { userRoles } from '../../constants/users';

interface PropTypes {
	className?: string;
	product?: Product;
	images: ProductMedia[];
	hasSelection?: boolean;
	activeSku?: string;
	children?: React.ReactNode;
	hasValidConfiguratorConfig?: Boolean;
}

const isDragProductAllowed = (
	userType: UserType,
	sidebarCollapsed: boolean,
	activeSidebarTab: SidebarTabOptions,
	cartLoading: boolean,
	lookView: LookView,
	selectedLookId?: string
): boolean => {
	if (sidebarCollapsed) return false;

	switch (activeSidebarTab) {
		case "cart":
			return !cartLoading;
		case "showcase":
			return userType === userRoles.STYLIST;
		case "outfits":
			return (
				userType === userRoles.STYLIST &&
				lookView === "LOOK_DETAILS" &&
				!!selectedLookId
			);
		default:
			return false;
	}
};

const getDragTooltipMessage = (activeSidebarTab: SidebarTabOptions) => {
	switch (activeSidebarTab) {
		case "cart":
			return "GENERIC.BUTTONS.DND_PRODUCT_TO_CART";
		case "outfits":
			return "GENERIC.BUTTONS.DND_PRODUCT_TO_OUTFITS";
		case "showcase":
			return "GENERIC.BUTTONS.DND_PRODUCT_TO_SHOWCASE";
		default:
			return "";
	}
};

const selector = createSelector(
	[
		({ auth }: RootState) => auth.userType,
		({ appInterface }: RootState) => appInterface.sidebarCollapsed,
		({ appInterface }: RootState) => appInterface.activeSidebarTab,
		({ cart }: RootState) => cart.cartLoading,
		({ looks }: RootState) => looks.lookView,
		({ looks }: RootState) => looks.selectedLookId,
	],
	(
		userType,
		sidebarCollapsed,
		activeSidebarTab,
		cartLoading,
		lookView,
		selectedLookId,
	) => ({
		isProductDraggable: isDragProductAllowed(
			userType,
			sidebarCollapsed,
			activeSidebarTab,
			cartLoading,
			lookView,
			selectedLookId
		),
		dragTooltipMessage: getDragTooltipMessage(activeSidebarTab)
	})
);


const Gallery: React.FC<PropTypes> = ({ images, product, activeSku, hasSelection, hasValidConfiguratorConfig = false }) => {
	const [position, setPosition] = React.useState<number>(0);
	const { isProductDraggable, dragTooltipMessage } = useAppSelector(selector);
	const { isMobile } = useScreen();
	const { t } = useTranslation();
	const { handleDragStart, handleDragEnd } = useProductDraggable();

	const dragEnabled = hasSelection && isProductDraggable && !isMobile && !hasValidConfiguratorConfig;

	const dragStart = React.useCallback((event: any) => {
		handleDragStart(event, { product, activeSku })
	}, [product, activeSku, handleDragStart])

	const handleImageSelect = React.useCallback((idx: number): void => setPosition(idx), []);

	const handlePositionChange = React.useCallback((position: number): void => {
		if (position >= 0 && position < images.length) {
			handleImageSelect(position);
		}
	}, [images, handleImageSelect]);

	React.useEffect(() => {
		handleImageSelect(0);
	}, [images, handleImageSelect]);

	return (
		<React.Fragment>
			<div className="position-relative w-100">
				<GalleryControls position={position} items={images.length || 0} onChange={handlePositionChange} />
				<DraggableItem draggable={dragEnabled} onDragStart={dragStart} onDragEnd={handleDragEnd}>
					{!isMobile && (
						<React.Fragment>
							{!hasValidConfiguratorConfig ? <div
								id="dnd-product"
								className={classnames(
									"position-absolute end-0 btn-primary top-0 rounded-circle p-1 shadow-none",
									dragEnabled ? "btn-primary" : "btn-secondary opacity-50"
								)}
							>
								<i className="bx bx-move font-size-20 align-middle" />

								{dragEnabled && (
									<UncontrolledTooltip
										fade={false}
										placement="top"
										target="dnd-product"
									>
										{t(dragTooltipMessage)}
									</UncontrolledTooltip>
								)}
							</div> : null}
						</React.Fragment>
					)}
					{images.map((image, index: number) => (
						<SkeletonImage height={250} width={250} src={image.url} alt={image.label} key={image.url} className={classnames("product-preview mx-auto", { 'd-none': index !== position })} skeletonClassName={classnames("product-preview mx-auto", { 'd-none': index !== position })} />
					))}
					{images.length === 0 && <SkeletonImage height={250} width={250} src={logoDark} className="product-preview mx-auto" skeletonClassName="product-preview mx-auto" />}
				</DraggableItem>
			</div>
			<GalleryIndicators position={position} length={images.length} onSelect={handleImageSelect} />
		</React.Fragment>
	)
}
export default Gallery;