
import { payment } from '../common/appSettings';
import i18n from "../i18n";
interface CreatePaymentUrlProps {
  orderId: string;
  paymentLink: string;
}

export const createPaymentUrl = ({ orderId, paymentLink }: CreatePaymentUrlProps): string => {

  const successUrl = payment.redirectUrl + `/success`;
  const cancelUrl = payment.redirectUrl + `/cancel`;

  const paymentUrl: string = encodeURI([
    paymentLink,
    "?cancelUrl=",
    cancelUrl,
    "&successUrl=",
    successUrl,
    "&lng=",
    i18n.language

  ].join(""));

  return paymentUrl;
}