import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormActions from "../Forms/FormActions";
import TextConfigTrans from "../Text/ConfigTrans";

interface PropTypes {
  isShown: boolean;
  title: string;
  titleParams?: { [key: string]: string };
  message: string;
  messageParams?: { [key: string]: string };
  cancelText?: string;
  submitText?: string;
  submitBtnClass?: string;
  submitting?: boolean;
  footerClass?: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const ConfirmationModal: React.FC<PropTypes> = ({
  isShown,
  title = "",
  titleParams = {},
  message = "",
  messageParams = {},
  cancelText = "GENERIC.BUTTONS.CANCEL",
  submitText = "GENERIC.BUTTONS.CONFIRM",
  submitBtnClass,
  submitting = false,
  footerClass = "border-0",
  onCancel,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isShown}
      toggle={onCancel}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <ModalHeader toggle={onCancel}>
        <TextConfigTrans text={title} textParams={titleParams} />
      </ModalHeader>

      <ModalBody>
        <TextConfigTrans text={message} textParams={messageParams} />
      </ModalBody>

      <ModalFooter className={footerClass}>
        <FormActions
          className="w-100 justify-content-end"
          cancelText={cancelText}
          submitText={submitText}
          submitBtnClass={submitBtnClass}
          submitBtnType="button"
          submitting={submitting}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
