import classNames from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { ActionMenuButton } from '../Button/ActionMenu';

// SALES-V2: Call in redux update
const MicrophoneToggleBtn: React.FC = () => {
  const dailyMeetingState = useAppSelector((state) => state.call.dailyMeetingState);
  const isMicMuted = useAppSelector((state) => state.appInterface.isMicMuted);
  const dispatch = useAppDispatch();

  const handleToggleMicrophone = React.useCallback(() => dispatch({ type: 'ToggleMicrophone' }), [dispatch]);

  return (
    <ActionMenuButton
      id="toggle-mic-btn"
      disabled={dailyMeetingState !== 'joined-meeting'}
      icon={<i className={classNames("bx font-size-20 align-middle", { "bxs-microphone-off": isMicMuted, "bxs-microphone": !isMicMuted })} />}
      color={isMicMuted ? 'primary' : 'secondary'}
      onClick={handleToggleMicrophone}
      tooltip={isMicMuted ? "GENERIC.BUTTONS.MIC_OFF" : "GENERIC.BUTTONS.MIC_ON"} />
  )
}
export default MicrophoneToggleBtn;