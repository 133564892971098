import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { initialCatalogFilterPrice } from '../../constants/catalog';
import { useAppSelector } from '../../store';
import CollapsableListItem from '../CollapsableListItem';
import CategoryFilter from '../Filters/CategoryFilter/categoryFilter';
import PriceSelect from '../Filters/priceFilter';
import useClearFilters from '../Filters/useClearFilters';
import useFetchFilters from '../Filters/useFetchFilters';
import LoadingSpinner from '../LoadingSpinner';
import SearchTerm from '../productCatalog/SearchTerm';

interface PropTypes {
  isOpen: boolean;
  toggle: () => void;
}

const MobileFilters: React.FC<PropTypes> = ({ isOpen, toggle }) => {
  const catalogLoading = useAppSelector((state) => state.catalog.catalogLoading);
  const { categories, filtersLoading, initializedFilters, searchParams } = useAppSelector((state) => state.filters);
  const pagination = useAppSelector((state) => state.catalog.pagination);
  const clearFilters = useClearFilters();
  const fetchFilters = useFetchFilters();
  const { t } = useTranslation();

  // TODO: show available products retrieved from get catalog meta data

  const hasActiveFilter = React.useMemo(
    () =>
      searchParams.categories.length > 0 ||
      searchParams.price.from !== initialCatalogFilterPrice.from ||
      searchParams.price.to !== initialCatalogFilterPrice.to ||
      !!searchParams.searchTerm,
    [searchParams]
  );

  React.useEffect(() => {
    if (!initializedFilters && !filtersLoading) {
      fetchFilters();
    }
  }, [initializedFilters, filtersLoading, fetchFilters])

  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} backdrop={false}>
      <OffcanvasHeader toggle={toggle} className="">
        <h5 className="mb-0">{t("GENERIC.LABELS.FILTERS")}</h5>
      </OffcanvasHeader>
      <OffcanvasBody>

        <SearchTerm />

        <ul className="list-unstyled">
          <CollapsableListItem id="category" label={t("GENERIC.LABELS.CATEGORIES")} clear={() => clearFilters('category')} disabled={searchParams.categories.length === 0}>
            <CategoryFilter categories={categories} />
          </CollapsableListItem>
          {<CollapsableListItem
            id="price"
            label={t("GENERIC.LABELS.PRICE")}
            clear={() => clearFilters('price')}
            disabled={initialCatalogFilterPrice.from === searchParams.price.from && initialCatalogFilterPrice.to === searchParams.price.to}>
            <PriceSelect price={initialCatalogFilterPrice} />
          </CollapsableListItem>}
        </ul>

        <div className="d-flex justify-content-between">
          {<Button
            color="primary"
            className="w-50 mx-2"
            disabled={!hasActiveFilter}
            onClick={() => clearFilters('all')}
          >
            {t("GENERIC.BUTTONS.CLEAR_FILTERS")}
          </Button>}
          <Button
            color="primary"
            className="w-50 mx-2"
            onClick={toggle}
            disabled={catalogLoading}
          >
            {catalogLoading && <LoadingSpinner isBtn />}
            {!catalogLoading && <React.Fragment>
              {pagination.total}{" "}
              {t("GENERIC.LABELS.PRODUCTS")}
            </React.Fragment>}
          </Button>
        </div>

      </OffcanvasBody>

    </Offcanvas >
  )
}
export default MobileFilters;