import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { createPaymentUrl } from "../../helpers/order";
import { OrderCreateSuccessResponse, OrderState } from "../../interfaces/Order";
import { createAppAsyncThunk } from "../extras";
import { getMeeting } from "./meeting";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const createOrder = createAppAsyncThunk(
  "order/create",
  async (_, { rejectWithValue, getState }) => {
    try {
      const cartId = getState().cart.cartId || "";
      const response: OrderCreateSuccessResponse = await api.OrderService.createOrder({ cartId });
      return {
        status: response.status,
        paymentUrl: createPaymentUrl({ orderId: response._id, paymentLink: response.paymentLink }),
        id: response._id
      };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
);

export const retrieveOrder = createAppAsyncThunk(
  "order/retrieve",
  async (orderId: string, { rejectWithValue }) => {
    try {
      const response: any = await api.OrderService.retrieveOrder(orderId);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

const initialState: OrderState = {
  orderId: undefined,
  paymentUrl: undefined,
  orderStatus: undefined,
  orderLoading: false,
  orderCreating: false
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Create Order ---- */
      .addCase(createOrder.pending, (state) => {
        state.orderCreating = true;
      })
      .addCase(createOrder.fulfilled, (state, action: PayloadAction<any>) => {
        state.orderId = action.payload._id;
        state.paymentUrl = action.payload.paymentUrl;
        state.orderStatus = action.payload.status;
        state.orderCreating = false;
      })
      .addCase(createOrder.rejected, (state) => {
        state.orderCreating = false;
      })

      /* ---- Retrieve Order ---- */
      .addCase(retrieveOrder.pending, (state) => {
        state.orderLoading = true;
      })
      .addCase(retrieveOrder.fulfilled, (state, action) => {
        state.orderId = action.payload._id;
        state.orderStatus = action.payload.status;
        state.paymentUrl = createPaymentUrl({ orderId: action.payload._id, paymentLink: action.payload.paymentLink });
        state.orderLoading = false;
      })
      .addCase(retrieveOrder.rejected, (state) => {
        state.orderLoading = false;
      })

      /* ---- EXTERNAL: Retrieve Meeting ---- */
      .addCase(getMeeting.fulfilled, (state, action) => {
        if (action.payload.orderId) {
          state.orderId = action.payload.orderId._id;
          state.orderStatus = action.payload.orderId.status;
          state.paymentUrl = createPaymentUrl({ orderId: action.payload.orderId._id, paymentLink: action.payload.orderId.paymentLink });
        }
      })

      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        // state.paymentUrl = initialState.paymentUrl;       
        // state.orderId = initialState.orderId;
        // state.orderStatus = initialState.orderStatus;
        // state.orderLoading = initialState.orderLoading;
        // state.orderCreating = initialState.orderCreating;
      })

      /* ----  SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.paymentUrl = initialState.paymentUrl;
        state.orderId = initialState.orderId;
        state.orderStatus = initialState.orderStatus;
        state.orderLoading = initialState.orderLoading;
        state.orderCreating = initialState.orderCreating;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.paymentUrl = initialState.paymentUrl;
        state.orderId = initialState.orderId;
        state.orderStatus = initialState.orderStatus;
        state.orderLoading = initialState.orderLoading;
        state.orderCreating = initialState.orderCreating;
      })
  }
});
// export const {  } = orderSlice.actions;
export default orderSlice.reducer;