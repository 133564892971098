import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardFooter, Table } from 'reactstrap';
import { userRoles } from '../../constants/users';
import useShowcaseActions from '../../hooks/useShowcaseActions';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleShowcaseClearModal } from '../../store/features/interface';
import ClearShowcaseModal from '../Modals/ClearShowcaseModal';
import TableHeaders from '../Table/TableHeaders';
import ShowcaseTableContent from './ShowcaseTableContent';

const Showcase: React.FC = () => {
  const showcaseProducts = useAppSelector((state) => state.showcase.products);
  const showcaseLoading = useAppSelector((state) => state.showcase.showcaseLoading);
  const showModal = useAppSelector((state) => state.appInterface.showcaseModalToggled);
  const userType = useAppSelector((state) => state.auth.userType);
  const { t } = useTranslation();
  const { clearShowcase } = useShowcaseActions();
  const dispatch = useAppDispatch();

  const handleToggleModal = React.useCallback(() => dispatch(toggleShowcaseClearModal()), [dispatch]);

  const handleClearShowcase = React.useCallback(() => {
    clearShowcase();
  }, [clearShowcase]);

  return (
    <React.Fragment>
      <Card className="mb-0 h-100 shadow-none">
        <CardBody className="px-2 pt-2 sidebar-list-container">
          <div className="table-responsive">
            <Table className="table align-middle mb-0 table-nowrap">
              <TableHeaders>
                <th className="w-25" scope="col">
                  {t("GENERIC.LABELS.PRODUCT")}
                </th>
                <th className="w-50" scope="col">
                  {t("GENERIC.LABELS.DESCRIPTION")}
                </th>
              </TableHeaders>
              <ShowcaseTableContent products={showcaseProducts} userType={userType} />
            </Table>
          </div>
        </CardBody>
        {userType === userRoles.STYLIST && <CardFooter className="bg-white border-top d-flex">
          <div className="d-flex w-100">
            <Button color="danger" className="font-size-13 ms-auto" disabled={showcaseProducts.length === 0 || showcaseLoading} onClick={handleToggleModal}>
              <i className='bx bx-x align-middle' />
              <span className="ms-1 align-middle">
                {t("GENERIC.BUTTONS.CLEAR")}
              </span>
            </Button>
          </div>
        </CardFooter>}
      </Card>
      <ClearShowcaseModal loading={showcaseLoading} isOpen={showModal} toggleModal={handleToggleModal} confirm={handleClearShowcase} />
    </React.Fragment>
  )
}
export default Showcase;