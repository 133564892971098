import React from "react";
import classNames from "classnames";

interface PropTypes {
  className?: any;
  color?: string;
  style?: any;
  rounded?: boolean;
  icon: string;
  iconSize?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  [rest: string]: any;
}

const IconButton: React.FC<PropTypes> = ({
  className,
  color,
  style = {},
  rounded = true,
  icon,
  iconSize = "font-size-14",
  onClick,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        " border-0 p-1 btn btn-sm",
        { "rounded-circle": rounded },
        className,
        color
      )}
      style={{ ...style, lineHeight: 0 }}
      onClick={onClick}
      {...rest}
    >
      <i className={classNames("align-middle", icon, iconSize)} />
    </button>
  );
};

export default IconButton;
