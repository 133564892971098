
import React from "react";
import RemoveConfirmationModal from "../Modals/RemoveConfirmationModal";
import { CartProduct } from "../../interfaces/Product";
import useAppNavigation from "../../hooks/useAppNavigation";
import useCartActions from "../../hooks/cart/useCartActions";
import TableEmpty from "../Table/TableEmpty";
import { useTranslation } from "react-i18next";
import CartTableItem from "./CartTableItem";

interface PropTypes {
  products: CartProduct[],
  onRemove?: (id: string) => void
}

const CartTableContent: React.FC<PropTypes> = ({ products, onRemove }) => {
  const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = React.useState<CartProduct | null>(null);
  const { visitProductDetails } = useAppNavigation();
  const { removeFromCart, addToCart, cartActionsDisabled } = useCartActions();
  const { t } = useTranslation();

  const toggleRemoveModal = (product?: CartProduct): void => {
    if (product) {
      setSelectedProduct(product);
    };
    setShowRemoveModal(!showRemoveModal);
  };

  const confirmItemRemoval = (): void => {
    if (selectedProduct?._id && onRemove) {
      onRemove(selectedProduct._id);
    };
    setShowRemoveModal(!showRemoveModal);
  };

  return (
    <React.Fragment>
      <tbody>
        {products.map((product: CartProduct, index: number) => (
          <CartTableItem
            key={product._id}
            visitDetails={visitProductDetails}
            onRemove={removeFromCart}
            onAdd={addToCart}
            product={product}
            actionsDisabled={cartActionsDisabled}
            index={index}
            toggleModal={toggleRemoveModal}
          />
        ))}
        {products.length === 0 && <TableEmpty colSpan={3}>
          {t("GENERIC.LABELS.CART_EMPTY")}
        </TableEmpty>}
      </tbody>
      <RemoveConfirmationModal isShown={showRemoveModal} name={selectedProduct?.details?.product.name} toggleModal={toggleRemoveModal} confirm={confirmItemRemoval} />
    </React.Fragment>
  );
};

export default CartTableContent;
