import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import error from "../../assets/images/error-img.png"

/* ---- https://docs.daily.co/docs/browsers ---- */
const BrowserUnsupportedPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container className="my-5">
      <Row>
        <Col lg="12">
          <div className="text-center mb-3">
            <h4 className="text-uppercase text-center">
              {t("PAGES.BROWSER_UNSUPPORTED.LABELS.UPDATE_BROWSER")}
              <br />
              <br />
              {t("PAGES.BROWSER_UNSUPPORTED.LABELS.SUPPORTED_BROWSERS")}
            </h4>
            <ul className="list-unstyled mt-2">
              <li className="py-2">Chrome 74 {t("PAGES.BROWSER_UNSUPPORTED.LABELS.AND_ABOVE")}</li>
              <li className="py-2">Safari 12.1 {t("PAGES.BROWSER_UNSUPPORTED.LABELS.AND_ABOVE")}</li>
              <li className="py-2">Firefox 78 ESR {t("PAGES.BROWSER_UNSUPPORTED.LABELS.AND_ABOVE")}</li>
              <li className="py-2">Microsoft Edge 74 {t("PAGES.BROWSER_UNSUPPORTED.LABELS.AND_ABOVE")}</li>
              <li className="py-2">iOS Safari - iOS 12.1 {t("PAGES.BROWSER_UNSUPPORTED.LABELS.AND_ABOVE")}</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8" xl="6">
          <div>
            <img src={error} alt="error" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BrowserUnsupportedPage;
