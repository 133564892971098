import React from 'react';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import Product from '../../interfaces/Product';
import DiscountBadge from '../Badge/DiscountBadge';
import ProductPrice from '../ProductDetails/ProductPrice';
import SkeletonImage from '../SkeletonImage';

interface PropTypes {
  product: Product;
}

const ProductCard: React.FC<PropTypes> = ({ product }) => {

  const hasDiscount = React.useMemo(() => {
    return !!product.price_range.maximum_price.discount.amount_off
  }, [product]);

  return (
    <Link to={`details/${encodeURIComponent(product.sku)}`} className="card shadow-none position-relative">

      {hasDiscount ? <DiscountBadge {...product.price_range.maximum_price.discount} /> : null}

      <SkeletonImage
        src={product.thumbnail?.url}
        alt={product.thumbnail?.label}
        title={product.thumbnail?.label}
        className="product-card-image"
        skeletonClassName="product-card-image"
        loading="lazy"
      />
      <CardBody className="p-0">

        <div className="mt-1 px-1 justify-content-center">
          <h6 className="text-wrap text-left mb-1 product-card-description">
            {product.name}
          </h6>
          <ProductPrice
            type={product.__typename}
            price={{
              regular: product.price_range.maximum_price.regular_price.value,
              final: product.price_range.maximum_price.final_price.value
            }}
          />
        </div>
      </CardBody>
    </Link>
  )
}
export default ProductCard;