import React from "react";
import { Col } from "reactstrap";
import MainSection from "../../components/Call/MainSection";
import MainParticipantTile from "../../components/Tile/MainParticipantTile";
import { userRoles } from "../../constants/users";
import { useScreen } from "../../providers/ScreenSize";
import { useAppSelector } from "../../store";

// SALES-V2: Call in redux update
const MeetingPage: React.FC = () => {
  const sidebarCollapsed = useAppSelector((state) => state.appInterface.sidebarCollapsed);
  const userType = useAppSelector((state) => state.auth.userType);
  const { isMobile } = useScreen();

  return (
    <React.Fragment>

      {isMobile && <React.Fragment>
        {userType === userRoles.CONSUMER && <MainSection />}
        {userType === userRoles.STYLIST && <MainParticipantTile />}
      </React.Fragment>}

      {!isMobile && <React.Fragment>
        <Col md={sidebarCollapsed ? 12 : 8} className="h-100 pe-0">
          <MainSection />
        </Col>
      </React.Fragment>}

    </React.Fragment>
  )
};

export default MeetingPage;