import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useAppSelector } from '../../store';

interface PropsTypes {
  toggleModal: () => void;
  isOpen: boolean;
  paymentUrl?: string;
}

const CheckoutModal: React.FC<PropsTypes> = ({ isOpen, toggleModal, paymentUrl }) => {
  const { t } = useTranslation();
  const orderStatus = useAppSelector((state) => state.order.orderStatus);

  const isPaid = React.useMemo(() => orderStatus === 'paid', [orderStatus]);

  const handleCopy = (): void => {
    if (paymentUrl) {
      navigator.clipboard.writeText(paymentUrl);
      toast.info(t("TOASTS.LINK_COPY"));
    }
  }

  return (
    <Modal isOpen={isOpen} centered={true} toggle={() => toggleModal()}>
      <ModalHeader className="border-0" toggle={toggleModal} />

      <ModalBody className="d-flex flex-column text-center">

        {!isPaid && <React.Fragment>
          <div className="mx-auto bg-primary bg-soft rounded-circle mb-2">
            <i className='bx bxs-cart-alt display-5 text-primary align-middle p-3' />
          </div>

          <h2 className="text-primary pt-3 pb-2">{t("MODALS.CHECKOUT.HEADER")}</h2>
          <p className="mx-5 text-muted ">{t("MODALS.CHECKOUT.SUBHEADER")}</p>

          <div className="input-group bg-light rounded mt-2">
            <a className="form-control text-primary bg-transparent overflow-x-scroll text-start text-nowrap border-0 hide-scrollbar" href={paymentUrl} children={paymentUrl} target="_blank" rel="noreferrer" />
            <Button color="primary" type="button" onClick={handleCopy}>
              <i className='bx bxs-copy' />
            </Button>
          </div>
          {paymentUrl &&
            <div className="mx-auto p-4 my-2">
              <QRCode size={196} value={paymentUrl} />
            </div>}
        </React.Fragment>}

        {isPaid && <React.Fragment>
          <div className="mx-auto bg-primary bg-soft rounded-circle">
            <i className='bx bx-check m-2 bg-success rounded-circle text-white align-middle' style={{ fontSize: '3.5rem' }} />
          </div>
          <h4 className="my-3 text-primary">{t("MODALS.PAYMENT_STATUS.LABELS.COMPLETE")}</h4>
          <div>
            <Button color="success" onClick={toggleModal}>{t("MODALS.PAYMENT_STATUS.BUTTONS.RETURN_TO_CALL")}</Button>
          </div>
        </React.Fragment>}

      </ModalBody>
    </Modal>
  )
}
export default CheckoutModal;