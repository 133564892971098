import React from "react";
import VideoOverlay from "./VideoOverlay";
import "./Tile.scss";
import Video from "./Video";
import CornerMicStatus from "./CornerMicStatus";
import { useScreen } from "../../providers/ScreenSize";
import CornerName from "./CornerName";
import CornerRoleIcon from "./RoleIcon";
import { DailyTrackState } from "@daily-co/daily-js";
import { Button, Popover, PopoverBody, UncontrolledTooltip } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store";
import useMqttMessage from "../../hooks/mqtt/useMessage";
import classnames from "classnames";
import { MQTT_VIEW_USER } from "../../interfaces/Mqtt";
import { setActivePresentLook, setActivePresentWindow } from "../../store/features/interface";
import { useTranslation } from "react-i18next";
import { userRoles } from "../../constants/users";
import { ejectFromCall, setMainParticipant } from "../../store/features/call";
import { UserType } from "../../interfaces/Auth";

const getTrackUnavailableMessage = (kind: 'video' | 'audio', trackState: DailyTrackState | undefined | null): string | null | undefined => {
  if (!trackState) return;
  switch (trackState.state) {
    case "blocked":
      if (trackState?.blocked?.byPermissions) {
        return `${kind} permission denied`;
      } else if (trackState?.blocked?.byDeviceMissing) {
        return `${kind} device missing`;
      }
      return `${kind} blocked`;
    case "off":
      if (trackState?.off?.byUser) {
        return `${kind} muted`;
      } else if (trackState?.off?.byBandwidth) {
        return `${kind} muted to save bandwidth`;
      }
      return `${kind} off`;
    case "sendable":
      return `${kind} not subscribed`;
    case "loading":
      return `${kind} loading...`;
    case "interrupted":
      return `${kind} interrupted`;
    case "playable":
      return null;
    default:
      return null;
  }
};

interface PropTypes {
  id: string;
  videoTrackState: DailyTrackState | null | undefined;
  audioTrackState?: DailyTrackState | null | undefined;
  isLocalPerson: boolean;
  isMainScreen: boolean;
  name?: string;
  className?: string;
  userData?: { role?: UserType };
}

// SALES-V2: Call in redux update
const Tile: React.FC<PropTypes> = ({ videoTrackState, id, audioTrackState, name, isLocalPerson, isMainScreen, className, userData }) => {
  const { userType, isShowroom } = useAppSelector((state) => state.auth);
  const [popoverVisible, setPopoverVisible] = React.useState<boolean>(false);
  const videoEl = React.useRef<HTMLVideoElement | null>(null);
  const audioEl = React.useRef<HTMLAudioElement | null>(null);
  const sendMqttMessage = useMqttMessage();
  const participants = useAppSelector((state) => state.call.participants);
  const dispatch = useAppDispatch();
  const { isMobile } = useScreen();
  const { t } = useTranslation();

  const handleChangeParticipant = React.useCallback((id: string) => {
    if (participants.length > 1) {
      dispatch(setMainParticipant(id));
    }
  }, [dispatch, participants]);

  const togglePopover = React.useCallback(() => setPopoverVisible((curr) => !curr), []);

  const videoTrack = React.useMemo<MediaStreamTrack | null | undefined>(() => {
    return videoTrackState && videoTrackState.state === "playable"
      ? videoTrackState.track
      : null;
  }, [videoTrackState]);

  const audioTrack = React.useMemo<MediaStreamTrack | null | undefined>(() => {
    return audioTrackState && audioTrackState.state === "playable"
      ? audioTrackState.track
      : null;
  }, [audioTrackState]);

  const videoUnavailableMessage = React.useMemo<string | null | undefined>(() => {
    return getTrackUnavailableMessage("video", videoTrackState);
  }, [videoTrackState]);

  const audioUnavailableMessage = React.useMemo<string | null | undefined>(() => {
    return getTrackUnavailableMessage("audio", audioTrackState);
  }, [audioTrackState]);

  /**
   * When video track changes, update video srcObject
   */
  React.useEffect(() => {
    videoTrack && videoEl.current && (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [videoTrack]);

  /**
   * When audio track changes, update audio srcObject
   */
  React.useEffect(() => {
    audioTrack && audioEl.current && (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [audioTrack]);


  const handleEjectFromCall = React.useCallback(() => {
    dispatch(ejectFromCall(id));
    setPopoverVisible(false);
  }, [dispatch, id]);

  const handleSetMainScreen = React.useCallback(() => {
    if (userType === userRoles.CONSUMER || isShowroom) {
      handleChangeParticipant(id);
    };
  }, [handleChangeParticipant, id, isShowroom, userType]);

  const handleSetMainScreenForAll = React.useCallback(() => {
    sendMqttMessage({ type: MQTT_VIEW_USER, payload: { userId: id } });
    dispatch(setActivePresentWindow());
    dispatch(setActivePresentLook(''));
  }, [dispatch, sendMqttMessage, id]);

  return (
    <div id={`participant-${id}`} className={classnames('tile bg-black position-relative overflow-hidden', className, { 'rounded-3': !isMobile, 'local': isLocalPerson })} onClick={handleSetMainScreen}>
      {name && <CornerName name={isLocalPerson ? t("GENERIC.LABELS.YOU") : name} />}
      {userData?.role ? <CornerRoleIcon role={userData.role} /> : null}

      <CornerMicStatus audioUnavailableMessage={audioUnavailableMessage} />
      <VideoOverlay videoUnavailableMessage={videoUnavailableMessage} isMainScreen={isMainScreen} name={name} />
      <Video videoTrack={videoTrack} videoEl={videoEl} />

      {userType === userRoles.STYLIST && !isShowroom && id &&
        <Popover popperClassName="border-primary border my-0" trigger="hover" hideArrow placement="bottom" isOpen={popoverVisible} target={`participant-${id}`} toggle={togglePopover}>
          <PopoverBody className="d-flex justify-content-between">
            <Button id="popover-set-for-you" size="sm" color="primary" className="rounded-circle px-1 me-3" onClick={() => handleChangeParticipant(id)}>
              <i className='bx bx-desktop font-size-18 align-middle' />
              <UncontrolledTooltip fade={false} placement="top" target="popover-set-for-you">
                {t("GENERIC.BUTTONS.SET_MAINSCREEN")}
              </UncontrolledTooltip>
            </Button>
            <Button id="popover-set-for-all" size="sm" color="primary" className="rounded-circle px-1" outline onClick={handleSetMainScreenForAll}>
              <i className='bx bxs-devices font-size-18 align-middle' />
              <UncontrolledTooltip fade={false} placement="top" target="popover-set-for-all">
                {t("GENERIC.BUTTONS.SET_MAINSCREEN_ALL")}
              </UncontrolledTooltip>
            </Button>
            {!isLocalPerson &&
              <Button id="popover-eject-from-call" size="sm" color="danger" className="rounded-circle px-1 ms-3" onClick={handleEjectFromCall}>
                <i className='bx bxs-phone font-size-18 align-middle' />
                <UncontrolledTooltip fade={false} placement="top" target="popover-eject-from-call">
                  {t("GENERIC.BUTTONS.REMOVE_FROM_CALL")}
                </UncontrolledTooltip>
              </Button>}
          </PopoverBody>
        </Popover>}

      {!isLocalPerson && audioTrack && <audio autoPlay playsInline ref={audioEl} />}
    </div>
  );
}
export default Tile;