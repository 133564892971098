import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { defaultUserAvatar } from '../../assets';
import { stylistAppUrl } from '../../common/appSettings';
import { useAppDispatch, useAppSelector } from '../../store';
import { leaveCall } from '../../store/features/call';
import { toggleLocalMode } from '../../store/features/interface';
import SkeletonImage from '../SkeletonImage';

// SALES-V2: Call in redux update
const UserDropdown: React.FC = () => {
  const { fullName, avatarUrl } = useAppSelector((state) => state.auth);
  const isLocalMode = useAppSelector((state) => state.appInterface.isLocalMode);
  const [toggled, setToggled] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSignOut = () => {
    dispatch(leaveCall());
    dispatch({ type: 'Logout' });
    toast.info(t("TOASTS.LOG_OUT"));
  };

  const handleLocalMode = React.useCallback(() => dispatch(toggleLocalMode()), [dispatch]);

  const handleToggleDropdown = React.useCallback(() => setToggled((curr) => !curr), []);

  return (
    <Dropdown isOpen={toggled} toggle={handleToggleDropdown} className="d-inline-block">
      <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
        {avatarUrl ?
          <SkeletonImage
            src={avatarUrl}
            alt={fullName}
            className={classnames("avatar-xs rounded-circle header-profile-user",)}
            defaultSrc={defaultUserAvatar}
          />
          :
          <div className="avatar-xs rounded-circle bg-primary d-flex border border-3 border-light">
            <h4 className="text-white text-center m-auto text-uppercase">{fullName?.slice(0, 1) || 'Y'}</h4>
          </div>}
      </DropdownToggle>
      <DropdownMenu>
        {stylistAppUrl && <DropdownItem tag="a" href={stylistAppUrl} target="_blank" rel="noopener noreferrer" >
          <i className="bx bx-user font-size-16 align-middle me-1" />
          {t("GENERIC.BUTTONS.OPEN_STYLIST_APP")}
        </DropdownItem>}
        <DropdownItem tag="a" href="#" onClick={handleLocalMode}>
          <i className="bx bx-store font-size-16 align-middle me-1" />
          {isLocalMode ? t("GENERIC.BUTTONS.DISABLE_LOCAL_MODE") : t("GENERIC.BUTTONS.ENABLE_LOCAL_MODE")}
        </DropdownItem>
        <div className="dropdown-divider" />
        <Link to="#" onClick={handleSignOut} className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          {t("GENERIC.BUTTONS.LOG_OUT")}
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}
export default UserDropdown;