import React from "react";
import { Col, Container, Row } from "reactstrap";
import { applicationCopyright } from "../../common/appSettings";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={6}>{applicationCopyright}</Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
