import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import useAppNavigation from '../../hooks/useAppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { setActiveWindow, setPreviousWindow } from '../../store/features/interface';
import NotificationBadge from '../Badge/NotificationBadge';

const MobileShowcaseButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { returnToMeeting } = useAppNavigation();
  const activeWindow = useAppSelector((state) => state.appInterface.activeWindow);
  const notificationCounter = useAppSelector((state) => state.appInterface.showcaseNotificationCounter)

  const handleToggleShowcase = (): void => {
    if (activeWindow === 'showcase') {
      dispatch(setPreviousWindow());
    } else {
      dispatch(setActiveWindow('showcase'));
    }
    returnToMeeting();
  };

  return (
    <Button className="px-0 outline-0 shadow-none bg-transparent border-0 text-start " onClick={handleToggleShowcase}>
      <span className="text-black">{t("GENERIC.LABELS.SHOWCASE")}</span>
      <span className="text-muted position-relative mx-2">
        <i className='bx bxs-store align-middle font-size-16' />
        <NotificationBadge show={activeWindow !== 'showcase'} count={notificationCounter} className="top-0 start-100 translate-middle" />
      </span>
    </Button>
  )
}
export default MobileShowcaseButton;