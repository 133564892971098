import axios from 'axios';
import { CartProductPayload, UpdateCartProductPayload, UpdateCartPayload } from '../interfaces/Cart';
import { constructRequestHeaders, SERVER_URL } from "./helpers";
export default class CartService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/cart`;
  }

  async retrieveCart(cartId: string): Promise<any> {
    try {
      const response = await axios.get(`${this.baseUrl}s/${cartId}`, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async updateCart(cartId: string, payload: UpdateCartPayload): Promise<any> {
    try {
      const response = await axios.put(`${this.baseUrl}s/${cartId}`, payload, {
        headers: constructRequestHeaders({})
      });
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async addCartItem(cartId: string, payload: CartProductPayload): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}s/${cartId}/item`, payload, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async updateCartItem(cartId: string, cartItemId: string, payload: UpdateCartProductPayload): Promise<any> {
    try {
      const response = await axios.put(`${this.baseUrl}s/${cartId}/items/${cartItemId}`, payload, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error)
    }
  }

  async removeCartItem(cartId: string, cartItemId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.baseUrl}s/${cartId}/items/${cartItemId}`, {
        headers: constructRequestHeaders({})
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}