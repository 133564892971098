import React from 'react';
import { userRoles } from '../../constants/users';
import { UserType } from '../../interfaces/Auth';

interface PropTypes {
  role: UserType;
}

// SALES-V2: Call in redux update
const TileRoleIcon: React.FC<PropTypes> = ({ role }) => {
  return (
    <React.Fragment>
      {role === userRoles.STYLIST ? <i className='bx bxs-crown font-size-16 position-absolute bg-secondary bg-soft text-white top-0 start-0 p-1' /> : null}
      {role === userRoles.CONSUMER ? <i className='bx bxs-cart-alt font-size-16 position-absolute bg-secondary bg-soft text-white top-0 start-0 p-1' /> : null}
      {role === userRoles.SHOWROOM ? <i className='bx bxs-show font-size-16 position-absolute bg-secondary bg-soft text-white top-0 start-0 p-1' /> : null}
      {role === userRoles.GUEST ? <i className='bx bxs-user font-size-16 position-absolute bg-secondary bg-soft text-white top-0 start-0 p-1' /> : null}
    </React.Fragment>
  )
}
export default TileRoleIcon;