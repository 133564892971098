import classnames from "classnames";
import React from "react";
import { logoDark, logoLight } from "../../assets";
import { useAppSelector } from "../../store";

interface PropTypes {
  className?: string;
  light?: boolean;
}

const Logo: React.FC<PropTypes> = ({ className, light }) => {
  const { retrieved, logo, title } = useAppSelector((state) => state.tenancy)

  return (
    <React.Fragment>
      {retrieved && logo && <img src={logo} alt={title || ""} width="68" height="36" className={classnames('img-fluid', className)} />}
      {retrieved && !logo && <div>No Logo</div>}
      {!retrieved && <img src={light ? logoLight : logoDark} alt="Eligo" width="68" height="36" className={classnames('img-fluid', className)} />}
    </React.Fragment>
  );
};

export default Logo;
