import { useCallback } from 'react';
import { initialCatalogFilter, initialCatalogFilterPrice } from '../../constants/catalog';
import useAppNavigation from '../../hooks/useAppNavigation';
import useCatalog from '../../hooks/useCatalog';

const useClearFilters = () => {
  const { retrieveCatalog } = useCatalog();
  const { returnToMeeting } = useAppNavigation();

  return useCallback((type: 'all' | 'price' | 'category' | 'brand' | 'rating') => {
    switch (type) {
      case 'all':
        retrieveCatalog(initialCatalogFilter);
        returnToMeeting();
        break;
      case 'price':
        retrieveCatalog({
          price: initialCatalogFilterPrice
        });
        returnToMeeting();
        break;
      case 'category':
        retrieveCatalog({ categories: [] });
        returnToMeeting();
        break;
      case 'brand':

        break;
      case 'rating':

        break;

      default:
        break;
    }
  }, [retrieveCatalog, returnToMeeting]);
}
export default useClearFilters;