import React from 'react'


const useScrollPosition = () => {
  const prevScrollY = React.useRef(0);
  const [goingUp, setGoingUp] = React.useState(false);
  const [isAtTop, setIsAtTop] = React.useState(false);

  const onScroll = (e: any) => {
    const currentScrollY = e.target.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    // HARDCODED:
    if (prevScrollY.current < 35) {
      setIsAtTop(true);
    }
    if (prevScrollY.current > 0 && !goingUp) {
      setIsAtTop(false)
    }
    prevScrollY.current = currentScrollY;
  }

  return { onScroll, goingUp, isAtTop }
}

export default useScrollPosition;