import React from 'react';
import { Button, Col, UncontrolledTooltip } from 'reactstrap';
import { presentTypes } from '../../constants/interface';
import useMqttMessage from '../../hooks/mqtt/useMessage';
import useAppNavigation from '../../hooks/useAppNavigation';
import { PresentWindowCatalog, PresentWindowDetails } from '../../interfaces/AppInterface';
import { MQTT_VIEW_WINDOW } from '../../interfaces/Mqtt';
import { useAppDispatch } from '../../store';
import { setActivePresentWindow } from '../../store/features/interface';
import Typography from '../Typography';

interface PropTypes {
  activePresentWindow: PresentWindowCatalog | PresentWindowDetails;
}

const PresentationTile: React.FC<PropTypes> = ({ activePresentWindow }) => {
  const dispatch = useAppDispatch();
  const sendMqttMessage = useMqttMessage();
  const { visitProductDetails } = useAppNavigation();

  const handleClear = () => {
    dispatch(setActivePresentWindow());
    sendMqttMessage({ type: MQTT_VIEW_WINDOW, payload: { window: 'video' } });
  };

  const handleVisitDetails = React.useCallback(() => {
    if (activePresentWindow.window === presentTypes.DETAILS)
      visitProductDetails(encodeURIComponent(activePresentWindow.sku))
  }, [activePresentWindow, visitProductDetails])

  return (
    <Col className="col-auto tile border border-primary bg-white rounded rounded-3 p-2 d-flex flex-column" style={{ maxWidth: '225px' }}>
      <div className="d-flex justify-content-between">
        <Typography Variant='h6' text={'GENERIC.LABELS.PRESENTING'} className="mb-0 me-1" />
        <Button id="cancel-present-btn" color="danger" className="lh-1 p-0 m-0" onClick={handleClear}>
          <i className="bx bx-x font-size-15 align-middle" />
        </Button>
        <UncontrolledTooltip fade={false} placement="top" target={'cancel-present-btn'}>
          <Typography Variant='span' text={'GENERIC.BUTTONS.CANCEL'} />
        </UncontrolledTooltip>
      </div>

      {activePresentWindow.window === presentTypes.DETAILS ? <React.Fragment>
        <span className="text-muted text-truncate">{activePresentWindow.name}</span>
        <img
          onClick={handleVisitDetails}
          alt={activePresentWindow.name}
          src={activePresentWindow.img}
          className="m-auto h-50"
          style={{ objectFit: "contain", cursor: 'pointer' }}
        />
      </React.Fragment>
        :
        <Typography Variant='h6' text={'GENERIC.LABELS.CATALOGUE'} className="m-auto" />}
    </Col>
  )
}
export default PresentationTile;

