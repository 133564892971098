import { useCallback, useState } from "react";
import { Look } from "../../interfaces/Look";

const useLookModalDetails = (): [boolean, Look | null, (look: Look) => void, () => void] => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedLook, setSelectedLook] = useState<Look | null>(null);

  const openHandler = useCallback((look: Look): void => {
    setSelectedLook(look);
    setOpen(true);
  }, []);

  const closeHandler = useCallback((): void => {
    setOpen(false);
    setSelectedLook(null);
  }, []);

  return [open, selectedLook, openHandler, closeHandler];
};
export default useLookModalDetails;