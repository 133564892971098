import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import CallLayout from "../layouts/CallLayout";
import MainLayout from "../layouts/MainLayout";
import NotFoundPage from '../pages/404';
import BrowserUnsupported from "../pages/browserUnsupported";
import ConfirmationPage from '../pages/ConfirmationPage';
import EntryUser from "../pages/EntryUser";
import Login from '../pages/Login';
import MeetingPage from "../pages/MeetingPage";
import PasswordRecoveryPage from '../pages/PasswordRecover';
import CancelPage from '../pages/PaymentCancelPage';
import SuccessPage from '../pages/PaymentSuccessPage';
import ProductDetailsPage from "../pages/ProductDetailsPage";
import RemovedFromCall from '../pages/RemovedFromCall';
import Showroom from '../pages/ShowroomPage';
import MqttProvider from '../providers/Mqtt';
import AuthRoute from './AuthRoute';
// import OrderStatusPage from '../pages/OrderStatusPage';
import { userRoles } from '../constants/users';
import MeetingGuard from '../layouts/MeetingGuard';
import TenancyLayout from '../layouts/TenancyLayout';
import EntryGuest from '../pages/EntryGuest';
import MeetingConfirmation from '../pages/MeetingConfirmation';
import ResetPassword from '../pages/PasswordReset';
import MobileGuard from './MobileGuard';

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/confirmation/:confirmationId" element={<ConfirmationPage />} />
        <Route path="/passwordreset" element={<PasswordRecoveryPage />} />
        <Route path="/removed" element={<RemovedFromCall />} />
        <Route path="/passwordreset/:resetId" element={<ResetPassword />} />
        <Route path="/consumer/confirm/:meetingId" element={<MeetingConfirmation />} />

        <Route element={<AuthRoute allowedRoles={[userRoles.CONSUMER, userRoles.STYLIST]} />} >
          <Route element={<MobileGuard />}>
            <Route element={<TenancyLayout />}>
              <Route element={<MeetingGuard />}>
                <Route element={<MqttProvider />}>
                  <Route element={<CallLayout />}>

                    <Route path="/meeting/:meetingId" element={<Outlet />}>
                      <Route index element={<MeetingPage />} />
                      <Route path="details/:productId" element={<ProductDetailsPage />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route element={<AuthRoute allowedRoles={['stylist']} />}>
        <Route element={<TenancyLayout />}>

          <Route element={<MeetingGuard />}>
            <Route element={<CallLayout />}>
              <Route path="/showroom/:meetingId" element={<Showroom />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<AuthRoute allowedRoles={['stylist']} allowedToken={true} />}>
        <Route element={<TenancyLayout />}>

          <Route path="/stylist/:meetingId" element={<EntryUser />} />
        </Route>
      </Route>

      <Route element={<AuthRoute allowedRoles={['consumer']} allowedToken={true} />}>
        <Route element={<TenancyLayout />}>

          <Route path="/consumer/:meetingId" element={<EntryUser />} />
        </Route>
      </Route>

      <Route path="/consumer/:meetingId/guest/:guestToken" element={<EntryGuest />} />


      <Route path="/success" element={<SuccessPage />} />
      <Route path="/cancel" element={<CancelPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/browser-unsupported" element={<BrowserUnsupported />} />
      <Route path="/" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
export default MainRoutes;