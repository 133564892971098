import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import i18n from './i18n';
import ScreenProvider from "./providers/ScreenSize";
import store, { persistor } from './store/index';

const root = document.getElementById("root");

const isIOS = /(MacIntel|Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

if (!isIOS) root?.classList.add('windows-scrollbar');

// SALES-V2: Call in redux update
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <ScreenProvider>
            <App />
          </ScreenProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  root
);