import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { userRoles } from "../../constants/users";
import { capitalizeFirstLetter } from "../../helpers/utils";
import { MeetingState, MeetingUpdateRequest } from "../../interfaces/Meeting";
import { createAppAsyncThunk } from "../extras";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const getMeeting = createAppAsyncThunk(
  "meeting/getMeeting",
  async ({ meetingId }: { meetingId: string }, { rejectWithValue, getState }) => {
    try {
      const response: any = await api.MeetingService.getMeeting(meetingId);
      const role = getState().auth.userType;
      return {
        callUrl: role === userRoles.STYLIST ? response.stylist_meetingUrl : response.customer_meetingUrl,
        meetingId,
        customerProfile: response.customerId,
        ownerProfile: response.ownerId,
        startDate: response.startDate,
        finishDate: response.finishDate,
        notes: response?.notes ? response?.notes : undefined,
        endingNotes: response?.endingNotes ? response.endingNotes : '',
        products: response?.products || [],
        cartId: response.cartId,
        orderId: response.orderId,
        looks: response.looks || []
      };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const updateMeeting = createAppAsyncThunk(
  "meeting/updateMeeting",
  async ({ meetingId, payload }: MeetingUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await api.MeetingService.updateMeeting({ meetingId, payload });
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const createMeetingLook = createAppAsyncThunk(
  "meeting/createMeetingLook",
  async ({ look }: { look: string }, { rejectWithValue, getState }) => {
    try {
      const meetingId = getState().meeting.meetingId || "";
      const response = await api.MeetingService.createMeetingLook(meetingId, look);
      return response;
    }
    catch (rejected: any) {
      rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
);

const initialState: MeetingState = {
  meetingLoading: false,
  meetingId: undefined,
  callUrl: undefined,
  startDate: undefined,
  finishDate: undefined,
  endingNotes: '',
  notes: undefined,
  mainCustomer: {
    type: userRoles.CONSUMER,
    firstName: undefined,
    lastName: undefined,
    profileId: undefined,
    initials: undefined,
    fullName: undefined,
    userId: undefined
  },
  owner: {
    type: userRoles.STYLIST,
    firstName: undefined,
    lastName: undefined,
    id: undefined,
    social: undefined,
    initials: undefined,
    fullName: undefined
  }
}

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Retrieve Meeting ---- */
      .addCase(getMeeting.pending, (state) => {
        state.meetingLoading = true;
      })
      .addCase(getMeeting.fulfilled, (state, action) => {
        state.meetingLoading = false;
        state.callUrl = action.payload.callUrl;
        state.meetingId = action.payload.meetingId;
        state.startDate = action.payload.startDate;
        state.endingNotes = action.payload.endingNotes;
        state.notes = action.payload.notes;

        // TODO: optimize checks

        state.mainCustomer.firstName = action.payload?.customerProfile?.firstname || 'Guest';
        state.mainCustomer.lastName = action.payload?.customerProfile?.lastname || "";
        // TODO: make a check for one name only if possible
        state.mainCustomer.fullName = state.mainCustomer.firstName + " " + state.mainCustomer.lastName

        state.mainCustomer.initials = (action.payload?.customerProfile?.firstname?.slice(0, 1) + action.payload?.customerProfile?.lastname?.slice(0, 1)) || 'G';
        state.mainCustomer.profileId = action.payload?.customerProfile?._id;
        state.mainCustomer.address = action.payload?.customerProfile?.address;
        state.mainCustomer.description = action.payload?.customerProfile?.description;
        state.mainCustomer.userId = {
          role: action.payload?.customerProfile?.userId?.role,
          id: action.payload?.customerProfile?.userId?._id,
          email: action.payload?.customerProfile?.userId?.email
        };

        state.owner.firstName = action.payload?.ownerProfile?.firstname;
        state.owner.lastName = action.payload?.ownerProfile?.lastname;
        state.owner.fullName = `${action.payload?.ownerProfile?.firstname + " " + action.payload?.ownerProfile?.lastname}` || capitalizeFirstLetter(userRoles.STYLIST);
        state.owner.initials = action.payload?.ownerProfile?.firstname.slice(0, 1) + action.payload?.ownerProfile?.lastname.slice(0, 1) || 'S';

        state.owner.social = action.payload?.ownerProfile?.social;
        state.owner.id = action.payload?.ownerProfile._id;
        state.owner.description = action.payload?.ownerProfile?.description;
        state.owner.avatar = {
          sizes: {
            150: {
              url: action.payload.ownerProfile.avatar?.sizes?.['150']?.url
            }
          }
        }
        state.owner.banner = {
          sizes: {
            original: {
              url: action.payload.ownerProfile.banner?.sizes?.original?.url
            }
          }
        }
      })
      .addCase(getMeeting.rejected, (state) => {
        state.meetingLoading = false;
      })

      /* ---- Update Meeting ---- */
      .addCase(updateMeeting.pending, () => { })
      .addCase(updateMeeting.fulfilled, (state, action) => {
        if (action.meta.arg.payload.endingNotes) {
          state.endingNotes = action.meta.arg.payload.endingNotes;
        }
        if (action.meta.arg.payload.notes) {
          state.notes = action.meta.arg.payload.notes;
        }
      })
      .addCase(updateMeeting.rejected, () => { })

      /* ---- Create Meeting Look ---- */
      .addCase(createMeetingLook.pending, (state) => { })
      .addCase(createMeetingLook.fulfilled, (state) => { })
      .addCase(createMeetingLook.rejected, (state) => { })



      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        // state.callUrl = initialState.callUrl;
        // state.mainCustomer = initialState.mainCustomer;
        // state.meetingId = initialState.meetingId;
        // state.owner = initialState.owner;
        // state.startDate = initialState.startDate;
        // state.finishDate = initialState.finishDate;
      })

      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.callUrl = initialState.callUrl;
        state.mainCustomer = initialState.mainCustomer;
        state.meetingId = initialState.meetingId;
        state.owner = initialState.owner;
        state.startDate = initialState.startDate;
        state.finishDate = initialState.finishDate;
        state.endingNotes = initialState.endingNotes;
        state.notes = initialState.notes;
      })

      /* ---- SHARED: Logout ---- */
      .addCase(resetState, (state) => {
        state.callUrl = initialState.callUrl;
        state.mainCustomer = initialState.mainCustomer;
        state.meetingId = initialState.meetingId;
        state.owner = initialState.owner;
        state.startDate = initialState.startDate;
        state.finishDate = initialState.finishDate;
        state.endingNotes = initialState.endingNotes;
        state.notes = initialState.notes;
      })
  },
})
export default meetingSlice.reducer;