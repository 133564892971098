import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useError from "../useError";
import useModal from "../useModal";
import { useAppDispatch, useAppSelector } from "../../store";
import { deleteLook, getLooks } from "../../store/features/looks";
import { Look } from "../../interfaces/Look";
import useMqttMessage from "../mqtt/useMessage";
import { MQTT_VIEW_LOOKS } from "../../interfaces/Mqtt";

const useDeleteLook = (): [
  string,
  boolean,
  boolean,
  (look: Look) => void,
  () => void,
  () => void
] => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sendMqttMessage = useMqttMessage();
  const { handleError } = useError();
  const [look, setLook] = React.useState<Look | undefined>();
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const activePresentLook = useAppSelector((state) => state.appInterface.activePresentLook);

  const [isModalOpen, openModal, closeModal] = useModal();

  const openConfirmationModal = React.useCallback(
    (look: Look) => {
      setLook(look);
      openModal();
    },
    [openModal]
  );

  const handleDeletion = React.useCallback(async () => {
    try {
      setDeleting(true);

      await dispatch(deleteLook(look?._id ?? "")).unwrap();

      setDeleting(false);
      toast.info(t("TOASTS.OUTFITS.REMOVED"));
      if (look?._id === activePresentLook.split('_')[1]) {
        sendMqttMessage({ type: MQTT_VIEW_LOOKS, payload: { toggleLooks: false, lookId: undefined } });
      }
      closeModal();

      await dispatch(getLooks()).unwrap();
    } catch (error) {
      setDeleting(false);
      handleError(error);
    }
  }, [look, activePresentLook, sendMqttMessage, closeModal, handleError, t, dispatch]);

  return [
    look?.title ?? "",
    isModalOpen,
    deleting,
    openConfirmationModal,
    closeModal,
    handleDeletion,
  ];
};
export default useDeleteLook;
