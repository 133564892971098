import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import CatalogToggleBtn from '../components/ActionMenu/CatalogToggle';
import StylistActionMenu from '../components/ActionMenu/StylistActionsMenu';
import UserActionsMenu from '../components/ActionMenu/UserActionsMenu';
import ParticipantGrid from '../components/Call/ParticipantGrid';
import ChatFab from '../components/Chat/ChatFab';
import EarlyJoinScreen from '../components/Screen/EarlyJoinScreen';
import EndCallScreen from '../components/Screen/EndCallScreen';
import LoadingScreen from '../components/Screen/LoadingScreen';
import SessionExpiredScreen from '../components/Screen/SessionExpired';
import Sidebar from '../components/Sidebar';
import StatusIndicators from '../components/StatusIndicators';
import { userRoles } from '../constants/users';
import { useAppDispatch, useAppSelector } from '../store';
import { setSidebarCollapsed } from '../store/features/interface';

const TabletLayout: React.FC = ({ children }) => {
  const { sidebarCollapsed, catalogRightsToggled } = useAppSelector((state) => state.appInterface);
  const dailyCallState = useAppSelector((state) => state.call.dailyMeetingState);
  const { userType, isShowroom } = useAppSelector((state) => state.auth);
  const startDate = useAppSelector((state) => state.meeting.startDate);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const toggleCollapsibleSidebar = React.useCallback(() => dispatch(setSidebarCollapsed()), [dispatch]);

  return (
    <React.Fragment>
      {dailyCallState !== 'joined-meeting' ? (
        <React.Fragment>
          {(dailyCallState === 'loading' || dailyCallState === 'joining-meeting') && <LoadingScreen />}
          {dailyCallState === 'left-meeting' && <EndCallScreen />}
          {dailyCallState === 'error' &&
            <React.Fragment>
              {startDate && Date.parse(startDate) > Date.now() ? <EarlyJoinScreen /> : <SessionExpiredScreen />}
            </React.Fragment>
          }
        </React.Fragment>
      ) : (
        <React.Fragment>

          <Row className="action-menu">
            <Col md={sidebarCollapsed ? '12' : '8'} className="d-flex my-auto">
              <UserActionsMenu />
              {(userType === userRoles.STYLIST && !location.pathname.includes('/showroom')) && <StylistActionMenu />}
              {(userType === userRoles.CONSUMER && catalogRightsToggled) && <div className="d-flex w-50 justify-content-center">
                <CatalogToggleBtn />
              </div>}
            </Col>
          </Row>

          <Row className="position-relative core-content">
            {children}
            {!isShowroom && <React.Fragment>
              <Col md={4} className={classnames('h-100 sidebar-raise', { 'd-none': sidebarCollapsed, 'd-block': !sidebarCollapsed })}>
                <Sidebar />
              </Col>
              {userType === userRoles.STYLIST && <Button id="toggle-sidebar-btn" className="position-absolute top-50 end-0 collapse-button me-n4 outline-none bg-white  my-auto shadow rounded-none" onClick={toggleCollapsibleSidebar}>
                <i className={classnames('font-size-18 text-primary fa', { 'fa-angle-left': sidebarCollapsed, 'fa-angle-right': !sidebarCollapsed })} />
                <UncontrolledTooltip fade={false} placement="left" target="toggle-sidebar-btn">
                  {sidebarCollapsed ? t("GENERIC.BUTTONS.SIDEBAR_SHOW") : t("GENERIC.BUTTONS.SIDEBAR_HIDE")}
                </UncontrolledTooltip>
              </Button>}
            </React.Fragment>}
          </Row>
          <ChatFab />
          {!isShowroom && <StatusIndicators />}
          <ParticipantGrid />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default TabletLayout;

