import React from "react";
import { Table } from "reactstrap";
import LooksProductListItem from "./ProductListItem";
import { LookProduct } from "../../interfaces/Product";
import { useAppSelector } from "../../store";

interface PropTypes {
  products: LookProduct[];
  onRemove: (lookProductId: string) => void;
}

const LooksProductList: React.FC<PropTypes> = ({ products, onRemove }) => {
  const userType = useAppSelector((state) => state.auth.userType);
  return (
    <div className="table-responsive">
      <Table className="table align-middle mb-0 table-nowrap">
        <tbody>
          {products.map((p: LookProduct, i: number) => (
            <LooksProductListItem key={i} product={p} onRemove={onRemove} userType={userType} index={i} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default LooksProductList;
