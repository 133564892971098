import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import logo from "../../assets/images/logo-180x180.png";
import profile from "../../assets/images/profile-img.png";

interface PropTypes {
  title: string;
  description: string;
  children?: React.ReactNode;
  image?: string;
}

const EligoCard: React.FC<PropTypes> = ({ title, description, children, image = profile }) => {
  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs={7}>
            <div className="text-primary p-4 mb-1">
              <h4 className="text-primary text-nowrap">{title}</h4>
              <p className="font-size-15 fw-normal text-nowrap mb-5">{description}</p>
            </div>
          </Col>
          <Col xs={5} className="align-self-end">
            <img src={image} alt="" className="img-fluid py-1 px-2" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <div className="auth-logo">
          <div className="avatar-md profile-user-wid mb-4">
            <span className="avatar-title rounded-circle bg-light">
              <img src={logo} alt="Eligo" className="rounded-circle" height="34" />
            </span>
          </div>
        </div>
        <div className="p-2">
          {children}
        </div>
      </CardBody>
    </Card>
  );
};

export default EligoCard;
