/* eslint-disable no-underscore-dangle */
declare var window: Partial<
  Window &
  typeof globalThis & {
    _env_: any;
  }
>;

/* TBD: use .env file to set the env variables */
const {
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH,
  REACT_APP_ID,
  REACT_APP_NAME,
  REACT_APP_VERSION,
  REACT_APP_PAYMENT_REDIRECT_URL,
  REACT_APP_STYLIST_URL,
  REACT_APP_ORDER_POLL_LIMIT,
  REACT_APP_ORDER_POLL_INTERVAL,
  REACT_APP_ANALYTICS_ID,
  REACT_APP_CONTACT_EMAIL,
  REACT_APP_PRODUCT_DESCRIPTION_LIMIT
} = window._env_;

console.log(
  "ENV:",
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH,
  REACT_APP_ID,
  REACT_APP_PAYMENT_REDIRECT_URL,
  REACT_APP_STYLIST_URL,
  REACT_APP_ORDER_POLL_LIMIT,
  REACT_APP_ORDER_POLL_INTERVAL
);

// API domain
const api = {
  protocol: REACT_APP_API_PROTOCOL,
  host: REACT_APP_API_HOST,
  port: REACT_APP_API_PORT,
  basePath: REACT_APP_API_BASEPATH,
};

const applicationId = REACT_APP_ID;

const payment = {
  redirectUrl: REACT_APP_PAYMENT_REDIRECT_URL,
};

const contactEmail = REACT_APP_CONTACT_EMAIL;

// Analytics
const analytics = {
  id: REACT_APP_ANALYTICS_ID
};


const stylistAppUrl = REACT_APP_STYLIST_URL;

const defaultPollMaxDuration = 180000;
const defaultPollInterval = 5000;
const defaultProductDescriptionLimit = 150;

const productDescriptionLimit = Number(REACT_APP_PRODUCT_DESCRIPTION_LIMIT) > 1 ? Number(REACT_APP_PRODUCT_DESCRIPTION_LIMIT) : defaultProductDescriptionLimit;

const orderPollMaxDuration = REACT_APP_ORDER_POLL_LIMIT * 60 * 1000 || defaultPollMaxDuration;
const orderPollInterval = REACT_APP_ORDER_POLL_INTERVAL * 1000 || defaultPollInterval;

const applicationCopyright = [
  "©",
  new Date().getFullYear(),
  ["-", REACT_APP_NAME || "PLATFORM"].join(""),
  ["V", REACT_APP_VERSION].join(""),
].join(" ");

export { api, productDescriptionLimit, contactEmail, applicationId, payment, applicationCopyright, stylistAppUrl, orderPollMaxDuration, orderPollInterval, analytics };
