import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input } from "reactstrap";

interface SearchInputTypes {
  initialValue: string;
  disabled?: boolean;
  onSearch: (value: string) => void;
}

const SearchInput: React.FC<SearchInputTypes> = ({ initialValue, disabled, onSearch, }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSearch(value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Form
      className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center"
      onSubmit={handleSubmit}
    >
      <div className="search-box me-5">
        <div className="position-relative">
          <Input
            value={value}
            className="form-control border-1"
            type="text"
            placeholder={t("GENERIC.INPUTS.SEARCH.PLACEHOLDER") + "..."}
            disabled={disabled}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>
    </Form>
  );
};

export default SearchInput;
