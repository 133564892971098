import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { DetailsState } from "../../interfaces/Details";
import { CartProductVariant } from "../../interfaces/Product";
import { createAppAsyncThunk } from "../extras";
import { endCallState, joinCallState, resetState } from "./sharedActions";

export const getDetails = createAppAsyncThunk(
  // export const getDetails = createAsyncThunk<{ product: Product, variants: CartProductVariant[] }, string, { state: RootState }>(
  "details/getDetails",
  async (sku: string, { rejectWithValue, getState }) => {
    try {

      const itemVariants = getState().cart.cartProducts.find((product) => product.baseSku === sku)?.variants;

      const response = await api.ProductService.getProduct(sku);
      return {
        product: response,
        variants: itemVariants ? itemVariants : []
      };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: DetailsState = {
  product: undefined,
  detailsLoading: false,
  showConfigurator: false,
  configuratorData: undefined,
  hasValidConfiguratorConfig: false,
  configuratorVariants: [],
  configuratorStep: 0,
  configuratorActivePanel: ""
  // TODO: keep last visited productSKU to avoid refetching if it's  the same
}

export const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    toggleConfigurator: (state, action: PayloadAction<boolean>) => {
      if (state.hasValidConfiguratorConfig)
        state.showConfigurator = action.payload;
      else
        state.showConfigurator = false;

      state.configuratorStep = 0;
    },
    updateConfiguratorVariant: (state, action: PayloadAction<CartProductVariant>) => {
      const variant = state.configuratorVariants.find((item) => item.name === action.payload.name);
      if (variant) {
        state.configuratorVariants = state.configuratorVariants.map((variant) => {
          if (variant.name === action.payload.name) {
            return { ...variant, value: action.payload.value }
          } else
            return variant;
        })
      } else {
        state.configuratorVariants = [...state.configuratorVariants, action.payload];
      }
    },
    setConfiguratorActivePanel: (state, action: PayloadAction<string>) => {
      state.configuratorActivePanel = action.payload;
    },
    setConfiguratorVariants: (state, action: PayloadAction<CartProductVariant[]>) => {
      state.configuratorVariants = action.payload;
    },
    setConfiguratorStep: (state, action: PayloadAction<number>) => {
      state.configuratorStep = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetails.pending, (state) => {
        state.product = initialState.product;
        state.showConfigurator = initialState.showConfigurator;
        state.configuratorData = initialState.configuratorData;
        state.hasValidConfiguratorConfig = initialState.hasValidConfiguratorConfig;
        state.configuratorVariants = initialState.configuratorVariants;
        state.configuratorStep = initialState.configuratorStep;
        state.configuratorActivePanel = initialState.configuratorActivePanel;
        state.detailsLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.product = action.payload.product;
        state.detailsLoading = false;

        if (action.payload.product.el_configurable && action.payload.product.el_configuration) {
          try {
            state.configuratorData = JSON.parse(JSON.stringify(action.payload.product.el_configuration));
            state.hasValidConfiguratorConfig = true;
            state.configuratorVariants = action.payload.variants;
          } catch (error) {
            state.hasValidConfiguratorConfig = initialState.hasValidConfiguratorConfig;
          }
        }
      })
      .addCase(getDetails.rejected, (state) => {
        state.detailsLoading = false;
      })

      /* ---- End call ---- */
      .addCase(endCallState, (state) => {
        state.product = initialState.product;
        state.detailsLoading = initialState.detailsLoading;
        state.showConfigurator = initialState.showConfigurator;
        state.configuratorData = initialState.configuratorData;
        state.hasValidConfiguratorConfig = initialState.hasValidConfiguratorConfig;
        state.configuratorVariants = initialState.configuratorVariants;
        state.configuratorStep = initialState.configuratorStep;
      })

      /* ---- Join call ---- */
      .addCase(joinCallState, (state) => {
        state.product = initialState.product;
        state.detailsLoading = initialState.detailsLoading;
        state.showConfigurator = initialState.showConfigurator;
        state.configuratorData = initialState.configuratorData;
        state.hasValidConfiguratorConfig = initialState.hasValidConfiguratorConfig;
        state.configuratorVariants = initialState.configuratorVariants;
        state.configuratorStep = initialState.configuratorStep;
        state.configuratorActivePanel = initialState.configuratorActivePanel;
      })

      /* ---- Log out ---- */
      .addCase(resetState, (state) => {
        state.product = initialState.product;
        state.detailsLoading = initialState.detailsLoading;
        state.configuratorData = initialState.configuratorData;
        state.showConfigurator = initialState.showConfigurator;
        state.hasValidConfiguratorConfig = initialState.hasValidConfiguratorConfig;
        state.configuratorVariants = initialState.configuratorVariants;
        state.configuratorStep = initialState.configuratorStep;
        state.configuratorActivePanel = initialState.configuratorActivePanel;
      })
  }
})
export const { toggleConfigurator, setConfiguratorStep, setConfiguratorActivePanel, setConfiguratorVariants, updateConfiguratorVariant } = detailsSlice.actions;
export default detailsSlice.reducer;