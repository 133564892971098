import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { userRoles } from "../../constants/users";
import { CreateLookPayload, Look, LookDetailsView, LookProductPayload, LookState, LookView } from "../../interfaces/Look";
import { LookProduct } from "../../interfaces/Product";
import { createAppAsyncThunk } from "../extras";
import { createMeetingLook } from "./meeting";
import { endCallState, joinCallState, resetState } from "./sharedActions";

// TODO: right now, we retrieve meeting twice to get the looks
export const getLooks = createAppAsyncThunk(
  "looks/getLooks",
  async (_, { rejectWithValue, getState }) => {
    try {
      // const search = getState().looks.searchLooks || "";
      // const response = await api.LookService.getLooks(search);
      const meetingId = getState().meeting.meetingId || "";
      const response = await api.MeetingService.getMeeting(meetingId);
      return response.looks;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
);

export const getLook = createAppAsyncThunk(
  "looks/getLook",
  async ({ lookId }: { lookId: string }, { rejectWithValue, getState }) => {
    try {
      const response = await api.LookService.getLook(lookId);
      return response;
    } catch (rejected: any) {
      let userType = getState().auth.userType;
      if (typeof rejected === 'object' && userType) {
        rejected['userType'] = userType;
      }
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
);

export const createLook = createAppAsyncThunk(
  "looks/createLook",
  async (payload: CreateLookPayload, { rejectWithValue }) => {
    try {
      const response = await api.LookService.createLook(payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const deleteLook = createAppAsyncThunk(
  "looks/deleteLook",
  async (lookId: string, { rejectWithValue }) => {
    try {
      const response = await api.LookService.deleteLook(lookId);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const addLookItem = createAppAsyncThunk(
  "looks/addItem",
  async ({ lookId, payload }: { lookId: string, payload: LookProductPayload }, { rejectWithValue }) => {
    try {
      const response = await api.LookService.createLookProduct(lookId, payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const updateLookItem = createAppAsyncThunk(
  "looks/updateItem",
  async ({ lookId, productId, payload }: { lookId: string, productId: string, payload: LookProductPayload }, { rejectWithValue }) => {
    try {
      const response = await api.LookService.updateLookProduct(lookId, productId, payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const removeLookItem = createAppAsyncThunk(
  "looks/removeItem",
  async ({ lookId, productId }: { lookId: string, productId: string }, { rejectWithValue }) => {
    try {
      const response = await api.LookService.deleteLookProduct(lookId, productId);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

const initialState: LookState = {
  customerId: undefined,
  customerName: undefined,
  lookView: "LOOK_LIST",
  lookDetailsView: "LOOK_PRODUCTS_LIST",
  looks: [],
  lookProductsInitial: [],
  lookProductsCurrent: [],
  looksHasChanges: false,
  looksRetrieved: false,
  selectedLookId: undefined,
  selectedLook: undefined,
  lookRetrieved: false,
  looksLoading: false,
  looksUpdating: false,
  selectedItem: "",
  searchLooks: ""
};

export const lookSlice = createSlice({
  name: "look",
  initialState,
  reducers: {
    setLookView: (state, action: PayloadAction<LookView>) => {
      state.lookView = action.payload;
    },
    setLookDetailsView: (state, action: PayloadAction<LookDetailsView>) => {
      state.lookDetailsView = action.payload;
    },
    setSelectedLookId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedLookId = action.payload;
    },
    setSelectedLook: (state, action: PayloadAction<Look | undefined>) => {
      state.selectedLook = action.payload;
    },
    setLooksRetrieved: (state, action: PayloadAction<boolean>) => {
      state.looksRetrieved = action.payload;
    },
    setSelectedItem: (state, action: PayloadAction<string>) => {
      state.selectedItem = action.payload;
    },
    setSearchLooks: (state, action: PayloadAction<string>) => {
      state.searchLooks = action.payload;
    },
    setLookCurrentProducts: (state, action: PayloadAction<LookProduct[]>) => {
      state.lookProductsCurrent = action.payload;
      state.looksHasChanges = true;
      state.lookDetailsView = 'LOOK_PRODUCTS_COMPOSER';
      // state.looksHasChanges = !([
      //   ...current(state.lookProductsInitial).filter((p) => !action.payload.includes(p)),
      //   ...action.payload.filter((p) => !state.lookProductsInitial.includes(p))
      // ].length === 0)
    },
    resetLookCurrentProducts: (state) => {
      state.lookProductsCurrent = state.lookProductsInitial;
      state.looksHasChanges = false;
      state.selectedItem = "";
    },
    setLooksUpdating: (state, action: PayloadAction<boolean>) => {
      state.looksUpdating = action.payload; // NOTE: this was decided to be kept, it was deemed impractical to implement an endpoint to update array items in bulk
    }
  },
  extraReducers: (builder) => {
    builder
      /* ---- Create Look ---- */
      .addCase(createLook.pending, (state) => { })
      .addCase(createLook.fulfilled, (state) => { })
      .addCase(createLook.rejected, (state) => { })

      /* ---- Delete Look ---- */
      .addCase(deleteLook.pending, (state) => { })
      .addCase(deleteLook.fulfilled, (state) => { })
      .addCase(deleteLook.rejected, (state) => { })

      /* ---- Get Looks ---- */
      .addCase(getLooks.pending, (state) => {
        state.looksLoading = true;
      })
      .addCase(getLooks.fulfilled, (state, action) => {
        state.looks = action.payload;
        state.looksRetrieved = true;
        state.looksLoading = false;
      })
      .addCase(getLooks.rejected, (state) => {
        state.looksLoading = false;
      })

      /* ---- Get Look ---- */
      .addCase(getLook.pending, (state) => {
        state.looksLoading = true;
        state.selectedLook = undefined;
      })
      .addCase(getLook.fulfilled, (state, action: PayloadAction<Look>) => {
        state.selectedLook = action.payload;
        state.selectedLookId = action.payload?._id;
        state.lookRetrieved = true;
        state.looksLoading = false;
        state.lookProductsInitial = action.payload.products;
        state.lookProductsCurrent = action.payload.products;
        state.looksHasChanges = false;
      })

      .addCase(getLook.rejected, (state, action: PayloadAction<any, string, { arg: { lookId: string } }, never>) => {
        state.looksLoading = false;
        state.selectedLookId = undefined;
        if (action.payload?.userType === userRoles.STYLIST)
          state.lookView = 'LOOK_LIST';
      })

      /* ---- Add Look Item ---- */
      .addCase(addLookItem.pending, (state) => {
        // state.looksUpdating = true 
      })
      .addCase(addLookItem.fulfilled, (state) => {
        //  state.looksUpdating = false
      })
      .addCase(addLookItem.rejected, (state) => {
        // state.looksUpdating = false 
      })

      /* ---- Update Look Item ---- */
      .addCase(updateLookItem.pending, (state) => {
        // state.looksUpdating = true
      })
      .addCase(updateLookItem.fulfilled, (state) => {
        //  state.looksUpdating = false
      })
      .addCase(updateLookItem.rejected, (state) => {
        // state.looksUpdating = false
      })

      /* ---- Remove Look Item ---- */
      .addCase(removeLookItem.pending, (state) => {
        //  state.looksUpdating = true 
      })
      .addCase(removeLookItem.fulfilled, (state) => {
        //  state.looksUpdating = false 
      })
      .addCase(removeLookItem.rejected, (state) => {
        // state.looksUpdating = false
      })


      /* ---- EXTERNAL: Meeting Look ---- */
      .addCase(createMeetingLook.pending, (state) => { state.looksLoading = true; })
      .addCase(createMeetingLook.fulfilled, (state) => { state.looksLoading = false; })
      .addCase(createMeetingLook.rejected, (state) => { state.looksLoading = false; })

      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        state.customerId = initialState.customerId;
        state.customerName = initialState.customerName;
        state.lookView = initialState.lookView;
        state.lookDetailsView = initialState.lookDetailsView;
        state.looks = initialState.looks;
        state.lookProductsInitial = initialState.lookProductsInitial;
        state.lookProductsCurrent = initialState.lookProductsCurrent;
        state.looksHasChanges = initialState.looksHasChanges;
        state.looksRetrieved = initialState.looksRetrieved;
        state.selectedLookId = initialState.selectedLookId;
        state.selectedLook = initialState.selectedLook;
        state.lookRetrieved = initialState.lookRetrieved;
        state.looksLoading = initialState.looksLoading;
        state.looksUpdating = initialState.looksUpdating;
        state.selectedItem = initialState.selectedItem;
      })

      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.customerId = initialState.customerId;
        state.customerName = initialState.customerName;
        state.lookView = initialState.lookView;
        state.lookDetailsView = initialState.lookDetailsView;
        state.looks = initialState.looks;
        state.lookProductsInitial = initialState.lookProductsInitial;
        state.lookProductsCurrent = initialState.lookProductsCurrent;
        state.looksHasChanges = initialState.looksHasChanges;
        state.looksRetrieved = initialState.looksRetrieved;
        state.selectedLookId = initialState.selectedLookId;
        state.selectedLook = initialState.selectedLook;
        state.lookRetrieved = initialState.lookRetrieved;
        state.looksLoading = initialState.looksLoading;
        state.looksUpdating = initialState.looksUpdating;
        state.selectedItem = initialState.selectedItem;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.customerId = initialState.customerId;
        state.customerName = initialState.customerName;
        state.lookView = initialState.lookView;
        state.lookDetailsView = initialState.lookDetailsView;
        state.looks = initialState.looks;
        state.lookProductsInitial = initialState.lookProductsInitial;
        state.lookProductsCurrent = initialState.lookProductsCurrent;
        state.looksHasChanges = initialState.looksHasChanges;
        state.looksRetrieved = initialState.looksRetrieved;
        state.selectedLookId = initialState.selectedLookId;
        state.selectedLook = initialState.selectedLook;
        state.lookRetrieved = initialState.lookRetrieved;
        state.looksLoading = initialState.looksLoading;
        state.looksUpdating = initialState.looksUpdating;
        state.selectedItem = initialState.selectedItem;
      })
  },
});
export const {
  setLookView,
  setLookDetailsView,
  setSelectedLookId,
  setSelectedLook,
  setLooksRetrieved,
  setLookCurrentProducts,
  resetLookCurrentProducts,
  setSelectedItem,
  setSearchLooks,
  setLooksUpdating
} = lookSlice.actions;
export default lookSlice.reducer;
