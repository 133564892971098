import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { userRoles } from "../../constants/users";
import { constructProductMainImage, formatPrice } from "../../helpers/product";
import useAppNavigation from "../../hooks/useAppNavigation";
import useFavorite from "../../hooks/useFavorite";
import { UserType } from "../../interfaces/Auth";
import { LookProduct } from "../../interfaces/Product";
import FavouriteButton from "../Button/Favourite";
import SkeletonImage from "../SkeletonImage";

interface PropTypes {
  product: LookProduct;
  onRemove: (pId: string) => void;
  userType: UserType;
  index: number;
}

const LooksProductListItem: React.FC<PropTypes> = ({ product, onRemove, userType, index }) => {
  const { visitProductDetails } = useAppNavigation();
  const { isFavorite, toggleFavorite, likedProductsLoading } = useFavorite();
  const isInFavorites = React.useMemo(() => isFavorite(product.baseSku), [product, isFavorite]);
  const { t } = useTranslation();

  return (
    <tr onClick={() => visitProductDetails(encodeURIComponent(product.baseSku))} style={{ cursor: 'pointer' }}>
      <td className="w-25 p-1">
        <SkeletonImage
          loading="lazy"
          className="sidebar-product-img"
          skeletonClassName="sidebar-product-img"
          src={constructProductMainImage(product.details.product)}
          alt={product.details.product.name || "-"} />
      </td>
      <td className="w-75 p-1">
        <h5 className="font-size-13 text-wrap text-dark product-card-description">
          {product.details.product.name || "-"}
        </h5>
        <p className="mb-0 text-muted">{formatPrice(product.details.product.price_range.maximum_price.final_price.value)}</p>
      </td>
      <td className="w-auto p-1">
        <FavouriteButton
          id={`showcase-favorite-btn-${index}`}
          isFavorite={isInFavorites}
          onClick={(e) => { e.stopPropagation(); toggleFavorite(product.baseSku) }}
          disabled={likedProductsLoading}
          className="rounded-circle me-1 p-2"
          size="sm"
          tooltip={isInFavorites ? t("GENERIC.BUTTONS.REMOVE_FROM_FAVORITES") : t("GENERIC.BUTTONS.ADD_TO_FAVORITES")}
        />
        {userType === userRoles.STYLIST && <Button color="danger" size="sm" outline className="rounded-circle p-2" onClick={(e) => {
          e.stopPropagation();
          onRemove(product._id)
        }}>
          <i className="bx bx-trash align-middle font-size-18" />
        </Button>}
      </td>
    </tr>
  );
};

export default LooksProductListItem;
