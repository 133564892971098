import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { userRoles } from "../../constants/users";
import { ShowcaseState } from "../../interfaces/Showcase";
import { createAppAsyncThunk } from "../extras";
import { getMeeting } from "./meeting";
import { endCallState, joinCallState, resetState } from "./sharedActions";


export const getShowcaseItems = createAppAsyncThunk(
  "showcase/items/retrieve",
  async (_, { rejectWithValue, getState }) => {
    try {
      const meetingId = getState().meeting.meetingId;
      const userType = getState().auth.userType;
      const products = getState().showcase.products;
      const notificationCounter = getState().appInterface.showcaseNotificationCounter;
      const response = await api.MeetingService.getMeetingProducts(meetingId!);

      let counter = notificationCounter;
      if (userType === userRoles.CONSUMER) {
        if (products.length < response.length) {
          counter += response.length - products.length;
        }
      }

      return { products: response, notificationCounter: counter };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const removeShowcaseItem = createAppAsyncThunk(
  "showcase/item/remove",
  async ({ productId }: { productId: string }, { rejectWithValue, getState }) => {
    try {
      const meetingId = getState().meeting.meetingId;
      const response = await api.MeetingService.deleteMeetingProduct(meetingId!, productId);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const addShowcaseItem = createAppAsyncThunk(
  "showcase/item/add",
  async (payload: { baseSku: string, sku?: string }, { rejectWithValue, getState }) => {
    try {
      const meetingId = getState().meeting.meetingId;
      const response = await api.MeetingService.createMeetingProduct(meetingId!, payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const clearShowcaseItems = createAppAsyncThunk(
  "showcase/clear",
  async (_, { rejectWithValue, getState }) => {
    try {
      const meetingId = getState().meeting.meetingId;
      const response = await api.MeetingService.clearMeetingProducts(meetingId!);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: ShowcaseState = {
  showcaseInitialized: false,
  showcaseLoading: false,
  products: []
}

export const ShowcaseSlice = createSlice({
  name: 'showroom',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Get Showcase Items ---- */
      .addCase(getShowcaseItems.pending, (state) => {
        state.showcaseLoading = true;
      })
      .addCase(getShowcaseItems.fulfilled, (state, action) => {
        state.products = [...action.payload.products];
        state.showcaseLoading = false;
      })
      .addCase(getShowcaseItems.rejected, (state) => {
        state.showcaseLoading = false;
      })

      /* ---- Add Showcase Item ---- */
      .addCase(addShowcaseItem.pending, (state) => {
        state.showcaseLoading = true;
      })
      .addCase(addShowcaseItem.fulfilled, (state, action) => {
        state.products = [...action.payload.products];
        state.showcaseLoading = false;
      })
      .addCase(addShowcaseItem.rejected, (state) => {
        state.showcaseLoading = false;
      })

      /* ---- Remove Showcase Item ---- */
      .addCase(removeShowcaseItem.pending, (state) => {
        state.showcaseLoading = true;
      })
      .addCase(removeShowcaseItem.fulfilled, (state, action) => {
        state.products = [...state.products.filter((p) => p._id !== action.meta.arg.productId)];
        state.showcaseLoading = false;
      })
      .addCase(removeShowcaseItem.rejected, (state) => {
        state.showcaseLoading = false;
      })

      /* ---- Clear Showcase Items ---- */
      .addCase(clearShowcaseItems.pending, (state) => {
        state.showcaseLoading = true;
      })
      .addCase(clearShowcaseItems.fulfilled, (state) => {
        state.showcaseLoading = false;
        state.products = [];
      })
      .addCase(clearShowcaseItems.rejected, (state) => {
        state.showcaseLoading = false;
      })


      /* ---- EXTERNAL: Get Meeting ---- */
      .addCase(getMeeting.fulfilled, (state, action) => {
        state.products = [...action.payload.products];
        state.showcaseInitialized = true;
      })

      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        state.showcaseInitialized = initialState.showcaseInitialized;
        state.showcaseLoading = initialState.showcaseLoading;
        state.products = initialState.products;
      })

      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        state.showcaseInitialized = initialState.showcaseInitialized;
        state.showcaseLoading = initialState.showcaseLoading;
        state.products = initialState.products;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.showcaseInitialized = initialState.showcaseInitialized;
        state.showcaseLoading = initialState.showcaseLoading;
        state.products = initialState.products;
      })
  }
})
// export const { } = ShowcaseSlice.actions;
export default ShowcaseSlice.reducer;