import React from "react";
import classNames from "classnames";
import { logoDark } from "../../assets";

interface PropTypes {
	skeletonClassName?: string;
	skeletonStyle?: React.CSSProperties;
	animationEnabled?: boolean;
	defaultSrc?: string;
	onLoad?: () => void;
	onError?: () => void;
}

const SkeletonImage: React.FC<PropTypes & React.ImgHTMLAttributes<HTMLImageElement>> = ({
	src = logoDark,
	alt = "",
	title,
	style,
	height,
	width,
	className = "",
	skeletonClassName,
	skeletonStyle,
	animationEnabled = true,
	defaultSrc = "",
	onLoad,
	onError,
	...rest
}) => {
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);

	const handleLoad = () => {
		setLoading(false);
		if (onLoad) onLoad();
	};

	const handleError = () => {
		setError(true);
		if (onError) onError();
	};

	return (
		<React.Fragment>
			{loading && (
				<div
					className={classNames(
						"skeleton image",
						{ animation: animationEnabled },
						skeletonClassName
					)}
					style={{ ...skeletonStyle, height, width }}
				/>
			)}
			<img
				height={height}
				width={width}
				className={loading ? "hw-0 d-block" : className}
				src={!error ? src : defaultSrc}
				alt={alt}
				title={title}
				onLoad={handleLoad}
				onError={handleError}
				style={loading ? {} : style}
				{...rest}
			/>
		</React.Fragment>
	);
};

export default SkeletonImage;
