import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
interface PropTypes {
  disabled?: boolean;
  children: React.ReactNode;
  toggle: () => void;
  active: boolean,
}

const TabHeader: React.FC<PropTypes> = ({ children, disabled, active, toggle }) => {
  return (
    <NavItem>
      <NavLink disabled={disabled} className={`${active ? 'active text-primary' : 'bg-gradient text-muted bg-light'} position-relative ms-2 border-0 shadow-sm`} onClick={(toggle)}>
        {children}
      </NavLink>
    </NavItem>
  )
}
export default TabHeader;