import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface PropTypes {
  text: string;
}

const useCopyText = () => {
  const { t } = useTranslation();
  return React.useCallback(({ text }: PropTypes): void => {
    if (text) {
      navigator.clipboard.writeText(text);
      toast.success(t("TOASTS.LINK_COPY"));
    }
  }, [t]);
};

export default useCopyText;
