import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "./Draggable.scss";

interface PropTypes {
  enabled: boolean;
  message?: string;
  children: React.ReactNode;
  onDrop: (e: any) => void;
}

const DraggableDropArea: React.FC<PropTypes> = ({
  enabled,
  message = "",
  children,
  onDrop,
}) => {
  const { t } = useTranslation();
  const [hovering, setHovering] = React.useState<boolean>(false);

  const handleDragOver = (e: any) => {
    if (!enabled) return;

    e.preventDefault();
  };

  const handleDragEnter = (e: any) => {
    if (!enabled) return;

    e.preventDefault();

    setHovering(true);
  };

  const handleDragLeave = () => {
    if (!enabled) return;

    setHovering(false);
  };

  const handleDrop = (event: any) => {
    if (!enabled) return;

    setHovering(false);

    onDrop(event);
  };

  return (
    <div
      className="drop__area w-100"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
    >
      {enabled && (
        <div className="overlay__container" onDragLeave={handleDragLeave}>
          <div className="overlay__blur" />

          <div
            className={classNames("overlay__main", {
              "overlay__main--hovering": hovering,
            })}
          />

          <div className="overlay__content">
            <h4 className="text-muted">{t(message)}</h4>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default DraggableDropArea;
