import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface PropTypes {
  isOpen: boolean;
  toggleModal: () => void;
  confirm: () => void;
  loading?: boolean;
}

const ClearShowcaseModal: React.FC<PropTypes> = ({ loading, isOpen, toggleModal, confirm }) => {
  const { t } = useTranslation();

  const handleToggleModal = React.useMemo(() => loading ? undefined : () => toggleModal(), [loading, toggleModal]);

  return (
    <Modal isOpen={isOpen} size={'sm'} toggle={handleToggleModal}>
      <ModalHeader className="text-center" toggle={handleToggleModal}>
        {t("MODALS.CLEAR_SHOWCASE.HEADER")}
      </ModalHeader>
      <ModalBody className="text-center">
        {t("MODALS.CLEAR_SHOWCASE.SUBHEADER")}<br />
        <span className="h5">{t("GENERIC.LABELS.SHOWCASE")}</span> ?
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={handleToggleModal} disabled={loading}>
          {t("GENERIC.BUTTONS.CLOSE")}
        </Button>
        <Button color="danger" onClick={confirm} disabled={loading}>
          {t("GENERIC.BUTTONS.CLEAR")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ClearShowcaseModal;