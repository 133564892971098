import React from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { useAppSelector } from "../../store";
import MobileCartView from "../Mobile/MobileCartView";
import MobileLookView from "../Mobile/MobileLookView";
import MobileShowcaseView from "../Mobile/MobileShowcaseView";
import ProductCatalog from "../productCatalog";
import WaitingForParticipantsScreen from "../Screen/WaitingParticipantsScreen";
import Tile from "../Tile";

// SALES-V2: Call in redux update
const MainSection: React.FC = () => {
  const activeWindow = useAppSelector((state) => state.appInterface.activeWindow);
  const activeParticipant = useAppSelector((state) => state.call.activeParticipant);
  const { isMobile } = useScreenSize();

  return (
    <div className={`h-100 ${!isMobile ? 'rounded' : 'overflow-hidden'}`}>
      {activeWindow === "cart" && isMobile && <MobileCartView />}
      {activeWindow === "looks" && isMobile && <MobileLookView />}
      {activeWindow === "productCatalog" && <ProductCatalog />}
      {activeWindow === "showcase" && isMobile && <MobileShowcaseView />}
      {activeWindow === "video" && ((activeParticipant ? (
        <Tile
          id={activeParticipant.user_id}
          videoTrackState={activeParticipant.tracks.video}
          audioTrackState={activeParticipant.tracks.audio}
          isLocalPerson={activeParticipant.local}
          isMainScreen={true}
          name={activeParticipant.user_name}
          userData={activeParticipant?.userData || undefined}
          className="main"
        />
        // TODO: review prop boolean and className
      ) : (
        <WaitingForParticipantsScreen isMobile={isMobile} />
      )))}
    </div>
  );
};
export default MainSection;
