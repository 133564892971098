import React, { RefObject } from "react";

const useOnClickInside = (ref: RefObject<HTMLDivElement>, handler: Function) => {
  React.useEffect(() => {
    const listener = (event: any) => {
      if (
        ref.current &&
        ref.current.contains(event.target) &&
        (ref.current?.isSameNode(event.target))
      )
        handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickInside;
