import React from 'react'
import { MdFlipCameraIos } from 'react-icons/md';
import { useAppSelector } from '../../store';
import { ActionMenuButton } from '../Button/ActionMenu';

// SALES-V2: Call in redux update
const CameraFlipToggleBtn: React.FC = () => {
  const { dailyCallObject, dailyMeetingState, availableVideoDevices } = useAppSelector((state) => state.call);

  const handleFlipCamera = async (): Promise<void> => {
    try {
      await dailyCallObject?.cycleCamera();
    } catch (error) {
      console.log("Unable to change camera");
    }
  };

  return availableVideoDevices! >= 2 ? <ActionMenuButton
    id="flip-cam-btn"
    disabled={dailyMeetingState !== "joined-meeting"}
    icon={<MdFlipCameraIos size={20} className="text-white" />}
    color="secondary"
    onClick={handleFlipCamera}
    tooltip="GENERIC.BUTTONS.CAM_FLIP" /> : null
};
export default CameraFlipToggleBtn;