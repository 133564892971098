import React from 'react';
import { Button } from 'reactstrap';


interface PropTypes {
	items: number;
	position: number;
	onChange: (position: number) => void
}

const GalleryControls: React.FC<PropTypes> = ({ items, position, onChange }) => {
	const handlePrevious = React.useCallback(() => {
		if (position > 0) onChange(position - 1)
	}, [position, onChange]);
	const handleNext = React.useCallback(() => {
		if (position < items - 1) onChange(position + 1)
	}, [position, items, onChange]);


	const previousDisabled = React.useMemo(() => {
		return position === 0
	}, [position])
	const nextDisabled = React.useMemo(() => {
		return position >= items - 1;
	}, [position, items])

	if (items === 0) return null;

	return (
		<React.Fragment>
			<Button className={`position-absolute start-0 top-50 ms-1 rounded-circle p-1 shadow-none opacity-75 ${previousDisabled ? 'invisible' : 'visible'}`} disabled={previousDisabled} color="light" onClick={handlePrevious}>
				<i className="bx bx-left-arrow-alt font-size-20 align-middle" />
			</Button>
			<Button className={`position-absolute end-0 top-50 me-1 rounded-circle p-1 shadow-none opacity-75 ${nextDisabled ? 'invisible' : 'visible'}`} disabled={nextDisabled} color="light" onClick={handleNext}>
				<i className="bx bx-right-arrow-alt font-size-20 align-middle" />
			</Button>
		</React.Fragment>
	)
}
export default GalleryControls;