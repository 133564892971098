import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import jwt from "jsonwebtoken";
import { AuthState, LoginPayload } from "../../interfaces/Auth";
import { UserType } from "../../interfaces/Auth";
import { endCallState, joinCallState, resetState } from "./sharedActions";
import { memoPaths } from "../../constants/routes";
import { userRoles } from "../../constants/users";
import { createAppAsyncThunk } from "../extras";

export const loginUser = createAppAsyncThunk(
  "auth/loginUser",
  async ({ email, password }: LoginPayload, { rejectWithValue }) => {
    try {
      const response = await api.UserService.loginUser({
        email,
        password
      });
      let decodedToken: any = jwt.decode(response.data.data.token);
      let role: UserType = decodedToken?.user?.role;
      let userId: string = decodedToken?.user?.id;
      let profileId: string = decodedToken?.user?.profileId;
      return {
        ...response.data.data,
        userId,
        profileId,
        userType: role ? role : userRoles.CONSUMER
      };
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
);

export const loginGuest = createAppAsyncThunk(
  "auth/loginGuest",
  async ({ username }: { username: string }, { rejectWithValue }) => {
    try {
      const response = await api.UserService.loginGuest({ username, provider: 'mtgguest' });
      let decodedToken: any = jwt.decode(response.data.data.token);
      let role: UserType = decodedToken?.user?.role;
      let userId: string = decodedToken?.user?.id;
      let profileId: string = decodedToken?.user?.profileId;
      return {
        ...response.data.data,
        userId,
        profileId,
        userType: role ? role : userRoles.CONSUMER,
      }
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
);

export const getUserDetails = createAppAsyncThunk(
  "auth/getUser",
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const response: any = await api.UserService.getUserDetails(userId);
      return { profile: response?.data?.data?.profiles?.[0]?.profileId, role: response?.data?.data?.role }
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const likeProduct = createAppAsyncThunk(
  "auth/likeProduct",
  async ({ baseSku, sku }: { baseSku: string, sku?: string }, { rejectWithValue }) => {
    try {
      const response: any = await api.UserService.likeProduct({ baseSku, sku });
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

export const unlikeProduct = createAppAsyncThunk(
  "auth/dislikeProduct",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response: any = await api.UserService.unlikeProduct(id);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
)

const initialState: AuthState = {
  fullName: undefined,
  firstName: undefined,
  lastName: undefined,
  avatarUrl: undefined,
  userId: undefined,
  profileId: undefined,
  favorites: [],
  userType: userRoles.CONSUMER,
  accessToken: undefined,
  isLoggedIn: false,
  isShowroom: false,
  authLoading: false,
  likedProductsLoading: false,
  pathIndex: memoPaths.default,
  userInitialized: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: (state, action: PayloadAction<{ accessToken: string, userType: UserType }>) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.userType = action.payload.userType;
    },
    setShowroom: (state, action: PayloadAction<boolean>) => {
      state.isShowroom = action.payload;
    },
    setPathIndex: (state, action: PayloadAction<string>) => {
      state.pathIndex = action.payload
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      /* ---- Login ---- */
      .addCase(loginUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<{ token: string, profileId: string, userId: string, userType: UserType }>) => {
        state.authLoading = false;
        state.userId = action.payload.userId;
        state.profileId = action.payload.profileId;
        // state.isLoggedIn = true;
        state.accessToken = action.payload.token;
        state.userType = action.payload.userType;
        // state.pathIndex = ``;
      })
      .addCase(loginUser.rejected, (state) => {
        state.authLoading = false;
      })

      /* ---- Login Guest ---- */
      .addCase(loginGuest.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(loginGuest.fulfilled, (state, action) => {
        state.authLoading = false;
        state.userId = action.payload.userId;
        state.profileId = action.payload.profileId;
        // state.isLoggedIn = true;
        state.accessToken = action.payload.token;
        state.userType = action.payload.userType;
        // state.pathIndex = ``;
      })
      .addCase(loginGuest.rejected, (state) => {
        state.authLoading = false;
      })
      // TODO: make more constants, e.g. like userTypes guest
      /* ---- Get User ---- */
      .addCase(getUserDetails.pending, () => { })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.firstName = action.payload?.profile?.firstname || "Guest";
        state.lastName = action.payload?.profile?.lastname || "";
        state.fullName = state.firstName + " " + state.lastName
        state.avatarUrl = action.payload?.profile?.avatar?.sizes['150']?.url;
        state.favorites = action.payload?.profile?.favorites;
        state.userType = action.payload.role;
        state.userInitialized = true;
      })
      .addCase(getUserDetails.rejected, () => { })

      /* ---- SHARED: End call ---- */
      .addCase(endCallState, (state) => {
        // state.accessToken = initialState.accessToken;
        // state.isLoggedIn = initialState.isLoggedIn;
        // state.isShowroom = initialState.isShowroom;
        // state.userType = initialState.userType;
        // state.userId = initialState.userId;
        // state.profileId = initialState.profileId;
        // state.favorites = initialState.favorites;
        // state.fullName = initialState.fullName;
        // state.firstName = initialState.firstName;
        // state.lastName = initialState.lastName;
        // state.avatarUrl = initialState.avatarUrl;
        // state.pathIndex = initialState.pathIndex;
        // state.userInitialized = initialState.userInitialized;
      })

      /* ---- SHARED: Join call ---- */
      .addCase(joinCallState, (state) => {
        // state.accessToken = initialState.accessToken;
        // state.isLoggedIn = initialState.isLoggedIn;
        // state.isShowroom = initialState.isShowroom;
        // state.userType = initialState.userType;
        // state.userId = initialState.userId;
        // state.profileId = initialState.profileId;
        // state.favorites = initialState.favorites;
        // state.fullName = initialState.fullName;
        // state.firstName = initialState.firstName;
        // state.lastName = initialState.lastName;
        // state.avatarUrl = initialState.avatarUrl;
        // state.userInitialized = initialState.userInitialized;
        state.pathIndex = initialState.pathIndex;
      })

      /* ---- Like Product ---- */
      .addCase(likeProduct.pending, (state) => {
        state.likedProductsLoading = true;
      })
      .addCase(likeProduct.fulfilled, (state, action) => {
        state.likedProductsLoading = false;
        state.favorites = [...action.payload.favorites];
      })
      .addCase(likeProduct.rejected, (state) => {
        state.likedProductsLoading = false;
      })

      /* ---- Unlike Product ---- */
      .addCase(unlikeProduct.pending, (state) => {
        state.likedProductsLoading = true;
      })
      .addCase(unlikeProduct.fulfilled, (state, action) => {
        state.likedProductsLoading = false;
        state.favorites = state.favorites.filter((product) => product._id !== action.meta.arg.id)
      })
      .addCase(unlikeProduct.rejected, (state) => {
        state.likedProductsLoading = false;
      })

      /* ---- SHARED: Log out ---- */
      .addCase(resetState, (state) => {
        state.accessToken = initialState.accessToken;
        state.isLoggedIn = initialState.isLoggedIn;
        state.isShowroom = initialState.isShowroom;
        state.userType = initialState.userType;
        state.userId = initialState.userId;
        state.profileId = initialState.profileId;
        state.favorites = initialState.favorites;
        state.fullName = initialState.fullName;
        state.firstName = initialState.firstName;
        state.lastName = initialState.lastName;
        state.avatarUrl = initialState.avatarUrl;
        state.pathIndex = initialState.pathIndex;
        state.userInitialized = initialState.userInitialized;
      })
  }
})
export const { initAuth, setShowroom, setLoggedIn, setPathIndex } = authSlice.actions;
export default authSlice.reducer