import classnames from "classnames";
import React from "react";
import "./Tile.scss";
interface OverlayTileOptions {
  videoUnavailableMessage: string | null | undefined;
  isMainScreen: boolean;
  name?: string;
}

const OverlayTile: React.FC<OverlayTileOptions> = ({ videoUnavailableMessage, name, isMainScreen }) => {
  return (
    <React.Fragment>
      {videoUnavailableMessage && (
        <div className={classnames('d-flex position-absolute top-50 start-50 translate-middle bg-primary rounded-circle', { 'overlay-user-main': isMainScreen, 'overlay-user': !isMainScreen })}>
          <h1 className="text-white text-center m-auto text-uppercase">{name?.slice(0, 1)}</h1>
        </div>
      )}
    </React.Fragment>
  );
};

export default OverlayTile;
