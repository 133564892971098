import React from "react";
import { CartProduct, CartProductVariant, LookProduct } from "../../interfaces/Product";
import { useAppSelector } from "../../store";
import useProductHandler, { useProductMultiHandler } from "../useProductHandler";

const useCartActions = () => {
  const { cartId, cartProducts, cartLoading } = useAppSelector((state) => state.cart);
  const paymentUrl = useAppSelector((state) => state.order.paymentUrl)
  const handleCartProduct = useProductHandler();
  const handleCartProductMulti = useProductMultiHandler();

  const cartActionsDisabled = React.useMemo(() => {
    return cartLoading || !!paymentUrl
  }, [cartLoading, paymentUrl]);

  const addToCart = React.useCallback(({ baseSku, sku, notes, variants }: { baseSku: string, sku?: string, notes?: string, variants?: CartProductVariant[] }): void => {
    if (cartId) {
      const existingProduct = cartProducts.find((p: CartProduct) => sku ? p.sku === sku : p.baseSku === baseSku)
      handleCartProduct({
        cartId: cartId,
        product: {
          _id: existingProduct?._id,
          baseSku,
          sku: sku,
          qty: existingProduct?.qty ? existingProduct.qty + 1 : 1,
          notes: notes, // TODO: remove
          variants: variants
        }
      });
    }
  }, [cartId, cartProducts, handleCartProduct]);

  const addMultiToCart = React.useCallback((products: LookProduct[]): void => {
    if (cartId) {
      const _products: Omit<CartProduct, 'price'>[] = [];
      let existingProduct: CartProduct | undefined;
      for (let index = 0; index < products.length; index++) {
        existingProduct = cartProducts.find((p: CartProduct) => p?.sku ? p.sku === products[index].sku : p.baseSku === products[index].baseSku);
        _products.push(
          {
            _id: existingProduct?._id,
            baseSku: products[index].baseSku,
            sku: products[index].sku ? products[index].sku : undefined,
            qty: existingProduct?.qty ? existingProduct?.qty + 1 : 1,
            details: {
              product: products[index].details.product,
              attributes: []
            }
          }
        )
      }
      handleCartProductMulti({ cartId, products: _products });
    }
  }, [cartId, cartProducts, handleCartProductMulti]);

  const removeFromCart = React.useCallback((id: string): void => {
    if (cartId) {
      const existingProduct: CartProduct | undefined = cartProducts.find((p: CartProduct) => p._id === id);
      if (existingProduct) {
        handleCartProduct({ cartId: cartId!, product: { ...existingProduct, qty: existingProduct.qty - 1 } });
      } else {
        console.log('Product not found');
      }
    } else {
      console.log('Cart is undefined');
    }
  }, [cartId, handleCartProduct, cartProducts]);


  const deleteFromCart = React.useCallback((id: string): void => {
    if (cartId) {
      const existingProduct: CartProduct | undefined = cartProducts.find((p: CartProduct) => p._id === id);
      if (existingProduct) {
        handleCartProduct({ cartId, product: { ...existingProduct, qty: 0 } });
      } else {
        console.log('Product not found');
      }
    } else {
      console.log('Cart is undefined');
    }
  }, [cartId, handleCartProduct, cartProducts])


  return { addToCart, addMultiToCart, removeFromCart, deleteFromCart, cartActionsDisabled };
}

export default useCartActions;