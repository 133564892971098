export const throttles = { drag: 10, resize: 10, rotate: 0 };

export const badgesDisplayDays = { create: 4, update: 5 };

export const composer = {
  height: 450,
  width: 500,
  offset: 16
}

export const composerRatio = Math.round((composer.width / composer.height) * 1000) / 1000