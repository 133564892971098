import React from 'react'
import { Badge, Button } from 'reactstrap';
import useCatalog from '../../hooks/useCatalog';
import { useAppSelector } from '../../store';
import Typography from '../Typography';

const CatalogSearchTerm: React.FC = () => {
  const { searchParams } = useAppSelector((state) => state.filters);
  const { retrieveCatalog } = useCatalog();

  const handleClearSearchTerm = React.useCallback(() => {
    retrieveCatalog({ searchTerm: '' });
  }, [retrieveCatalog]);

  if (!searchParams.searchTerm) return null;

  return (
    <div className="mt-n2 mb-2 ms-3">
      <Typography Variant="span" text={"GENERIC.LABELS.SEARCH_RESULT_FOR"} />
      {" "}
      <Badge color="light">
        <span className=" me-1 px-1">{searchParams.searchTerm}</span>
        <Button color="" className="px-1 border-start pe-0 py-0" onClick={handleClearSearchTerm}>
          <i className="bx bx-x align-middle" />
        </Button>
      </Badge>
    </div>
  )
}
export default CatalogSearchTerm;