import classNames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { LookDetailsView } from "../../interfaces/Look";
import { useAppSelector } from "../../store";

interface PropTypes {
  activeTab: string | number;
  tabs: { key: LookDetailsView, icon: string }[];
  onToggle: (view: LookDetailsView) => void;
}

const TabIcons: React.FC<PropTypes> = ({ activeTab, tabs, onToggle }) => {
  const { looksUpdating } = useAppSelector((state) => state.looks)

  return (
    <Nav className="icon-tab nav-justified flex-nowrap">
      {tabs.map((tab) => (
        <NavItem key={tab.key}>
          <NavLink disabled={looksUpdating} style={{ cursor: "pointer" }} className={classNames("py-1 px-3", { active: activeTab === tab.key, "opacity-50": looksUpdating })} onClick={() => { onToggle(tab.key); }}>
            <i className={classNames("font-size-16 align-middle", tab.icon)} />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default TabIcons;
